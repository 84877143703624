import React, { forwardRef, ReactNode } from 'react';
import InputMask, { Props } from 'react-input-mask';

import styles from './Input.module.css';
import { withFormField } from '@shared/ui-kit/FormField';

type MaskProps = Partial<Pick<Props, 'mask' | 'maskChar' | 'maskPlaceholder'>>;

type ConventionalInputElementProps = Pick<Props, 'value' | 'onChange' | 'placeholder' | 'maxLength' | 'id'> & MaskProps;

export interface InputProps extends ConventionalInputElementProps {
  icon?: ReactNode;
  className?: string;
  type?: 'text' | 'password' | 'tel';
  size?: 'medium' | 'large';
  borderRadius?: 'medium' | 'large';
}

export const Input = forwardRef<HTMLInputElement, InputProps>(
  ({ icon = null, size = 'medium', borderRadius = 'large', mask, className, ...rest }, ref) => {
    return (
      <div className={`${styles['input-container']} ${icon ? styles['input-container_with-icon'] : ''} ${className}`}>
        <InputMask
          inputRef={ref}
          {...rest}
          mask={mask ?? ''}
          className={[styles['input'], size === 'large' && styles['input_size-large']].filter(Boolean).join(' ')}
          style={{ borderRadius: borderRadius === 'large' ? '25px' : '15px' }}
        />
        {icon && <div className={styles['icon']}>{icon}</div>}
      </div>
    );
  },
);

export const InputFormField = withFormField(Input);
