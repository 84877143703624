import { InputFormField } from '@ui-kit/Input/Input.component';
import { FormDatePicker } from '@ui-kit/DatePicker/DatePicker.component';
import { FormToggleGroup } from '@ui-kit/ToggleGroup/ToggleGroup';
import { DocumentsForm } from '@modules/documents/types/documents.types';
import { ElementType } from 'react';

type FormInputsData = {
  id: keyof DocumentsForm;
  label: string;
  baseInput: ElementType;
  required: boolean;
  mask?: string;
};

export const PASSPORT_FORM_INPUTS_DATA: Array<FormInputsData> = [
  {
    id: 'issuerName',
    label: 'Кем выдан',
    baseInput: InputFormField,
    required: true,
  },
  {
    id: 'serial',
    label: 'Серия',
    baseInput: InputFormField,
    required: true,
  },
  {
    id: 'number',
    label: 'Номер',
    baseInput: InputFormField,
    required: true,
  },
  {
    id: 'issuedAt',
    label: 'Дата выдачи',
    baseInput: FormDatePicker,
    required: true,
  },
  {
    id: 'issuerId',
    label: 'Код подразделения',
    baseInput: InputFormField,
    mask: '999-999',
    required: true,
  },
  {
    id: 'name',
    label: 'Имя',
    baseInput: InputFormField,
    required: true,
  },
  {
    id: 'surname',
    label: 'Фамилия',
    baseInput: InputFormField,
    required: true,
  },
  {
    id: 'patronymic',
    label: 'Отчество',
    baseInput: InputFormField,
    required: false,
  },
  {
    id: 'residence',
    label: 'Место рождения',
    baseInput: InputFormField,
    required: true,
  },
  {
    id: 'gender',
    label: 'Пол',
    baseInput: FormToggleGroup,
    required: true,
  },
  {
    id: 'birthday',
    label: 'Дата рождения',
    baseInput: FormDatePicker,
    required: true,
  },
];
