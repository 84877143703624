import { studentApiClient } from '@shared/api/client/client';
import { ApiSuccessResponse } from '@shared/api/client/responses/ApiSuccessResponse';
import {
  StudentGetTotalCountOfPaidSoloCoursesResponseSchema,
  StudentListPaidSoloCoursesRequestSchema,
  StudentListPaidSoloCoursesResponseSchema,
} from '@generated-student';

export class CoursesService {
  static getListPaidSoloCourses(data: StudentListPaidSoloCoursesRequestSchema) {
    return studentApiClient.post<ApiSuccessResponse<StudentListPaidSoloCoursesResponseSchema>>(
      'listPaidSoloCourses',
      data,
    );
  }
  static getTotalCountOfPaidSoloCourses() {
    return studentApiClient.post<ApiSuccessResponse<StudentGetTotalCountOfPaidSoloCoursesResponseSchema>>(
      'getTotalCountOfPaidSoloCourses',
    );
  }
}
