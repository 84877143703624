import * as yup from 'yup';

const NONE_DIGIT_EXP = [/^\D*$/, 'Поле не может содержать цифр'] as const;

export const nameValidator = yup
  .string()
  .required('Введите имя')
  .matches(...NONE_DIGIT_EXP);

export const surnameValidator = yup
  .string()
  .required('Введите фамилию')
  .matches(...NONE_DIGIT_EXP);

export const patronymicValidator = yup
  .string()
  .notRequired()
  .matches(...NONE_DIGIT_EXP);
