import React, { FC, useCallback, useEffect, useRef, useState } from 'react';
import styles from './SplashScreen.module.css';
import { ReactComponent as SplashLogoSvg } from '@svg/immutable/splash-logo.svg';

export type SplashScreenProps = {
  active: boolean;
};

export const SplashScreen: FC<SplashScreenProps> = ({ active }) => {
  const ref = useRef<HTMLDivElement>(null);
  const [visible, setVisible] = useState(active);

  const transitionEnd = useCallback(() => {
    if (!active && visible) {
      setVisible(false);
    }
  }, [active, visible]);

  useEffect(() => {
    if (active && !visible) {
      setVisible(true);
    }
  }, [active, visible]);

  useEffect(() => {
    const element = ref.current;
    if (!element) {
      return;
    }

    const endListener = () => {
      transitionEnd();
      element.removeEventListener('transitionend', endListener);
    };

    element.addEventListener('transitionend', endListener);
    return () => {
      element.removeEventListener('transitionend', endListener);
    };
  }, [transitionEnd]);

  const classList = [styles['splashscreen']];
  active ? classList.push(styles['splashscreen--active']) : classList.push(styles['splashscreen--inactive']);

  return (
    <div ref={ref} className={classList.join(' ')} style={{ visibility: visible ? 'visible' : 'hidden' }}>
      <SplashLogoSvg width={'auto'} height={'50%'} />
    </div>
  );
};
