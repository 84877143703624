import React, { FC, ReactNode } from 'react';
import { NavLink, NavLinkProps } from 'react-router-dom';

export interface NavItemProps {
  children?: ReactNode;
  route: string;
  onClickNavLink?: () => void;
  className: string;
  activeClassName: string;
}

export const NavItem: FC<NavItemProps> = ({ route, children, onClickNavLink, className, activeClassName }) => {
  const getNavLinkClassname: NavLinkProps['className'] = ({ isActive }) => {
    const navLinkClasses = [className];
    if (isActive) {
      navLinkClasses.push(activeClassName);
    }
    return navLinkClasses.join(' ');
  };

  return (
    <NavLink to={route} className={getNavLinkClassname} onClick={onClickNavLink}>
      {children}
    </NavLink>
  );
};
