import React from 'react';
import Wrong from '@svg/solid/wrong.svg';
import { Button } from '@ui-kit/Button/Button.component';

const Fail = ({ onClick }: { onClick: () => void }) => {
  return (
    <div className={'flex flex-col items-center gap-[32px] w-[429px] justify-center'}>
      <img alt={'ERROR'} src={Wrong} className={'w-[100px] h-[100px]'} />
      <div className={'text-center'}>
        <h4 className={'pb-[20px]'}>Ошибка оплаты</h4>
        <p>Что-то пошло не так</p>
      </div>
      <Button onClick={onClick} variant="outlined">
        Попробовать ещё раз
      </Button>
    </div>
  );
};

export default Fail;
