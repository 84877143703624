import { createEffect, createEvent, createStore, sample } from 'effector';
import {
  StudentCreateOrderCreated,
  StudentCreateOrderExisting,
  StudentCreateOrderWithEmptyModuleIdsRequestSchema,
  StudentCreateOrderWithEmptySoloCourseIdsRequestSchema,
  StudentGetOrderStatusResponseSchema,
} from '@generated-student';
import { ApiThrownError } from '@shared/api/client/responses/ApiErrorFactory';
import { PaymentService } from '@shared/api/client/services/Payment';

export const $Order = createStore<StudentCreateOrderCreated | StudentCreateOrderExisting | null>(null);
export const orderReset = createEvent();

export const $bankCardIdStore = createStore<string | null>(null);
export const setBankCardId = createEvent<{ id: string | null }>();

export const $payUrl = createStore<null | string>(null);
export const payUrlReset = createEvent();

export const createOrderFx = createEffect<
  StudentCreateOrderWithEmptyModuleIdsRequestSchema | StudentCreateOrderWithEmptySoloCourseIdsRequestSchema,
  StudentCreateOrderCreated | StudentCreateOrderExisting,
  ApiThrownError
>(async (params) => {
  const {
    data: { data },
  } = await PaymentService.createOrder(params);
  return data;
});

export const getOrderStatusFx = createEffect<{ id: string }, StudentGetOrderStatusResponseSchema, ApiThrownError>(
  async (params) => {
    await new Promise((resolve) => {
      setTimeout(() => {
        resolve(1);
      }, 2000);
    });
    const {
      data: { data },
    } = await PaymentService.getOrderStatus({ id: params.id });
    return data;
  },
);

export const getOrderStatusPureFx = createEffect<{ id: string }, StudentGetOrderStatusResponseSchema, ApiThrownError>(
  async (params) => {
    const {
      data: { data },
    } = await PaymentService.getOrderStatus({ id: params.id });
    return data;
  },
);

$bankCardIdStore.on(setBankCardId, (_, payload) => {
  return payload.id;
});

$payUrl
  .on(getOrderStatusFx.doneData, (state, payload) => {
    if (payload.status === 'payment_form_initializing_succeeded' && state !== payload.paymentOrderUrl) {
      return payload.paymentOrderUrl;
    }
    return state;
  })
  .reset(payUrlReset);

$Order.on(orderReset, () => {
  return null;
});

sample({
  source: createOrderFx.doneData,
  fn: ({ orderId }) => {
    return { id: orderId };
  },
  target: getOrderStatusFx,
});

sample({
  source: createOrderFx.doneData,
  target: $Order,
});
