import React, { useEffect, useRef } from 'react';
import { ModuleList } from '@modules/module/components/ModuleList/ModuleList.component';
import { ModulesAmount } from '@modules/module/components/ModulesAmount/ModulesAmount.component';
import { Header } from '@ui-kit/Header/Header.component';
import {
  $ModulesList,
  $Offset,
  getModules,
  getModulesListFx,
  resetModulesList,
  resetOffset,
} from '@modules/module/stores/modules-list.store';
import { useNavigate, useParams } from 'react-router-dom';
import { $Course, getCourseFx, resetCourse } from '@modules/user-course/stores/course.store';
import { useStore, useUnit } from 'effector-react';
import { ENTITY_IDS, NAVIGATION } from '@shared/constants';
import { ModuleViewModelType } from '@modules/module/types/module.types';
import { CoursePreviewCard } from '@modules/course/components/CoursePreviewCard/CoursePreviewCard.component';
import styles from './UserCourse.module.css';
import { useIsFirstRender } from '@shared/hooks/useIsFirstRender';

/**
 * Страница образовательной программы
 */
export const UserCoursePage = () => {
  const { courseId } = useParams();
  const pageWrapperRef = useRef<HTMLDivElement>(null);
  const navigate = useNavigate();
  const { itemsAmount } = useStore($ModulesList);

  const isLoading = useUnit(getModulesListFx.pending);

  const isFirstRender = useIsFirstRender();

  const userCourse = useStore($Course);
  const offset = useStore($Offset);

  const handleClickModule = (moduleId: string, type: ModuleViewModelType) => {
    if (!courseId) return;

    let urlToRedirect = '';

    //Если модуль не куплен - переводим юзера на страницу покупки
    if (type === 'not_purchased_module') {
      urlToRedirect = NAVIGATION.studentCourseModulePurchase
        .replace(`:${ENTITY_IDS.courseId}`, courseId)
        .replace(`:${ENTITY_IDS.moduleId}`, moduleId);
    }

    //Если модуль куплен - переводим юзера к прохождению модуля
    if (type === 'purchased_module') {
      urlToRedirect = NAVIGATION.studentUserCourseModule
        .replace(`:${ENTITY_IDS.courseId}`, courseId)
        .replace(`:${ENTITY_IDS.moduleId}`, moduleId);
    }

    if (!urlToRedirect) return;

    navigate(urlToRedirect);
  };

  useEffect(() => {
    const fetchInitialData = async () => {
      if (courseId) {
        await getCourseFx(courseId);

        getModules(courseId);
      }
    };

    fetchInitialData();

    return () => {
      resetModulesList();
      resetCourse();
      resetOffset();
    };
  }, []);

  useEffect(() => {
    if (!isFirstRender && courseId) {
      getModules(courseId);
    }
  }, [offset]);

  return (
    <div className={styles['user-course']} ref={pageWrapperRef}>
      <Header headerText={''} redirectTo={NAVIGATION.studentUserCourses} />
      {!isLoading && (
        <section className={styles[itemsAmount === 1 ? 'user-course-content-alt' : 'user-course-content']}>
          {userCourse && (
            <CoursePreviewCard
              author={userCourse.author}
              studyHours={userCourse.studyHours}
              coverUrl={userCourse.coverUrl}
              progress={userCourse.progress}
              description={userCourse.description}
            />
          )}

          <div className={'flex flex-col gap-y-[20px] w-full'}>
            <ModulesAmount />
            <ModuleList wrapperRef={pageWrapperRef} withButton={true} onClick={handleClickModule} />
          </div>
        </section>
      )}
    </div>
  );
};
