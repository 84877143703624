import {
  StudentGetCertificateResponseSchema,
  StudentListAllCertificatesItem,
  StudentListAllCertificatesResponseSchema,
} from '@generated-student';
import {
  ListAllCertificatesViewModel,
  UserCertificateViewModel,
} from '@modules/user-certificate/types/user-certificate.types';
import dayjs from 'dayjs';
import { YEAR_MONTH_DAY_DATE_FORMAT } from '@shared/constants/date';

export class ApiToViewMapper {
  static _getCertificate({ certificate, soloCourse }: StudentListAllCertificatesItem): UserCertificateViewModel {
    const dateOfIssue = dayjs(certificate.dateOfIssue).format(YEAR_MONTH_DAY_DATE_FORMAT);

    return {
      certificate: { dateOfIssue, id: certificate.id, pdf: certificate.pdf },
      soloCourse: { ...soloCourse, cover: `//${soloCourse.cover.sizes.original.url}` },
    };
  }

  static getListAllCertificatesViewModel(
    apiModel: StudentListAllCertificatesResponseSchema,
  ): ListAllCertificatesViewModel {
    return apiModel.map(this._getCertificate);
  }

  static getCertificateViewModel(apiModel: StudentGetCertificateResponseSchema): UserCertificateViewModel {
    return this._getCertificate(apiModel);
  }
}
