import { ReactComponent as GraduationCapSvg } from '@svg/solid/graduationCap.svg';
import { ReactComponent as CalendarSvg } from '@svg/solid/calendar.svg';
import { ReactComponent as CartSvg } from '@svg/solid/cart.svg';
import { NAVIGATION } from '@shared/constants';
import { SubNavigationProfile } from '@ui-kit/Sidebar/SubNavigation/SubNavigationProfile/SubNavigationProfile.component';
import { SubNavigationEducation } from '@ui-kit/Sidebar/SubNavigation/SubNavigationEducation/SubNavigationEducation.component';
import { SubNavigationNotification } from '@ui-kit/Sidebar/SubNavigation/SubNavigationNotification/SubNavigationNotification.component';
import { SubNavigationSettings } from '@ui-kit/Sidebar/SubNavigation/SubNavigationSettings/SubNavigationSettings.component';
import { SubNavigationCalendar } from '@ui-kit/Sidebar/SubNavigation/SubNavigationCalendar/SubNavigationCalendar.component';
import { SubNavigationCart } from './SubNavigation/SubNavigationCart/SubNavigationCart.component';

export const STUDENT_NAVIGATION_ITEMS = [
  {
    logo: GraduationCapSvg,
    route: NAVIGATION.studentEducation,
  },

  {
    logo: CartSvg,
    route: NAVIGATION.studentCart,
  },

  // TODO В текущей версии не используется
  // {
  //   logo: CalendarSvg,
  //   route: NAVIGATION.studentCalendar,
  // },
  // {
  //   logo: NotificationSvg,
  //   route: NAVIGATION.studentNotification,
  // },
  // {
  //   logo: SettingsSvg,
  //   route: NAVIGATION.studentSettings,
  // },
];

export const TEACHER_NAVIGATION_ITEMS = [
  {
    logo: CalendarSvg,
    route: NAVIGATION.teacherCalendar,
  },
  {
    logo: GraduationCapSvg,
    route: NAVIGATION.teacherEducation,
  },
];

/* Объект мапит роуты NAVIGATION_ITEMS, используемые в сайдбаре для внешней, основной навигации (левая колонка)
 * к компоненту внутренней навигации сайдбара
 */
export const SLIDER_PATH_TO_INNER_NAV_COMPONENT_MAPPER = {
  [NAVIGATION.studentProfile]: SubNavigationProfile,
  [NAVIGATION.studentEducation]: SubNavigationEducation,
  [NAVIGATION.studentNotification]: SubNavigationNotification,
  [NAVIGATION.studentSettings]: SubNavigationSettings,
  [NAVIGATION.studentCalendar]: SubNavigationCalendar,
  [NAVIGATION.studentCart]: SubNavigationCart,
};
