import React from 'react';
import PasswordChangeForm from '@modules/password/components/PasswordChangeForm.component';
import styles from './Password.module.css';
import { updatePasswordFx } from '@shared/stores/password.store';
import { showSuccessModalEvent } from '@ui-kit/Modal/ModalResult/ModalResult.store';
const PasswordPage = () => {
  const onSubmit = async (password: string) => {
    await updatePasswordFx(password);
    showSuccessModalEvent({
      description: 'Пароль успешно обновлен',
    });
  };

  return (
    <div className={styles['passwordPage']}>
      <div className={styles['substrate']}>
        <PasswordChangeForm onSubmit={onSubmit} />
      </div>
    </div>
  );
};

export default PasswordPage;
