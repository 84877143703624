import React, { FC } from 'react';
import styles from './ModuleCard.module.css';
import { MODULE_STATUS_VIEW_BY_STATUS } from './ModuleCard.constants';
import { ReactComponent as BookIcon } from '@svg/outline/book.svg';
import { NotPurchasedModuleViewModel } from '@modules/module/types/module.types';
import { toRubles } from '@shared/utils/toRubles';
import { Button } from '@ui-kit/Button/Button.component';
import { CartStoreModule } from '@shared/types/cart.types';

type NotPurchasedModuleCardProps = NotPurchasedModuleViewModel & {
  withButton: boolean;
  onRemoveFromCart?: (moduleId: string) => void;
  onAddToCart?: (module: CartStoreModule) => void;
  inCart?: boolean;
};

/**
 * Компонент карточки неоплаченного модуля
 */
export const NotPurchasedModuleCard: FC<NotPurchasedModuleCardProps> = ({
  price,
  title,
  description,
  id,
  discountedPrice,
  discount,
  withButton,
  type,
  inCart,
  onAddToCart,
  onRemoveFromCart,
}) => {
  const hasDiscount = Number(discount) > 0;
  const priceText = toRubles(Number(price));
  const discountText = toRubles(Number(discountedPrice));

  const handleClick = () => {
    return inCart ? onRemoveFromCart?.(id) : onAddToCart?.({ id, type });
  };

  return (
    <>
      <div className={styles['module-card']}>
        <div className="flex flex-col pt-[12px] w-full">
          <div className="flex items-center justify-between mb-[14px]">
            <div className="flex items-center">
              <BookIcon className="w-[30px] h-[30px] fill-blue-800 mr-[10px]" />
              <span className="text-14-regular text-grey-70 m:text-18-regular">{title}</span>
            </div>
            <div className="flex items-center">
              <span className="text-16-regular text-grey-70">{MODULE_STATUS_VIEW_BY_STATUS['unavailable'].title}</span>
              <div className="ml-[10px]">{MODULE_STATUS_VIEW_BY_STATUS['unavailable'].icon}</div>
            </div>
          </div>
          <div className="mb-[14px]">
            <h6 className={styles['module-card__title']}>{description}</h6>
          </div>
          {withButton && (
            <div className="flex items-center gap-x-[10px]">
              <div className="flex items-center gap-x-[7px] text-18-bold text-grey-70">
                {hasDiscount ? (
                  <>
                    <s className="text-18-regular text-grey-75">
                      <span>{priceText}</span>
                    </s>
                    <span>{discountText}</span>
                  </>
                ) : (
                  <span>{priceText}</span>
                )}
              </div>

              <Button onClick={handleClick} variant="outlined" className={'text-s font-bold h-[40px]'}>
                {inCart ? 'Удалить из корзины' : 'Положить в корзину'}
              </Button>
            </div>
          )}
        </div>
      </div>
    </>
  );
};
