import {
  components,
  ControlProps,
  DropdownIndicatorProps,
  MenuListProps,
  MenuProps,
  MultiValueProps,
  OptionProps,
  PlaceholderProps,
} from 'react-select';
import { ReactComponent as ArrowIcon } from '@svg/outline/downSmall.svg';
import { Checkbox } from '@shared/ui-kit/Checkbox/Checkbox.component';
import { ReactComponent as Search } from '@svg/outline/search.svg';
import { OptionType } from './MultiSelect.component';

export const DropdownIndicator = (props: DropdownIndicatorProps<OptionType>) => {
  const menuIsOpen = props.selectProps.menuIsOpen;
  const rotateDegree = menuIsOpen ? 0 : 180;

  return (
    <components.DropdownIndicator {...props}>
      <ArrowIcon
        style={{
          fill: '#39B4FF',
          transform: `rotate(${rotateDegree}deg)`,
          transition: 'transform 0.12s ease',
        }}
      />
    </components.DropdownIndicator>
  );
};

export const MultiValue = (props: MultiValueProps<OptionType>) => {
  const values = props.getValue().length;
  return <>{!props.index && <span className={'text-grey-50 text-16-regular'}>{`Выбрано: ${values} `}</span>}</>;
};

export const Option = (props: OptionProps<OptionType>) => {
  return (
    <components.Option className={'flex p-[0px_30px_0px_15px] gap-[15px] cursor-pointer'} {...props}>
      <Checkbox
        checked={props.isSelected}
        onChange={() => {
          return;
        }}
      />
      <span className={'font-body-medium text-secondary truncate'}>{props.data.label}</span>
    </components.Option>
  );
};

export const Menu = (props: MenuProps<OptionType>) => {
  return (
    <components.Menu
      className={
        'flex w-[350px] flex-col items-start bg-white p-[20px_5px] gap-[10px] rounded-[25px] border border-opacity-white-24 z-[999] shadow-big'
      }
      {...props}
    >
      {props.children}
    </components.Menu>
  );
};

export const MenuList = (props: MenuListProps<OptionType>) => {
  return (
    <components.MenuList className={'flex w-full flex-col items-start gap-4'} {...props}>
      {props.children}
    </components.MenuList>
  );
};

export const Control = (props: ControlProps<OptionType>) => {
  return (
    <components.Control
      className={
        'flex h-[50px] px-2 items-center gap-[10px] rounded-[25px] border border-opacity-darkBlue-10 bg-blue-500 shadow-big mb-[10px] truncate'
      }
      {...props}
    >
      <Search color="#39B4FF" />
      {props.children}
    </components.Control>
  );
};

export const Placeholder = (props: PlaceholderProps<OptionType>) => {
  return (
    <components.Placeholder {...props}>
      <span className={'text-grey-50 text-16-regular'}>{props.children}</span>
    </components.Placeholder>
  );
};
