import { studentApiClient } from '@shared/api/client/client';
import { ApiSuccessResponse } from '@shared/api/client/responses/ApiSuccessResponse';
import {
  StudentCreatePassportRequestSchema,
  StudentGetPassportResponseSchema,
  StudentUpdatePassportRequestSchema,
} from '@generated-student';

export class DocumentsService {
  static createPassport(data: StudentCreatePassportRequestSchema) {
    return studentApiClient.post<ApiSuccessResponse<void>>('createPassport', data);
  }

  static updatePassport(data: StudentUpdatePassportRequestSchema) {
    return studentApiClient.post<ApiSuccessResponse<void>>('updatePassport', data);
  }

  static getPassport() {
    return studentApiClient.post<ApiSuccessResponse<StudentGetPassportResponseSchema | null>>('getPassport');
  }
}
