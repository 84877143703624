import React from 'react';
import styles from './ModulesAmount.module.css';
import { useStore } from 'effector-react';
import { $ModulesList } from '../../stores/modules-list.store';

/** Компонент отображает кол-во модулей */
export const ModulesAmount = () => {
  const { itemsAmount } = useStore($ModulesList);

  return (
    <div className="flex items-center">
      <h6 className={'text-grey-90 ml-[20px]'}>Модулей курса:</h6>
      <div
        className={`rounded-[50px] w-[52px] h-[34px]
                 ml-[10px] bg-white border-[1px] border-solid border-blue-500 flex justify-center items-center`}
      >
        <span className={styles['modules-amount']}>{itemsAmount}</span>
      </div>
    </div>
  );
};
