import React, { FC } from 'react';
import { Progress } from '@ui-kit/Progress/Progress.component';
import styles from './EntityProgress.module.css';
import { ReactComponent as PercentIcon } from '@svg/outline/percent.svg';
import { ReactComponent as TimeIcon } from '@svg/outline/time.svg';

type EntityProgressProps = {
  progress?: number;
  hours?: number;
};

/**
 * Компонент отображает прогресс прохождения какой либо сущности
 */
export const EntityProgress: FC<EntityProgressProps> = ({ progress, hours }) => {
  progress = progress || 0;

  const hoursText = hours !== undefined && !isNaN(hours) ? `${hours}. ч. ` : '';

  return (
    <div className="flex flex-col">
      <div className="mb-[15px]">
        <Progress className={styles['progress']} value={progress} />
      </div>
      <div className="flex justify-between items-center py-[5px]">
        <h5 className="flex items-center text-grey-70">
          {progress}
          <PercentIcon className="ml-[5px] fill-blue-800" />
        </h5>

        {hoursText && (
          <h5 className="flex items-center text-grey-70">
            {hoursText}
            <TimeIcon className="ml-[5px] fill-blue-800" />
          </h5>
        )}
      </div>
    </div>
  );
};
