import { useEvent, useStore } from 'effector-react';
import {
  $UserListAllCertificatesStore,
  getListAllCertificatesFx,
  resetUserCertificates,
} from '@modules/user-certificate/stores/user-list-all-certificates.store';
import { useEffect } from 'react';

export const useUserCertificatesPage = () => {
  const getListAllCertificates = useEvent(getListAllCertificatesFx);
  const certificates = useStore($UserListAllCertificatesStore);

  useEffect(() => {
    getListAllCertificates();

    return () => resetUserCertificates();
  }, []);
  return { certificates };
};
