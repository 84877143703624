import { privateApiClient } from '@shared/api/client/client';
import { ApiSuccessResponse } from '@shared/api/client/responses/ApiSuccessResponse';
import { AccountContacts, AccountEmailRequestSchema } from '@generated-student';

export class AccountService {
  static getContactData() {
    return privateApiClient.post<ApiSuccessResponse<AccountContacts>>('/getContacts');
  }

  static updateEmail(data: AccountEmailRequestSchema) {
    return privateApiClient.post<ApiSuccessResponse>('/updateEmail', data);
  }
}
