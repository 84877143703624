import React, { FC } from 'react';
import { ExerciseContentArticle } from '@modules/exercise/types/exercise.types';
import { Markdown } from '@shared/ui-kit/Markdown/Markdown.component';

type BlockArticleProps = {
  data: ExerciseContentArticle;
};

export const BlockArticle: FC<BlockArticleProps> = ({ data }) => {
  const { title, text } = data;

  return (
    <div className={'flex flex-col gap-y-[40px]'}>
      <h4>{title}</h4>
      <Markdown>{text}</Markdown>
    </div>
  );
};
