import { ApiError } from '@shared/api/client/responses/ApiErrorFactory';

type ErrorType = typeof ApiError.prototype.data.type;
type ErrorTranslator<T extends ErrorType> = (err: ApiError & { data: { type: T } }) => string;

type ApiErrorTranslateMap<T extends ErrorType = ErrorType> = {
  [key in T]: ErrorTranslator<key> | string;
};

const API_ERROR_TRANSLATE: ApiErrorTranslateMap = {
  access_token_expired: 'Время жизни токена истекло',
  invalid_email_or_password: 'Неверный email или пароль',
  invalid_access_token: 'Неверный токен',
  invalid_birthday: 'Неверная дата рождения',
  discount_is_more_than_price: 'Неверная цена',
  admin_profile_does_not_exist: 'Профиль администратора не существует',
  entity_is_archived: 'Сущность находится в архиве',
  entity_is_not_archived: 'Cущность не заархивирована',
  entity_not_found_by_uuid: 'Сущность не найдена по ID',
  too_many_requests(err) {
    const endTime = new Date(err.data.retryAfter);
    const now = new Date();
    const diff = endTime.getTime() - now.getTime();
    const seconds = diff / 1000;
    return `Слишком много запросов. Повторите через ${seconds} секунд.`;
  },
  invalid_refresh_token: 'Неверный refresh-токен',
  invalid_test_reference: 'Вопрос не относится к тесту',
  option_ids_are_not_unique: 'Идентификаторы вариантов ответов не уникальны',
  document_does_not_exist: 'Документ не существует',
  solo_course_is_not_completed:
    'У самостоятельного курса есть не заполненные модули и/или не заполненные задания модуля',
  solo_course_price_is_not_specified: 'Не указана стоимость курса',
  too_few_entities_in_list: 'Слишком мало сущностей в списке',
  too_few_matchings: 'Слишком мало сопоставлений',
  first_part_ids_are_not_unique: 'Идентификаторы первых частей сопоставлений не уникальны',
  second_part_ids_are_not_unique: 'Идентификаторы вторых частей сопоставлений не уникальны',
  email_already_taken: 'Почта уже занята',
  phone_already_taken: 'Номер телефона уже занят',
  invalid_passport_issued_at: 'Дата выдачи паспорта больше чем текущая дата.',
  too_few_answers: 'Слишком мало ответов',
  question_ids_are_not_unique: 'Идентификаторы вопросов не уникальны',
  entity_is_purchased: 'Сущность приобретена (куплена)',
  wrong_confirmation_code: 'Неверный код подтверждения',
  invalid_testing: 'Не корректно заполнен тест',
  solo_course_is_empty: 'У самостоятельного курса нет модулей',
  concurrent_access_to_entity_api_problem: 'Одновременное изменение сущности двумя запросами',
  document_exists: 'Документ существует',
  entities_are_linked: 'Сущности связаны',
  entities_are_not_linked: 'Сущности не связаны',
  entity_is_in_list: 'Сущность есть в списке',
  entity_is_not_in_list: 'Сущность в списке отсутствует',
  entity_is_not_published: 'Сущность не опубликована',
  entity_is_published: 'Сущность опубликована',
  invalid_position: 'Некорректная позиция',
  invalid_price_range: 'Некорректные значения интервала стоимости',
  invalid_publication_dates_range_of_solo_course: 'Некорректные значения начала/конца курса',
  invalid_diploma_year_of_issue: 'Год вручения диплома больше текущего года',
  module_is_linked_to_another_course: 'Модуль привязан к другому курсу',
  student_profile_does_not_exist: 'Отсутствует профиль студента',
  solo_course_discount_is_not_specified: 'Не указана скидка курса',
  test_is_passed: 'Тест пройден.',
  password_only_access_available: 'Вход в систему доступен только по паролю.',
  account_must_have_password: 'У аккаунта должен быть установлен пароль.',
  entity_cannot_be_purchased: 'Сущность не может быть оплачена',
  payment_order_has_conflicted_items: 'Можно оплатить либо курс, либо его модули',
  invalid_password: 'Неверный пароль',
} as const;

export class ApiErrorTranslate {
  public static translateError(err: ApiError) {
    const translator = API_ERROR_TRANSLATE[err.data.type];

    if (typeof translator === 'function') {
      return translator(err as never);
    }

    return translator;
  }
}
