import { combine, createEffect, createEvent, createStore, merge, sample } from 'effector';
import {
  StudentListPaidSoloCoursesItemResponseSchema,
  StudentListPaidSoloCoursesResponseSchema,
} from '@generated-student';
import { CoursesService } from '@shared/api/client/services/Courses';
import { ApiThrownError } from '@shared/api/client/responses/ApiErrorFactory';
import { handleError } from '@shared/handle-error/handleError';
import { DEFAULT_PAGE_SIZE } from '@shared/constants/pagination';

type Cursor = string | null;

type UserCoursesCount = {
  total: number;
  passed: number;
  notPassed: number;
};

type GetUserCoursesParams = {
  cursor: Cursor;
  isPassed: boolean | null;
};
export const $UserCourses = createStore<Array<StudentListPaidSoloCoursesItemResponseSchema>>([]);
export const getUserCourses = createEvent();
export const resetUserCourses = createEvent();

export const $Cursor = createStore<Cursor>(null);

export const $UserCoursesCount = createStore<UserCoursesCount>({ notPassed: 0, passed: 0, total: 0 });

export const $IsPassedUserCourses = createStore<boolean | null>(null);
export const setIsPassedUserCourses = createEvent<'all' | 'passed' | 'active'>();

export const getUserCoursesFx = createEffect<
  GetUserCoursesParams,
  StudentListPaidSoloCoursesResponseSchema,
  ApiThrownError
>(async ({ cursor, isPassed }) => {
  const {
    data: { data },
  } = await CoursesService.getListPaidSoloCourses({
    filter: {
      isPassed: isPassed,
    },
    pagination: {
      amount: DEFAULT_PAGE_SIZE,
      cursor: cursor,
    },
  });
  return data;
});

export const getTotalCountOfPaidSoloCoursesFx = createEffect(async () => {
  const { data } = await CoursesService.getTotalCountOfPaidSoloCourses();
  return data.data;
});

getUserCoursesFx.failData.watch((error) => {
  handleError(error);
});

getTotalCountOfPaidSoloCoursesFx.failData.watch((error) => {
  handleError(error);
});

$UserCoursesCount.on(getTotalCountOfPaidSoloCoursesFx.doneData, (state, payload) => {
  return payload;
});

$Cursor
  .on(getUserCoursesFx.doneData, (state, payload) => {
    return payload.cursor;
  })
  .reset(resetUserCourses);

$UserCourses
  .on(getUserCoursesFx.doneData, (store, payload) => {
    return store.concat(payload.items);
  })
  .reset([resetUserCourses, setIsPassedUserCourses]);

$IsPassedUserCourses
  .on(setIsPassedUserCourses, (state, payload) => {
    return payload === 'all' ? null : payload === 'passed';
  })
  .reset(resetUserCourses);

const clock = merge([setIsPassedUserCourses, getUserCourses]);
const source = combine({ cursor: $Cursor, isPassed: $IsPassedUserCourses });

sample({
  clock,
  source,
  target: [getUserCoursesFx, getTotalCountOfPaidSoloCoursesFx],
});
