import {
  StudentGetSoloCourseResponseSchema,
  StudentListAllExerciseAttachmentsInSoloCourseResponseSchema,
} from '@generated-student';
import {
  AttachmentWithFileViewModel,
  CourseAttachmentsViewModel,
  ExercisesAttachments,
} from '@modules/user-course/types/user-course-attachments.types';
import { UserCourseViewModel } from '@modules/user-course/types/user-course.types';
import { FileType } from '@shared/types/file.types';

export class MapperApiToViewMapper {
  static getDocumentsViewModel(
    apiModel: StudentListAllExerciseAttachmentsInSoloCourseResponseSchema,
  ): CourseAttachmentsViewModel {
    const courseAttachmentsViewModel = {
      modules: apiModel.modules.map((module) => ({
        ...module,
        exercises: module.exercises.map((exercise) => ({
          ...exercise,
          attachments: exercise.attachments.reduce<ExercisesAttachments>(
            (acc, attachment) => {
              if (attachment.type === 'file') {
                const mappedFileAttachment: AttachmentWithFileViewModel = {
                  ...attachment,
                  file: { ...attachment.file, type: attachment.file.mimeType.split('/')[1] as FileType },
                };

                return {
                  ...acc,
                  exerciseAttachmentWithFile: [...acc.exerciseAttachmentWithFile, mappedFileAttachment],
                };
              }
              if (attachment.type === 'external_link') {
                return { ...acc, attachmentWithExternalLink: [...acc.attachmentWithExternalLink, attachment] };
              }
              return acc;
            },
            {
              attachmentWithExternalLink: [],
              exerciseAttachmentWithFile: [],
            },
          ),
        })),
      })),
    };
    return courseAttachmentsViewModel;
  }

  static getCourse(responseData: StudentGetSoloCourseResponseSchema): UserCourseViewModel {
    const progress = Math.round((responseData.studyHours.passed / responseData.studyHours.total) * 100);

    return {
      id: responseData.id,
      description: responseData.longDescription,
      coverUrl: `//${responseData.cover.sizes.original.url}`,
      studyHours: responseData.studyHours.total,
      title: responseData.title,
      progress,
      isCompleted: responseData.studyHours.passed === responseData.studyHours.total,
      author: responseData.author,
    };
  }
}
