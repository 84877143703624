import React, { memo } from 'react';
import { ReactComponent as Receipt } from '@svg/outline/receipt.svg';

export type Course = { cover: string | null; name: string; type: string };

export type Payment = {
  id: string;
  createdAt: string;
  sum: string;
  receipt: string | null;
  course: Course;
};

export const PaymentOperation = memo((payment: Payment) => {
  PaymentOperation.displayName = 'PaymentOperation';
  const { course, sum, createdAt } = payment;

  return (
    <li className={'flex gap-x-[10px] items-center'}>
      <div className={'flex-1'}>
        <span className={'text-16-regular text-grey-90'}>{course.name}</span>
        <div className={'text-14-regular text-grey-50 flex flex-col items-start gap-y-[5px] mt-[5px]'}>
          <span>{course.type}</span>
          <span>
            {new Intl.DateTimeFormat('ru', {
              day: 'numeric',
              month: 'long',
              hour: 'numeric',
              minute: 'numeric',
            }).format(new Date(createdAt))}
          </span>
        </div>
      </div>
      <h5 className={'text-blue-800 headlines-20-bold'}>{sum}</h5>
      {payment.receipt && (
        <a
          href={payment.receipt}
          target={'_blank'}
          rel="noreferrer"
          className={'bg-opacity-darkBlue-5 flex items-center p-[7.5px] rounded-[10px]'}
        >
          <Receipt className={'fill-blue-800'} />
        </a>
      )}
    </li>
  );
});
