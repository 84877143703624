import { createEffect, createEvent, createStore, forward, sample } from 'effector';
import { CoursesFilter, CoursesViewModel } from '../../types/courses.types';
import { ApiThrownError } from '@shared/api/client/responses/ApiErrorFactory';
import { SoloCourseService } from '@shared/api/client/services/SoloCourse';
import { coursesResponseDataToViewModelMappers, coursesViewModelToRequestDataMappers } from './courses-list.mapper';
import { Direction } from '@generated-student';
import { handleError } from '@shared/handle-error/handleError';
import { COURSES_LIST_DEFAULT_PRICE_FILTER } from '../../constants/constants';

/**
 * Объявление сторов
 */

type CoursesListStore = CoursesViewModel;
type SearchStore = string;
type OffsetStore = number;
type FilterStore = CoursesFilter;

export const defaultCoursesListStore: CoursesListStore = {
  items: [],
  itemsAmount: 0,
};
export const defaultSearchStore: SearchStore = '';
export const defaultOffsetStore: OffsetStore = 0;
export const defaultFilterStore: FilterStore = {
  price: COURSES_LIST_DEFAULT_PRICE_FILTER,
  priceRange: COURSES_LIST_DEFAULT_PRICE_FILTER,
  directions: [],
  authors: [],
};

/**
 * Модель поиска по курсам
 *
 *
 */
export const changeSearch = createEvent<string>();
export const resetSearch = createEvent();
export const $Search = createStore<SearchStore>(defaultSearchStore);

/**
 * Модель фильтра по курсам
 *
 *
 */
export const getCoursesFilterFx = createEffect<void, CoursesFilter, ApiThrownError>(async () => {
  const {
    data: { data },
  } = await SoloCourseService.getFilterForListSoloCoursesToPurchase();

  return coursesResponseDataToViewModelMappers.getCoursesFilter(data);
});
export const applyFilter = createEvent();
export const changePriceFilter = createEvent<Array<number>>();
export const changeDirectionsFilter = createEvent<{ value: Direction; label: string }[]>();
export const changeAuthorsFilter = createEvent<{ id: string; name: string }[]>();
export const removeAuthorFromFilter = createEvent<string>();
export const removeDirectionFromFilter = createEvent<string>();
export const resetFilter = createEvent();
export const $Filter = createStore<FilterStore>(defaultFilterStore);
export const $FilterRange = createStore<Array<number>>([0, 0]);

/**
 * Модель отступа при пагинации по курсам
 *
 *
 */
export const $Offset = createStore<OffsetStore>(defaultOffsetStore);
export const changeOffset = createEvent<number>();
export const resetOffset = createEvent();

/**
 * Модель списка курсов
 *
 *
 */
export const fetchCoursesListFx = createEffect<
  CoursesFilter & { search: string } & { offset: number },
  CoursesViewModel,
  ApiThrownError
>(async (payload) => {
  const requestPayload = coursesViewModelToRequestDataMappers.getCoursesList(payload);

  const {
    data: { data },
  } = await SoloCourseService.getListSoloCoursesToPurchase(requestPayload);

  return coursesResponseDataToViewModelMappers.getCoursesList(data);
});
export const applyCoursesFilterFx = createEffect<
  CoursesFilter & { search: string } & { offset: number },
  CoursesViewModel,
  ApiThrownError
>(async (payload) => {
  return fetchCoursesListFx(payload);
});
export const getCoursesListFx = createEffect<
  CoursesFilter & { search: string } & { offset: number },
  CoursesViewModel,
  ApiThrownError
>(async (payload) => {
  return fetchCoursesListFx(payload);
});
export const resetCoursesList = createEvent();
export const getCourses = createEvent();
const addCoursesToList = createEvent<CoursesViewModel>();
const setCourses = createEvent<CoursesViewModel>();
export const $CoursesList = createStore<CoursesListStore>(defaultCoursesListStore);

/**
 * Бизнес логика листинга курсов
 */

$Search.on(changeSearch, (_, value) => value).on(resetSearch, () => '');
$Offset.on(changeOffset, (_, value) => value).on(resetOffset, () => 0);
$Filter
  .on(changePriceFilter, (prevFilter, priceFilter) => ({
    ...prevFilter,
    price: priceFilter,
  }))
  .on(changeDirectionsFilter, (prevFilter, directions) => {
    return {
      ...prevFilter,
      directions,
    };
  })
  .on(removeDirectionFromFilter, (prev, id) => {
    const newDirections = prev.directions.filter((direction) => {
      return direction.value !== id;
    });

    return {
      ...prev,
      directions: newDirections,
    };
  })
  .on(changeAuthorsFilter, (prev, authors) => {
    return {
      ...prev,
      authors,
    };
  })
  .on(removeAuthorFromFilter, (prev, id) => {
    const newAuthors = prev.authors.filter((author) => {
      return author.id !== id;
    });

    return {
      ...prev,
      authors: newAuthors,
    };
  })
  .on(resetFilter, () => defaultFilterStore)
  .on(getCoursesFilterFx.doneData, (prev, payload) => {
    return payload;
  });
$FilterRange.on(getCoursesFilterFx.doneData, (state, payload) => {
  return payload.priceRange;
});
$CoursesList
  .on(addCoursesToList, (currentStore, coursesList) => ({
    itemsAmount: coursesList.itemsAmount,
    items: [...currentStore.items, ...coursesList.items],
  }))
  .on(setCourses, (currentStore, currentList) => currentList)
  .on(resetCoursesList, () => defaultCoursesListStore);

[
  getCoursesFilterFx.failData,
  applyCoursesFilterFx.failData,
  getCoursesListFx.failData,
  fetchCoursesListFx.failData,
].forEach((failedEffect) => {
  failedEffect.watch((error) => {
    handleError(error);
  });
});

/**
 * Процесс запроса и установки курсов при изменение параметров поиска курсов -
 * в частности, на данный момент это изменение оффсета пагинации при бесконечном скроле
 *
 * В результате данного процесса новые элементы списка должны ДОБАВИТЬСЯ к уже существующим
 */
sample({
  clock: getCourses,
  source: { filter: $Filter, search: $Search, offset: $Offset },
  fn: ({ filter, search, offset }) => ({ ...filter, search, offset }),
  target: getCoursesListFx,
});
forward({ from: getCoursesListFx.doneData, to: addCoursesToList });

/**
 * Процесс запроса и установки курсов при применении фильтров
 *
 * При применение фильтров происходит сброс оффесета пагинации к начальному значению,
 * и запрос списка курсов с текущими значениями фильтра, поисковой строки и оффсета
 *
 * В результате данного процесса новые элементы списка ПЕРЕЗАПИСАТЬ старые
 */
sample({
  clock: applyFilter,
  fn: () => defaultOffsetStore,
  target: changeOffset,
});
sample({
  clock: applyFilter,
  source: { filter: $Filter, search: $Search, offset: $Offset },
  fn: ({ filter, search, offset }) => ({ ...filter, search, offset }),
  target: applyCoursesFilterFx,
});
forward({ from: applyCoursesFilterFx.doneData, to: setCourses });
