import { CoursesFilter, CoursesViewModel } from '../../types/courses.types';
import {
  SoloCoursePriceRange,
  StudentGetFilterForListSoloCoursesToPurchaseResponseSchema,
  StudentListSoloCoursesToPurchaseRequestSchema,
  StudentListSoloCoursesToPurchaseResponseSchema,
} from '@generated-student';
import { COURSES_LIST_DEFAULT_PRICE_FILTER } from '../../constants/constants';
import { courseMappers } from '../course/course.mapper';
import { DEFAULT_PAGE_SIZE } from '@shared/constants/pagination';

/** Функция приводит данные по курсам, получаемые с апи метода getListSoloCoursesToPurchase к вью моделе */
export const getCoursesList = (responseData: StudentListSoloCoursesToPurchaseResponseSchema): CoursesViewModel => {
  return {
    items: responseData.items.map((course) => {
      if (course.type === 'fully_purchased_solo_course') {
        return courseMappers.mapToFullyPurchasedCourse(course);
      }

      if (course.type === 'partially_purchased_solo_course') {
        return courseMappers.mapToPartiallyPurchasedCourseViewModel(course);
      }

      return courseMappers.mapToNotPurchasedCourseViewModel(course);
    }),
    itemsAmount: responseData.itemsAmount,
  };
};

/** Функция приводит данные по фильтрам курса, получаемые с апи метода getFilterForListSoloCoursesToPurchase к вью моделе */
export const getCoursesFilter = ({
  priceRange,
}: StudentGetFilterForListSoloCoursesToPurchaseResponseSchema): CoursesFilter => {
  const coursesFiltersPrice =
    priceRange !== null ? [priceRange.from.rubles, priceRange.to.rubles + 1] : COURSES_LIST_DEFAULT_PRICE_FILTER;

  return {
    priceRange: coursesFiltersPrice,
    price: coursesFiltersPrice,
    directions: [],
    authors: [],
  };
};

export const getCoursesListRequestData = (
  payload: CoursesFilter & { search: string } & { offset: number },
): StudentListSoloCoursesToPurchaseRequestSchema => {
  const priceFilter = payload.price;

  const priceRangeFrom: SoloCoursePriceRange['from'] = {
    rubles: priceFilter[0],
    pennies: 0,
  };

  const priceRangeTo: SoloCoursePriceRange['to'] = {
    rubles: priceFilter[1],
    pennies: 0,
  };

  const authorIds = payload.authors.map((author) => {
    return author.id;
  });

  const directions = payload.directions.map((direction) => {
    return direction.value;
  });

  return {
    filter: {
      directions: directions,
      priceRange: { from: priceRangeFrom, to: priceRangeTo },
      search: payload.search || null,
      authorIds: authorIds,
    },
    pagination: {
      amount: DEFAULT_PAGE_SIZE,
      offset: payload.offset,
    },
    /** В текущей версии сортировка не меняется */
    sorting: {
      direction: 'asc',
      field: 'title',
    },
  };
};

export const coursesViewModelToRequestDataMappers = {
  getCoursesList: getCoursesListRequestData,
};

export const coursesResponseDataToViewModelMappers = {
  getCoursesList,
  getCoursesFilter,
};
