import React, { useState, useCallback, forwardRef } from 'react';
import styles from './Input.module.css';
import { Input, InputProps } from './Input.component';
import { ReactComponent as EyeSvg } from '@svg/outline/eye.svg';

type Props = Omit<InputProps, 'type'>;

export const PasswordInput = forwardRef<HTMLInputElement, Props>(({ value, ...rest }, ref) => {
  const [isShown, setIsShown] = useState(false);

  const handleClick = useCallback(() => {
    setIsShown(!isShown);
  }, [isShown]);
  return (
    <Input
      ref={ref}
      {...rest}
      icon={
        <button type={'button'} onClick={handleClick}>
          <EyeSvg height={20} width={20} className={'fill-blue-800'} />
        </button>
      }
      type={isShown ? 'text' : 'password'}
      size={'large'}
      borderRadius={'medium'}
      className={!isShown && value ? styles['input-container_type-password'] : undefined}
    />
  );
});
