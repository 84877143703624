import { BlockType, TestingQuestionType } from '@modules/exercise/types/exercise.types';
import { ElementType } from 'react';
import { BlockArticle } from '@modules/exercise/components/blocks/BlockArticle';
import { BlockPresentation } from '@modules/exercise/components/blocks/BlockPresentation';
import { BlockTesting } from '@modules/exercise/components/blocks/BlockTesting';
import { BlockVideo } from '@modules/exercise/components/blocks/BlockVideo';

/* В роуте получения блоков занятия приходит массив блоков, у каждого блока есть поле type
 * Объект мапит тип блока в компнент, используемый в ui  списке
 */
export const BLOCK_TYPE_TO_COMPONENT_MAPPER: Record<BlockType, ElementType> = {
  article: BlockArticle,
  presentation: BlockPresentation,
  testing: BlockTesting,
  video: BlockVideo,
};

export const ENG_TO_RU_QUESTION_TYPE_MAPPER: Record<TestingQuestionType, string> = {
  single_choice: 'Вопрос с единственным вариантом ответа',
  matching: 'Вопрос на сопоставление',
  multi_choice: 'Вопрос с множественнным выбором',
};

export const VALIDATION_ERROR_MSG = {
  toShort: 'Выберите хотя бы один ответ',
  sameAnswers: 'Одинаковые ответы не допускаются',
  notFullField: 'Необходимо заполнить все ответы',
};
