import React, { FC, RefObject } from 'react';
import { ModuleCard } from '../ModuleCard/ModuleCard.component';
import { ModuleViewModelType } from '../../types/module.types';
import { InfiniteScroll } from '@shared/components/InfiniteScroll/InfiniteScroll.component';
import { useStore } from 'effector-react';
import { $ModulesList, changeOffset } from '../../stores/modules-list.store';
import { $Cart, addToCartEvent, removeFromCartEvent } from '@shared/stores/cart.store';
import { CartStoreModule } from '@shared/types/cart.types';

type ModuleListProps = {
  wrapperRef: RefObject<HTMLElement>;
  onClick: (moduleId: string, type: ModuleViewModelType) => void;
  withButton: boolean;
};

/**
 * Компонент списка модулей курса
 */
export const ModuleList: FC<ModuleListProps> = ({ wrapperRef, onClick, withButton }) => {
  const { items, itemsAmount } = useStore($ModulesList);
  const cartStore = useStore($Cart);

  const loadMoreModules = () => {
    changeOffset(items.length);
  };

  const cartIds = cartStore.map((it) => {
    return it.id;
  });

  const handleAddToCart = (module: CartStoreModule) => {
    addToCartEvent(module);
  };

  const handleRemoveFromCart = (moduleId: string) => {
    removeFromCartEvent(moduleId);
  };

  return (
    <InfiniteScroll loadMore={loadMoreModules} hasMore={items.length < itemsAmount} scrollContainerRef={wrapperRef}>
      <ul className="flex flex-col gap-y-5">
        {items.map((module) => {
          const inCart = cartIds.includes(module.id);
          return (
            <li key={module.id} className="relative">
              <ModuleCard
                {...module}
                onClickModule={onClick}
                withButton={withButton}
                inCart={inCart}
                onAddToCart={handleAddToCart}
                onRemoveFromCart={handleRemoveFromCart}
              />
            </li>
          );
        })}
      </ul>
    </InfiniteScroll>
  );
};
