import ReactSlider from 'react-slider';
import styles from './Filter.module.css';
import { getIsActiveTrack } from './Filter.utils';
import { forwardRef, Ref } from 'react';
import { SliderValue } from './Filter.types';

type FilterProps = {
  className?: string;
  value?: SliderValue | null;
  onChange?: (value: SliderValue) => void;
  min?: number;
  max?: number;
  step?: number;
};

/**
 * Компонент фильтра
 * Количество ползунков зависит от того, что передано в value
 * если передано число, то ползунок будет один
 * если передан массив, то количество ползунков будет равно количеству элементов в массиве
 *
 * PS Наименование компонента взято из фигмы, но в общем такой компонент именуют slider -
 * ползунок или два ползунка, с помощью которых можно задать диапазон чисел
 */
export const Filter = forwardRef<ReactSlider<SliderValue>, FilterProps>(
  (
    { step = 1, min: minProp = 0, max: maxProp = 100, value: valueProp, onChange, className },
    ref?: Ref<ReactSlider<SliderValue>>,
  ) => {
    const handleAfterChange = (value: SliderValue) => {
      if (onChange) {
        onChange(value);
      }
    };

    const value = valueProp ?? minProp ?? 0;

    return (
      <div className={className}>
        {Array.isArray(value) && (
          <div className={'flex items-center justify-between mb-[16px]'}>
            <div className={styles['filter-label-container']}>
              <span className={styles['filter-label']}>{'от'}</span>
              <input
                onChange={(e) => {
                  const currentValue = Number(e.target.value);
                  const maxVal = value[1];
                  handleAfterChange([currentValue, maxVal]);
                }}
                className={styles['filter-input']}
                min={minProp}
                max={maxProp}
                value={value[0].toString()}
                type={'number'}
                onBlur={(e) => {
                  let currentValue = Number(e.target.value);
                  const maxVal = value[1];
                  if (currentValue < minProp || currentValue > maxProp) {
                    currentValue = minProp;
                  }
                  handleAfterChange([currentValue, maxVal]);
                }}
              />
            </div>
            <div className={'flex items-center gap-x-2'}>
              <span className={styles['filter-label']}>{'до'}</span>
              <input
                onChange={(e) => {
                  const currentValue = Number(e.target.value);
                  const minVal = value[0];
                  handleAfterChange([minVal, currentValue]);
                }}
                value={value[1].toString()}
                className={styles['filter-input']}
                min={minProp}
                max={maxProp}
                type={'number'}
                onBlur={(e) => {
                  let currentValue = Number(e.target.value);
                  const minVal = value[0];
                  if (currentValue > maxProp || currentValue < minProp) {
                    currentValue = maxProp;
                  }
                  handleAfterChange([minVal, currentValue]);
                }}
              />
            </div>
          </div>
        )}

        <ReactSlider<SliderValue>
          ref={ref}
          value={value}
          min={minProp}
          max={maxProp}
          step={step}
          className={styles['filter-container']}
          thumbClassName={styles['filter-thumb']}
          trackClassName={styles['filter-track']}
          onAfterChange={handleAfterChange}
          renderThumb={(props) => (
            <div {...props}>
              <div className={styles['filter-thumb__outer-circle']}>
                <div className={styles['filter-thumb__inner-circle']} />
              </div>
            </div>
          )}
          renderTrack={(elProps, elState) => {
            return (
              <div
                {...elProps}
                className={`${styles['filter-track']} ${
                  getIsActiveTrack(elState.index, elState.value) && styles['filter-track_active']
                }`}
              />
            );
          }}
        />
      </div>
    );
  },
);
