import React, { FC } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import styles from './MainLayout.module.css';
import { getCumulativeUrlPathSegments } from '@shared/utils/getCumulativeUrlPathSegments';
import { SLIDER_PATH_TO_INNER_NAV_COMPONENT_MAPPER } from '@ui-kit/Sidebar/constants';
import { Sidebar } from '@ui-kit/Sidebar/Sidebar.component';

export const MainLayout: FC = () => {
  const location = useLocation();

  /**
   * Внутренний блок навигации сайдбара определяется по первым двум сегментам url страницы
   * Например, выполнен запрос вида вида - student/profile/documents
   * в secondSegment будет получено - student/profile === NAVIGATION.studentProfile
   * через объект - маппер SLIDER_PATH_TO_INNER_SIDEBAR_COMPONENT_MAPPER будет получен компонент - внутренний блок
   * навигации сайдбара SubNavigationProfile
   */
  const [, , secondSegment] = getCumulativeUrlPathSegments(location.pathname);

  const innerNavComponent = SLIDER_PATH_TO_INNER_NAV_COMPONENT_MAPPER[secondSegment];

  return (
    <div className={styles['main-layout']}>
      <Sidebar userRole={'student'} innerNavComponent={innerNavComponent} />
      <main className={styles['main']}>
        <Outlet />
      </main>
    </div>
  );
};
