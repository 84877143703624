import { createEvent, createStore } from 'effector';

type Modal = {
  title?: string;
  description?: string | JSX.Element | Array<JSX.Element>;
  onCancel?: () => void;
};

type ErrorModal = Modal & {
  type: 'error';
};

type SuccessModal = Modal & {
  type: 'success';
};

type ResultModalStore = ErrorModal | SuccessModal | null;

export const showErrorModalEvent = createEvent<Modal | void>();
export const showSuccessModalEvent = createEvent<Modal | void>();
export const hideResultModal = createEvent();

export const $ResultModal = createStore<ResultModalStore>(null);
$ResultModal
  .on(showErrorModalEvent, (state, modal) => ({ ...modal, type: 'error' }))
  .on(showSuccessModalEvent, (state, modal) => ({ ...modal, type: 'success' }))
  .on(hideResultModal, () => null);
