import { useEvent, useStore } from 'effector-react';
import { $UserCourseAttachments, getCourseAttachmentsFx } from '@modules/user-course/stores/course-attachments.store';

export const useUserCourseAttachments = () => {
  const { userCourseAttachments } = useStore($UserCourseAttachments);

  const getCourseAttachments = useEvent(getCourseAttachmentsFx);

  return {
    userCourseAttachments,
    getCourseAttachments,
  };
};
