import React, { FC } from 'react';
import { Avatar } from '@shared/ui-kit/Avatar/Avatar';
import styles from './PersonalData.module.css';
import { PersonalDataForm } from '@modules/personalData/components/PersonalDataForm/PersonalDataForm.component';
import { usePersonalData } from '@modules/personalData/hooks/usePersonalData';

export const PersonalDataPage: FC = () => {
  const { onAvatarChange, submitForm, personalData } = usePersonalData();
  return (
    <div className={styles['personalDataPage']}>
      <div className={styles['substrate']}>
        <div className={styles['wrapper']}>
          <div className="h-[100px]">
            <Avatar withBadge url={personalData.avatar?.url} onFilePick={onAvatarChange} />
          </div>
          <h5 className="mb-[30px] text-grey-400">Персональные данные</h5>
          <PersonalDataForm initialValues={personalData} onSubmit={submitForm} />
        </div>
      </div>
    </div>
  );
};
