import React from 'react';
import { ModuleStatus } from './ModuleCard.types';
import { ReactComponent as CheckSolidIcon } from '@svg/solid/check.svg';
import { ReactComponent as GrowthSolidIcon } from '@svg/solid/growth.svg';
import { ReactComponent as LockSolidIcon } from '@svg/solid/lock.svg';

export const MODULE_STATUS_VIEW_BY_STATUS: Record<ModuleStatus, { title: string; icon: JSX.Element }> = {
  completed: { title: 'Модуль пройден', icon: <CheckSolidIcon className="text-light-green" /> },
  progress: { title: 'В процессе', icon: <GrowthSolidIcon className="fill-blue-800" /> },
  unavailable: { title: 'Модуль недоступен', icon: <LockSolidIcon className="fill-grey-50" /> },
};
