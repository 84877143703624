import { Header } from '@ui-kit/Header/Header.component';
import { Button } from '@ui-kit/Button/Button.component';
import React, { useEffect } from 'react';
import { NAVIGATION } from '@shared/constants';
import { getCourseFx, $Course, resetCourse } from '@modules/user-course/stores/course.store';
import { useStore } from 'effector-react';
import { ReactComponent as DiplomaIcon } from '@svg/outline/diploma.svg';
import { ReactComponent as Gallery } from '@svg/solid/gallery.svg';
import { useNavigate, useParams } from 'react-router-dom';
import { useUserCertificatePage } from '@modules/user-certificate/hooks/useUserCertificatePage';
import { CertificateCard } from '@modules/user-certificate/components/CertificateCard/CertificateCard.component';
import styles from './UserCourseResult.module.css';
/**
 * Страница с результатом прохождения курса
 */
export const UserCourseResultPage = () => {
  const { courseId } = useParams();
  const navigation = useNavigate();
  const course = useStore($Course);
  const { certificate, getCertificate } = useUserCertificatePage();
  const redirectToCoursesList = () => {
    navigation(NAVIGATION.studentUserCourses);
  };

  useEffect(() => {
    const fetchCourse = async () => {
      if (!courseId) return;

      const course = await getCourseFx(courseId);

      /**
       * Если пользователь случайно попал на страницу с результатом о пройденом курсе
       * при сам курс еще не пройден, то редиректим его на страницу листинга курсов
       */
      if (!course.isCompleted) {
        redirectToCoursesList();
      }

      await getCertificate({ id: courseId });
    };

    fetchCourse();

    return () => {
      resetCourse();
    };
  }, []);

  return (
    <div className={styles['user-course-result']}>
      <Header headerText="К списку курсов" redirectTo={NAVIGATION.studentUserCourses} hasBackButton />
      <div className={styles['substrate']}>
        <div className="flex items-center gap-x-[10px] mb-[60px]">
          <DiplomaIcon width={60} height={60} className={'text-blue-800'} />
          <h5 className="text-blue-800">{`Поздравляем с успешным прохождением курса: ${
            course ? course.title : ''
          }`}</h5>
        </div>

        {certificate !== null && <CertificateCard {...certificate} />}

        <Button
          variant="ghost"
          className="flex items-center gap-x-[10px] cursor-pointer"
          onClick={redirectToCoursesList}
        >
          <Gallery className={'text-blue-800'} />
          <h6 className="text-blue-800">Смотреть все курсы</h6>
        </Button>
      </div>
    </div>
  );
};
