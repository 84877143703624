import React, { FC } from 'react';
import { NavigationList } from '@ui-kit/NavigationList/NavigationList.component';
import { NAVIGATION } from '@shared/constants';
import { ReactComponent as User } from '@svg/solid/user.svg';
import { ReactComponent as Passport } from '@svg/solid/passport.svg';
import { ReactComponent as File } from '@svg/solid/file.svg';
import { ReactComponent as Lock } from '@svg/solid/lock.svg';
import { ReactComponent as Card } from '@svg/solid/card.svg';

const PROFILE_TOP_NAV_ITEMS = [
  {
    title: 'Персональные данные',
    icon: User,
    url: NAVIGATION.studentPersonalData,
  },
  {
    title: 'Контактные данные',
    icon: Passport,
    url: NAVIGATION.studentContacts,
  },
  {
    title: 'Документы',
    icon: File,
    url: NAVIGATION.studentDocuments,
  },
  {
    title: 'Безопасность',
    icon: Lock,
    url: NAVIGATION.studentPassword,
  },
];

/*В данной версии не реализовано */
const PROFILE_BOTTOM_NAV_ITEMS = [
  {
    title: 'История платежей',
    icon: Card,
    url: NAVIGATION.studentBilling,
  },
];

const PROFILE_NAV_ITEMS = {
  mainNavigation: { items: PROFILE_TOP_NAV_ITEMS, title: 'Персональные сведения' },
  subNavigation: { items: PROFILE_BOTTOM_NAV_ITEMS, title: 'Биллинг' },
};

export const SubNavigationProfile: FC = () => {
  return (
    <div className="flex flex-col">
      <div className={'px-[5%] py-[8%]'}>
        <h5 className={'text-white text-l m:text-xl'}>{'Ваш профиль'}</h5>
        <span className={'text-14-regular text-white m:text-s'}>{'Управление входом и личными данными'}</span>
      </div>
      <NavigationList navigationData={PROFILE_NAV_ITEMS} />
    </div>
  );
};
