import React, { FC, InputHTMLAttributes } from 'react';
import styles from './Checkbox.module.css';
import { ReactComponent as CheckIcon } from '@svg/outline/check.svg';

type CheckboxStyleConfig = {
  containerStyle: string;
  labelStyle: string;
};

export interface CheckboxProps extends InputHTMLAttributes<HTMLInputElement> {
  label?: string;
  styleConfig?: Partial<CheckboxStyleConfig>;
}

export const Checkbox: FC<CheckboxProps> = ({ label, styleConfig, ...props }) => {
  const { containerStyle, labelStyle } = styleConfig || {};

  return (
    <div className={`${styles['checkbox-container']} ${containerStyle ?? ''}`}>
      <input {...props} type={'checkbox'} className={styles['checkbox']} />
      <div className={styles['checkbox-control']}>
        {props.checked && (
          <CheckIcon
            className={'absolute bottom-1/2 right-1/2 translate-x-1/2 translate-y-1/2'}
            color="white"
            width={15}
            height={15}
          />
        )}
      </div>
      {label !== undefined && (
        <label className={`${styles['label']} ${labelStyle ?? ''}`} htmlFor={props.id}>
          {label}
        </label>
      )}
    </div>
  );
};
