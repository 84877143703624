import { useRef, useEffect } from 'react';

/**
 * Хук признака первого рендера
 */
export const useIsFirstRender = (): boolean => {
  const isFirstRenderRef = useRef(true);

  useEffect(() => {
    isFirstRenderRef.current = false;
  }, []);

  return isFirstRenderRef.current;
};
