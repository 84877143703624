import React, { FC } from 'react';

export const SubNavigationCalendar: FC = () => {
  return (
    <div>
      <div className={'p-[18px_20px_18px_30px]'}>
        <h5 className={'text-white'}>{'Календарь'}</h5>
        <span className={'text-16-regular text-white'}>{'Календарь'}</span>
      </div>
    </div>
  );
};
