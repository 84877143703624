import React, { FC } from 'react';
import styles from './Certificates.module.css';
import { CertificateCard } from '@modules/user-certificate/components/CertificateCard/CertificateCard.component';
import { Button } from '@ui-kit/Button/Button.component';
import { ReactComponent as ApplicationIcon } from '@svg/outline/application.svg';
import { useNavigate } from 'react-router-dom';
import { NAVIGATION } from '@shared/constants';
import { useUserCertificatesPage } from '@modules/user-certificate/hooks/useUserCertificatesPage';
import { Header } from '@ui-kit/Header/Header.component';

export const CertificatesPage: FC = () => {
  const navigate = useNavigate();
  const { certificates } = useUserCertificatesPage();

  const redirectTo = () => {
    navigate(NAVIGATION.studentCoursesCatalog);
  };

  return (
    <div className={styles['certificates-page']}>
      <Header
        headerText="Ваши сертификаты"
        hasBackButton={false}
        styleConfig={{ header: styles['header'] }}
        rightElement={
          <Button variant="filled" rightIcon={<ApplicationIcon color="white" />} onClick={redirectTo}>
            Каталог курсов
          </Button>
        }
      />
      <ul className={styles['certificates']}>
        {certificates?.map((certificate) => (
          <CertificateCard key={certificate.certificate.id} {...certificate} />
        ))}
      </ul>
    </div>
  );
};
