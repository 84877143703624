import React, { FC } from 'react';
import dayjs from 'dayjs';
import { withFormField } from '@ui-kit/FormField';
import { YEAR_MONTH_DAY_DATE_FORMAT } from '@shared/constants/date';

type StaticFieldProps = {
  inputValue?: string | Date | keyof typeof GENDER_MAPPER;
};

const GENDER_MAPPER = {
  male: 'Мужчина',
  female: 'Женщина',
};

const StaticField: FC<StaticFieldProps> = ({ inputValue }) => {
  let value;
  if (inputValue === undefined) {
    value = '';
  } else if (inputValue instanceof Date) {
    value = dayjs(inputValue).format(YEAR_MONTH_DAY_DATE_FORMAT);
  } else if (inputValue in GENDER_MAPPER) {
    value = GENDER_MAPPER[inputValue as keyof typeof GENDER_MAPPER];
  } else {
    value = inputValue;
  }

  return <p>{value}</p>;
};

export const StaticFieldWithLabel = withFormField(StaticField);
