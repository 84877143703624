import React, { FC, PropsWithChildren } from 'react';
import styles from './TestQuestionContainer.module.css';
import { ReactComponent as Right } from '@svg/solid/right.svg';
import { ReactComponent as Wrong } from '@svg/solid/wrong.svg';
import { QuestionWasAnsweredCorrectly } from '@modules/exercise/types/exercise.types';

export type TestQuestionContainerProps = {
  isQuestionWasAnsweredCorrectly: QuestionWasAnsweredCorrectly;
  className?: string;
};

export const TestQuestionContainer: FC<PropsWithChildren<TestQuestionContainerProps>> = ({
  isQuestionWasAnsweredCorrectly,
  children,
  className,
}) => {
  const icon =
    isQuestionWasAnsweredCorrectly === null ? null : isQuestionWasAnsweredCorrectly ? (
      <Right className={'text-light-green'} />
    ) : (
      <Wrong className={'text-red-intensive'} />
    );

  return (
    <div className={`${styles.container} ${className}`}>
      {icon && <div className={'absolute top-0 right-0 translate-x-1/2 translate-y-[-50%]'}>{icon}</div>}
      {children}
    </div>
  );
};
