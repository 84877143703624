import { Header } from '@ui-kit/Header/Header.component';
import React, { useEffect } from 'react';
import { useEvent, useStore } from 'effector-react';
import {
  $PaymentCardList,
  getPaymentCardListFx,
  resetPaymentCardList,
} from '@modules/payment/stores/payment-card-list.store';
import { handleError } from '@shared/handle-error/handleError';
import { NAVIGATION } from '@shared/constants';
import style from './CartPage.module.css';
import { BankCard } from '@modules/payment/components/BankCard/BankCard.component';
import { NewBankCard } from '@modules/payment/components/NewBankCard/NewBankCard.component';
import {
  $bankCardIdStore,
  $Order,
  $payUrl,
  createOrderFx,
  orderReset,
  payUrlReset,
  setBankCardId,
} from '@shared/stores/purchase-store';
import { useStartCheckingHook } from '@shared/hooks/startCheckingHook';
import { PaymentDetailsCard } from '@modules/payment/components/PaymentDetailsCard/PaymentDetailsCard.component';
import { $Cart, removeFromCartEvent } from '@shared/stores/cart.store';
import { NotPurchasedModuleCard } from '@modules/module/components/ModuleCard/NotPurchasedModuleCard.component';
import { handleApiError } from '@shared/handle-error/default-handlers/handleApiError';
import { $ModulesToPurchase, getNotPurchasedModulesFx } from '@modules/module/stores/modules-purchase.store';
import { getCoursesToPurchaseFx, $CoursesToPurchase } from '@modules/course/stores/course/course-purchase.store';
import { NotPurchasedCourseCard } from '@modules/course/components/CourseCard/NotPurchasedCourseCard.component';
import { ModalBasic } from '@shared/ui-kit/Modal/ModalBasic/ModalBasic.component';
import { showModalEvent } from '@ui-kit/Modal/ModalBasic/ModalBasic.store';
import { Button } from '@shared/ui-kit/Button/Button.component';
import { ModuleUnpaidCard } from '@modules/module/components/ModuleUnpaidCard/ModuleUnpaidCard.component';
import { CourseUnpaidCard } from '@modules/course/components/CourseUnpaidCard/CourseUnpaidCard.component';

export const CartPage = () => {
  const selectedBankCardId = useStore($bankCardIdStore);
  const getNotPurchasedModules = useEvent(getNotPurchasedModulesFx);
  const getCoursesToPurchase = useEvent(getCoursesToPurchaseFx);
  const payUrl = useStore($payUrl);
  const order = useStore($Order);

  const paymentCardList = useStore($PaymentCardList);
  const modulesToPurchase = useStore($ModulesToPurchase);
  const coursesToPurchase = useStore($CoursesToPurchase);

  const cartStore = useStore($Cart);
  const createOrder = useEvent(createOrderFx);

  const moduleIds = cartStore.filter((item) => item.type === 'not_purchased_module');
  const soloCourseIds = cartStore.filter((item) => item.type === 'not_purchased_solo_course');

  const getModulesAndCourses = async () => {
    await Promise.all([
      getNotPurchasedModules(moduleIds.map((it) => it.id)),
      getCoursesToPurchase(soloCourseIds.map((it) => it.id)),
    ]);
  };

  useEffect(() => {
    getModulesAndCourses();
  }, [cartStore]);

  const onBuyHandler = async () => {
    try {
      const order = await createOrder({
        moduleIds: modulesToPurchase.map((module) => {
          return module.id;
        }),
        soloCourseIds: coursesToPurchase.map((course) => {
          return course.id;
        }),
        paymentCardId: selectedBankCardId === 'new' ? null : selectedBankCardId,
      });

      if (order?.status === 'order_existing') {
        showModalEvent();
      }
    } catch (error) {
      handleError(error, {
        apiError: (err) => {
          handleApiError(err);
        },
      });
    }
  };

  const { startChecking } = useStartCheckingHook();

  useEffect(() => {
    if (payUrl && order?.status === 'order_created') {
      window.open(payUrl, '_blank');
    }
  }, [payUrl, order]);

  useEffect(() => {
    return () => {
      payUrlReset();
      orderReset();
    };
  }, []);

  useEffect(() => {
    const fetchInitialData = async () => {
      try {
        await getPaymentCardListFx();
      } catch (error) {
        handleError(error);
      }
    };

    fetchInitialData();

    return () => {
      resetPaymentCardList();
    };
  }, []);

  useEffect(() => {
    order?.orderId && startChecking(order.orderId);
  }, [order?.orderId]);

  const onBankCardClickHandler = async (id: string) => {
    setBankCardId({ id });
  };

  return (
    <div className="flex flex-col p-[24px]">
      <Header headerText="Назад в каталог" hasBackButton={true} redirectTo={NAVIGATION.studentCoursesCatalog} />
      <div className={style['purchase-container']}>
        <div className="flex flex-col gap-y-5">
          <h4>{'Вы выбрали'}</h4>
          {[...modulesToPurchase, ...coursesToPurchase].map((it) => {
            if (it.type === 'not_purchased_module') {
              return (
                <NotPurchasedModuleCard
                  key={it.id}
                  {...it}
                  onRemoveFromCart={() => {
                    removeFromCartEvent(it.id);
                  }}
                  withButton={true}
                  inCart={true}
                />
              );
            }
            return (
              <NotPurchasedCourseCard
                key={it.id}
                {...it}
                withButton={true}
                isDisabled={true}
                inCart={true}
                onRemoveFromCart={() => {
                  removeFromCartEvent(it.id);
                }}
              />
            );
          })}
        </div>
        <PaymentDetailsCard onBuy={onBuyHandler} items={[...modulesToPurchase, ...coursesToPurchase]} />
      </div>

      <div className="flex flex-col items-start gap-y-5">
        <h4>Способ оплаты:</h4>
        <div className="flex py-5 px-[30px] gap-x-5 rounded-[30px] bg-white">
          {paymentCardList.map((card) => (
            <BankCard
              isActive={card.id === selectedBankCardId}
              onClick={onBankCardClickHandler}
              size="s"
              key={card.id}
              id={card.id}
              paymentSystem={card.paymentSystem}
              lastFourDigits={card.lastFourDigits}
            />
          ))}
          <NewBankCard size="s" id={'new'} isActive={'new' === selectedBankCardId} onClick={onBankCardClickHandler} />
        </div>
      </div>
      <ModalBasic wrapperClassName={'p-[30px_40px_40px_40px]'}>
        <div className={'flex flex-col gap-10 items-end'}>
          <div className={'flex flex-col items-center gap-[30px] mt-10'}>
            <h5>У вас есть неоплаченный заказ</h5>
            <div
              className={
                'flex flex-col items-center gap-[30px] max-h-[517px] pt-[24px] pb-[32px] mb-[-32px] mx-[-52px] px-[52px] overflow-x-hidden'
              }
            >
              {order?.status === 'order_existing' &&
                order.orderItems.map((it) => {
                  const isModule = 'moduleId' in it;
                  return isModule ? <ModuleUnpaidCard module={it} /> : <CourseUnpaidCard course={it} />;
                })}
            </div>
          </div>
          <Button
            disabled={!payUrl}
            onClick={() => {
              if (payUrl) {
                window.open(payUrl, '_blank');
              }
            }}
            className={'w-full'}
            variant={'filled'}
          >
            {'Оплатить'}
          </Button>
        </div>
      </ModalBasic>
    </div>
  );
};
