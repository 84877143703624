export const ENTITY_IDS = {
  id: 'id',
  courseId: 'courseId',
  moduleId: 'moduleId',
  exerciseId: 'exerciseId',
  orderId: 'orderId',
};

const STUDENT = '/student';

const STUDENT_PROFILE = `${STUDENT}/profile`;

const STUDENT_EDUCATION = `${STUDENT}/education`;

//Преподаватель
const TEACHER = '/teacher';

const TEACHER_PROFILE = `${TEACHER}/profile`;

const TEACHER_EDUCATION = `${TEACHER}/education`;

const LOGIN = '/login';

export const NAVIGATION = {
  root: '/',
  login: LOGIN,

  createPassword: '/createPassword',

  paymentRedirect: '/paymentRedirect',

  /* Студент */
  //Профиль
  studentProfile: STUDENT_PROFILE,
  studentPersonalData: `${STUDENT_PROFILE}/personal-data`,
  studentContacts: `${STUDENT_PROFILE}/contacts`,
  studentDocuments: `${STUDENT_PROFILE}/documents`,
  studentBilling: `${STUDENT_PROFILE}/billing`,
  studentPassword: `${STUDENT_PROFILE}/password`,

  //Образование
  studentEducation: STUDENT_EDUCATION,

  /** Урлы страниц, свзанные с курсами, которые студент уже приобрел */
  studentUserCourses: `${STUDENT_EDUCATION}/user-courses`,
  studentUserCourse: `${STUDENT_EDUCATION}/user-courses/:${ENTITY_IDS.courseId}`,
  studentUserCourseModule: `${STUDENT_EDUCATION}/user-courses/:${ENTITY_IDS.courseId}/module/:${ENTITY_IDS.moduleId}`,
  studentUserCourseResult: `${STUDENT_EDUCATION}/user-courses/:${ENTITY_IDS.courseId}/result`,
  studentUserCourseExercise: `${STUDENT_EDUCATION}/user-courses/:${ENTITY_IDS.courseId}/module/:${ENTITY_IDS.moduleId}/exercise/:${ENTITY_IDS.exerciseId}`,

  studentPaymentSuccess: `${STUDENT_EDUCATION}/success`,
  studentPaymentFailed: `${STUDENT_EDUCATION}/failed`,

  /** Урлы страниц, свзанные с курсами, которые студент еще НЕ приобрел */
  studentCoursesCatalog: `${STUDENT_EDUCATION}/courses`,

  // Роут страницы курса, где пользователь может выбрать что он хочет оплатить - или курс целиком, или модули курса по отдельности
  studentCourseToPurchase: `${STUDENT_EDUCATION}/courses/:${ENTITY_IDS.courseId}`,
  // Роут страницы оплаты курса целиком
  studentCoursePurchase: `${STUDENT_EDUCATION}/courses/:${ENTITY_IDS.courseId}/purchase`,
  // Роут страницы оплаты модуля курса
  studentCourseModulePurchase: `${STUDENT_EDUCATION}/courses/:${ENTITY_IDS.courseId}/module/:${ENTITY_IDS.moduleId}/purchase`,

  studentProgress: `${STUDENT_EDUCATION}/progress`,
  studentPassbook: `${STUDENT_EDUCATION}/passbook`,
  studentDiplomas: `${STUDENT_EDUCATION}/diplomas`,
  studentCertificates: `${STUDENT_EDUCATION}/certificates`,

  // Корзина
  studentCart: `${STUDENT}/cart`,

  //Календарь
  studentCalendar: `${STUDENT}/calendar`,

  //Поддержка
  studentSupport: `${STUDENT}/support`,

  //Уведомления
  studentNotification: `${STUDENT}/notification`,

  //Настройки
  studentSettings: `${STUDENT}/settings`,

  /* Преподаватель */
  teacherEducation: TEACHER_EDUCATION,
  teacherProfile: TEACHER_PROFILE,

  //Календарь
  teacherCalendar: `${TEACHER}/calendar`,

  //Поддержка
  teacherSupport: `${TEACHER}/support`,
} as const;
