import React, { FC, RefObject } from 'react';
import { useStore } from 'effector-react';
import styles from './UserCoursesList.module.css';
import { $Cursor, $UserCourses, getUserCourses } from '../../stores/user-courses-list.store';
import { InfiniteScroll } from '@shared/components/InfiniteScroll/InfiniteScroll.component';
import { ENTITY_IDS, NAVIGATION } from '@shared/constants';
import { useNavigate } from 'react-router-dom';
import { UserCourseCardAlternative } from '../UserCourseCardAlternative/UserCourseCardAlternative.component';

type UserCoursesListProps = {
  wrapperRef: RefObject<HTMLElement>;
};

export const UserCoursesList: FC<UserCoursesListProps> = ({ wrapperRef }) => {
  const navigate = useNavigate();

  const userCourses = useStore($UserCourses);
  const cursor = useStore($Cursor);

  const handleClickCourseCard = (courseId: string) => {
    const userCoursePage = NAVIGATION.studentUserCourse.replace(`:${ENTITY_IDS.courseId}`, courseId);

    navigate(userCoursePage);
  };

  const loadMoreItems = () => {
    getUserCourses();
  };

  return (
    <InfiniteScroll
      loadMore={loadMoreItems}
      scrollContainerRef={wrapperRef}
      hasMore={Boolean(cursor)}
      containerClassName={styles['user-course-list']}
    >
      {userCourses.map((course) => (
        <UserCourseCardAlternative
          onClick={handleClickCourseCard}
          key={course.id}
          data={{ ...course, coverUrl: course.cover.sizes.original.url }}
        />
      ))}
    </InfiniteScroll>
  );
};
