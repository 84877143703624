import { InputFormField } from '@ui-kit/Input/Input.component';
import { FormToggleGroup } from '@ui-kit/ToggleGroup/ToggleGroup';
import { GENDER_SELECT_ITEMS } from '@shared/constants';
import { FormDatePicker } from '@ui-kit/DatePicker/DatePicker.component';
import { Button } from '@ui-kit/Button/Button.component';
import React, { useEffect } from 'react';
import { FormDropdown } from '@ui-kit/Dropdown/Dropdown.component';
import {
  PersonalData,
  PersonalDataFormProps,
} from '@modules/personalData/components/PersonalDataForm/PersonalDataForm.typedef';
import { Controller, useForm } from 'react-hook-form';
import { isTimezone } from '@shared/guards/isTimezone';
import { getTimeZonesOptions } from '@shared/utils/getTimeZones';
import {
  MAX_USER_NAME_LENGTH,
  MAX_USER_PATRONYMIC_LENGTH,
  MAX_USER_SURNAME_LENGTH,
} from '@modules/personalData/constants/inputCharactersMaxLength';
import { requiredValidator } from '@shared/constants/validators/required.validator';
import { maxLengthValidator } from '@shared/constants/validators/maxLength.validator';
import { alphabetStringValidator } from '@shared/constants/validators/alphabetString.validator';
import { dateValidator } from '@shared/constants/validators/date.validator';
import styles from './PersonalDataForm.module.css';

export const PersonalDataForm = ({ onSubmit, initialValues }: PersonalDataFormProps) => {
  const {
    handleSubmit,
    setValue,
    control,
    reset,
    formState: { errors },
  } = useForm<PersonalData>({ defaultValues: initialValues });

  useEffect(() => {
    reset(initialValues);
  }, [initialValues]);

  const timeZones = getTimeZonesOptions();

  return (
    <form className={styles['form-wrapper']} onSubmit={handleSubmit(onSubmit)}>
      <div className={styles['surname']}>
        <Controller
          rules={{
            ...requiredValidator('Фамилию'),
            ...maxLengthValidator(MAX_USER_SURNAME_LENGTH),
            ...alphabetStringValidator(),
          }}
          control={control}
          render={({ field }) => {
            return (
              <InputFormField
                {...field}
                errorMessage={errors.surname?.message}
                label={'Фамилия'}
                required
                id={field.name}
                placeholder={'Введите фамилию'}
              />
            );
          }}
          name={'surname'}
        />
      </div>
      <div className={styles['name']}>
        <Controller
          rules={{
            ...requiredValidator('Имя'),
            ...maxLengthValidator(MAX_USER_NAME_LENGTH),
            ...alphabetStringValidator(),
          }}
          control={control}
          name={'name'}
          render={({ field }) => {
            return (
              <InputFormField
                {...field}
                errorMessage={errors.name?.message}
                label={'Имя'}
                required
                id={field.name}
                placeholder={'Введите имя'}
              />
            );
          }}
        />
      </div>
      <div className={styles['patronymic']}>
        <Controller
          rules={{
            ...maxLengthValidator(MAX_USER_PATRONYMIC_LENGTH),
            ...alphabetStringValidator(),
          }}
          name={'patronymic'}
          control={control}
          render={({ field }) => {
            return (
              <InputFormField
                {...field}
                errorMessage={errors.patronymic?.message}
                label={'Отчество'}
                id={field.name}
                placeholder={'Введите отчество'}
              />
            );
          }}
        />
      </div>

      <p className={styles['caption']}>
        Ваши ФИО нужны для выдачи документов о прохождении образовательных программ на нашей платформе. Также они
        доступны преподавателям.
      </p>

      <div className={styles['gender']}>
        <Controller
          rules={requiredValidator('Пол')}
          name={'gender'}
          control={control}
          render={({ field }) => {
            return (
              <FormToggleGroup
                {...field}
                errorMessage={errors.gender?.message}
                label={'Пол'}
                required
                id={field.name}
                items={GENDER_SELECT_ITEMS}
              />
            );
          }}
        />
      </div>
      <div className={styles['date']}>
        <Controller
          rules={dateValidator()}
          control={control}
          name={'birthday'}
          render={({ field }) => {
            return (
              <FormDatePicker
                {...field}
                errorMessage={errors.birthday?.message}
                required
                id={field.name}
                label={'Дата рождения'}
              />
            );
          }}
        />
      </div>
      <div className={styles['location']}>
        <Controller
          rules={{ ...requiredValidator('Населенный пункт'), ...alphabetStringValidator(true) }}
          control={control}
          render={({ field }) => {
            return (
              <InputFormField
                {...field}
                errorMessage={errors.residence?.message}
                label={'Населенный пункт'}
                required
                id={field.name}
              />
            );
          }}
          name={'residence'}
        />
      </div>

      <div className={styles['timezone']}>
        <Controller
          rules={requiredValidator('Часовой пояс')}
          name={'timezone'}
          control={control}
          render={({ field }) => {
            return (
              <FormDropdown
                {...field}
                options={timeZones}
                errorMessage={errors.timezone?.message}
                selectedItem={timeZones.find((zone) => {
                  return zone.value === field.value;
                })}
                label={'Часовой пояс'}
                required
                id={field.name}
                onChange={(e) => {
                  setValue(field.name, isTimezone(e.value) ? e.value : 'GMT');
                }}
              />
            );
          }}
        />
      </div>

      <div className={styles['buttons']}>
        <Button variant={'filled'}>Сохранить</Button>
      </div>
    </form>
  );
};
