import React, { FC, useEffect, useRef } from 'react';
import { Badge } from '@shared/ui-kit/Badge/Badge.component';
import styles from './Billing.module.css';
import { PaymentOperationList } from '@modules/payment/components/PaymentOperationList/PaymentOperationList.component';
import { useStore } from 'effector-react';
import {
  $Billing,
  $BillingCount,
  $BillingCursor,
  getBilling,
  resetBilling,
} from '@modules/payment/stores/billing.store';

export const BillingPage: FC = () => {
  const billing = useStore($Billing);
  const count = useStore($BillingCount);
  const hasMore = useStore($BillingCursor);
  const pageWrapperRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    getBilling();
    return () => {
      resetBilling();
    };
  }, []);

  return (
    <div className={styles['billing-page']} ref={pageWrapperRef}>
      <div className={styles['substrate']}>
        <div className={'flex gap-x-[20px] mb-[51px]'}>
          <h4 className={'text-grey-90'}>{'Ваша история платежей'}</h4>
          <Badge>{count}</Badge>
        </div>
        <PaymentOperationList
          hasMore={Boolean(hasMore)}
          wrapperRef={pageWrapperRef}
          loadMore={getBilling}
          items={billing}
        />
      </div>
    </div>
  );
};
