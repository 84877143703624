import React, { FC, useRef } from 'react';
import { TabsModule } from '@shared/ui-kit/Tabs/TabsModule.component';
import { useTabs } from '@shared/ui-kit/Tabs/useTabs';
import { TabPanel } from '@shared/ui-kit/Tabs/TabsPanel.component';
import { Header } from '@shared/ui-kit/Header/Header.component';
import { ReactComponent as PlayIcon } from '@svg/solid/play.svg';
import { Button } from '@ui-kit/Button/Button.component';
import { ModuleInfo } from '@modules/module/components/ModuleInfo/ModuleInfo.component';
import { ModuleExercises } from '@modules/module/components/ModuleExercises/ModuleExercises.component';
import { UserCourseAttachments } from '@modules/user-course/components/UserCourseAttachments/UserCourseAttachments.component';
import { useModuleInfo } from '@modules/module/hooks/useModuleInfo';
import { ENTITY_IDS, NAVIGATION } from '@shared/constants';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import styles from './Module.module.css';
import { getModuleExercisesEvent } from '@modules/module/stores/module-exercises.store';

const ModulePage: FC = () => {
  const pageWrapperRef = useRef<HTMLDivElement>(null);
  const { courseId, moduleId } = useParams();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { moduleInfo, handleRedirectToNextCourse } = useModuleInfo();
  const { currentTab, handleClickTab } = useTabs({ initialTab: 'info' });

  const handleRedirectToExercise = (exerciseId: string) => {
    const exerciseUrl = pathname + `/exercise/${exerciseId}`;
    navigate(exerciseUrl);
  };

  const getModuleExercises = () => {
    if (moduleId) {
      getModuleExercisesEvent(moduleId);
    }
  };

  const courseUrl = NAVIGATION.studentUserCourse.replace(`:${ENTITY_IDS.courseId}`, courseId ?? '');
  return (
    <div className={styles['module']} ref={pageWrapperRef}>
      <Header
        redirectTo={courseUrl}
        headerText={''}
        rightElement={
          <Button variant="filled" rightIcon={<PlayIcon color="white" />} onClick={handleRedirectToNextCourse}>
            Приступить
          </Button>
        }
      >
        <TabsModule currentTab={currentTab} onChange={handleClickTab} />
      </Header>
      <TabPanel index={'info'} currentTab={currentTab}>
        <ModuleInfo moduleInfo={moduleInfo} />
      </TabPanel>
      <TabPanel index={'exercises'} currentTab={currentTab}>
        <ModuleExercises
          wrapperRef={pageWrapperRef}
          onClickExercise={handleRedirectToExercise}
          getModuleExercises={getModuleExercises}
        />
      </TabPanel>
      <TabPanel index={'materials'} currentTab={currentTab}>
        <UserCourseAttachments />
      </TabPanel>
    </div>
  );
};
export default ModulePage;
