import { useStore } from 'effector-react';
import {
  $ModuleExercises,
  resetModuleExercisesEvent,
  resetOffset,
  $Offset,
  changeOffset,
} from '@modules/module/stores/module-exercises.store';
import { useEffect } from 'react';
import { useIsFirstRender } from '@shared/hooks/useIsFirstRender';

type UseModuleExercisesParams = {
  getModuleExercises: () => void;
};

export const useModuleExercises = ({ getModuleExercises }: UseModuleExercisesParams) => {
  const isFirstRender = useIsFirstRender();

  const moduleExercises = useStore($ModuleExercises);
  const offset = useStore($Offset);

  const loadMoreExercises = () => {
    changeOffset(moduleExercises.items.length);
  };

  useEffect(() => {
    getModuleExercises();

    return () => {
      resetModuleExercisesEvent();
      resetOffset();
    };
  }, []);

  useEffect(() => {
    if (!isFirstRender) {
      getModuleExercises();
    }
  }, [offset]);

  return { moduleExercises, loadMoreExercises };
};
