import React from 'react';
import { ModuleInfoCard } from '@modules/module/components/ModuleInfoCard/ModuleInfoCard.component';
import { ModuleInfoProps } from '@modules/module/components/ModuleInfo/ModuleInfo.typedef';
import { EntityProgress } from '@shared/components/EntityProgress/EntityProgress.component';
import styles from './ModuleInfo.module.css';

export const ModuleInfo = ({ moduleInfo }: ModuleInfoProps) => {
  if (moduleInfo === null) {
    return null;
  }

  return (
    <div className={styles['module-info']}>
      <section className={'mb-[20px]'}>
        <ModuleInfoCard data={moduleInfo} />
      </section>
      <section className={'mb-[20px]'}>
        <EntityProgress
          progress={Math.ceil((Number(moduleInfo.studyHours.passed) / Number(moduleInfo.studyHours.total)) * 100)}
          hours={moduleInfo.studyHours.total}
        />
      </section>
    </div>
  );
};
