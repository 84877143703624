import React, { forwardRef, FunctionComponent } from 'react';
import { FormFieldLayout } from './FormFieldLayout.component';
import { FormFieldProps } from './FormField.types';

export function withFormField<P>(OriginalComponent: FunctionComponent<P>) {
  return forwardRef((props: P & FormFieldProps, ref) => {
    const { errorMessage, id, label, required, classnameConfig, ...passThroughProps } = props;

    return (
      <FormFieldLayout
        errorMessage={errorMessage}
        id={id}
        label={label}
        classnameConfig={classnameConfig}
        required={required}
      >
        <OriginalComponent {...(passThroughProps as P)} id={id} ref={ref} />
      </FormFieldLayout>
    );
  });
}
