import React, { FC } from 'react';
import { ModuleInfoViewModel } from '@modules/module/types/module.types';
import styles from './ModuleInfoCard.module.css';

type ModuleInfoCardProps = { data: Pick<ModuleInfoViewModel, 'title' | 'description'> | null };

export const ModuleInfoCard: FC<ModuleInfoCardProps> = ({ data }) => {
  const { title, description } = data || {};

  return (
    <div className={styles['module-info-card']}>
      <div className="py-[19.5px]">
        <h3 className={'text-grey-90 text-xl mb-[30px] m:text-2xl'}>{title}</h3>
        <p className={'px-[10px] text-16-regular m:text-18-regular text-justify'}>{description}</p>
        <div className="inline-flex gap-x-[40px]"></div>
      </div>
    </div>
  );
};
