import { createEffect } from 'effector/effector.umd';
import { FileService, RequestFileUploadUrl } from '@shared/api/client/services/FileService';
import { ApiThrownError } from '@shared/api/client/responses/ApiErrorFactory';
import { FileUploadCredentials } from '@generated-student';
import { handleError } from '@shared/handle-error/handleError';
import axios from 'axios';
import { FuseResponse, UploadFileRequestParams } from '@shared/types/fus.typedef';
import { createStore } from 'effector';

export const getUploadUrlFx = createEffect<RequestFileUploadUrl, FileUploadCredentials, ApiThrownError>(
  async (params) => {
    const res = await FileService.getUploadUrl(params);
    return res.data.data;
  },
);

getUploadUrlFx.failData.watch((e) => {
  handleError(e);
});

export const uploadFileFx = createEffect<UploadFileRequestParams, FuseResponse, ApiThrownError>(async (params) => {
  const formData = new FormData();
  formData.append('file', params.file);
  const res = await axios.post<FuseResponse>(params.url, formData, {
    headers: { 'Content-Type': 'multipart/form-data' },
  });
  return res.data;
});

uploadFileFx.failData.watch((e) => {
  handleError(e);
});

export const $isLoadings = createStore([uploadFileFx.pending, getUploadUrlFx.pending]);
export const $isLoading = $isLoadings.map((state) => {
  return state.some(Boolean);
});
