import { useState } from 'react';

type UseTabsParams<T> = {
  initialTab: T;
};

export function useTabs<T = string>({ initialTab }: UseTabsParams<T>) {
  const [currentTab, setCurrentTab] = useState(initialTab);

  const handleClickTab = (tabId: T) => {
    setCurrentTab(tabId);
  };

  return { currentTab, handleClickTab };
}
