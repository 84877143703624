import React, { FC } from 'react';
import { useStore } from 'effector-react';
import { $BasicModal, hideModalEvent } from './ModalBasic.store';
import { ModalBasicProps } from '../Modal.types';
import { ModalCore } from '../ModalCore.component';
import { ReactComponent as Close } from '@svg/outline/close.svg';

/**
 * Компонент модального окна с произвольным контентом
 */
export const ModalBasic: FC<ModalBasicProps> = ({
  children,
  wrapperClassName,
  onCancel,
  withCloseBtn = true,
  closeBtn: CloseBtn,
}) => {
  const basicModal = useStore($BasicModal);

  const handleCancel = () => {
    hideModalEvent();

    if (onCancel) onCancel();
  };

  return (
    <ModalCore
      isOpen={basicModal}
      variant="basic"
      hideModal={hideModalEvent}
      wrapperClassName={wrapperClassName}
      onCancel={onCancel}
    >
      {withCloseBtn && (
        <div className="flex justify-end">
          {CloseBtn !== undefined ? (
            <div className="flex cursor-pointer" onClick={handleCancel}>
              {CloseBtn}
            </div>
          ) : (
            <Close className="cursor-pointer text-blue-800" onClick={handleCancel} />
          )}
        </div>
      )}
      {children}
    </ModalCore>
  );
};
