import React, { FocusEvent } from 'react';
import styles from './BasicInput.module.css';
import { ReactComponent as InfoIcon } from '@svg/solid/info.svg';
import InputMask, { Props } from 'react-input-mask';

type MaskProps = Partial<Pick<Props, 'mask' | 'maskChar'>>;

export type BasicInputProps = Omit<JSX.IntrinsicElements['input'], 'placeholder' | 'ref'> &
  MaskProps & {
    label?: string; // Одновременно выполняет роль placeholder'а
    error?: string;
    containerClassName?: string;
  };

export const BasicInput = React.forwardRef<HTMLInputElement, BasicInputProps>(
  ({ label, error, containerClassName, ...props }, ref) => {
    const containerClassList = [styles['container'], containerClassName].filter(Boolean);
    error && containerClassList.push(styles['container-error']);

    const handleFocus = (event: FocusEvent<HTMLInputElement>) => {
      event.target.classList.add(styles['input_focused']);

      if (props.onFocus) {
        props.onFocus(event);
      }
    };

    const handleBlur = (event: FocusEvent<HTMLInputElement>) => {
      /** Если поле заполнено, то не удаляем класс фокуса, чтобы зафиксировать элемент label над полем ввода */
      if (event.target.value === '') {
        event.target.classList.remove(styles['input_focused']);
      }

      if (props.onBlur) {
        props.onBlur(event);
      }
    };

    const inputClassname = [styles['input'], props.className, props.value ? styles['input_focused'] : '']
      .filter(Boolean)
      .join(' ');

    return (
      <label className={containerClassList.join(' ')}>
        <InputMask
          inputRef={ref}
          {...props}
          mask={props.mask ?? ''}
          onBlur={handleBlur}
          onFocus={handleFocus}
          placeholder={label ?? '*'}
          className={inputClassname}
        />
        <span className={styles['label']}>{label}</span>
        <div className={styles['divider']} />
        <p className={styles['error']}>
          {error && (
            <>
              <InfoIcon color={'white'} width={14} height={14} />
              <span>{error}</span>
            </>
          )}
        </p>
      </label>
    );
  },
);
