import React, { FC, PropsWithChildren, ReactElement } from 'react';
import { useAuthorization } from '../../useAuthorization';
import { Navigate } from 'react-router-dom';

export type ProtectedAccessProps = PropsWithChildren & {
  // Правила для авторизованных пользователей
  loggedRules?: {
    // Куда перенаправляем авторизованного пользователя
    redirectTo: string;
  };
  // Куда перенаправляем не авторизованного пользователя
  redirectTo?: string;
  // Разрешить не авторизованным пользователям доступ
  isPublic?: boolean;
};

/**
 * Добавляет логику проверки наличия авторизации для доступа к контенту (incl. loader, error)
 * @param OriginalComponent - компонент (преимущественно страница)
 * @param loggedRules - правила доступа для авторизованных пользователей
 * @param redirectTo - куда перенаправляем не авторизованных пользователей
 */
export const ProtectedAccess: FC<ProtectedAccessProps> = ({ children, loggedRules, redirectTo, isPublic }) => {
  const { isLoading, isAuthorized, whoTryLogin } = useAuthorization();

  if (whoTryLogin === null) {
    if (isLoading && !isPublic) {
      return <p>Authorization loader</p>;
    } else if (!isAuthorized && !isPublic) {
      return redirectTo ? <Navigate to={redirectTo} replace={true} /> : <p>Authorization required</p>;
    } else if (isAuthorized && loggedRules) {
      return <Navigate to={loggedRules.redirectTo} replace={true} />;
    }
  }

  // https://github.com/DefinitelyTyped/DefinitelyTyped/issues/33006
  return children as ReactElement;
};
