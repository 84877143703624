/** TODO на данный момент функция обрабатывает не все кейсы ссылок
 * например, ссылку такого вида функция не обработает - https://www.youtube.com/watch?v=tZHKHsuI1dI&list=RDi7NnvtjCBDI&index=2
 * https://github.com/spiks/most-edu-front-public/issues/370
 */
import { LONG_YOUTUBE_LINK_REG_EXP, SHORT_YOUTUBE_LINK_REG_EXP } from '@shared/constants/regexp';

/**
 *
 * Функция возражает ссылку на встраиваемое видео с youtube
 * @param value ссылка на ютуб
 */
export const getYoutubeEmbedLink = (value: string): string => {
  if (SHORT_YOUTUBE_LINK_REG_EXP.test(value)) {
    const values = SHORT_YOUTUBE_LINK_REG_EXP.exec(value);
    if (values === null) return '';
    return `https://www.youtube.com/embed/${values[1]}`;
  }

  if (LONG_YOUTUBE_LINK_REG_EXP.test(value)) {
    const values = LONG_YOUTUBE_LINK_REG_EXP.exec(value);
    if (values === null) return '';
    return `https://www.youtube.com/embed/${values[2]}`;
  }

  return value;
};
