import { studentApiClient } from '@shared/api/client/client';
import {
  StudentCreateOrderCreated,
  StudentCreateOrderExisting,
  StudentCreateOrderWithEmptyModuleIdsRequestSchema,
  StudentCreateOrderWithEmptySoloCourseIdsRequestSchema,
  StudentGetOrderStatusRequestSchema,
  StudentGetOrderStatusResponseSchema,
  StudentGetTotalCountOfPaymentsRequestSchema,
  StudentGetTotalCountOfPaymentsResponseSchema,
  StudentListPaymentCardsResponseSchema,
  StudentListPaymentHistoryRequestSchema,
  StudentListPaymentHistoryResponseSchema,
} from '@generated-student';
import { ApiSuccessResponse } from '@shared/api/client/responses/ApiSuccessResponse';

export class PaymentService {
  static getListPaymentCards() {
    return studentApiClient.post<ApiSuccessResponse<StudentListPaymentCardsResponseSchema>>('/listPaymentCards');
  }
  static createOrder(
    data: StudentCreateOrderWithEmptyModuleIdsRequestSchema | StudentCreateOrderWithEmptySoloCourseIdsRequestSchema,
  ) {
    return studentApiClient.post<ApiSuccessResponse<StudentCreateOrderCreated | StudentCreateOrderExisting>>(
      '/createOrder',
      data,
    );
  }
  static getOrderStatus(data: StudentGetOrderStatusRequestSchema) {
    return studentApiClient.post<ApiSuccessResponse<StudentGetOrderStatusResponseSchema>>('getOrderStatus', data);
  }
  static getListPaymentHistory(data: StudentListPaymentHistoryRequestSchema) {
    return studentApiClient.post<ApiSuccessResponse<StudentListPaymentHistoryResponseSchema>>(
      'listPaymentHistory',
      data,
    );
  }
  static getTotalCountOfPayments(data: StudentGetTotalCountOfPaymentsRequestSchema) {
    return studentApiClient.post<ApiSuccessResponse<StudentGetTotalCountOfPaymentsResponseSchema>>(
      'getTotalCountOfPayments',
      data,
    );
  }
}
