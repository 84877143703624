import React, { FC } from 'react';
import styles from './NewBankCard.module.css';
import Plus from '@svg/immutable/plus.svg';

type NewBankCardProps = {
  id: string;
  isActive: boolean;
  size?: 's' | 'm';
  onClick?: (id: string) => void;
};

export const NewBankCard: FC<NewBankCardProps> = ({ size = 'm', onClick, isActive, id }) => {
  const containerClassName = `${styles[`bank-card_size-${size}`]} ${isActive ? styles['bank-card_active'] : ''}`;
  const onClickHandler = () => {
    onClick && onClick(id);
  };
  return (
    <button className={containerClassName} onClick={onClickHandler}>
      <div className={'flex flex-col items-center gap-[11px]'}>
        <img className={styles[`plus-image-${size}`]} src={Plus} alt={'PLUS'} />
        <div className={styles['title-wrapper']}>
          <span className={styles[`title-${size}`]}>Оплата новой картой</span>
        </div>
      </div>
    </button>
  );
};
