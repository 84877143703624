import { InputFormField } from '@ui-kit/Input/Input.component';
import { Button } from '@ui-kit/Button/Button.component';
import React, { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { requiredValidator } from '@shared/constants/validators/required.validator';
import { maxLengthValidator } from '@shared/constants/validators/maxLength.validator';
import { emailValidator } from '@shared/constants/validators/email.validator';
import { EmailFormProps } from './EmailForm.typedef';

export const EmailForm = ({ onSubmit, initialValues }: EmailFormProps) => {
  const {
    handleSubmit,
    control,
    reset,
    formState: { errors },
  } = useForm({ defaultValues: initialValues });

  useEffect(() => {
    reset(initialValues);
  }, [initialValues]);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Controller
        rules={{
          ...requiredValidator('Email'),
          ...maxLengthValidator(255),
          ...emailValidator(),
        }}
        control={control}
        render={({ field }) => {
          return (
            <InputFormField {...field} errorMessage={errors.email?.message} placeholder={'Введите электронную почту'} />
          );
        }}
        name={'email'}
      />

      <div className={'flex items-center justify-center mt-2'}>
        <Button variant={'filled'}>Сохранить</Button>
      </div>
    </form>
  );
};
