import { ApiValidationError } from '@shared/api/client/responses/ApiErrorFactory';

type Violation = typeof ApiValidationError.prototype.data.violations[number];
type ViolationTranslator<T extends Violation['type']> = (violation: Violation & { type: T }) => string;

type ApiValidationErrorTranslateMap<T extends Violation['type'] = Violation['type']> = {
  [key in T]: ViolationTranslator<key>;
};

const API_VALIDATION_ERROR_TRANSLATE: ApiValidationErrorTranslateMap = {
  enum_value_is_not_allowed(violation) {
    let allowedValues = '';

    /** Поля allowedValues может не быть в объекте ошибки */
    if (violation.allowedValues !== undefined) {
      allowedValues = violation.allowedValues.join(', ');

      allowedValues = `, допустимые значения: ${allowedValues}`;
    }

    return `Значение не допустимо для поля "${violation.pointer}${allowedValues}`;
  },
  one_of_fields_must_be_not_empty(violation) {
    return `Одно из полей ${violation.fields} не должно быть пустым`;
  },
  date_range_is_not_valid(violation) {
    return `Значения в поле "${violation.pointer}" не корректны`;
  },
  array_is_not_unique(violation) {
    return `Значения в поле "${violation.pointer}" не уникальны`;
  },
  array_is_too_long(violation) {
    return `Слишком много значений в поле "${violation.pointer}"`;
  },
  array_should_have_exact_length(violation) {
    return `Поле "${violation.pointer}" должно содержать ${violation.length} значений`;
  },
  array_is_too_short(violation) {
    return `Поле "${violation.pointer}" должно содержать больше значений`;
  },
  array_is_not_valid(violation) {
    return `Поле "${violation.pointer}" содержит неверные значения`;
  },
  date_is_not_valid(violation) {
    return `Поле "${violation.pointer}" содержит неверную дату`;
  },
  mandatory_field_missing(violation) {
    return `Поле "${violation.pointer}" обязательно для заполнения`;
  },
  value_does_not_match_regex(violation) {
    return `Поле "${violation.pointer}" не соответствует формату ${violation.regex}`;
  },
  wrong_property_type(violation) {
    return `Поле "${violation.pointer}" должно быть типа ${violation.allowedTypes.join(', ')}`;
  },
  number_is_too_small(violation) {
    return `Поле "${violation.pointer}" должно быть больше ${violation.min}`;
  },
  number_is_too_big(violation) {
    return `Поле "${violation.pointer}" должно быть меньше ${violation.max}`;
  },
  string_is_too_long(violation) {
    return `Поле "${violation.pointer}" должно быть короче ${violation.maxLength} символов`;
  },
  string_is_too_short(violation) {
    return `Поле "${violation.pointer}" должно быть длиннее ${violation.minLength} символов`;
  },
  timezone_is_not_valid(violation) {
    return `Поле "${violation.pointer}" содержит неверный часовой пояс`;
  },
  value_should_not_be_null(violation) {
    return `Поле "${violation.pointer}" должно быть указано обязательно`;
  },
  uuid_is_not_valid(violation) {
    return `Невалидный формат UUID поля ${violation.pointer}`;
  },
  too_few_search_words() {
    return '';
  },
} as const;

export class ApiValidationErrorTranslate {
  public static translateViolation(violation: Violation): string {
    // https://github.com/microsoft/TypeScript/issues/42790
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    return API_VALIDATION_ERROR_TRANSLATE[violation.type]?.(violation) ?? '';
  }
}
