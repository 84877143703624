import React, { forwardRef } from 'react';
import style from './DatePicker.module.css';
import { Dropdown } from '@shared/ui-kit/Dropdown/Dropdown.component';
import { getDayOptions, getLastHundredYears, MONTH_LIST } from '@shared/ui-kit/DatePicker/DatePicker.utils';
import { withFormField } from '@shared/ui-kit/FormField';
import dayjs from 'dayjs';

type DatePickerProps = {
  name?: string;
  value: Date | undefined;
  onChange: (date: Date) => void;
};

export const DatePicker = forwardRef<HTMLInputElement, DatePickerProps>(({ name, value, onChange }, ref) => {
  const validDate = dayjs(value).isValid() ? value : undefined;
  return (
    <div>
      <input
        ref={ref}
        name={name}
        className="hidden"
        value={validDate !== undefined ? validDate.getUTCDate() : undefined}
        type={'date'}
      />
      <div className={style['datePicker']}>
        <Dropdown
          selectedItem={
            validDate !== undefined
              ? {
                  value: validDate.getDate().toString(),
                  label: validDate.getDate().toString(),
                }
              : undefined
          }
          onChange={(val) => {
            onChange(new Date(new Date(validDate ?? new Date()).setDate(Number(val.value))));
          }}
          viewType="default"
          options={getDayOptions(validDate ?? new Date())}
        />
        <Dropdown
          selectedItem={
            validDate !== undefined
              ? {
                  value: validDate.getMonth().toString(),
                  label: MONTH_LIST[validDate.getMonth()]?.label ?? '',
                }
              : undefined
          }
          onChange={(val) => {
            onChange(new Date(new Date(validDate ?? new Date()).setMonth(Number(val.value))));
          }}
          viewType="default"
          options={MONTH_LIST}
        />
        <Dropdown
          selectedItem={
            validDate !== undefined
              ? {
                  value: validDate.getFullYear().toString(),
                  label: validDate.getFullYear().toString(),
                }
              : undefined
          }
          onChange={(val) => {
            onChange(new Date((validDate ?? new Date()).setFullYear(Number(val.value))));
          }}
          viewType="default"
          options={getLastHundredYears()}
        />
      </div>
    </div>
  );
});

export const FormDatePicker = withFormField(DatePicker);
