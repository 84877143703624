import React, { FC, useEffect } from 'react';
import style from './PassportForm.module.css';
import { Controller, useForm } from 'react-hook-form';
import { StaticFieldWithLabel } from '@modules/documents/components/PassportForm/StaticField';
import { PASSPORT_FORM_INPUTS_DATA } from '@modules/documents/components/PassportForm/constants';
import { DocumentsForm } from '@modules/documents/types/documents.types';
import { GENDER_SELECT_ITEMS } from '@shared/constants';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { passportSerialValidator } from '@shared/constants/validators/passportSerial.validator';
import { passportNumberValidator } from '@shared/constants/validators/passportNumber.validator';
import { passportIssuerIdValidator } from '@shared/constants/validators/passportIssuerId.validator';
import { nameValidator, patronymicValidator, surnameValidator } from '@shared/constants/validators/name.validator';
import { birthdayValidator } from '@shared/constants/validators/birthday.validator';
import { genderValidator } from '@shared/constants/validators/gender.validator';
import { passportIssuedValidator } from '@shared/constants/validators/passportIssued.validator';
import { residenceValidator } from '@shared/constants/validators/residence.validator';
import { issuerNameValidator } from '@shared/constants/validators/issuerName.validator';

type PassportFormProps = {
  data: DocumentsForm | null;
  onSubmit: (data: DocumentsForm) => void;
  isEditMode: boolean;
  id?: string;
};

// const REQUIRED_MSG = 'Поле обязательно к заполнению';

const schema = yup.object({
  issuerName: issuerNameValidator,
  serial: passportSerialValidator,
  number: passportNumberValidator,
  issuedAt: passportIssuedValidator,
  issuerId: passportIssuerIdValidator,
  name: nameValidator,
  surname: surnameValidator,
  patronymic: patronymicValidator,
  residence: residenceValidator,
  gender: genderValidator,
  birthday: birthdayValidator,
});

export const PassportForm: FC<PassportFormProps> = ({ data, onSubmit, isEditMode, id }) => {
  const {
    handleSubmit,
    formState: { errors },
    control,
    reset,
  } = useForm<DocumentsForm>({
    resolver: yupResolver(schema),
    defaultValues: {
      issuerName: '',
      serial: '',
      number: '',
      issuedAt: undefined,
      issuerId: '',
      name: '',
      surname: '',
      patronymic: undefined,
      residence: '',
      gender: 'male',
      birthday: undefined,
    },
  });

  useEffect(() => {
    if (!data) return;
    reset(data);
  }, [data]);

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      className={`${style['form']} ${isEditMode ? style['form_edit-mode'] : ''}`}
      id={id}
    >
      {PASSPORT_FORM_INPUTS_DATA.map(({ id, label, baseInput: BaseInput, mask, required }) => (
        <div className={style[id]} key={id}>
          <Controller
            name={id}
            control={control}
            render={({ field: { value, onChange, name } }) => {
              return (
                <>
                  <>
                    {isEditMode ? (
                      <BaseInput
                        required={required}
                        id={name}
                        label={label}
                        value={value}
                        onChange={onChange}
                        items={GENDER_SELECT_ITEMS}
                        mask={mask}
                        maskChar={null}
                      />
                    ) : (
                      <StaticFieldWithLabel
                        inputValue={value || undefined}
                        label={label}
                        id={name}
                        classnameConfig={{ label: style['field-label'] }}
                      />
                    )}
                  </>
                  <span className={'text-14-regular text-red'}>{errors[name]?.message}</span>
                </>
              );
            }}
          />
        </div>
      ))}
    </form>
  );
};
