import { studentApiClient } from '@shared/api/client/client';
import { ApiSuccessResponse } from '@shared/api/client/responses/ApiSuccessResponse';
import { StudentProfileResponseSchema, UpdateStudentProfileRequestSchema } from '@generated-student';

export class ProfileService {
  static getProfile() {
    return studentApiClient.post<ApiSuccessResponse<StudentProfileResponseSchema>>('getProfile');
  }

  static updateProfile(data: UpdateStudentProfileRequestSchema) {
    return studentApiClient.post<ApiSuccessResponse>('updateProfile', data);
  }
}
