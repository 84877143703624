import { createEffect, createEvent, createStore } from 'effector';
import { handleError } from '@shared/handle-error/handleError';
import { ApiThrownError } from '@shared/api/client/responses/ApiErrorFactory';
import { CourseId } from '@shared/types/course.types';
import { SoloCourseService } from '@shared/api/client/services/SoloCourse';
import { MapperApiToViewMapper } from '../mappers/user-course.mapper';
import { UserCourseViewModel } from '../types/user-course.types';

type CourseStore = UserCourseViewModel | null;

/**
 * Объявление сторов
 */

export const defaultCourseStore: CourseStore = null;

export const getCourseFx = createEffect<CourseId, UserCourseViewModel, ApiThrownError>(async (courseId) => {
  const {
    data: { data },
  } = await SoloCourseService.getSoloCourse({ id: courseId });

  return MapperApiToViewMapper.getCourse(data);
});
export const resetCourse = createEvent();
export const $Course = createStore<CourseStore>(defaultCourseStore);

/**
 * Бизнес логика листинга модулей
 */

$Course
  .on(resetCourse, () => defaultCourseStore)
  .on(getCourseFx.doneData, (currentStore, course) => course)
  .on(getCourseFx.failData, (currentStore, error) => {
    handleError(error);
  });
