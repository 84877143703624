import { studentApiClient } from '@shared/api/client/client';
import { ApiSuccessResponse } from '@shared/api/client/responses/ApiSuccessResponse';
import {
  StudentGetCurrentNotPassedExerciseInSoloCourseModuleRequestSchema,
  StudentGetCurrentNotPassedExerciseInSoloCourseModuleResponseSchema,
  StudentGetCurrentNotPassedExerciseInSoloCourseRequestSchema,
  StudentGetCurrentNotPassedExerciseInSoloCourseResponseSchema,
  StudentGetExerciseRequestSchema,
  StudentGetExerciseResponseSchema,
  StudentListExercisesRequestSchema,
  StudentListExercisesResponseSchema,
  StudentMarkExerciseAsPassedRequestSchema,
} from '@generated-student';

export class ExerciseService {
  static getListExercises(data: StudentListExercisesRequestSchema) {
    return studentApiClient.post<ApiSuccessResponse<StudentListExercisesResponseSchema>>('listExercises', data);
  }

  static getExercise(data: StudentGetExerciseRequestSchema) {
    return studentApiClient.post<ApiSuccessResponse<StudentGetExerciseResponseSchema>>('getExercise', data);
  }

  static getCurrentNotPassedExerciseInSoloCourse(data: StudentGetCurrentNotPassedExerciseInSoloCourseRequestSchema) {
    return studentApiClient.post<ApiSuccessResponse<StudentGetCurrentNotPassedExerciseInSoloCourseResponseSchema>>(
      'getCurrentNotPassedExerciseInSoloCourse',
      data,
    );
  }

  static getCurrentNotPassedExerciseInSoloCourseModule(
    data: StudentGetCurrentNotPassedExerciseInSoloCourseModuleRequestSchema,
  ) {
    return studentApiClient.post<
      ApiSuccessResponse<StudentGetCurrentNotPassedExerciseInSoloCourseModuleResponseSchema>
    >('getCurrentNotPassedExerciseInSoloCourseModule', data);
  }

  static markExerciseAsPassed(data: StudentMarkExerciseAsPassedRequestSchema) {
    return studentApiClient.post<ApiSuccessResponse<void>>('markExerciseAsPassed', data);
  }
}
