import React, { FC } from 'react';

export const SubNavigationNotification: FC = () => {
  return (
    <div>
      <div className={'p-[18px_20px_18px_30px]'}>
        <h5 className={'text-white'}>{'Ваш профиль'}</h5>
        <span className={'text-16-regular text-white'}>{'Управление входом и личными данными'}</span>
      </div>
    </div>
  );
};
