import React, { FC } from 'react';
import { OptionType } from '../MultiSelect.component';
import { ReactComponent as Close } from '@svg/outline/close.svg';

type MultiSelectTagListProps = {
  tags: OptionType[];
  onRemove: (id: string) => void;
};

export const MultiSelectTagList: FC<MultiSelectTagListProps> = ({ tags, onRemove }) => {
  return (
    <section className={'flex flex-wrap gap-[9px]'}>
      {tags.map(({ value, label }) => {
        return (
          <div
            className={
              'flex p-[5px_10px] bg-blue-500 items-center justify-center gap-2 rounded-[40px] border border-opacity-white-24'
            }
            key={value}
          >
            <span className={'text-grey-400 text-xs'}>{label}</span>
            <button
              onClick={() => {
                onRemove(value);
              }}
            >
              <Close className={'fill-blue-800'} />
            </button>
          </div>
        );
      })}
    </section>
  );
};
