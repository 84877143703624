import React, { FC, Fragment } from 'react';
import { Button } from '@ui-kit/Button/Button.component';
import { useNavigate } from 'react-router-dom';
import { NAVIGATION } from '@shared/constants';
import { toRubles } from '@shared/utils/toRubles';

type PaymentDetailsCardItem = {
  id: string;
  title: string;
  price: string;
  discount: string;
  discountedPrice: string;
};

type PaymentDetailsProps = {
  items: PaymentDetailsCardItem[];
  onBuy: () => void;
};

/** Карточка с деталями оплаты */
export const PaymentDetailsCard: FC<PaymentDetailsProps> = ({ onBuy, items }) => {
  const navigate = useNavigate();

  const handleCancelPayment = () => {
    const coursesList = NAVIGATION.studentCoursesCatalog;

    navigate(coursesList);
  };

  const totalSum = items.reduce((acc, it) => {
    const hasDiscount = Number(it.discount) > 0;
    return acc + (hasDiscount ? Number(it.discountedPrice) : Number(it.price));
  }, 0);

  return (
    <div className="flex flex-col p-5 pt-[30px] rounded-[30px] bg-white">
      <h6 className="mb-5">Детали оплаты</h6>
      <div className="flex flex-col gap-y-5 py-5 border-t border-opacity-darkBlue-10 text-16-regular">
        {items.map((it) => {
          const hasDiscount = Number(it.discount) > 0;
          const priceText = toRubles(Number(it.price));
          const discountText = toRubles(Number(it.discount));
          return (
            <Fragment key={it.id}>
              <div className="flex items-center justify-between gap-x-4">
                <span className={'truncate max-w-[240px]'}>{it.title}</span>
                <span>{priceText}</span>
              </div>
              {hasDiscount && (
                <div className="flex items-center justify-between">
                  <span>Скидка</span>
                  <span>{`-${discountText}`}</span>
                </div>
              )}
            </Fragment>
          );
        })}
      </div>

      <div className="flex flex-col items-stretch pt-5 gap-x-[10px] border-t border-opacity-darkBlue-10">
        <div className="flex items-center justify-between mb-5">
          <h6>Итого:</h6>
          <h6>{toRubles(totalSum)}</h6>
        </div>

        <div className="flex items-center justify-center gap-x-[10px]">
          <Button variant="ghost" onClick={handleCancelPayment}>
            Отменить
          </Button>
          <Button disabled={!items.length} variant="filled" onClick={onBuy}>
            Оплатить
          </Button>
        </div>
      </div>
    </div>
  );
};
