import React, { FC } from 'react';
import { CourseCardViewModel } from '../../types/course.types';
import { FullyPurchasedCourseCard } from './FullyPurchasedCourseCard.component';
import { NotPurchasedCourseCard } from './NotPurchasedCourseCard.component';
import { PartiallyPurchasedCourseCard } from './PartiallyPurchasedCourseCard.component';

export type CourseCardProps = CourseCardViewModel & {
  withButton: boolean;
  isDisabled: boolean;
};

/** Карточка курса */
export const CourseCard: FC<CourseCardProps> = (props) => {
  if (props.type === 'not_purchased_solo_course') {
    return (
      <NotPurchasedCourseCard
        author={props.author}
        longDescription={props.longDescription}
        id={props.id}
        coverUrl={props.coverUrl}
        discountPercentage={props.discountPercentage}
        type={props.type}
        modulesAmount={props.modulesAmount}
        title={props.title}
        direction={props.direction}
        shortDescription={props.shortDescription}
        studyHours={props.studyHours}
        discount={props.discount}
        price={props.price}
        discountedPrice={props.discountedPrice}
        withButton={props.withButton}
        isDisabled={props.isDisabled}
        isOnlyFullPurchase={props.isOnlyFullPurchase}
      />
    );
  }

  if (props.type === 'partially_purchased_solo_course') {
    return (
      <PartiallyPurchasedCourseCard
        author={props.author}
        id={props.id}
        coverUrl={props.coverUrl}
        purchasedModulesAmount={props.purchasedModulesAmount}
        longDescription={props.longDescription}
        discountPercentage={props.discountPercentage}
        type={props.type}
        modulesAmount={props.modulesAmount}
        title={props.title}
        direction={props.direction}
        shortDescription={props.shortDescription}
        studyHours={props.studyHours}
        discount={props.discount}
        price={props.price}
        discountedPrice={props.discountedPrice}
        withButton={props.withButton}
        isDisabled={props.isDisabled}
        isOnlyFullPurchase={props.isOnlyFullPurchase}
      />
    );
  }

  if (props.type === 'fully_purchased_solo_course') {
    return (
      <FullyPurchasedCourseCard
        author={props.author}
        longDescription={props.longDescription}
        id={props.id}
        coverUrl={props.coverUrl}
        type={props.type}
        modulesAmount={props.modulesAmount}
        title={props.title}
        direction={props.direction}
        shortDescription={props.shortDescription}
        isOnlyFullPurchase={props.isOnlyFullPurchase}
        studyHours={props.studyHours}
        withButton={props.withButton}
      />
    );
  }

  return null;
};
