import React, { FC, RefObject, useEffect } from 'react';
import { useModuleExercises } from '@modules/module/hooks/useModuleExercises';
import { ModuleExerciseCard } from '@modules/module/components/ModuleExerciseCard/ModuleExerciseCard.component';
import { InfiniteScroll } from '@shared/components/InfiniteScroll/InfiniteScroll.component';

type ModuleExercisesProps = {
  wrapperRef: RefObject<HTMLElement>;
  onClickExercise: (exerciseId: string) => void;
  getModuleExercises: () => void;
};

export const ModuleExercises: FC<ModuleExercisesProps> = ({ wrapperRef, onClickExercise, getModuleExercises }) => {
  const { moduleExercises, loadMoreExercises } = useModuleExercises({ getModuleExercises });

  useEffect(() => {
    if (moduleExercises.items.length === 1) {
      onClickExercise(moduleExercises.items[0].id);
    }
  }, [moduleExercises]);

  return (
    <InfiniteScroll
      scrollContainerRef={wrapperRef}
      loadMore={loadMoreExercises}
      hasMore={moduleExercises.items.length < moduleExercises.itemsAmount}
      containerClassName="flex flex-col gap-y-[40px]"
    >
      {moduleExercises.itemsAmount > 1 &&
        moduleExercises.items.map((exercise, idx) => (
          <ModuleExerciseCard key={exercise.id} onClick={onClickExercise} data={exercise} idx={idx} />
        ))}
    </InfiniteScroll>
  );
};
