import { CartStoreCourse, CartStoreModule } from '@shared/types/cart.types';
import { createEvent, createStore } from 'effector';
import { persist } from 'effector-storage/local';

export const $Cart = createStore<(CartStoreCourse | CartStoreModule)[]>([]);

export const addToCartEvent = createEvent<CartStoreCourse | CartStoreModule>();
export const removeFromCartEvent = createEvent<string>();
export const resetCartEvent = createEvent();
export const updateCartEvent = createEvent<string[]>();

$Cart.on(addToCartEvent, (state, payload) => {
  return [...state, payload];
});

$Cart
  .on(removeFromCartEvent, (state, id) => {
    return state.filter((course) => {
      return course.id !== id;
    });
  })
  .on(updateCartEvent, (state, payload) => {
    const newState = state.filter(({ id }) => {
      return !payload.includes(id);
    });
    return newState;
  })
  .reset(resetCartEvent);

persist({ store: $Cart, key: 'MOST_EDU_PUBLIC_CART' });
