import React, { ElementType, FC, InputHTMLAttributes } from 'react';
import styles from './RadioButton.module.css';

type RadioButtonStyleConfig = {
  labelStyle: string;
};

export interface RadioButtonProps extends InputHTMLAttributes<HTMLInputElement> {
  label?: string;
  currentValue: string;
  externalCustomRadioComponent?: ElementType<{ isChecked: boolean }>;
  styleConfig?: Partial<RadioButtonStyleConfig>;
}

export const RadioButton: FC<RadioButtonProps> = ({
  currentValue,
  value,
  label,
  styleConfig,
  externalCustomRadioComponent: ExternalCustomRadioComponent,
  ...props
}) => {
  const isChecked = currentValue === value;
  const shouldRenderExternalCustomComponent = ExternalCustomRadioComponent !== undefined;
  const { labelStyle } = styleConfig || {};

  return (
    <label htmlFor={props.id} className={`${styles['radio-label']} ${labelStyle ?? ''}`}>
      <input {...props} checked={isChecked} className={styles['radio-input']} type="radio" hidden />
      {shouldRenderExternalCustomComponent ? (
        <ExternalCustomRadioComponent isChecked={isChecked} />
      ) : (
        <span className={`${styles['custom-radio']} ${isChecked && styles['custom-radio_checked']}`} />
      )}
      {label}
    </label>
  );
};
