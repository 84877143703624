import { createEffect, createEvent, createStore } from 'effector';
import { handleError } from '@shared/handle-error/handleError';
import { ApiThrownError } from '@shared/api/client/responses/ApiErrorFactory';
import { PaymentCardViewModel } from '../types/payment-card';
import { paymentCardResponseDataToViewModelMappers } from '@modules/payment/mappers/payment-card.mapper';
import { PaymentService } from '@shared/api/client/services/Payment';

type PaymentCardListStore = Array<PaymentCardViewModel>;

/**
 * Объявление сторов
 */

export const defaultPaymentCardListStore: PaymentCardListStore = [];

/** Модель списка банковских карт
 *
 *
 */
export const getPaymentCardListFx = createEffect<void, PaymentCardListStore, ApiThrownError>(async () => {
  const {
    data: { data },
  } = await PaymentService.getListPaymentCards();

  return paymentCardResponseDataToViewModelMappers.getPaymentCardList(data);
});
export const resetPaymentCardList = createEvent();
export const $PaymentCardList = createStore<PaymentCardListStore>(defaultPaymentCardListStore);

/**
 * Бизнес логика списка банковских карт
 */
$PaymentCardList.on(resetPaymentCardList, () => defaultPaymentCardListStore);
$PaymentCardList.on(getPaymentCardListFx.doneData, (prev, paymentCards) => paymentCards);
$PaymentCardList.on(getPaymentCardListFx.failData, (prev, error) => {
  handleError(error);
});
