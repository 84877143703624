import { FilePurpose, FileUploadCredentials } from '@generated-student';
import { fileApiClient } from '@shared/api/client/client';
import { ApiSuccessResponse } from '@shared/api/client/responses/ApiSuccessResponse';

export type RequestFileUploadUrl = {
  purpose: FilePurpose;
};

export class FileService {
  static getUploadUrl(data: RequestFileUploadUrl) {
    return fileApiClient.post<ApiSuccessResponse<FileUploadCredentials>>('/requestFileUploadUrl', data);
  }
}
