import React from 'react';
import Clock from '@svg/solid/clock.svg';
import { Button } from '@ui-kit/Button/Button.component';

const Pending = ({ onClick }: { onClick: () => void }) => {
  return (
    <div className={'flex flex-col items-center gap-[32px] w-[429px] justify-center'}>
      <img alt={'ERROR'} src={Clock} className={'w-[100px] h-[100px]'} />
      <div className={'text-center'}>
        <h4 className={'pb-[20px]'}>Ваша оплата в обработке</h4>
        <p>Ваш платёж принят обрабатывается. Попробуйте купить курс позже.</p>
      </div>
      <Button onClick={onClick} variant="outlined">
        Хорошо
      </Button>
    </div>
  );
};

export default Pending;
