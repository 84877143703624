import { isIssuedAuthorizationCredentials } from '@shared/guards/isIssuedAuthorizationCredentials';
import { IssuedAuthorizationCredentials } from '@generated-student';

const itemPrefix = 'MOST_EDU_PUBLIC_';

export class ClientStorage {
  public static getCredentials() {
    const json = ClientStorage.get('CREDENTIALS');

    if (!json) {
      return false;
    }

    const cred = JSON.parse(json);
    const isValid = isIssuedAuthorizationCredentials(cred);
    return isValid ? cred : false;
  }

  public static setCredentials(cred: IssuedAuthorizationCredentials) {
    return ClientStorage.set('CREDENTIALS', JSON.stringify(cred));
  }

  public static clearCredentials() {
    return localStorage.removeItem(itemPrefix + 'CREDENTIALS');
  }

  public static clearCart() {
    return localStorage.removeItem(itemPrefix + 'CART');
  }

  private static get(key: string) {
    return localStorage.getItem(itemPrefix + key);
  }

  private static set(key: string, value: string) {
    return localStorage.setItem(itemPrefix + key, value);
  }
}
