import React, { FC } from 'react';
import { Button } from '@ui-kit/Button/Button.component';
import styles from './ModuleExerciseCard.module.css';
import { ReactComponent as WikiIcon } from '@svg/solid/wiki.svg';
import { ReactComponent as TapIcon } from '@svg/solid/tap.svg';
import { ModuleExercise } from '@modules/module/types/module.types';

type ModuleExerciseCardProps = {
  data: ModuleExercise;
  onClick: (id: string) => void;
  idx: number;
};

export const ModuleExerciseCard: FC<ModuleExerciseCardProps> = ({ data, onClick, idx }) => {
  const { isPassed, title, description } = data;
  const titleWithIdx = `${++idx}. ${title}`;

  return (
    <div className={`${!isPassed ? styles['moduleEducationCard_active'] : styles['moduleEducationCard']}`}>
      <h4 className={`mb-[15px] text-xl ${!isPassed ? 'text-black' : 'text-white'} m:text-2xl`}>{titleWithIdx}</h4>
      <p className={'mb-[30px] text-s m:text-m'}>{description}</p>
      <Button
        variant={'outlined'}
        rightIcon={isPassed ? <TapIcon className={'text-blue-800'} /> : <WikiIcon className={'text-blue-800'} />}
        type={'button'}
        onClick={() => onClick(data.id)}
      >
        Смотреть
      </Button>
    </div>
  );
};
