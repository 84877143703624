import React from 'react';
import styles from './PasswordChangeForm.module.css';
import { useForm } from 'react-hook-form';
import { PasswordInput } from '@ui-kit/Input/PasswordInput.component';
import { Button } from '@ui-kit/Button/Button.component';
import { PasswordValues } from '@shared/types/password.typedef';

type PasswordChangeFormProps = {
  onSubmit: (data: string) => void;
};
const PasswordChangeForm = ({ onSubmit }: PasswordChangeFormProps) => {
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm<PasswordValues>();

  const submitHandler = (values: PasswordValues) => {
    onSubmit(values.password);
  };
  return (
    <div className={styles['wrapper']}>
      <div>
        <h6 className={'text-m'}>Безопасность</h6>
      </div>
      <div className={styles['password-block']}>
        <div>
          <h6 className={'text-m'}>Пароль</h6>
        </div>
        <form onSubmit={handleSubmit(submitHandler)} className={styles['password-form']}>
          <div className={'flex flex-col gap-[10px] items-stretch'}>
            <label>Пароль</label>
            <PasswordInput {...register('password', { required: true })} />
          </div>
          <div className={'flex flex-col gap-[10px] items-stretch'}>
            <label>Повторите пароль</label>
            <PasswordInput
              {...register('passwordConfirm', {
                required: true,
                validate: (val: string) => {
                  if (watch('password') !== val) {
                    return 'Пароли не совпадают';
                  }
                },
              })}
            />
            {errors?.passwordConfirm && <p className={'text-red'}>{errors.passwordConfirm.message}</p>}
          </div>
          <div className={'flex justify-center mt-auto'}>
            <Button variant={'ghost'} type={'reset'}>
              Отменить
            </Button>
            <Button variant={'filled'}>Сохранить</Button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default PasswordChangeForm;
