import { createEffect, createEvent, createStore } from 'effector';
import { NotPurchasedCourseViewModel } from '../../types/course.types';
import { handleError } from '@shared/handle-error/handleError';
import { ApiThrownError } from '@shared/api/client/responses/ApiErrorFactory';
import { SoloCourseService } from '@shared/api/client/services/SoloCourse';
import { courseMappers } from '../course/course.mapper';
import { CourseId } from '@shared/types/course.types';

type CoursePurchaseStore = NotPurchasedCourseViewModel[];

/**
 * Объявление сторов
 */

export const $CoursesToPurchase = createStore<CoursePurchaseStore>([]);

/** Модель курса
 *
 *
 */
export const getCourseToPurchaseFx = createEffect<CourseId, NotPurchasedCourseViewModel, ApiThrownError>(
  async (courseId) => {
    const {
      data: { data },
    } = await SoloCourseService.getSoloCourseToPurchase({ soloCourseId: courseId });

    return courseMappers.mapToNotPurchasedCourseViewModel({ ...data, type: 'not_purchased_solo_course' });
  },
);

export const getCoursesToPurchaseFx = createEffect(
  async (ids: string[]) => await Promise.all(ids.map((id) => getCourseToPurchaseFx(id))),
);

export const resetCourseToPurchaseEvent = createEvent();

/**
 * Бизнес логика курса
 */
$CoursesToPurchase.on(resetCourseToPurchaseEvent, () => []);
$CoursesToPurchase.on([getCoursesToPurchaseFx.doneData], (_, payload) => payload);
$CoursesToPurchase.on(getCourseToPurchaseFx.doneData, (_, payload) => [payload]);

$CoursesToPurchase.on([getCoursesToPurchaseFx.failData, getCourseToPurchaseFx.failData], (error) => handleError(error));
