import React, { forwardRef } from 'react';
import { DropdownProps } from './Dropdown.types';
import { DropdownCore } from './DropdownCore.component';
import styles from './Dropdown.module.css';
import { ReactComponent as CheckIcon } from '@svg/outline/check.svg';
import { withFormField } from '@shared/ui-kit/FormField';

/**
 * Компонент скрывает под собой логику определения
 * конкртеной вьюхи под переданный тип
 */
export const Dropdown = forwardRef<HTMLInputElement, DropdownProps>(
  ({ viewType = 'default', prefix, ...dropdownProps }, ref) => {
    switch (viewType) {
      case 'grouped':
        return (
          <DropdownCore
            {...dropdownProps}
            ref={ref}
            withDivider
            selectClassName={[styles['dropdown-grouped__select'], 'text-gradient-blue-dark-horizontal'].join(' ')}
            arrowClassName={styles['dropdown-grouped__arrow']}
            itemRender={(option) => (
              <div className="flex items-center">
                <div className="mr-[10px] w-[20px] h-[20px]">
                  {option.selected && <CheckIcon width={20} height={20} />}
                </div>
                <span className="text-14-regular">{option.label}</span>
              </div>
            )}
            placement="bottom-right"
            prefix={
              prefix !== undefined ? (
                <span className="text-18-regular text-grey-400 truncate">{prefix}</span>
              ) : undefined
            }
          />
        );
      default:
        return (
          <DropdownCore
            {...dropdownProps}
            ref={ref}
            prefix={prefix}
            menuClassName={styles['dropdown-default__menu']}
            arrowClassName={styles['dropdown-default__arrow']}
          />
        );
    }
  },
);

export const FormDropdown = withFormField(Dropdown);
