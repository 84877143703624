import { createEffect, createStore, createEvent } from 'effector';
import { ApiThrownError } from '@shared/api/client/responses/ApiErrorFactory';
import { handleError } from '@shared/handle-error/handleError';
import { StudentGetSoloCourseModuleToPurchaseRequestSchema } from '@generated-student';
import { NotPurchasedModuleViewModel } from '@modules/module/types/module.types';
import { MapperApiToViewMapper } from '@modules/module/mappers/module.mapper';
import { SoloCourseModuleService } from '@shared/api/client/services/SoloCourseModule';

type ModulesToPurchaseStore = NotPurchasedModuleViewModel[];

export const $ModulesToPurchase = createStore<ModulesToPurchaseStore>([]);
export const resetModulesToPurchaseEvent = createEvent();

export const getNotPurchasedModuleFx = createEffect<
  StudentGetSoloCourseModuleToPurchaseRequestSchema,
  NotPurchasedModuleViewModel,
  ApiThrownError
>(async (requestData) => {
  const {
    data: { data },
  } = await SoloCourseModuleService.getSoloCourseModuleToPurchase(requestData);

  return MapperApiToViewMapper.getNotPurchasedModule(data);
});

export const getNotPurchasedModulesFx = createEffect(
  async (ids: string[]) => await Promise.all(ids.map((id) => getNotPurchasedModuleFx({ moduleId: id }))),
);

$ModulesToPurchase.on(resetModulesToPurchaseEvent, () => []);

$ModulesToPurchase.on(getNotPurchasedModulesFx.doneData, (_, payload) => payload);

$ModulesToPurchase.on(getNotPurchasedModuleFx.failData, (_, error) => {
  handleError(error);
});
