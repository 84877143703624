import { StudentProfileResponseSchema, UpdateStudentProfileRequestSchema } from '@generated-student';
import { UserProfile } from './user-profile.types';
import dayjs from 'dayjs';
import { YEAR_MONTH_DAY_DATE_FORMAT } from '@shared/constants/date';
import { KEEP_AS_IS } from '@shared/constants';
import { isTimezone } from '@shared/guards/isTimezone';
import { DEFAULT_TIMEZONE } from '@shared/constants/timezone';

export const mapDataToUpdateUserProfile = (userProfile: UserProfile): UpdateStudentProfileRequestSchema => {
  return {
    birthday: dayjs(userProfile.birthday).format(YEAR_MONTH_DAY_DATE_FORMAT),
    gender: userProfile.gender,
    name: userProfile.name,
    patronymic: userProfile.patronymic || null,
    residence: userProfile.residence,
    surname: userProfile.surname,
    timezone: userProfile.timezone,
    avatar:
      userProfile.avatar?.token === undefined
        ? {
            type: KEEP_AS_IS,
          }
        : {
            type: 'update',
            value: userProfile.avatar.token,
          },
  };
};

export const mapDataToUserProfile = (userProfile: StudentProfileResponseSchema): Partial<UserProfile> => {
  return {
    // Урлы картинок с бэка приходят без протокола, поэтому подставляем //, если урл есть
    avatar: userProfile.avatar?.sizes?.original?.url
      ? { url: `//${userProfile.avatar?.sizes?.original?.url}` }
      : undefined,
    birthday: userProfile.birthday ? dayjs(userProfile.birthday).toDate() : undefined,
    gender: userProfile.gender ?? 'male',
    name: userProfile.name ?? undefined,
    patronymic: userProfile.patronymic ?? undefined,
    residence: userProfile.residence ?? undefined,
    surname: userProfile.surname ?? undefined,
    timezone: isTimezone(userProfile.timezone) ? userProfile.timezone : DEFAULT_TIMEZONE,
  };
};
