import React, { FC, useEffect, useRef, useState } from 'react';
import styles from './CoursePreviewCard.module.css';
import { Button } from '@ui-kit/Button/Button.component';
import { EntityProgress } from '@shared/components/EntityProgress/EntityProgress.component';
import { Author } from '@shared/api/generated-student';
import { Markdown } from '@shared/ui-kit/Markdown/Markdown.component';

type CoursePreviewCardProps = {
  studyHours: number;
  /** Прогресс прохождения в процентах */
  progress?: number;
  coverUrl: string;
  description: string;
  author: Author | null;
};

/**
 * Компонент карточки с превью курса
 */
export const CoursePreviewCard: FC<CoursePreviewCardProps> = ({
  progress,
  studyHours,
  description,
  coverUrl,
  author,
}) => {
  const [isCollapsed, setIsCollapsed] = useState(true);
  const [isShowCollapsedButton, setIsShowCollapsedButton] = useState<boolean>(false);
  const describeRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    setIsShowCollapsedButton(Number(describeRef.current?.scrollHeight) >= 100);
  }, []);

  return (
    <div className={styles['course-preview-container']}>
      <img className={'aspect-video object-cover rounded-[30px] mb-[16px]'} alt="Module cover" src={coverUrl} />
      {progress !== undefined && (
        <div className="mb-[30px]">
          <EntityProgress progress={progress} hours={studyHours} />
        </div>
      )}
      <div id="description" className={['pb-[20px]', isCollapsed ? 'overflow-hidden' : ''].join(' ')}>
        <div ref={describeRef} className={isCollapsed ? `max-h-[100px]` : 'max-h-max'}>
          <Markdown className={'text-justify'}>{description}</Markdown>
        </div>
      </div>
      {isShowCollapsedButton && (
        <Button
          variant={'text'}
          onClick={() => {
            setIsCollapsed((prevState) => {
              return !prevState;
            });
          }}
        >
          <span className={'text-18-bold text-blue-800'}>{isCollapsed ? 'Больше информации о курсе' : 'Скрыть'}</span>
        </Button>
      )}
      {author && (
        <div className={'flex items-center gap-2'}>
          <img
            src={`https://${author?.avatar.sizes.original.url}`}
            className={'rounded-[100%] w-[50px] h-[48px] object-cover'}
            alt={'avatar'}
          />
          <div className={'flex flex-col gap-1'}>
            <span className="text-16-bold text-grey-70">{author?.name}</span>
            <span className={'text-14-regular text-grey-70'}>{author.regalia}</span>
          </div>
        </div>
      )}
    </div>
  );
};
