//1048576, 1073741824, 1099511627776 - 1024^2, 1024^3, 1024^4
/**
 * Функция возвращает размер человеко читаемый размер файла
 * Размер файла округляется к ближайшему целому
 * 1 Килобайт = 1024 байта
 * @param size - размер файла в байтах
 */
export const fileSizeFormatter = (size: number): string => {
  if (size < 1024) {
    return `${Math.round(size).toString()} byte`;
  }
  if (size < 1048576) {
    return `${Math.round(size / 1024).toString()} Kb`;
  }
  if (size < 1073741824) {
    return `${Math.round(size / 1048576).toString()} Mb`;
  }
  if (size < 1099511627776) {
    return `${Math.round(size / 1073741824).toString()} Gb`;
  }
  return 'Большой';
};
