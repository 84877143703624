import { createEffect } from 'effector';
import { ApiThrownError } from '@shared/api/client/responses/ApiErrorFactory';
import { PasswordService } from '@shared/api/client/services/Password';
import { handleError } from '@shared/handle-error/handleError';

export const updatePasswordFx = createEffect<string, void, ApiThrownError>(async (params) => {
  await PasswordService.updatePassword({ password: params });
});
updatePasswordFx.failData.watch((error) => {
  handleError(error);
});
