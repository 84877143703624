import React, { FC, PropsWithChildren } from 'react';
import { ReactComponent as LogoSvg } from '@svg/immutable/logo.svg';
import styles from './LoginLayout.module.css';

export const LoginLayout: FC<PropsWithChildren> = ({ children }) => {
  return (
    <div className={'bg-login bg-center bg-cover bg-no-repeat min-h-full min-w-full flex justify-center items-center'}>
      <main className={styles['main']}>
        <section className={'w-1/2 bg-white flex items-center justify-center flex-col p-[30px] box-content'}>
          <div className={'m-auto text-center flex flex-col items-center  '}>
            <LogoSvg className={'w-[172px] m:w-[220px] h-[220px]'} />
            <h5 className={'mt-[30px] text-16-bold m:text-l'}>
              Институт
              <br />
              психологии
              <br />и психотерапии
            </h5>
          </div>
          <div className={'mt-auto flex flex-col text-center label-14-semibold'}>
            <p className={'text-grey-75 whitespace-nowrap text-[12px] m:text-[14px]'}>
              Нажимая кнопку «Войти», вы принимаете и <br /> соглашаетесь со всеми правилами сообщества
            </p>
            <a className={'text-middle-blue text-[12px] m:text-[14px]'} href={'/'} target={'_blank'} rel="noreferrer">
              Согласие на обработку персональных данных
            </a>
            <a className={'text-middle-blue text-[12px] m:text-[14px]'} href={'/'} target={'_blank'} rel="noreferrer">
              Пользовательское соглашение
            </a>
          </div>
        </section>
        <section className={'w-1/2 h-full bg-blue-500 p-[64px_24px_50px_30px] box-content'}>{children}</section>
      </main>
    </div>
  );
};
