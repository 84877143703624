import { ErrorHandlers } from '@shared/handle-error/handleError';
import { showErrorModalEvent } from '@ui-kit/Modal/ModalResult/ModalResult.store';

export const handleApiError: ErrorHandlers['apiError'] = (err) => {
  if (err.data.type === 'invalid_refresh_token') {
    console.error('invalid_refresh_token');
    return;
  }
  showErrorModalEvent({ description: err.getUserFriendlyMessage(), title: 'Ошибка' });
};
