import { createEffect, createEvent, createStore } from 'effector';
import { handleError } from '@shared/handle-error/handleError';
import { ApiThrownError } from '@shared/api/client/responses/ApiErrorFactory';
import { ListAllCertificatesViewModel } from '../types/user-certificate.types';
import { CertificateService } from '@shared/api/client/services/CertificateService';
import { ApiToViewMapper } from '@modules/user-certificate/mappers/user-certificate.mapper';

type UserListAllCertificatesStore = ListAllCertificatesViewModel | null;

export const getListAllCertificatesFx = createEffect<void, ListAllCertificatesViewModel, ApiThrownError>(async () => {
  const {
    data: { data },
  } = await CertificateService.getListAllCertificates();
  const mappedData = ApiToViewMapper.getListAllCertificatesViewModel(data);
  return mappedData;
});

export const $UserListAllCertificatesStore = createStore<UserListAllCertificatesStore>(null);

export const resetUserCertificates = createEvent();

$UserListAllCertificatesStore.on(getListAllCertificatesFx.doneData, (_, payload) => payload);
$UserListAllCertificatesStore.on(getListAllCertificatesFx.failData, (prev, error) => {
  handleError(error);

  return prev;
});

$UserListAllCertificatesStore.reset(resetUserCertificates);
