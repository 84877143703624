import {
  StudentGetSoloCourseModuleResponseSchema,
  StudentGetSoloCourseModuleToPurchaseResponseSchema,
  StudentListSoloCourseModulesNotPurchasedItemResponseSchema,
  StudentListSoloCourseModulesPurchasedItemResponseSchema,
} from '@generated-student';
import {
  ModuleInfoViewModel,
  PurchasedModuleViewModel,
  NotPurchasedModuleViewModel,
  CommonModuleViewModel,
} from '@modules/module/types/module.types';

export const mapToCommonModuleViewModel = (
  module:
    | StudentListSoloCourseModulesPurchasedItemResponseSchema
    | StudentListSoloCourseModulesNotPurchasedItemResponseSchema,
): CommonModuleViewModel => {
  return {
    id: module.id,
    title: module.title,
    description: module.description,
  };
};
export const mapToPurchasedModuleViewModel = (
  module: StudentListSoloCourseModulesPurchasedItemResponseSchema,
): PurchasedModuleViewModel => {
  return {
    ...mapToCommonModuleViewModel(module),
    type: 'purchased_module',
    studyHours: {
      total: module.studyHours.total,
      passed: module.studyHours.passed,
    },
  };
};

export const mapToNotPurchasedModuleViewModel = (
  module: StudentListSoloCourseModulesNotPurchasedItemResponseSchema,
): NotPurchasedModuleViewModel => {
  return {
    ...mapToCommonModuleViewModel(module),
    price: `${module.cost.price.rubles}.${module.cost.price.pennies}`,
    discount: `${module.cost.discount.rubles}.${module.cost.discount.pennies}`,
    discountedPrice: `${module.cost.discountedPrice.rubles}.${module.cost.discountedPrice.pennies}`,
    type: 'not_purchased_module',
  };
};

export class MapperApiToViewMapper {
  static getModuleInfoViewModel(apiModel: StudentGetSoloCourseModuleResponseSchema): ModuleInfoViewModel {
    return {
      ...apiModel,
    };
  }

  static getNotPurchasedModule(
    apiModel: StudentGetSoloCourseModuleToPurchaseResponseSchema,
  ): NotPurchasedModuleViewModel {
    return {
      ...apiModel,
      type: 'not_purchased_module',
      price: `${apiModel.cost.price.rubles}.${apiModel.cost.price.pennies}`,
      discount: `${apiModel.cost.discount.rubles}.${apiModel.cost.discount.pennies}`,
      discountedPrice: `${apiModel.cost.discountedPrice.rubles}.${apiModel.cost.discountedPrice.pennies}`,
    };
  }
}
