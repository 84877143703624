import { ApiThrownError } from '@shared/api/client/responses/ApiErrorFactory';
import { AuthorService } from '@shared/api/client/services/Author';
import { ClientListAuthorsResponseSchema } from '@shared/api/generated-student';
import { handleError } from '@shared/handle-error/handleError';
import { createEffect, createStore } from 'effector';

const defaultAuthorStore: ClientListAuthorsResponseSchema = {
  items: [],
  itemsAmount: 0,
};

export const $Authors = createStore<ClientListAuthorsResponseSchema>(defaultAuthorStore);

export const getAuthorsFx = createEffect<void, ClientListAuthorsResponseSchema, ApiThrownError>(async () => {
  const {
    data: { data },
  } = await AuthorService.getAuthors({
    filter: { search: null },
    pagination: { amount: 50, offset: 0 },
  });
  return data;
});

$Authors.on([getAuthorsFx.doneData], (currentStore, authors) => ({
  itemsAmount: authors.itemsAmount,
  items: authors.items,
}));

$Authors.on([getAuthorsFx.failData], (prev, payload) => {
  handleError(payload);
});
