import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { FormHookResult } from '@shared/types/form-hook.types';
import { OtpForm } from '../types';
import { useForm } from 'react-hook-form';
import { smsCodeValidator } from '@shared/constants/validators/smsCode.validator';
import { useEvent } from 'effector-react';
import { loginBySmsFx, resetPasswordFx } from '@shared/stores/authorization.store';
import { useNavigate } from 'react-router-dom';
import { NAVIGATION } from '@shared/constants';
import { useAuthorization } from '@modules/authorization/useAuthorization';

const schema = yup.object<OtpForm>().shape({
  smsCode: smsCodeValidator,
});

type useOtpFormParams = {
  type: 'restore' | 'create';
};

export function useOtpForm({ type }: useOtpFormParams): FormHookResult<OtpForm> {
  const navigate = useNavigate();
  const login = useEvent(loginBySmsFx);
  const resetPassword = useEvent(resetPasswordFx);
  const { whoTryLogin } = useAuthorization();

  const form = useForm<OtpForm>({
    resolver: yupResolver(schema),
  });

  const handleSubmit = form.handleSubmit(async (data) => {
    if (whoTryLogin?.phone) {
      if (type === 'create') {
        await login({ ...data, phone: whoTryLogin.phone });
      } else {
        await resetPassword({ ...data, phone: whoTryLogin.phone });
      }

      navigate(NAVIGATION.createPassword);
    }
  });

  return {
    handleSubmit,
    params: {
      smsCode: form.register('smsCode'),
    },
    formState: form.formState,
  };
}
