import { OrderItemModule } from '@generated-student';

export function isOrderItemModule(value: unknown): value is OrderItemModule {
  return (
    typeof value === 'object' &&
    value !== null &&
    'moduleId' in value &&
    'soloCourseId' in value &&
    'soloCourseTitle' in value &&
    'soloCourseDirection' in value &&
    'moduleTitle' in value &&
    'moduleCost' in value &&
    'moduleCover' in value
  );
}
