import React, { memo, ReactNode } from 'react';
import styles from './Dropdown.module.css';
import { OptionsGroup, ItemRenderOption, Value } from './Dropdown.types';

type DropdownMenuItemProps = {
  data: Value | OptionsGroup;
  selectedValue?: Value['value'];
  itemRender?: (option: ItemRenderOption) => ReactNode;
  onSelect: (option: Value) => void;
};

export const DropdownMenuItem = memo(({ selectedValue, onSelect, itemRender, data }: DropdownMenuItemProps) => {
  const getMenuItemRender = (item: Value) => {
    const isSelected = selectedValue === item.value;
    const className = [styles['menu-item'], isSelected ? styles[`menu-item-selected`] : undefined].join(' ');

    return (
      <li onClick={() => onSelect(item)} className={className}>
        {itemRender ? (
          itemRender({ ...item, selected: isSelected })
        ) : (
          <span className="text-14-regular text-grey-70 break-words">{item.label}</span>
        )}
      </li>
    );
  };

  /**
   * items в data есть в случае,
   * если нужно рендерить сгруппированные элементы выпадающего списка
   */
  if ('items' in data) {
    return (
      <>
        {data.items.map((item) => (
          <React.Fragment key={item.value}>{getMenuItemRender(item)}</React.Fragment>
        ))}
      </>
    );
  }

  return getMenuItemRender(data);
});

DropdownMenuItem.displayName = 'DropdownMenuItem';
