import { studentApiClient } from '@shared/api/client/client';
import { ApiSuccessResponse } from '@shared/api/client/responses/ApiSuccessResponse';
import {
  StudentGetCertificateBySoloCourseIdRequestSchema,
  StudentGetCertificateResponseSchema,
  StudentListAllCertificatesResponseSchema,
} from '@generated-student';

export class CertificateService {
  static getListAllCertificates() {
    return studentApiClient.post<ApiSuccessResponse<StudentListAllCertificatesResponseSchema>>('listAllCertificates');
  }

  static getCertificateBySoloCourseId(data: StudentGetCertificateBySoloCourseIdRequestSchema) {
    return studentApiClient.post<ApiSuccessResponse<StudentGetCertificateResponseSchema>>(
      'getCertificateBySoloCourseId',
      data,
    );
  }
}
