import React from 'react';
import style from './FileCard.module.css';
import doc from '@svg/immutable/typesForFiles/doc.svg';
import docx from '@svg/immutable/typesForFiles/docx.svg';
import html from '@svg/immutable/typesForFiles/html.svg';
import jpg from '@svg/immutable/typesForFiles/jpg.svg';
import mov from '@svg/immutable/typesForFiles/mov.svg';
import mp3 from '@svg/immutable/typesForFiles/mp3.svg';
import mp4 from '@svg/immutable/typesForFiles/mp4.svg';
import pdf from '@svg/immutable/typesForFiles/pdf.svg';
import ppt from '@svg/immutable/typesForFiles/ppt.svg';
import txt from '@svg/immutable/typesForFiles/txt.svg';
import xls from '@svg/immutable/typesForFiles/xsl.svg';
import { FileType } from '@shared/types/file.types';

type FileStyle = {
  icon: string;
  style: Record<string, unknown>;
};

const FILE_STYLES_CONFIG: Record<FileType, FileStyle> = {
  doc: {
    icon: doc,
    style: {
      background: '#1890FF',
    },
  },
  docx: {
    icon: docx,
    style: {
      background: '#1890FF',
    },
  },
  txt: {
    icon: txt,
    style: {
      background: '#1890FF',
    },
  },
  mov: {
    icon: mov,
    style: {
      background: '#1890FF',
    },
  },
  pdf: {
    icon: pdf,
    style: {
      background: 'linear-gradient(180deg, #FF002E 0%, #FF9900 100%)',
    },
  },
  ppt: {
    icon: ppt,
    style: {
      background: 'linear-gradient(180deg, #FF002E 0%, #FF9900 100%)',
    },
  },
  mp3: {
    icon: mp3,
    style: {
      background: 'linear-gradient(180deg, #FF002E 0%, #FF9900 100%)',
    },
  },
  html: {
    icon: html,
    style: {
      background: 'linear-gradient(155.9deg, #99FFB3 0%, #15A17B 100%)',
    },
  },
  jpg: {
    icon: jpg,
    style: {
      background: 'linear-gradient(155.9deg, #99FFB3 0%, #15A17B 100%)',
    },
  },
  jpeg: {
    icon: jpg,
    style: {
      background: 'linear-gradient(155.9deg, #99FFB3 0%, #15A17B 100%)',
    },
  },
  png: {
    icon: jpg,
    style: {
      background: 'linear-gradient(155.9deg, #99FFB3 0%, #15A17B 100%)',
    },
  },
  xls: {
    icon: xls,
    style: {
      background: 'linear-gradient(155.9deg, #99FFB3 0%, #15A17B 100%)',
    },
  },
  mp4: {
    icon: mp4,
    style: {
      background: 'linear-gradient(110.67deg, #750DA7 0%, #350759 100%)',
    },
  },
} as const;

export type FileCardProps = {
  fileType: FileType;
  title: string;
  description: string;
  url: string;
  className?: string;
};

export const FileCard = ({ fileType = 'doc', url, title, description, className }: FileCardProps) => {
  return (
    <a href={url} className={'cursor-pointer'}>
      <div className={`${style.body} ${className || ''}`}>
        <div style={FILE_STYLES_CONFIG[fileType].style} className={style.bookmark} />
        {/* TODO Добавить отображение размера файла и хелпер для его рассчета - https://github.com/spiks/most-edu-front-public/issues/229 */}
        <img src={FILE_STYLES_CONFIG[fileType].icon} className={style.icon} alt="icon" />
        <div className={style.head}>
          <div className={style.leftSide} />
          <div className={style.rectangle} />
        </div>
        <div />
        <dl className={style.content}>
          <dt className="text-18-bold w-[80%] overflow-hidden whitespace-nowrap overflow-ellipsis">{title}</dt>
          <dd className="text-14-regular">{description}</dd>
        </dl>
      </div>
    </a>
  );
};
