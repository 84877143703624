import React, { FC } from 'react';

interface CircleProgressProps {
  progress: number;
}

export const CircleProgress: FC<CircleProgressProps> = ({ progress }) => {
  const circumference = 2 * Math.PI * 6;
  const offset = circumference - (progress / 100) * circumference;

  return (
    <svg height="24" width="24" viewBox="0 0 24 24">
      <circle r="12" cx="12" cy="12" className={'fill-blue-700'} />
      <circle
        r="6"
        cx="8"
        cy="12"
        fill="transparent"
        stroke="#39B4FF"
        strokeWidth="12"
        strokeDasharray={`${circumference}`}
        strokeDashoffset={offset}
        transform="rotate(-90) translate(-20)"
      />
    </svg>
  );
};
