import React, { FC } from 'react';
import styles from './FormField.module.css';
import { ReactComponent as RequiredSvg } from '@svg/required.svg';
import { FormFieldLayoutProps } from './FormField.types';

export const FormFieldLayout: FC<FormFieldLayoutProps> = ({
  children,
  id,
  label,
  required,
  classnameConfig,
  errorMessage,
}) => {
  return (
    <div className={`${styles['form-field-container']} ${errorMessage ? styles['error-container'] : ''}`}>
      {label !== undefined && (
        <div className={'flex items-center mb-[10px]'}>
          {required && <RequiredSvg className={'mr-[4px]'} />}
          <label htmlFor={id} className={`text-16-regular text-grey-400 ${classnameConfig?.label ?? ''}`}>
            {label}
          </label>
        </div>
      )}
      {children}
      {errorMessage && (
        <div className="mt-[3px] ml-[5px] min-h-[17px]">
          <p className="text-14-regular text-red-intensive">{errorMessage}</p>
        </div>
      )}
    </div>
  );
};
