import React from 'react';
import { ButtonHTMLAttributes, DetailedHTMLProps, FC, PropsWithChildren, ReactElement } from 'react';
import styles from './Button.module.css';

export interface ButtonProps extends DetailedHTMLProps<ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement> {
  variant: 'ghost' | 'outlined' | 'text' | 'filled';
  size?: 'medium' | 'large';
  rightIcon?: ReactElement;
  leftIcon?: ReactElement;
}

export const Button: FC<PropsWithChildren<ButtonProps>> = ({
  variant,
  size,
  rightIcon,
  leftIcon,
  disabled,
  children,
  ...rest
}) => {
  const classNames = [
    styles['button'],
    styles[`button_variant-${variant}`],
    rest.className,
    styles[`button_size-${size}`],
  ].filter(Boolean);

  return (
    <button {...rest} disabled={disabled} className={classNames.join(' ')}>
      {leftIcon && <div>{leftIcon}</div>}
      {children}
      {rightIcon && <div>{rightIcon}</div>}
    </button>
  );
};
