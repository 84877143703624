import { ApiErrorFactory } from '@shared/api/client/responses/ApiErrorFactory';
import { AxiosInstance } from 'axios';

export const applyErrorResponseInterceptor = (axios: AxiosInstance) => {
  return axios.interceptors.response.use((response) => {
    const { data } = response;
    const { status, type } = data;

    if (status !== 'error' && type !== 'unauthorized') {
      return response;
    }

    const error = new ApiErrorFactory(response);
    throw error.createErrorTypedInstance();
  });
};
