import React from 'react';
import { Dropdown } from '@shared/ui-kit/Dropdown/Dropdown.component';
import { Value } from '@shared/ui-kit/Dropdown/Dropdown.types';

export const getDayOptions = (value: Date): Array<Value> => {
  const year = value.getFullYear();
  const month = value.getMonth();
  const date = new Date(Date.UTC(year, month, 1));
  const days: Array<Value> = [];
  while (date.getUTCMonth() === month) {
    const day = date.getDate().toString();
    days.push({
      label: day,
      value: day,
    });
    date.setUTCDate(date.getUTCDate() + 1);
  }
  return days;
};

export const getLastHundredYears = () => {
  const startYear = new Date().getFullYear();
  const years: Array<Value> = [];
  for (let i = startYear; i > startYear - 100; i--) {
    years.push({
      label: i.toString(),
      value: i.toString(),
    });
  }
  return years;
};

export const MONTH_LIST: Exclude<React.ComponentProps<typeof Dropdown>['options'], undefined> = [
  {
    label: 'Январь',
    value: '0',
  },
  {
    label: 'Февраль',
    value: '1',
  },
  {
    label: 'Март',
    value: '2',
  },
  {
    label: 'Апрель',
    value: '3',
  },
  {
    label: 'Май',
    value: '4',
  },
  {
    label: 'Июнь',
    value: '5',
  },
  {
    label: 'Июль',
    value: '6',
  },
  {
    label: 'Август',
    value: '7',
  },
  {
    label: 'Сентябрь',
    value: '8',
  },
  {
    label: 'Октябрь',
    value: '9',
  },
  {
    label: 'Ноябрь',
    value: '10',
  },
  {
    label: 'Декабрь',
    value: '11',
  },
];
