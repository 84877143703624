import { FC } from 'react';
import { DropdownIndicator, Menu, MenuList, Option, Control, Placeholder, MultiValue } from './MultiSelect.custom';
import Select from 'react-select';
import { MultiSelectTagList } from './MultiSelectTagList/MultiSelectTagList.component';

export type OptionType = {
  value: string;
  label: string;
};

export type AuthorSelectProps = {
  options: readonly OptionType[];
  onChange: (value: readonly OptionType[]) => void;
  selectedValue: OptionType[];
  onRemoveFromFilter?: (id: string) => void;
  placeholder?: string;
};

export const MultiSelect: FC<AuthorSelectProps> = ({
  onChange,
  selectedValue,
  options,
  onRemoveFromFilter,
  placeholder,
}) => {
  return (
    <div className={`flex flex-col gap-[5px]`}>
      <Select
        components={{
          DropdownIndicator,
          MultiValue,
          Menu,
          MenuList,
          Option,
          Control,
          Placeholder,
        }}
        options={options}
        noOptionsMessage={() => {
          return <p className={'font-label-large text-on-primary-container text-left pl-[16px]'}>{'Нет в системе'}</p>;
        }}
        closeMenuOnSelect={false}
        hideSelectedOptions={false}
        isClearable={false}
        backspaceRemovesValue={false}
        unstyled
        isMulti
        value={selectedValue}
        onChange={onChange}
        placeholder={placeholder}
      />
      {onRemoveFromFilter && <MultiSelectTagList tags={selectedValue} onRemove={onRemoveFromFilter} />}
    </div>
  );
};
