import React, { ElementType, FC, ReactNode, useState } from 'react';
import styles from './Accordion.module.css';

type AccordionClassNames = {
  header: string;
  leftIcon: string;
  content: string;
  wrapper: string;
};

type AccordionProps = {
  title: string;
  children: ReactNode;
  leftIcon?: ElementType;
  //Иконка состояния аккордеона - открыто/закрыто
  rightIcon: ElementType;
  //Объект с внешними стилями для стилизации эл-тов аккордеона
  classNames: Partial<AccordionClassNames>;
  isActiveByDefault?: boolean;
};

export const Accordion: FC<AccordionProps> = ({
  title,
  children,
  leftIcon: LeftIcon,
  rightIcon: RightIcon,
  classNames,
  isActiveByDefault = false,
}) => {
  const [isActive, setIsActive] = useState(isActiveByDefault);

  const toggleAccordion = () => {
    setIsActive((prevState) => !prevState);
  };

  return (
    <div className={`${styles['accordion']} ${classNames.wrapper ?? ''}`}>
      <button className={`${styles['header']} ${classNames.header ?? ''} `} onClick={toggleAccordion}>
        <div className="flex gap-x-[8px] min-w-0">
          {LeftIcon && <LeftIcon className={`${styles['left-icon']} ${classNames['leftIcon'] ?? ''}`} />}
          <p className={styles['title']}>{title}</p>
        </div>
        <RightIcon className={`${styles['right-icon']} ${isActive && styles['right-icon_active']}`} />
      </button>
      <div className={`${styles['content']} ${classNames.content ?? ''} ${isActive && styles['content_active']}`}>
        {children}
      </div>
    </div>
  );
};
