import { allTimezones } from '@shared/constants/timezone';
import dayjs from 'dayjs';

export const getTimeZonesOptions = () => {
  return Object.entries(allTimezones).map(([value, label]) => {
    const timeZoneHourOffset = dayjs().tz(value).format('Z');
    return {
      label: `(GMT${timeZoneHourOffset}) ${label}`,
      value,
    };
  });
};
