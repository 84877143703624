import React, { FC, useEffect } from 'react';
import { LoginForm } from '@modules/authorization/components/LoginForm/LoginForm.component';
import { OtpForm } from '@modules/authorization/components/OtpForm/OtpForm.component';
import { LoginLayout } from '@shared/layouts/LoginLayout/LoginLayout.component';
import { useEvent } from 'effector-react';
import { changeAuthStageEvent } from '@shared/stores/authorization.store';
import { useAuthorization } from '@modules/authorization/useAuthorization';
import LoginPasswordForm from '@modules/authorization/components/LoginPasswordForm/LoginPasswordForm.component';

export const LoginPage: FC = () => {
  const { whoTryLogin } = useAuthorization();
  const changeAuthStage = useEvent(changeAuthStageEvent);

  useEffect(() => {
    /** Первая стадия в процессе авторизации это ввод номера телефона */
    changeAuthStage('phone');
  }, []);

  const renderFormComponentByAuthStage = () => {
    switch (whoTryLogin?.stage) {
      case 'phone':
        return <LoginForm />;
      case 'smsCode':
        return <OtpForm type={'create'} />;
      case 'smsPasswordRestoreCode':
        return <OtpForm type={'restore'} />;
      case 'password':
        return <LoginPasswordForm type={'Password'} />;
      case 'createPassword':
        return <LoginPasswordForm type={'CreatePassword'} />;
      default:
        return <LoginForm />;
    }
  };

  return <LoginLayout>{renderFormComponentByAuthStage()}</LoginLayout>;
};
