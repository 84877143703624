import React, { FC, ReactNode } from 'react';
import styles from './TestWrapper.module.css';

type TestWrapperProps = {
  questionType: string;
  title: string;
  idx: number;
  children: ReactNode;
  errorMsg: string;
};

export const TestWrapper: FC<TestWrapperProps> = ({ questionType, title, idx, children, errorMsg }) => {
  const testIndex = ++idx;

  return (
    <li className={styles['test-wrapper']}>
      <h5 className={'mb-[6px] text-l m:text-xl'}>{`${testIndex}. ${title}`}</h5>
      <p className={'mb-[20px]'}>{questionType}</p>
      {children}
      <p className={`${styles['test-error']} ${errorMsg.length && styles['test-error_active']}`}>{errorMsg}</p>
    </li>
  );
};
