import React, { FC, useRef, useState } from 'react';
import SlickSlider, { Settings } from 'react-slick';
import styles from './Slider.module.css';
import { ReactComponent as SliderIcon } from '@svg/outline/right.svg';
import { ReactComponent as Presentation } from '@svg/solid/presentation.svg';
import { ReactComponent as ArrowCircle } from '@svg/solid/arrowCircle.svg';
import SliderType from 'react-slick';

export type SliderProps = {
  items: string[];
};

const arrow = (
  <div>
    <SliderIcon className={'text-white'} />
  </div>
);

const getSliderSettings = (items: string[]): Settings => {
  return {
    customPaging: function (index) {
      return <img alt={'active_slider_image'} src={items[index]} />;
    },
    className: styles.slider,
    dots: true,
    speed: 400,
    infinite: false,
    nextArrow: arrow,
    prevArrow: arrow,
  };
};

export const Slider: FC<SliderProps> = ({ items }) => {
  const [activeSlide, setActiveSlide] = useState(0);
  const slider = useRef<SliderType | null>();
  return (
    <div>
      <SlickSlider
        {...getSliderSettings(items)}
        ref={(ref) => (slider.current = ref)}
        beforeChange={(_, next) => {
          setActiveSlide(next);
        }}
      >
        {items.map((it, key) => {
          return <img key={it} alt={`slider_image_${key}`} src={it} className={'w-full h-full'} />;
        })}
      </SlickSlider>
      <div className={'flex justify-between mt-[27px]'}>
        <button
          onClick={() => {
            slider.current?.slickGoTo(0);
          }}
          className={'flex gap-x-[6px] items-center'}
        >
          <ArrowCircle className={'text-blue-800'} />
          <span className={'text-18-bold text-blue-800'}>Начало презентации</span>
        </button>
        <div className={'flex gap-x-[6px] items-center'}>
          <Presentation className={'text-blue-800'} />
          <span className={'text-18-bold text-grey-70'}>{`${activeSlide + 1} из ${items.length}`}</span>
        </div>
      </div>
    </div>
  );
};
