import React, { FC } from 'react';
import {
  TestQuestionContainer,
  TestQuestionContainerProps,
} from '@modules/exercise/components/tests/TestQuestionContainer.component';
import { Dropdown } from '@ui-kit/Dropdown/Dropdown.component';
import { HandleChangeMatchingTestValuesPayload } from '@modules/exercise/stores/exercise-content.store';
import {
  MatchTestAnswersViewModel,
  Option,
  StudentTestQuestionWithMatchingViewModel,
} from '@modules/exercise/types/exercise.types';

type DropdownTestQuestionProps = {
  testId: string;
  selectedItems: MatchTestAnswersViewModel;
  question: StudentTestQuestionWithMatchingViewModel;
  onChange: (payload: HandleChangeMatchingTestValuesPayload) => void;
} & TestQuestionContainerProps;

export const DropdownTestQuestion: FC<DropdownTestQuestionProps> = ({
  testId,
  question,
  isQuestionWasAnsweredCorrectly,
  onChange: handleChange,
  selectedItems,
}) => {
  /* Функция возвращает объект с текущим вариантом ответат в дропдауне */
  const getSelectedItem = (questionId: string): Option | undefined => {
    // Находим ответ на текущий вопрос по questionId
    const currentQuestionWithAnswer = Object.entries(selectedItems).find(([key]) => {
      return key === questionId;
    });

    if (!currentQuestionWithAnswer) return;

    /*
     * В secondPart содержатся объекты со всеми вариантами ответа
     * в currentQuestionWithAnswer[1] будет храниться id ответа для вопроса с questionId
     * находим нужный нам вариант ответа
     */
    return question.secondPart.find((answer) => answer.value === currentQuestionWithAnswer[1]);
  };

  return (
    <TestQuestionContainer
      className={'flex flex-col gap-y-[20px]'}
      isQuestionWasAnsweredCorrectly={isQuestionWasAnsweredCorrectly}
    >
      {question.firstPart.map((item, index) => {
        const activeItem = getSelectedItem(item.value);

        return (
          <div key={index} className={'grid grid-cols-2 items-center'}>
            <span className="text-18-regular text-grey-70">{item.label}</span>
            <div className={'min-w-[140px]'}>
              <Dropdown
                placement={'bottom-right'}
                selectedItem={activeItem}
                onChange={(value) => {
                  getSelectedItem(item.value);
                  handleChange({
                    testId,
                    totalQuestions: question.totalQuestions,
                    questionId: question.id,
                    firstPartId: item.value,
                    secondPartId: value.value,
                  });
                }}
                options={question.secondPart}
              />
            </div>
          </div>
        );
      })}
    </TestQuestionContainer>
  );
};
