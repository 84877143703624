import React, { FC } from 'react';
import { FullyPurchasedCourseViewModel } from '../../types/course.types';
import { COURSE_DIRECTION_DICTIONARY } from '@shared/constants/courses';
import { ReactComponent as MagazineIcon } from '@svg/solid/magazine.svg';
import { Button } from '@ui-kit/Button/Button.component';
import styles from './CourseCard.module.css';
import { ENTITY_IDS, NAVIGATION } from '@shared/constants';
import { useNavigate } from 'react-router-dom';

type FullyPurchasedCourseCardProps = FullyPurchasedCourseViewModel & {
  withButton: boolean;
};

/** Карточка полностью приобретенного самостоятельного курса */
export const FullyPurchasedCourseCard: FC<FullyPurchasedCourseCardProps> = ({
  coverUrl,
  id,
  title,
  direction,
  studyHours,
  withButton,
  author,
}) => {
  const navigate = useNavigate();

  const goToLearnCourse = () => {
    /** Урл страницы приобретенного студентом курса */
    const courseUrl = NAVIGATION.studentUserCourse.replace(`:${ENTITY_IDS.courseId}`, id);

    navigate(courseUrl);
  };

  return (
    <div className={`${styles['course-card__container']} cursor-pointer`} onClick={goToLearnCourse}>
      <img className={styles['course-card__cover']} src={coverUrl} alt="course" />

      <div className="flex flex-col items-stretch]">
        <div className={`${styles['course-card__direction-container']} self-start mb-[15px]`}>
          <MagazineIcon className={styles['course-card__icon']} />
          {COURSE_DIRECTION_DICTIONARY[direction]}
        </div>

        <h6 className={styles['course-card__title']}>{title}</h6>
        {author && (
          <div className={'flex items-center gap-2 mb-[15px]'}>
            <img
              src={`https://${author?.avatar.sizes.original.url}`}
              className={'rounded-[100%] w-[30px] h-[29px] object-cover'}
              alt={'avatar'}
            />
            <span className="text-16-regular text-grey-70">{author?.name}</span>
          </div>
        )}
        <div className="mb-5">
          <span className="text-16-regular text-grey-70">{`Срок обучения: ${studyHours} ч.`}</span>
        </div>

        {withButton && (
          <Button className={styles['course-card__button']} variant="outlined">
            Перейти к обучению
          </Button>
        )}
      </div>
    </div>
  );
};
