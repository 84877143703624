import { $Authorization, refreshTokenFx } from '@shared/stores/authorization.store';
import { AxiosInstance } from 'axios';
import { privateApiClient } from '@shared/api/client/client';

const isUnauthorizedTokenOrExpiredError = (err: unknown) => {
  return (
    typeof err === 'object' &&
    err !== null &&
    'error' in err &&
    typeof err['error'] === 'object' &&
    err['error'] !== null &&
    'type' in err['error'] &&
    (err['error']['type'] === 'unauthorized' || err['error']['type'] === 'access_token_expired')
  );
};

export function applyRefreshTokenInterceptor(axios: AxiosInstance) {
  return axios.interceptors.response.use(async (response) => {
    if (isUnauthorizedTokenOrExpiredError(response.data)) {
      await refreshTokenFx($Authorization.getState());
      return privateApiClient.request({ ...response.config, data: JSON.parse(response.config.data).arguments });
    }
    return response;
  });
}
