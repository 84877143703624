import React, { FC } from 'react';
import styles from './Markdown.module.css';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import rehypeRaw from 'rehype-raw';
import { ReactMarkdownOptions } from 'react-markdown/lib/react-markdown';

export const Markdown: FC<ReactMarkdownOptions> = ({ children, ...props }) => {
  const className = [styles['markdown'], props.className].filter(Boolean).join(' ');
  // У нас подчеркнутый текст выделяется ++, markdown не поддерживает такой синтаксис в принципе, поэтому делаю замену на <u></u>
  const resultMarkdown = children.replace(/\+\+(.*?)\+\+/gi, '<u>$1</u>');
  return (
    <ReactMarkdown
      rehypePlugins={[rehypeRaw]}
      remarkPlugins={[[remarkGfm, { singleTilde: false }]]}
      className={className}
    >
      {resultMarkdown}
    </ReactMarkdown>
  );
};
