import React from 'react';
import { LoginLayout } from '@shared/layouts/LoginLayout/LoginLayout.component';
import LoginPasswordForm from '@modules/authorization/components/LoginPasswordForm/LoginPasswordForm.component';

const CreatePasswordPage = () => {
  return (
    <LoginLayout>
      <LoginPasswordForm type={'CreatePassword'} />
    </LoginLayout>
  );
};

export default CreatePasswordPage;
