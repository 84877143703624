import { Button } from '@ui-kit/Button/Button.component';
import React, { FC, useEffect } from 'react';
import { COURSE_DIRECTION_OPTIONS } from '@shared/constants/courses';
import { Filter } from '@ui-kit/Filter/Filter.component';
import {
  changeDirectionsFilter,
  changePriceFilter,
  getCoursesFilterFx,
  $Filter,
  applyFilter,
  $FilterRange,
  changeAuthorsFilter,
  removeAuthorFromFilter,
  removeDirectionFromFilter,
} from '../../stores/courses/courses-list.store';
import { useStore } from 'effector-react';
import style from './CoursesFilter.module.css';
import { MultiSelect, OptionType } from '@shared/ui-kit/MultiSelect/MultiSelect.component';
import { $Authors, getAuthorsFx } from '@shared/stores/authors.store';
import { Direction } from '@shared/api/generated-student';

type CoursesFilterProps = {
  onClose: () => void;
};

/** Компонент с фильтром курсов */
export const CoursesFilter: FC<CoursesFilterProps> = ({ onClose }) => {
  const { directions: directionsFilter, price: priceFilter, authors: authorsFilter } = useStore($Filter);
  const authors = useStore($Authors);
  const [max, min] = useStore($FilterRange);

  const handleChangePriceFilter = (value: number | Array<number>) => {
    if (Array.isArray(value)) {
      changePriceFilter(value);
    }
  };

  const options: readonly OptionType[] = authors.items.map(({ id, name }) => {
    return { value: id, label: name };
  });

  const loadOptions = async () => {
    await getAuthorsFx();
  };

  useEffect(() => {
    loadOptions();
  }, []);

  const handleChangeDirectionFilter = (values: readonly OptionType[]) => {
    const mappedValues = values.map(({ value, label }) => {
      return { value: value as Direction, label };
    });
    changeDirectionsFilter(mappedValues);
  };

  const handleChangeAuthorsFilter = (values: readonly OptionType[]) => {
    const mappedValues = values.map((value) => {
      return { id: value.value, name: value.label };
    });
    changeAuthorsFilter(mappedValues);
  };

  const handleRemoveAuthorFromFilter = (id: string) => {
    return removeAuthorFromFilter(id);
  };

  const handleRemoveDirectionFromFilter = (id: string) => {
    return removeDirectionFromFilter(id);
  };

  const handleResetFilter = async () => {
    await getCoursesFilterFx();

    applyFilter();
  };

  const handleApplyFilter = () => {
    applyFilter();
    onClose();
  };

  const mappedAuthors = authorsFilter.map((author) => {
    return { value: author.id, label: author.name };
  });

  return (
    <div className={style.wrapper}>
      <h6 className={'mb-5'}>Направление</h6>
      <MultiSelect
        placeholder={'Выберите направление'}
        options={COURSE_DIRECTION_OPTIONS}
        selectedValue={directionsFilter}
        onChange={handleChangeDirectionFilter}
        onRemoveFromFilter={handleRemoveDirectionFromFilter}
      />
      <h6 className={'my-5'}>Автор курса</h6>
      <MultiSelect
        placeholder={'Выберите автора'}
        options={options}
        selectedValue={mappedAuthors}
        onChange={handleChangeAuthorsFilter}
        onRemoveFromFilter={handleRemoveAuthorFromFilter}
      />
      <div className="py-5 border-opacity-darkBlue-10">
        <h6 className="mb-5">Стоимость</h6>
        <div className="flex flex-col">
          <Filter step={1} min={max} max={min} value={priceFilter} onChange={handleChangePriceFilter} />
        </div>
      </div>
      <div className="flex justify-center items-center pt-5 gap-x-[10px] border-t border-opacity-darkBlue-10">
        <Button variant="ghost" onClick={handleResetFilter}>
          Очистить
        </Button>
        <Button variant="filled" onClick={handleApplyFilter}>
          Применить
        </Button>
      </div>
    </div>
  );
};
