import React, { FC } from 'react';
import {
  TestQuestionContainer,
  TestQuestionContainerProps,
} from '@modules/exercise/components/tests/TestQuestionContainer.component';
import { RadioButton } from '@ui-kit/RadioButton/RadioButton.component';
import { HandleChangeSingleChoiceTestValuesPayload } from '@modules/exercise/stores/exercise-content.store';
import { StudentTestQuestionWithSingleChoiceViewModel } from '@modules/exercise/types/exercise.types';
import styles from './RadioTestQuestion.module.css';

type RadioTestQuestionProps = {
  testId: string;
  question: StudentTestQuestionWithSingleChoiceViewModel;
  currentValue: string;
  onChange: (payload: HandleChangeSingleChoiceTestValuesPayload) => void;
} & TestQuestionContainerProps;

export const RadioTestQuestion: FC<RadioTestQuestionProps> = ({
  testId,
  currentValue,
  isQuestionWasAnsweredCorrectly,
  question,
  onChange: handleChange,
}) => {
  return (
    <TestQuestionContainer
      className={styles['radio-test-question']}
      isQuestionWasAnsweredCorrectly={isQuestionWasAnsweredCorrectly}
    >
      {question.options.map((option) => (
        <RadioButton
          value={option.value}
          label={option.label}
          currentValue={currentValue}
          onChange={() => handleChange({ testId, questionId: question.id, updatedValue: option.value })}
          key={option.value}
          styleConfig={{ labelStyle: styles['radio-test-label'] }}
        />
      ))}
    </TestQuestionContainer>
  );
};
