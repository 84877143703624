import { isObject } from '@shared/guards/isObject';
import { IssuedAuthorizationCredentials } from '@generated-student';

export function isIssuedAuthorizationCredentials(obj: unknown): obj is IssuedAuthorizationCredentials {
  if (!isObject(obj)) {
    return false;
  }

  return (
    Boolean(Object.keys(obj).length) &&
    Object.entries(obj).every(([key, value]) => {
      switch (key) {
        case 'userId':
        case 'refreshToken':
        case 'accessToken':
          return typeof value === 'string';
        case 'expiresAt':
          // eslint-disable-next-line no-self-compare
          return typeof value === 'string' && Date.parse(value) === Date.parse(value);
        case 'userProfile':
          return value === 'teacher' || value === 'student';
        default:
          return false;
      }
    })
  );
}
