import {
  AccessTokenExpiredApiProblem,
  AdminProfileDoesNotExistApiProblem,
  EntityIsArchivedApiProblem,
  EntityIsNotArchivedApiProblem,
  EntityNotFoundByUuidApiProblem,
  InternalServerErrorApiProblem,
  InvalidAccessTokenApiProblem,
  InvalidBirthdayApiProblem,
  InvalidEmailOrPasswordApiProblem,
  DiscountIsMoreThanPriceApiProblem,
  InvalidRefreshTokenApiProblem,
  TooManyRequestsApiProblem,
  ValidationErrorApiProblem,
  InvalidTestReferenceApiProblem,
  OptionIdsAreNotUniqueApiProblem,
  DocumentDoesNotExistApiProblem,
  SoloCourseIsNotCompletedApiProblem,
  SoloCoursePriceIsNotSpecifiedApiProblem,
  TooFewEntitiesInListApiProblem,
  TooFewMatchingsApiProblem,
  FirstPartIdsAreNotUniqueApiProblem,
  SecondPartIdsAreNotUniqueApiProblem,
  EmailAlreadyTakenApiProblem,
  PhoneAlreadyTakenApiProblem,
  TooFewAnswersApiProblem,
  QuestionIdsAreNotUniqueApiProblem,
  InvalidPassportIssuedAtApiProblem,
  EntityIsPurchasedApiProblem,
  WrongConfirmationCodeApiProblem,
  InvalidTestingApiProblem,
  SoloCourseIsEmptyApiProblem,
  ConcurrentAccessToEntityApiProblem,
  DocumentExistsApiProblem,
  EntitiesAreLinkedApiProblem,
  EntitiesAreNotLinkedApiProblem,
  EntityIsInListApiProblem,
  EntityIsNotInListApiProblem,
  EntityIsNotPublishedApiProblem,
  EntityIsPublishedApiProblem,
  InvalidPositionApiProblem,
  InvalidPriceRangeApiProblem,
  InvalidPublicationDatesRangeOfSoloCourseApiProblem,
  InvalidDiplomaYearOfIssueApiProblem,
  ModuleIsLinkedToAnotherCourseApiProblem,
  StudentProfileDoesNotExistApiProblem,
  SoloCourseDiscountIsNotSpecifiedApiProblem,
  TestIsPassedApiProblem,
  PasswordOnlyAccessAvailableApiProblem,
  AccountMustHavePasswordApiProblem,
  EntityCannotBePurchasedApiProblem,
  PaymentOrderHasConflictedItemsApiProblem,
  InvalidPasswordApiProblem,
} from '@generated-student';
import { AxiosResponse } from 'axios';
import { isObject } from '@shared/guards/isObject';
import { ApiErrorTranslate } from '@shared/api/client/translates/ApiErrorTranslate';
import { ApiValidationErrorTranslate } from '@shared/api/client/translates/ApiValidationErrorTranslate';

// Этот union нужно обновлять по мере добавления в API новых типов ошибок.
// Соглашение следующее - названия заканчиваются на `Problem`.
export type ApiErrorData =
  | InternalServerErrorApiProblem
  | TooManyRequestsApiProblem
  | ValidationErrorApiProblem
  | InvalidEmailOrPasswordApiProblem
  | InvalidBirthdayApiProblem
  | DiscountIsMoreThanPriceApiProblem
  | InvalidAccessTokenApiProblem
  | InvalidRefreshTokenApiProblem
  | AccessTokenExpiredApiProblem
  | AdminProfileDoesNotExistApiProblem
  | EntityNotFoundByUuidApiProblem
  | EntityIsArchivedApiProblem
  | EntityIsNotArchivedApiProblem
  | InvalidTestReferenceApiProblem
  | OptionIdsAreNotUniqueApiProblem
  | DocumentDoesNotExistApiProblem
  | SoloCourseIsNotCompletedApiProblem
  | SoloCoursePriceIsNotSpecifiedApiProblem
  | TooFewEntitiesInListApiProblem
  | TooFewMatchingsApiProblem
  | FirstPartIdsAreNotUniqueApiProblem
  | SecondPartIdsAreNotUniqueApiProblem
  | EmailAlreadyTakenApiProblem
  | PhoneAlreadyTakenApiProblem
  | InvalidPassportIssuedAtApiProblem
  | TooFewAnswersApiProblem
  | QuestionIdsAreNotUniqueApiProblem
  | EntityIsPurchasedApiProblem
  | WrongConfirmationCodeApiProblem
  | InvalidTestingApiProblem
  | SoloCourseIsEmptyApiProblem
  | ConcurrentAccessToEntityApiProblem
  | DocumentExistsApiProblem
  | EntitiesAreLinkedApiProblem
  | EntitiesAreNotLinkedApiProblem
  | EntityIsInListApiProblem
  | EntityIsNotInListApiProblem
  | EntityIsNotPublishedApiProblem
  | EntityIsPublishedApiProblem
  | InvalidPositionApiProblem
  | InvalidPriceRangeApiProblem
  | InvalidPublicationDatesRangeOfSoloCourseApiProblem
  | InvalidDiplomaYearOfIssueApiProblem
  | ModuleIsLinkedToAnotherCourseApiProblem
  | StudentProfileDoesNotExistApiProblem
  | SoloCourseDiscountIsNotSpecifiedApiProblem
  | TestIsPassedApiProblem
  | PasswordOnlyAccessAvailableApiProblem
  | AccountMustHavePasswordApiProblem
  | EntityCannotBePurchasedApiProblem
  | PaymentOrderHasConflictedItemsApiProblem
  | InvalidPasswordApiProblem;

export type ApiThrownError = ApiInternalError | ApiValidationError | ApiError;

// Полное тело ответа с ошибкой от сервера
export type ApiErrorResponse = {
  status: 'error';
  error: ApiErrorData;
};

export function isApiErrorResponse(obj: unknown): obj is ApiErrorResponse {
  if (!isObject(obj)) {
    return false;
  }

  return (
    obj['status'] === 'error' &&
    typeof obj['error'] === 'object' &&
    Object.keys(obj).every((key) => {
      return ['status', 'error'].includes(key);
    })
  );
}

/**
 * Базовый класс-конструктор ошибок от API
 */

export class ApiErrorFactory {
  public readonly data: ApiErrorData;

  constructor(public readonly axiosResponse: AxiosResponse) {
    const { data } = axiosResponse;

    if (!isApiErrorResponse(data)) {
      console.error('[ApiError] > response: ', axiosResponse);
      throw new Error('(ApiError) Неизвестная ошибка API: подробности в консоле');
    }

    this.data = data.error;
  }

  /**
   * Создаёт соответствующий типу ошибки класс, позволяющий распознать
   * тип посредством `instanceof` без необходимости двух-уровневной проверки.
   */
  createErrorTypedInstance() {
    switch (this.data.type) {
      case 'internal_server_error':
        return new ApiInternalError(this.data, this.axiosResponse);
      case 'validation_error':
        return new ApiValidationError(this.data, this.axiosResponse);
      default:
        return new ApiError(this.data, this.axiosResponse);
    }
  }
}

/**
 * Классы типов ошибок (упрощают валидацию в `catch`)
 */

export class ApiError extends Error {
  name = 'ApiError';
  constructor(
    public readonly data: Exclude<ApiErrorData, ValidationErrorApiProblem | InternalServerErrorApiProblem>,
    public readonly axiosResponse: AxiosResponse,
  ) {
    super(`${data.type}: ${data.detail}`);
  }

  getUserFriendlyMessage(): string {
    return ApiErrorTranslate.translateError(this);
  }

  public static is(obj: unknown): obj is ApiError {
    return obj instanceof ApiError;
  }
}

export class ApiInternalError extends Error {
  name = 'ApiInternalError';
  constructor(public readonly data: InternalServerErrorApiProblem, public readonly axiosResponse: AxiosResponse) {
    super(`${data.type}: ${data.detail}`);
  }

  getUserFriendlyMessage() {
    return 'Внутренняя ошибка сервера: попробуйте повторить операцию позже';
  }

  public static is(obj: unknown): obj is ApiInternalError {
    return obj instanceof ApiInternalError;
  }
}

export class ApiValidationError extends Error {
  name = 'ApiValidationError';
  constructor(public readonly data: ValidationErrorApiProblem, public readonly axiosResponse: AxiosResponse) {
    super(`${data.type}: ${data.detail}`);
  }

  getUserFriendlyMessages() {
    return this.data.violations.map((violation) => {
      return ApiValidationErrorTranslate.translateViolation(violation);
    });
  }

  public static is(obj: unknown): obj is ApiValidationError {
    return obj instanceof ApiValidationError;
  }
}
