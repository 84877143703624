import React, { FC } from 'react';
import { ReactComponent as Settings } from '@svg/outline/settings.svg';
import { isValidUploadFileSize } from '@shared/utils/ isValidUploadFileSize';

type AvatarProps = {
  url?: string;
  withBadge?: boolean;
  onFilePick?: (file: File) => void;
};

export const Avatar: FC<AvatarProps> = ({ url, withBadge = false, onFilePick }) => {
  const avatarUrl = url ?? '/avatar-placeholder.jpeg';

  const onInputHandler: React.FormEventHandler<HTMLInputElement> = (event) => {
    if (!onFilePick) {
      return;
    }
    const files = event.currentTarget.files;
    if (files !== null) {
      const file = files.item(0);

      if (file !== null && isValidUploadFileSize(file)) {
        onFilePick(file);
      }
    }
  };
  return (
    <div className={`h-full relative aspect-square`}>
      <img src={avatarUrl} className={'w-full h-full object-cover rounded-[50%]'} alt={'User avatar'} />
      {withBadge && (
        <label htmlFor={'avatar'}>
          <input
            id={'avatar'}
            type={'file'}
            accept="image/jpeg, image/png"
            className={'hidden'}
            onInput={onInputHandler}
          />
          <div className={`absolute bottom-0 right-0 w-[40%] h-[40%] grid place-items-center cursor-pointer`}>
            <div className={'bg-blue-800 w-[75%] h-[75%] rounded-[50%] grid place-items-center z-10'}>
              <Settings className={'w-[55%] h-[55%] z-10 text-white'} />
            </div>
          </div>
          <div className={`absolute bottom-0 right-0 w-full h-full rounded-[50%] overflow-hidden`}>
            <div className={`absolute bottom-0 right-0 w-[40%] h-[40%] rounded-[50%] bg-white`} />
          </div>
        </label>
      )}
    </div>
  );
};
