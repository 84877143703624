import React, { FC, useEffect, useState } from 'react';
import { ENG_TO_RU_QUESTION_TYPE_MAPPER } from '@modules/exercise/constants/constants';
import { CheckboxTestQuestion } from '@modules/exercise/components/tests/CheckboxTestQuestion.component';
import { ExerciseContentTestViewModel } from '@modules/exercise/types/exercise.types';
import { RadioTestQuestion } from '@modules/exercise/components/tests/RadioTestQuestion.component';
import { DropdownTestQuestion } from '@modules/exercise/components/tests/DropdownTestQuestion.component';
import { useEvent, useStore } from 'effector-react';
import {
  $ExerciseContent,
  handleChangeMatchingTestValues,
  handleChangeMultiChoiceTestValues,
  handleChangeSingleChoiceTestValues,
  submitTestAnswersFx,
  TestWithAnswersViewModel,
} from '@modules/exercise/stores/exercise-content.store';
import { Button } from '@ui-kit/Button/Button.component';
import { TestResult } from '@modules/exercise/components/tests/TestResult.component';
import { TestWrapper } from '@modules/exercise/components/blocks/TestWrapper';
import { TestValidation } from '@modules/exercise/utils/testValidation';
import { showModalEvent } from '@ui-kit/Modal/ModalBasic/ModalBasic.store';
import { ModalBasic } from '@ui-kit/Modal/ModalBasic/ModalBasic.component';
import { ReactComponent as Close } from '@svg/outline/close.svg';
import { TestResultComponent } from '@modules/exercise/components/TestResultComponent';

type BlockTestingProps = {
  data: ExerciseContentTestViewModel;
};

export const BlockTesting: FC<BlockTestingProps> = ({ data }) => {
  const submitTestAnswers = useEvent(submitTestAnswersFx);
  const { title, questions, id: testId, testAttempt } = data || {};

  const [isTestValid, setIsTestValid] = useState(false);

  const { testWithAnswers, testResult } = useStore($ExerciseContent);

  const handleSubmitTest = async () => {
    if (!testWithAnswers || !isTestValid) return;

    const testWithAnswersViewModel: TestWithAnswersViewModel = { [testId]: testWithAnswers[testId] };

    await submitTestAnswers(testWithAnswersViewModel);

    showModalEvent();
  };

  const testResultWithTitle = testResult && title ? { ...testResult, title } : null;

  useEffect(() => {
    if (!testWithAnswers) return;
    const isTestPassed = testAttempt?.isTestPassed;
    const isTestQuestionsValid = TestValidation.checkTestIsValid(testId, testWithAnswers[testId]);

    const isTestValid = isTestQuestionsValid && !isTestPassed;

    setIsTestValid(isTestValid);
  }, [testWithAnswers]);

  return (
    <div className={'flex flex-col gap-y-[40px]'}>
      {testAttempt && <TestResultComponent testAttempt={testAttempt} />}

      <h3 className={'text-grey-90 text-xl m:text-2xl'}>{`Проверка знаний`}</h3>
      <h3 className={'text-grey-90 text-xl m:text-2xl'}>{`Пройдите тест`}</h3>
      <h3 className={'text-grey-90 text-xl m:text-2xl'}>{title}</h3>
      <ul className={'flex flex-col gap-y-[22px]'}>
        {questions.map((question, idx) => {
          const questionType = ENG_TO_RU_QUESTION_TYPE_MAPPER[question.type];
          if (question.type === 'multi_choice' && testWithAnswers) {
            const currentTest = testWithAnswers[testId][question.type][question.id];
            const currentValues = currentTest.answerIds;
            const validationErr = currentTest.validation.errorMsg;

            return (
              <TestWrapper
                questionType={questionType}
                title={question.text}
                idx={idx}
                key={question.id}
                errorMsg={validationErr}
              >
                <CheckboxTestQuestion
                  testId={testId}
                  question={question}
                  checkedValues={currentValues}
                  onChange={handleChangeMultiChoiceTestValues}
                  isQuestionWasAnsweredCorrectly={currentTest.questionWasAnsweredCorrectly}
                />
              </TestWrapper>
            );
          }
          if (question.type === 'single_choice' && testWithAnswers) {
            const currentTest = testWithAnswers[testId][question.type][question.id];
            const currentValues = currentTest.answerId;
            const validationErr = currentTest.validation.errorMsg;

            return (
              <TestWrapper
                questionType={questionType}
                title={question.text}
                idx={idx}
                key={question.id}
                errorMsg={validationErr}
              >
                <RadioTestQuestion
                  testId={testId}
                  question={question}
                  currentValue={currentValues}
                  onChange={handleChangeSingleChoiceTestValues}
                  isQuestionWasAnsweredCorrectly={currentTest.questionWasAnsweredCorrectly}
                />
              </TestWrapper>
            );
          }
          if (question.type === 'matching' && testWithAnswers) {
            const currentTest = testWithAnswers[testId][question.type][question.id];
            const currentValues = currentTest.matching;
            const validationErr = currentTest.validation.errorMsg;

            return (
              <TestWrapper
                questionType={questionType}
                title={question.text}
                idx={idx}
                key={question.id}
                errorMsg={validationErr}
              >
                <DropdownTestQuestion
                  question={question}
                  testId={testId}
                  selectedItems={currentValues}
                  onChange={handleChangeMatchingTestValues}
                  isQuestionWasAnsweredCorrectly={currentTest.questionWasAnsweredCorrectly}
                />
              </TestWrapper>
            );
          }
          return <></>;
        })}
        {testAttempt?.isTestPassed !== true && (
          <Button variant={'filled'} className={'mt-[22px]'} onClick={handleSubmitTest} disabled={!isTestValid}>
            Ответить
          </Button>
        )}
      </ul>
      <ModalBasic
        closeBtn={
          <button>
            <Close />
          </button>
        }
      >
        <TestResult data={testResultWithTitle} />
      </ModalBasic>
    </div>
  );
};
