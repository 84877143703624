import { studentApiClient } from '@shared/api/client/client';
import {
  StudentGetSoloCourseModuleResponseSchema,
  StudentGetSoloCourseModuleRequestSchema,
  StudentListSoloCourseModulesRequestSchema,
  StudentBuySoloCourseModuleRequestSchema,
  StudentListSoloCourseModulesResponseSchema,
  StudentGetSoloCourseModuleToPurchaseResponseSchema,
  StudentGetSoloCourseModuleToPurchaseRequestSchema,
  StudentRequestSoloCourseModuleRequestSchema,
} from '@generated-student';
import { ApiSuccessResponse } from '@shared/api/client/responses/ApiSuccessResponse';

export class SoloCourseModuleService {
  static getSoloCourseModule(data: StudentGetSoloCourseModuleRequestSchema) {
    return studentApiClient.post<ApiSuccessResponse<StudentGetSoloCourseModuleResponseSchema>>(
      'getSoloCourseModule',
      data,
    );
  }

  static getSoloCourseModuleToPurchase(data: StudentGetSoloCourseModuleToPurchaseRequestSchema) {
    return studentApiClient.post<ApiSuccessResponse<StudentGetSoloCourseModuleToPurchaseResponseSchema>>(
      'getSoloCourseModuleToPurchase',
      data,
    );
  }

  static getListSoloCourseModules(data: StudentListSoloCourseModulesRequestSchema) {
    return studentApiClient.post<ApiSuccessResponse<StudentListSoloCourseModulesResponseSchema>>(
      '/listSoloCourseModules',
      data,
    );
  }

  static buySoloCourseModule(data: StudentBuySoloCourseModuleRequestSchema) {
    return studentApiClient.post<ApiSuccessResponse<void>>('/buySoloCourseModule', data);
  }

  static requestSoloCourseModule(data: StudentRequestSoloCourseModuleRequestSchema) {
    return studentApiClient.post<ApiSuccessResponse<void>>('/requestSoloCourseModule', data);
  }
}
