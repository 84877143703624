import { useEvent, useStore } from 'effector-react';
import { useEffect } from 'react';
import {
  $UserCertificateStore,
  getCertificateBySoloCourseIdFx,
  resetUserCertificate,
} from '@modules/user-certificate/stores/user-certificate.store';

export const useUserCertificatePage = () => {
  const getCertificate = useEvent(getCertificateBySoloCourseIdFx);
  const certificate = useStore($UserCertificateStore);

  useEffect(() => {
    return () => resetUserCertificate();
  }, []);
  return { certificate, getCertificate };
};
