/** Варианты для фильтра курсов по направлениям */
import { Direction } from '@generated-student';

/** Варианты направлений курса */
export const COURSE_DIRECTION_OPTIONS: Array<{ value: Direction; label: string }> = [
  { value: 'existential_analysis', label: 'Экзистенциальный анализ' },
  { value: 'gestalt_therapy', label: 'Гештальт-терапия' },
  { value: 'integrative_therapy', label: 'Интегративная терапия' },
  { value: 'multimodal_approach', label: 'Мультимодальный подход' },
  { value: 'neurocoaching', label: 'Нейрокоучинг' },
  { value: 'process_oriented_approach', label: 'Процессуально-ориентированный подход' },
  { value: 'psychological_counseling', label: 'Психологическое консультирование' },
  { value: 'wingwave_coaching', label: 'Wingwave-коучинг' },
];

/** Словарь направлений курса */
export const COURSE_DIRECTION_DICTIONARY = COURSE_DIRECTION_OPTIONS.reduce((acc, option) => {
  return {
    ...acc,
    [option.value]: option.label,
  };
}, {} as Record<Direction, string>);
