import { AxiosInstance } from 'axios';
import { $Authorization } from '@shared/stores/authorization.store';

/**
 * Подставляет токен из состояния $Authorization;
 */
export const applyAccessTokenInjectInterceptor = (axios: AxiosInstance) => {
  return axios.interceptors.request.use((config) => {
    const store = $Authorization.getState();
    if (!store.isAuthorized || store.isLoading) {
      throw new Error('Попытка доступа к приватному методу без авторизации!');
    }

    const { accessToken } = store.credentials;
    const { data } = config;

    config.data = {
      accessToken,
      ...data,
    };

    return config;
  });
};
