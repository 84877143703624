import React, { FC, ReactNode, RefObject } from 'react';
import InfiniteScroller from 'react-infinite-scroller';

type InfiniteScrollProps = {
  children: ReactNode;
  loadMore: () => void;
  initialLoad?: boolean;
  useWindow?: boolean;
  hasMore: boolean;
  /** Реф на контейнер, который должен скроллиться */
  scrollContainerRef?: RefObject<HTMLElement>;
  containerClassName?: string;
};

export const InfiniteScroll: FC<InfiniteScrollProps> = ({
  initialLoad = false,
  useWindow = false,
  children,
  hasMore,
  loadMore,
  scrollContainerRef,
  containerClassName,
}) => {
  return (
    <InfiniteScroller
      initialLoad={initialLoad}
      useWindow={useWindow}
      getScrollParent={scrollContainerRef ? () => scrollContainerRef.current : undefined}
      loadMore={loadMore}
      hasMore={hasMore}
      className={containerClassName}
    >
      {children}
    </InfiniteScroller>
  );
};
