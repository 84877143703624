import { useEffect, useState } from 'react';
import { getUserContactDataFx } from '@shared/stores/user-contact-data.store';
import { useEvent } from 'effector-react';
import { AccountContacts } from '@generated-student';

type ContactData = {
  phone: string;
  email: string;
};

const mapToContactData = (value: AccountContacts): ContactData => {
  return {
    email: value.email ?? '',
    phone: value.phone ?? '',
  };
};

export function useContactData() {
  const [data, setData] = useState<ContactData>({
    email: '',
    phone: '',
  });
  const getContactData = useEvent(getUserContactDataFx);

  const getAndSetData = async () => {
    setData(mapToContactData(await getContactData()));
  };
  useEffect(() => {
    getAndSetData();
  }, []);
  return { data };
}
