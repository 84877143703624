import { FC } from 'react';
import styles from './UserCourseCardAlternative.module.css';
import { ReactComponent as BookIcon } from '@svg/outline/book.svg';
import { ReactComponent as Lock } from '@svg/solid/lock.svg';
import { CircleProgress } from '@shared/ui-kit/CircleProgress/CircleProgress';
import { UserCoursesCardViewModel } from '@modules/user-course/types/user-course.types';
import { COURSE_DIRECTION_DICTIONARY } from '@shared/constants/courses';
import { getTextWithDeclination } from '@shared/utils/getTextWithDeclination';
import { ACCESS, MODULE } from '@shared/constants/declanations';

export type UserCourseCardProps = {
  data: UserCoursesCardViewModel;
  onClick: (courseId: string) => void;
};

export const UserCourseCardAlternative: FC<UserCourseCardProps> = ({ data, onClick }) => {
  const { title, direction, modulesAmount, purchasedModulesAmount, studyHours, coverUrl, author } = data;

  const percentageOfPassedModules = Math.floor((studyHours.passed / studyHours.total) * 100);

  const translatedDirection = COURSE_DIRECTION_DICTIONARY[direction];

  return (
    <article className={styles['container']} onClick={() => onClick(data.id)}>
      <img className={styles['img']} src={`https://${coverUrl}`} alt="course" />
      <div className={'flex flex-col items-start gap-2 w-full'}>
        <div className={'flex items-center gap-4'}>
          <div className="flex items-center gap-2">
            <BookIcon className="w-[24px] h-[24px] fill-blue-800" />
            <span className="text-xs font-semibold leading-1.4 text-grey-70">{translatedDirection}</span>
          </div>
          {author && (
            <div className={'flex items-center gap-2'}>
              <img
                src={`https://${author?.avatar.sizes.original.url}`}
                className={'rounded-[100%] w-[24px] h-[24px] object-cover'}
                alt={'avatar'}
              />
              <span className="text-xs font-semibold leading-1 text-grey-70">{author?.name}</span>
            </div>
          )}
        </div>
        <div className={styles['information']}>
          <h6 className={'headlines-20-bold'}>{title}</h6>
          <div className={'flex items-center gap-4'}>
            <div className={styles['progress-info']}>
              <CircleProgress progress={percentageOfPassedModules} />
              <span
                className={'text-xs font-semibold leading-1.4 text-grey-70'}
              >{`${percentageOfPassedModules} % пройдено`}</span>
            </div>
            <div className={styles['access-count']}>
              <Lock className={'fill-blue-800'} />
              <span className={'text-xs leading-1.4 font-semibold text-grey-70'}>{`${
                getTextWithDeclination(purchasedModulesAmount, ACCESS).split(' ')[1]
              } ${getTextWithDeclination(purchasedModulesAmount, MODULE)} из ${modulesAmount}`}</span>
            </div>
          </div>
        </div>
      </div>
    </article>
  );
};
