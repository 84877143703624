import React, { FC } from 'react';
import { isDev } from '@shared/utils/isDev';

/**
 * Проверяет правильность настройки приложения в `production`;
 */
export function withRuntimeEnv<C extends FC, P extends React.ComponentProps<C>>(App: C) {
  // Практически невозможно, но если не прочитать документацию - возможно
  if (!isDev() && !window._env_) {
    // Кладём приложение, если настроено не правильно
    console.error(
      '[RuntimeEnv]: Вероятно запрещено исполнение файла `runtime-env.sh`, либо забыли его запустить при сборке приложения.',
      '[RuntimeEnv]: Убедитесь, что выполнили все инструкции из документации к проекту.',
    );
    throw new Error('Не инициализированы переменные окружения! (см. консоль)');
  }

  const constructor: FC<P> = (props) => {
    return App(props);
  };

  constructor.displayName = `withRuntimeEnv(${App.displayName})`;
  return constructor;
}
