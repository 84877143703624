import { ErrorHandlers } from '@shared/handle-error/handleError';
import { showErrorModalEvent } from '@ui-kit/Modal/ModalResult/ModalResult.store';

export const handleApiValidationError: ErrorHandlers['apiValidationError'] = (err) => {
  const errorMessages = err.getUserFriendlyMessages();

  showErrorModalEvent({
    title: 'Ошибка',
    description: errorMessages.map((message, index) => (
      <p className="mb-2 text-14-regular text-grey-60" key={index}>
        {message}
      </p>
    )),
  });
};
