import React, { FC } from 'react';
import { ReactComponent as Check } from '@svg/solid/check.svg';
import { StudentExerciseContentTestAttempt } from '@generated-student';
import { ReactComponent as CloseOrDeleteError } from '@svg/solid/closeOrDeleteError.svg';

type TestResultComponentProps = {
  testAttempt: StudentExerciseContentTestAttempt;
};

export const TestResultComponent: FC<TestResultComponentProps> = ({ testAttempt }) => {
  const { isTestPassed, correctAnswerPercentage, passingThreshold } = testAttempt;

  return (
    <div className="bg-white rounded-[16px] p-[10px]">
      <div className="flex gap-x-[5px]">
        {isTestPassed ? (
          <Check className={'w-[20px] h-[20px] text-light-green'} />
        ) : (
          <CloseOrDeleteError className={'w-[20px] h-[20px] text-red-intensive'} />
        )}

        <div className="flex flex-col">
          <span className={'font-bold text-m leading-1'}>{isTestPassed ? 'Тест пройден' : 'Тест не пройден'}</span>
          <span
            className={'mt-[10px]'}
          >{`Вы набрали ${correctAnswerPercentage}% правльных ответов. (Порог прохождения теста - ${passingThreshold}%)`}</span>
        </div>
      </div>
    </div>
  );
};
