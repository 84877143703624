import { Payment } from '@modules/payment/components/PaymentOperationList/PaymentOperation/PaymentOperation.component';
import { OrderItemModule, OrderSoloCourseItem, StudentListPaymentHistoryResponseSchema } from '@generated-student';
import { toRubles } from '@shared/utils/toRubles';
import { isOrderItemModule } from '@shared/guards/isOrderItemModule';
import { normalizeUrlProtocol } from '@shared/utils/normalizeUrlProtocol';
import { COURSE_DIRECTION_DICTIONARY } from '@shared/constants/courses';

const getCourse = (orderItem: OrderItemModule | OrderSoloCourseItem): Payment['course'] => {
  if (isOrderItemModule(orderItem)) {
    return {
      cover: null,
      name: orderItem.moduleTitle,
      type: COURSE_DIRECTION_DICTIONARY[orderItem.soloCourseDirection],
    };
  }
  return {
    cover: normalizeUrlProtocol(orderItem.soloCourseCover.sizes.small.url),
    name: orderItem.soloCourseTitle,
    type: COURSE_DIRECTION_DICTIONARY[orderItem.soloCourseDirection],
  };
};
export const mapListPaymentHistoryItemsResponseToPayments = (
  items: StudentListPaymentHistoryResponseSchema['items'],
): Payment[] => {
  return items.map((item) => {
    const [orderItem] = item.orderItems;

    return {
      course: getCourse(orderItem),
      createdAt: item.createdAt,
      id: item.id,
      receipt: item.receipt,
      sum: toRubles(Number(`${item.price.rubles}.${item.price.pennies}`)),
    };
  });
};
