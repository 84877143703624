import { studentApiClient } from '@shared/api/client/client';
import {
  StudentListSoloCoursesToPurchaseRequestSchema,
  StudentListSoloCoursesToPurchaseResponseSchema,
  StudentGetSoloCourseToPurchaseRequestSchema,
  StudentGetSoloCourseToPurchaseResponseSchema,
  StudentGetSoloCourseRequestSchema,
  StudentGetSoloCourseResponseSchema,
  StudentGetFilterForListSoloCoursesToPurchaseResponseSchema,
  StudentListAllExerciseAttachmentsInSoloCourseRequestSchema,
  StudentListAllExerciseAttachmentsInSoloCourseResponseSchema,
  StudentRequestSoloCourseRequestSchema,
  StudentBuySoloCourseRequestSchema,
} from '@generated-student';
import { ApiSuccessResponse } from '@shared/api/client/responses/ApiSuccessResponse';

export class SoloCourseService {
  static getListSoloCoursesToPurchase(data: StudentListSoloCoursesToPurchaseRequestSchema) {
    return studentApiClient.post<ApiSuccessResponse<StudentListSoloCoursesToPurchaseResponseSchema>>(
      '/listSoloCoursesToPurchase',
      data,
    );
  }

  static getFilterForListSoloCoursesToPurchase() {
    return studentApiClient.post<ApiSuccessResponse<StudentGetFilterForListSoloCoursesToPurchaseResponseSchema>>(
      '/getFilterForListSoloCoursesToPurchase',
    );
  }

  static getSoloCourse(data: StudentGetSoloCourseRequestSchema) {
    return studentApiClient.post<ApiSuccessResponse<StudentGetSoloCourseResponseSchema>>('/getSoloCourse', data);
  }

  static getSoloCourseToPurchase(data: StudentGetSoloCourseToPurchaseRequestSchema) {
    return studentApiClient.post<ApiSuccessResponse<StudentGetSoloCourseToPurchaseResponseSchema>>(
      '/getSoloCourseToPurchase',
      data,
    );
  }

  static getAllExerciseAttachmentsInSoloCourse(data: StudentListAllExerciseAttachmentsInSoloCourseRequestSchema) {
    return studentApiClient.post<ApiSuccessResponse<StudentListAllExerciseAttachmentsInSoloCourseResponseSchema>>(
      'listAllExerciseAttachmentsInSoloCourse',
      data,
    );
  }

  static buySoloCourse(data: StudentBuySoloCourseRequestSchema) {
    return studentApiClient.post<ApiSuccessResponse<void>>('/buySoloCourse', data);
  }

  static requestSoloCourse(data: StudentRequestSoloCourseRequestSchema) {
    return studentApiClient.post<ApiSuccessResponse<void>>('/requestSoloCourse', data);
  }
}
