import { MatchTestAnswersViewModel, TestAnswers } from '@modules/exercise/types/exercise.types';
import { VALIDATION_ERROR_MSG } from '@modules/exercise/constants/constants';
export class TestValidation {
  static validateMultiChoiceTest(testAnswers: Array<string>) {
    const isTestValid = testAnswers.length > 0;

    return {
      isValid: isTestValid,
      errorMsg: isTestValid ? '' : VALIDATION_ERROR_MSG['toShort'],
    };
  }

  static validateSingleChoiceTest(testAnswer: string) {
    const isTestValid = testAnswer.length > 0;

    return {
      isValid: isTestValid,
      errorMsg: isTestValid ? '' : VALIDATION_ERROR_MSG['toShort'],
    };
  }

  static validateMatchingTest(currentMatchingResult: MatchTestAnswersViewModel, totalQuestions: number) {
    const matchingTestAnswers = Object.values(currentMatchingResult);

    const isAnswersEmpty = matchingTestAnswers.length === 0;

    //Если в массиве с ответами пресутствует 2 и более зачений, значит их необходимо проверить на уникальность
    const hasTestSameAnswers =
      matchingTestAnswers.length < 2 ? false : new Set(matchingTestAnswers).size !== matchingTestAnswers.length;

    const hasTestFullField = totalQuestions === matchingTestAnswers.length;

    const isTestValid = !hasTestSameAnswers && !isAnswersEmpty && hasTestFullField;

    const errorMsg = isAnswersEmpty
      ? VALIDATION_ERROR_MSG['toShort']
      : hasTestSameAnswers
      ? VALIDATION_ERROR_MSG['sameAnswers']
      : !hasTestFullField
      ? VALIDATION_ERROR_MSG['notFullField']
      : '';

    return {
      isValid: isTestValid,
      errorMsg,
    };
  }

  static checkTestIsValid(testId: string, test: TestAnswers) {
    return Object.values(test).every((testType) =>
      Object.values(testType).every((testResult) => testResult.validation.isValid),
    );
  }
}
