import React, { FC, ReactNode } from 'react';

type TabPanelProps = {
  children: ReactNode;
  index: string;
  currentTab: string;
};

export const TabPanel: FC<TabPanelProps> = ({ children, index, currentTab }) => {
  const isChildrenCurrentPage = index === currentTab;
  return isChildrenCurrentPage ? <>{children}</> : <></>;
};
