import { createEffect, createStore } from 'effector';
import { ApiThrownError } from '@shared/api/client/responses/ApiErrorFactory';
import { handleError } from '@shared/handle-error/handleError';
import { StudentListAllExerciseAttachmentsInSoloCourseRequestSchema } from '@generated-student';
import { CourseAttachmentsViewModel } from '@modules/user-course/types/user-course-attachments.types';
import { SoloCourseService } from '@shared/api/client/services/SoloCourse';
import { MapperApiToViewMapper } from '@modules/user-course/mappers/user-course.mapper';

type CourseAttachmentsStore = {
  isLoading: boolean;
  userCourseAttachments: CourseAttachmentsViewModel | null;
};

export const $UserCourseAttachments = createStore<CourseAttachmentsStore>({
  isLoading: false,
  userCourseAttachments: null,
});

export const getCourseAttachmentsFx = createEffect<
  StudentListAllExerciseAttachmentsInSoloCourseRequestSchema,
  CourseAttachmentsViewModel,
  ApiThrownError
>(async (requestData) => {
  const {
    data: { data },
  } = await SoloCourseService.getAllExerciseAttachmentsInSoloCourse(requestData);
  return MapperApiToViewMapper.getDocumentsViewModel(data);
});

$UserCourseAttachments.on([getCourseAttachmentsFx], (prev) => {
  return {
    ...prev,
    isLoading: true,
  };
});

$UserCourseAttachments.on([getCourseAttachmentsFx.doneData], (prev, payload) => {
  return {
    userCourseAttachments: payload,
    isLoading: false,
  };
});

$UserCourseAttachments.on([getCourseAttachmentsFx.failData], (prev, payload) => {
  handleError(payload);

  return {
    ...prev,
    isLoading: false,
  };
});
