import React, { FC, RefObject } from 'react';
import { CourseCard } from '../CourseCard/CourseCard.component';
import { useStore } from 'effector-react';
import { $CoursesList, changeOffset, getCourses } from '@modules/course/stores/courses/courses-list.store';
import { InfiniteScroll } from '@shared/components/InfiniteScroll/InfiniteScroll.component';

type CoursesListProps = {
  wrapperRef: RefObject<HTMLElement>;
};

/** Компонент с листингом курсов */
export const CoursesList: FC<CoursesListProps> = ({ wrapperRef }) => {
  const coursesList = useStore($CoursesList);

  const loadMoreItems = () => {
    changeOffset(coursesList.items.length);

    getCourses();
  };

  return (
    <InfiniteScroll
      scrollContainerRef={wrapperRef}
      loadMore={loadMoreItems}
      hasMore={coursesList.items.length < coursesList.itemsAmount}
      containerClassName="flex flex-col gap-y-5 w-full grow"
    >
      {coursesList.items.map((course) => {
        return <CourseCard {...course} key={course.id} withButton={false} isDisabled={false} />;
      })}
    </InfiniteScroll>
  );
};
