import axios from 'axios';
import { applyErrorResponseInterceptor } from '@shared/api/client/interceptors/applyErrorResponseInterceptor';
import { applyAccessTokenInjectInterceptor } from '@shared/api/client/interceptors/applyAccessTokenInjectInterceptor';
import { applyArgumentsWrapperInterceptor } from '@shared/api/client/interceptors/applyArgumentsWrapperInterceptor';
import { getEnv } from '@shared/utils/getEnv';
import { applyRefreshTokenInterceptor } from '@shared/api/client/interceptors/applyRefreshTokenInterceptor';

const baseURL = getEnv('API_URL');

if (typeof baseURL !== 'string' || !baseURL) {
  throw new Error('Required environment variable `API_URL` not defined!');
}

// Используем для запросов к методам студента
export const studentApiClient = axios.create({
  baseURL: `${baseURL}/student`,
});
export const privateApiClient = axios.create({
  baseURL: `${baseURL}`,
});

// Используем для обращения к методу загрузки файла
export const fileApiClient = axios.create({
  baseURL,
});

// Используем для запросов к публичным методам
export const publicApiClient = axios.create({
  baseURL,
});

/** Инициализация приватных клиентов */
[studentApiClient, fileApiClient, privateApiClient].forEach((apiClient) => {
  applyRefreshTokenInterceptor(apiClient);
  applyErrorResponseInterceptor(apiClient);
  applyAccessTokenInjectInterceptor(apiClient);
  applyArgumentsWrapperInterceptor(apiClient);
});

/** Инициализация публичных клиентов */
[publicApiClient].forEach((apiClient) => {
  applyErrorResponseInterceptor(apiClient);
  applyArgumentsWrapperInterceptor(apiClient);
});
