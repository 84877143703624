import { showErrorModalEvent } from '@shared/ui-kit/Modal/ModalResult/ModalResult.store';
/**
 * Фукция проверяет размер загружаемого файла
 */
export const isValidUploadFileSize = (file: File): boolean => {
  const isMaxSize = file.size < 15728640;

  if (!isMaxSize) {
    showErrorModalEvent({ description: 'Превышен максимальный размер файла!', title: 'Ошибка' });
    return false;
  }

  return true;
};
