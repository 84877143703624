import React, { FC } from 'react';
import styles from './ModuleCard.module.css';
import { ReactComponent as BookIcon } from '@svg/outline/book.svg';
import { ReactComponent as TimeIcon } from '@svg/outline/time.svg';
import { ModuleViewModelType, PurchasedModuleViewModel } from '@modules/module/types/module.types';
import { MODULE_STATUS_VIEW_BY_STATUS } from '@modules/module/components/ModuleCard/ModuleCard.constants';
import { CircleProgress } from '@shared/ui-kit/CircleProgress/CircleProgress';

type PurchasedModuleCardProps = PurchasedModuleViewModel & {
  onClickModule?: (id: string, type: ModuleViewModelType) => void;
};

/**
 * Компонент карточки оплаченного модуля
 */
export const PurchasedModuleCard: FC<PurchasedModuleCardProps> = ({
  title,
  description,
  studyHours,
  onClickModule,
  id,
  type,
}) => {
  const percentageOfPassedModules = Math.floor((studyHours.passed / studyHours.total) * 100);

  const moduleDurationText = `на ${studyHours.total} ч.`;

  const isModuleCompleted = studyHours.passed === studyHours.total;
  const moduleStatus = isModuleCompleted
    ? MODULE_STATUS_VIEW_BY_STATUS['completed']
    : MODULE_STATUS_VIEW_BY_STATUS['progress'];

  return (
    <>
      <div className={styles['module-card']} onClick={onClickModule ? () => onClickModule(id, type) : undefined}>
        <div className="flex flex-col pt-[12px] w-full">
          <div className="flex items-center justify-between mb-[14px]">
            <div className="flex items-center">
              <BookIcon className="w-[30px] h-[30px] fill-blue-800 mr-[10px]" />
              <span className="text-18-regular text-grey-70">{title}</span>
            </div>

            <div className="flex items-center">
              <span className="text-16-regular text-grey-70">{moduleStatus.title}</span>

              <div className="ml-[10px]">{moduleStatus.icon}</div>
            </div>
          </div>

          <div className="mb-[14px]">
            <h6 className={styles['module-card__title']}>{description}</h6>
          </div>

          <div className="flex items-center gap-x-[10px]">
            <div className="flex items-center justify-end p-[8px_16px_8px_8px] gap-2 rounded-[40px] border border-opacity-violet-5 bg-blue-500">
              <CircleProgress progress={percentageOfPassedModules} />
              <span className={'text-s leading-1.4 text-grey-70'}>{`${percentageOfPassedModules} % пройдено`}</span>
            </div>
            <div className="flex items-center rounded-[40px] bg-blue-500 border-blue-500 p-[5px_15px_5px_5px]">
              <TimeIcon width={30} height={30} className="fill-grey-70 mr-[12px]" />
              <span className="text-16-regular text-grey-70">{moduleDurationText}</span>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
