import React from 'react';
import { usePaymentResultHook } from '@modules/payment/hooks/usePaymentResult.hook';
import Pending from '@modules/payment/components/Result/Pending.component';
import Fail from '@modules/payment/components/Result/Fail.component';
import Success from '@modules/payment/components/Result/Success.component';
import { SplashScreen } from '@ui-kit/SplashScreen/SplashScreen.component';

export const ResultPage = () => {
  const { resultState, callBack } = usePaymentResultHook();

  return (
    <div className={'flex flex-col justify-center items-center h-[100vh]'}>
      {resultState === 'Pending' && <Pending onClick={callBack} />}
      {resultState === 'Error' && <Fail onClick={callBack} />}
      {resultState === 'Success' && <Success onClick={callBack} />}
      {resultState === 'Splash' && <SplashScreen active={true} />}
    </div>
  );
};
