import React, { FC } from 'react';
import { YouTubeVideoPlayer } from '@ui-kit/YouTubeVideoPlayer/YouTubeVideoPlayer.component';
import { ExerciseBlockVideo } from '@modules/exercise/types/exercise.types';

type BlockVideoProps = {
  data: ExerciseBlockVideo;
};

export const BlockVideo: FC<BlockVideoProps> = ({ data }) => {
  const { url, title, description } = data;
  return (
    <div className={'flex flex-col gap-y-[40px]'}>
      <YouTubeVideoPlayer url={url} className={'mb-[40px]'} />
      <div className={'flex flex-col gap-y-[30px]'}>
        <h5 className={'text-grey-90'}>{title}</h5>
        <p className={'text-18-regular text-grey-70'}>{description}</p>
      </div>
    </div>
  );
};
