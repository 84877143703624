import React, { useEffect } from 'react';
import { useUserCourseAttachments } from '@modules/user-course/hooks/useUserCourseAttachments';
import { useParams } from 'react-router-dom';
import { FileAttachmentsList } from '@ui-kit/AttachmentsList/FileAttachmentsList.component';
import { ExternalLinkAttachmentsList } from '@ui-kit/AttachmentsList/ExternalLinkAttachmentsList.component';
import styles from './UserCourseAttachments.module.css';

export const UserCourseAttachments = () => {
  const { courseId, moduleId } = useParams();
  const { userCourseAttachments, getCourseAttachments } = useUserCourseAttachments();

  const currentModuleExercises = userCourseAttachments?.modules.filter((module) => {
    return module.id === moduleId;
  })[0]?.exercises;

  useEffect(() => {
    if (!courseId) return;
    getCourseAttachments({ filter: { soloCourseId: courseId } });
  }, []);

  return (
    <div className={styles['user-course-attachments']}>
      <div className={'flex flex-col gap-6'}>
        {currentModuleExercises?.map(({ title, id, attachments }, index) => {
          const exerciseTitle = `${index + 1}. ${title}`;
          const hasAttachments =
            attachments.exerciseAttachmentWithFile.length || attachments.attachmentWithExternalLink.length;

          return (
            <div className={'flex flex-col gap-6'} key={id}>
              <h4>{exerciseTitle}</h4>
              {hasAttachments ? (
                <>
                  {attachments.exerciseAttachmentWithFile.length && (
                    <section>
                      <FileAttachmentsList data={attachments.exerciseAttachmentWithFile} />
                    </section>
                  )}
                  {attachments.attachmentWithExternalLink.length && (
                    <section>
                      <ExternalLinkAttachmentsList data={attachments.attachmentWithExternalLink} />
                    </section>
                  )}
                </>
              ) : (
                <p className={'text-m'}>{'Для данного занятия нет материалов'}</p>
              )}
            </div>
          );
        })}
      </div>
    </div>
  );
};
