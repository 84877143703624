import { getOrderStatusPureFx } from '@shared/stores/purchase-store';
import { getSuccessRedirectUrl } from '@shared/utils/getSuccessRedirectUrl';
import { useNavigate } from 'react-router-dom';
import { useEffect, useRef } from 'react';
import { showErrorModalEvent } from '@ui-kit/Modal/ModalResult/ModalResult.store';
import { updateCartEvent } from '@shared/stores/cart.store';
import { hideModalEvent } from '@shared/ui-kit/Modal/ModalBasic/ModalBasic.store';

export const useStartCheckingHook = () => {
  const navigate = useNavigate();
  const ref = useRef(false);

  useEffect(() => {
    return () => {
      ref.current = true;
    };
  }, []);
  const startChecking = async (orderId: string) => {
    if (ref.current) {
      return;
    }
    await new Promise((resolve) => {
      setTimeout(() => {
        resolve(1);
      }, 2000);
    });
    const { status, orderItems } = await getOrderStatusPureFx({ id: orderId });
    if (status === 'payment_succeeded') {
      updateCartEvent(orderItems.map((it) => ('moduleId' in it ? it.moduleId : it.soloCourseId)));
      hideModalEvent();
      return navigate(getSuccessRedirectUrl(orderItems));
    }
    if (['payment_canceled', 'payment_failed', 'payment_form_initializing_failed'].includes(status)) {
      showErrorModalEvent({ description: 'Ошибка оплаты', title: 'Ошибка' });
      return;
    }
    await startChecking(orderId);
  };

  return { startChecking };
};
