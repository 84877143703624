import { FC } from 'react';
import { ReactComponent as Link } from '@svg/link.svg';
import { StudentExerciseAttachmentWithExternalLink } from '@generated-student';

type ExternalLinkAttachmentsListProps = {
  data: Array<StudentExerciseAttachmentWithExternalLink>;
};

export const ExternalLinkAttachmentsList: FC<ExternalLinkAttachmentsListProps> = ({ data }) => {
  return (
    <div className={'bg-white py-[20px] px-[30px] rounded-[30px]'}>
      <h4 className={'mb-[20px] text-l'}>Ссылки на материалы:</h4>
      <ul className={'flex flex-col gap-y-[20px]'}>
        {data.map(({ url, title }) => (
          <li key={url}>
            <a href={url} target={'_blank'} className={'flex gap-x-[5px] items-center'} rel="noreferrer">
              <Link className={'text-blue-800'} />
              <span className={'text-18-regular text-blue-800'}>{title}</span>
            </a>
          </li>
        ))}
      </ul>
    </div>
  );
};
