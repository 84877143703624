import React, { ChangeEvent, FC } from 'react';
import {
  TestQuestionContainer,
  TestQuestionContainerProps,
} from '@modules/exercise/components/tests/TestQuestionContainer.component';
import { Checkbox } from '@ui-kit/Checkbox/Checkbox.component';
import { StudentTestQuestionWithMultiChoiceViewModel } from '@modules/exercise/types/exercise.types';
import { HandleChangeMultiChoiceTestValuesPayload } from '@modules/exercise/stores/exercise-content.store';
import styles from './CheckboxTestQuestion.module.css';

type CheckboxTestQuestionProps = {
  testId: string;
  checkedValues: Array<string>;
  question: StudentTestQuestionWithMultiChoiceViewModel;
  onChange: (payload: HandleChangeMultiChoiceTestValuesPayload) => void;
} & TestQuestionContainerProps;

export const CheckboxTestQuestion: FC<CheckboxTestQuestionProps> = ({
  question,
  testId,
  checkedValues,
  isQuestionWasAnsweredCorrectly,
  onChange: handleChange,
}) => {
  const handleClickCheckbox = (e: ChangeEvent<HTMLInputElement>) => {
    const checkboxId = e.target.value;
    const checkedValuesSet = new Set(checkedValues);
    checkedValuesSet.has(checkboxId) ? checkedValuesSet.delete(checkboxId) : checkedValuesSet.add(checkboxId);
    handleChange({ testId, questionId: question.id, updatedCheckedValues: Array.from(checkedValuesSet) });
  };

  return (
    <TestQuestionContainer
      className={styles['checkbox-test-question']}
      isQuestionWasAnsweredCorrectly={isQuestionWasAnsweredCorrectly}
    >
      {question.options.map((option) => {
        const isChecked = checkedValues.includes(option.value);

        return (
          <Checkbox
            id={option.value}
            value={option.value}
            label={option.label}
            key={option.value}
            checked={isChecked}
            onChange={handleClickCheckbox}
            styleConfig={{ labelStyle: styles['checkbox-label'] }}
          />
        );
      })}
    </TestQuestionContainer>
  );
};
