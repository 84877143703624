import { studentApiClient } from '@shared/api/client/client';
import {
  StudentListAllExerciseContentResponseSchema,
  StudentListAllExerciseContentRequestSchema,
  StudentSubmitTestAnswersResponseSchema,
  StudentSubmitTestAnswersRequestSchema,
} from '@generated-student';
import { ApiSuccessResponse } from '@shared/api/client/responses/ApiSuccessResponse';

export class ExerciseContentService {
  static getListAllExerciseContent(data: StudentListAllExerciseContentRequestSchema) {
    return studentApiClient.post<ApiSuccessResponse<StudentListAllExerciseContentResponseSchema>>(
      'listAllExerciseContent',
      data,
    );
  }

  static submitTestAnswers(data: StudentSubmitTestAnswersRequestSchema) {
    return studentApiClient.post<ApiSuccessResponse<StudentSubmitTestAnswersResponseSchema>>('submitTestAnswers', data);
  }
}
