/** Объект из документации {@link NumberFormat} */
type NumberFormatOptions = {
  localeMatcher?: string | undefined;
  style?: string | undefined;
  currency?: string | undefined;
  currencySign?: string | undefined;
  useGrouping?: boolean | undefined;
  minimumIntegerDigits?: number | undefined;
  minimumFractionDigits?: number | undefined;
  maximumFractionDigits?: number | undefined;
  minimumSignificantDigits?: number | undefined;
  maximumSignificantDigits?: number | undefined;
};

/**
 * Функция приводит переданное число к отображению в рублях
 *
 * @param {string | number} num число, которое необходимо отобразить в рублях
 * @param {NumberFormatOptions} [options] конфиг с настройками отображения
 */
export const toRubles = (num: number, options?: Omit<NumberFormatOptions, 'style' | 'currency'>) => {
  if (isNaN(num)) {
    return '';
  }

  const rubOptions = { style: 'currency', currency: 'RUB' };

  return Intl.NumberFormat('ru-RU', { ...rubOptions, ...options }).format(Number(num));
};
