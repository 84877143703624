import React, { FC } from 'react';
import { FileCard } from '@ui-kit/FileCard/FileCard.component';
import style from './PassportAttachment.module.css';
import { ReactComponent as Remove } from '@svg/outline/remove.svg';
import { Button } from '@ui-kit/Button/Button.component';
import { ReactComponent as Plus } from '@svg/outline/plus.svg';
import { ReactComponent as File } from '@svg/outline/file.svg';
import { UsePassportAttachmentResult } from '@modules/documents/components/PassportAttachment/usePassportAttachment';
import { isFileIsDocumentsViewModelFile } from '@modules/documents/guards/isFileIsDocumentsViewModelFile';
import { fileSizeFormatter } from '@shared/utils/fileSizeFormatter';

type PassportAttachmentProps = {
  isEditMode: boolean;
  attachedFile: UsePassportAttachmentResult['attachedFile'];
  onAttach: UsePassportAttachmentResult['handleAttachFile'];
  onReset: UsePassportAttachmentResult['handleResetAttachedFile'];
};

export const PassportAttachment: FC<PassportAttachmentProps> = ({ isEditMode, attachedFile, onReset, onAttach }) => {
  //На данный момент возможно прикрепление только одного файла
  //Если файл уже существует =>  блокируем инпут, сначала его необходимо удалить
  const isAttachDisabled = attachedFile !== undefined;

  return (
    <>
      {!isEditMode && isFileIsDocumentsViewModelFile(attachedFile) && (
        <div className={style['file-card-wrapper']}>
          <FileCard
            description={fileSizeFormatter(attachedFile.fileSize)}
            title={attachedFile.name}
            fileType={attachedFile.mimeType}
            url={attachedFile.url}
          />
        </div>
      )}
      {isEditMode && (
        <div>
          <div className={'flex'}>
            <p className="text-16-regular">
              Загрузите копию своего паспорта (первый разворот и страницу с регистрацией). Файл должнен быть форматов
              PDF, PNG, JPG. Размер файла не должен превышать 3 mb.
            </p>
            <label className={'cursor-pointer text-middle-blue'}>
              <input
                hidden
                type={'file'}
                accept="image/*"
                onChange={onAttach}
                onClick={(event) => {
                  event.currentTarget.value = '';
                }}
                disabled={isAttachDisabled}
              />
              <Plus />
            </label>
          </div>
          {attachedFile && (
            <div className={'flex justify-between mt-[25px]'}>
              <div className="flex items-center gap-x-[12px]">
                <File className={'w-[25px] h-[30px] fill-blue-800'} />
                <p className={'text-18-regular'}>{attachedFile.name}</p>
              </div>
              <Button variant={'ghost'} rightIcon={<Remove className={'fill-blue-800'} />} onClick={onReset} />
            </div>
          )}
        </div>
      )}
    </>
  );
};
