import { createEffect, createEvent, createStore } from 'effector';
import { ApiThrownError } from '@shared/api/client/responses/ApiErrorFactory';
import { handleError } from '@shared/handle-error/handleError';
import { ExerciseContentService } from '@shared/api/client/services/ExerciseContentService';
import {
  ExerciseViewModel,
  ListAllExerciseContentViewModel,
  StudentTestQuestionsViewModel,
  SubmitTestAnswersResponseSchemaViewModel,
  TestAnswers,
} from '@modules/exercise/types/exercise.types';
import { MapperApiToViewMapper, MapperViewToApiMapper } from '@modules/exercise/mappers/exercise.mapper';
import { ExerciseService } from '@shared/api/client/services/Exercises';
import { TestValidation } from '@modules/exercise/utils/testValidation';
import { getTestWithAnswersData } from '@modules/exercise/utils/getTestWithAnswersData';

type TestId = string;

export type TestWithAnswersViewModel = {
  [key: TestId]: TestAnswers;
};

type ExerciseContentStore = {
  exerciseContent: ListAllExerciseContentViewModel | null;
  exercise: ExerciseViewModel | null;
  isAllTestInExercisePassed: boolean | null;
  testWithAnswers: TestWithAnswersViewModel | null;
  testResult: SubmitTestAnswersResponseSchemaViewModel | null;
};

export type HandleChangeMultiChoiceTestValuesPayload = {
  testId: string;
  questionId: StudentTestQuestionsViewModel['id'];
  updatedCheckedValues: Array<string>;
};

export type HandleChangeSingleChoiceTestValuesPayload = {
  testId: string;
  questionId: StudentTestQuestionsViewModel['id'];
  updatedValue: string;
};

export type HandleChangeMatchingTestValuesPayload = {
  testId: string;
  questionId: StudentTestQuestionsViewModel['id'];
  firstPartId: string;
  secondPartId: string;
  totalQuestions: number;
};

export const $ExerciseContent = createStore<ExerciseContentStore>({
  exerciseContent: null,
  exercise: null,
  isAllTestInExercisePassed: null,
  testWithAnswers: null,
  testResult: null,
});

export const getListAllExerciseContentFx = createEffect<
  { exerciseId: string },
  {
    exerciseContent: ListAllExerciseContentViewModel;
    exercise: ExerciseViewModel;
    testWithAnswers: TestWithAnswersViewModel | null;
  },
  ApiThrownError
>(async (requestData) => {
  const [exerciseResponse, exerciseContentResponse] = await Promise.all([
    ExerciseService.getExercise({ id: requestData.exerciseId }),
    ExerciseContentService.getListAllExerciseContent({ filter: { exerciseId: requestData.exerciseId } }),
  ]);
  const mappedExercise = MapperApiToViewMapper.getExerciseViewModel(exerciseResponse.data.data);
  const { listAllExerciseContentViewModel, isAllTestInExercisePassed } =
    MapperApiToViewMapper.getExerciseContentViewModel(exerciseContentResponse.data.data);
  const testWithAnswers = getTestWithAnswersData(listAllExerciseContentViewModel);

  return {
    exercise: mappedExercise,
    exerciseContent: listAllExerciseContentViewModel,
    testWithAnswers,
    isAllTestInExercisePassed,
  };
});

export const submitTestAnswersFx = createEffect<
  TestWithAnswersViewModel,
  SubmitTestAnswersResponseSchemaViewModel,
  ApiThrownError
>(async (requestData) => {
  const mappedRequestData = MapperViewToApiMapper.getSubmitTestAnswersRequestData(requestData);
  const {
    data: { data },
  } = await ExerciseContentService.submitTestAnswers(mappedRequestData);

  const mappedResponseData = MapperApiToViewMapper.getTestResultViewModel(data);

  return mappedResponseData;
});

$ExerciseContent.on([getListAllExerciseContentFx.doneData], (prev, payload) => {
  return {
    ...prev,
    ...payload,
  };
});

$ExerciseContent.on([submitTestAnswersFx.doneData], (prev, payload) => {
  return {
    ...prev,
    testResult: payload,
  };
});

$ExerciseContent.on([getListAllExerciseContentFx.failData], (prev, payload) => {
  handleError(payload);

  return prev;
});

/*======== Эвенты различных блоков теста ============*/

export const handleChangeMultiChoiceTestValues = createEvent<HandleChangeMultiChoiceTestValuesPayload>();

export const handleChangeSingleChoiceTestValues = createEvent<HandleChangeSingleChoiceTestValuesPayload>();

export const handleChangeMatchingTestValues = createEvent<HandleChangeMatchingTestValuesPayload>();

export const resetExercise = createEvent();

/*========= Обработчики различных блоков теста ========*/

$ExerciseContent.on(
  handleChangeMultiChoiceTestValues,
  (prev, { testId, questionId, updatedCheckedValues }: HandleChangeMultiChoiceTestValuesPayload) => {
    if (prev.testWithAnswers === null) return prev;
    //переменная содержит объект с ответами для текущего теста
    const currentTest = prev.testWithAnswers[testId];

    const validation = TestValidation.validateMultiChoiceTest(updatedCheckedValues);
    const questionWasAnsweredCorrectly = currentTest['multi_choice'][questionId].questionWasAnsweredCorrectly;

    const updatedCurrentTest = {
      ...currentTest,
      multi_choice: {
        ...currentTest['multi_choice'],
        [questionId]: { answerIds: updatedCheckedValues, validation, questionWasAnsweredCorrectly },
      },
    };

    return {
      ...prev,
      testWithAnswers: {
        ...prev.testWithAnswers,
        [testId]: updatedCurrentTest,
      },
    };
  },
);

$ExerciseContent.on(
  handleChangeSingleChoiceTestValues,
  (prev, { testId, questionId, updatedValue }: HandleChangeSingleChoiceTestValuesPayload) => {
    if (prev.testWithAnswers === null) return prev;
    //переменная содержит объект с ответами для текущего теста
    const currentTest = prev.testWithAnswers[testId];

    const validation = TestValidation.validateSingleChoiceTest(updatedValue);
    const questionWasAnsweredCorrectly = currentTest['single_choice'][questionId].questionWasAnsweredCorrectly;

    const updatedCurrentTest = {
      ...currentTest,
      single_choice: {
        ...currentTest['single_choice'],
        [questionId]: {
          answerId: updatedValue,
          validation,
          questionWasAnsweredCorrectly,
        },
      },
    };

    return {
      ...prev,
      testWithAnswers: {
        ...prev.testWithAnswers,
        [testId]: updatedCurrentTest,
      },
    };
  },
);

$ExerciseContent.on(
  handleChangeMatchingTestValues,
  (prev, { testId, questionId, firstPartId, secondPartId, totalQuestions }: HandleChangeMatchingTestValuesPayload) => {
    if (prev.testWithAnswers === null) return prev;
    //переменная содержит объект с ответами для текущего теста
    const currentTest = prev.testWithAnswers[testId];

    const currentMatchingResult = currentTest.matching[questionId].matching;

    currentMatchingResult[firstPartId] = secondPartId;

    const validation = TestValidation.validateMatchingTest(currentMatchingResult, totalQuestions);

    const questionWasAnsweredCorrectly = currentTest['matching'][questionId].questionWasAnsweredCorrectly;

    const updatedCurrentTest = {
      ...currentTest,
      matching: {
        ...currentTest['matching'],
        [questionId]: { matching: currentMatchingResult, validation, questionWasAnsweredCorrectly },
      },
    };

    return {
      ...prev,
      testWithAnswers: {
        ...prev.testWithAnswers,
        [testId]: updatedCurrentTest,
      },
    };
  },
);

$ExerciseContent.reset(resetExercise);

/*================================*/
