import React, { useEffect, useState } from 'react';
import { DocumentsViewModel } from '@modules/documents/types/documents.types';

export type AttachedFile = DocumentsViewModel['file'] | File | undefined;

export type UsePassportAttachmentResult = {
  attachedFile: AttachedFile;
  handleResetAttachedFile: () => void;
  handleAttachFile: (e: React.ChangeEvent<HTMLInputElement>) => void;
};

type UsePassportAttachmentParams = {
  defaultFile: AttachedFile;
};

type UsePassportAttachment = (params: UsePassportAttachmentParams) => UsePassportAttachmentResult;

export const usePassportAttachment: UsePassportAttachment = ({ defaultFile }) => {
  const [attachedFile, setAttachedFile] = useState<AttachedFile>(undefined);

  const handleAttachFile = (e: React.ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files;
    if (!files) return;
    setAttachedFile(files[0]);
  };

  const handleResetAttachedFile = () => {
    setAttachedFile(undefined);
  };

  useEffect(() => {
    if (!defaultFile) return;
    setAttachedFile(defaultFile);
  }, [defaultFile]);

  return { attachedFile, handleAttachFile, handleResetAttachedFile };
};
