import { isDev } from '@shared/utils/isDev';

type EnvVar = keyof Window['_env_'];

export function getEnv(name: EnvVar) {
  if (isDev()) {
    // Хардкод ключей переменных необходим для корректной работы сервера разработки сторибука.
    // При текущей конфигурации сторибука отсутствует возможность обращаться к
    // свойствам process.env через ДИНАМИЧЕСКИЕ переменные(шаблонные строки/конкатенация строк и тд)
    //
    // Более подробная информация о проблеме описана в задаче https://github.com/spiks/most-edu-front-public/issues/400
    return name === 'API_URL' ? process.env['REACT_APP_API_URL'] : process.env[`REACT_APP_${name}`];
  } else {
    // В production используем инжектор (динамические env'ы в статике)
    return window._env_[name];
  }
}
