import React from 'react';
import { BasicInput } from '@ui-kit/Input/BasicInput.component';
import { Button } from '@ui-kit/Button/Button.component';
import { ReactComponent as RightOutlineSvg } from '@svg/outline/right.svg';
import { ReactComponent as InfoIcon } from '@svg/solid/info.svg';
import { useOtpForm } from '@modules/authorization/hooks/useOtpForm';
import { useEvent } from 'effector-react';
import {
  changeAuthStageEvent,
  requestAuthSmsCodeFx,
  requestPasswordResettingSmsCodeFx,
} from '@shared/stores/authorization.store';
import { useCountdown } from '@shared/hooks/useCountdown';
import { useAuthorization } from '@modules/authorization/useAuthorization';

type OtpFormProps = {
  type: 'restore' | 'create';
};
export const OtpForm = ({ type }: OtpFormProps) => {
  const {
    params,
    formState: { errors },
    handleSubmit,
  } = useOtpForm({ type });

  const requestAuthSmsCode = useEvent(requestAuthSmsCodeFx);
  const requestPasswordResettingSmsCode = useEvent(requestPasswordResettingSmsCodeFx);

  const { secondsLeft } = useCountdown({ seconds: 30 });

  const changeAuthStage = useEvent(changeAuthStageEvent);
  const { isLoading: isSubmitting, whoTryLogin } = useAuthorization();

  const goToPhoneAuthStage = () => {
    changeAuthStage('phone');
  };

  const retryRequestAuthSmsCode = async () => {
    if (whoTryLogin?.phone) {
      if (type === 'create') {
        await requestAuthSmsCode({ phone: whoTryLogin.phone });
      } else {
        await requestPasswordResettingSmsCode({ phone: whoTryLogin.phone });
      }
    }
  };

  const isAllowRetryRequestSmsCode = secondsLeft === 0;

  /** На форму ввода кода нужно попадать только после формы вввода номера телефона, признак этого наличие поля whoTryLogin.phone в сторе авторизации */
  if (!whoTryLogin?.phone) {
    return null;
  }

  return (
    <form className={'w-full h-full flex flex-col'} onSubmit={handleSubmit}>
      <h4 className={'text-dark-blue mb-[45px]'}>Вход в личный кабинет</h4>
      <div className={'mb-[24px] overflow-hidden'}>
        <p className={'p-[8px] rounded-[8px] bg-gray-80 flex justify-between gap-x-[8px]'}>
          <InfoIcon width={16} height={16} className={'mt-[2px] flex-shrink-0 text-dark-blue'} />
          <span className={'text-dark-blue text-16-regular block'}>
            {`На номер ${whoTryLogin.phone} был выслан 4-х значный код-подтверждение, введите его в поле расположенное ниже`}
          </span>
        </p>
      </div>
      <div className={'flex flex-col'}>
        <BasicInput
          {...params.smsCode}
          mask="9999"
          maskChar={null}
          error={errors.smsCode?.message}
          containerClassName={'mb-[-24px]'}
          label={'Код подтверждения'}
        />
        <div className="flex items-center h-[38px] mt-8 text-16-bold">
          <p
            className={`${isAllowRetryRequestSmsCode ? 'text-dark-blue' : 'text-grey-75'} cursor-pointer select-none`}
            onClick={isAllowRetryRequestSmsCode ? retryRequestAuthSmsCode : undefined}
          >
            Запросить код повторно
          </p>
          {!isAllowRetryRequestSmsCode && (
            <span className={'inline-block text-dark-blue rounded-[8px] bg-gray-80 ml-[8px] text-16-regular p-[8px]'}>
              {secondsLeft}
            </span>
          )}
        </div>
        {/** В текущей версии не реализуется */}
        {/*<Button className={'mt-[32px]'} variant={'text'} type={'button'} rightIcon={<MessageSvg color={'white'} />}>*/}
        {/*  <span className={'text-white text-16-bold'}>Обратиться в поддержку</span>*/}
        {/*</Button>*/}
        <Button
          className={'mt-[32px]'}
          variant={'filled'}
          rightIcon={<RightOutlineSvg color={'white'} />}
          disabled={isSubmitting}
        >
          <span className={'text-white'}>Войти</span>
        </Button>
      </div>
      <Button
        className={'mt-[16px]'}
        variant={'text'}
        type={'button'}
        onClick={goToPhoneAuthStage}
        leftIcon={<RightOutlineSvg className={'text-dark-blue'} style={{ transform: 'rotate(180deg)' }} />}
      >
        <span className={'text-dark-blue text-16-bold'}>Назад</span>
      </Button>
    </form>
  );
};
