import { createEffect } from 'effector';
import { AccountService } from '@shared/api/client/services/Account';
import { ApiThrownError } from '@shared/api/client/responses/ApiErrorFactory';
import { handleError } from '@shared/handle-error/handleError';

type ContactData = {
  phone: string;
  email: string;
};

export const getUserContactDataFx = createEffect<void, ContactData, ApiThrownError>(async () => {
  const {
    data: { data },
  } = await AccountService.getContactData();

  return {
    email: data.email ?? '',
    phone: data.phone ?? '',
  };
});

getUserContactDataFx.failData.watch((e) => {
  handleError(e);
});
