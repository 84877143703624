import React, { forwardRef, useCallback, useRef, useState } from 'react';
import styles from './Dropdown.module.css';
import { ReactComponent as ArrowIcon } from '@svg/outline/down.svg';
import { useOnClickOutside } from '@shared/hooks';
import { DropdownMenuItem } from './DropdownMenuItem.component';
import { Value, DropdownCoreProps } from './Dropdown.types';

export const DropdownCore = forwardRef<HTMLInputElement, DropdownCoreProps>(
  (
    {
      name,
      selectedItem,
      onChange,
      withDivider,
      options,
      placement = 'bottom-left',
      arrowClassName,
      prefix,
      itemRender,
      menuClassName,
      selectClassName,
    },
    ref,
  ) => {
    const [isOpen, setIsOpen] = useState(false);
    const dropdownRef = useRef(null);

    const handleToggleMenu = () => {
      setIsOpen((prevState) => !prevState);
    };

    const hideMenu = () => {
      setIsOpen(false);
    };

    const handleChangeValue = useCallback(
      (value: Value) => {
        if (onChange) {
          onChange(value);

          hideMenu();
        }
      },
      [onChange],
    );

    useOnClickOutside(dropdownRef, hideMenu);

    const selectClassNames = [styles['select-value'], selectClassName];
    const menuClassNames = [styles['menu-wrapper'], styles[`menu-wrapper-${placement}`], menuClassName];
    const arrowClassNames = [styles['select-arrow'], arrowClassName];

    return (
      <div className={styles['dropdown-container']} ref={dropdownRef}>
        <div className={styles['select-wrapper']} onClick={handleToggleMenu}>
          {prefix && <div className="mr-[20px]">{prefix}</div>}
          <div className="flex justify-between grow items-center w-full">
            <input hidden readOnly name={name} value={selectedItem?.value} ref={ref} />
            <span className={selectClassNames.join(' ')}>{selectedItem?.label}</span>
            <ArrowIcon className={arrowClassNames.join(' ')} />
          </div>
        </div>
        {isOpen && (
          <div className={menuClassNames.join(' ')}>
            {options && (
              <ul>
                {options.map((option, idx) => {
                  const isLastItem = options.length - 1 === idx;

                  return (
                    <React.Fragment key={option.value}>
                      <DropdownMenuItem
                        onSelect={handleChangeValue}
                        itemRender={itemRender}
                        selectedValue={selectedItem?.value}
                        data={option}
                      />
                      {withDivider && !isLastItem && (
                        <li className="my-[2px]">
                          <div className="h-[2px] bg-opacity-darkBlue-5" />
                        </li>
                      )}
                    </React.Fragment>
                  );
                })}
              </ul>
            )}
          </div>
        )}
      </div>
    );
  },
);
