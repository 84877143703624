import { PaymentCardViewModel } from '../types/payment-card';
import { StudentListPaymentCardsResponseSchema } from '@generated-student';
import { getPaymentName } from '@modules/payment/mappers/getPaymentName';

export const getPaymentCardList = (value: StudentListPaymentCardsResponseSchema): Array<PaymentCardViewModel> => {
  return value.map((card) => {
    const primaryAccountNumberLength = card.primaryAccountNumber.length;
    return {
      id: card.id,
      isActive: false,
      lastFourDigits: card.primaryAccountNumber.slice(primaryAccountNumberLength - 4, primaryAccountNumberLength),
      paymentSystem: getPaymentName(card.primaryAccountNumber),
    };
  });
};

export const paymentCardResponseDataToViewModelMappers = {
  getPaymentCardList,
};
