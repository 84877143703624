import { createEffect, createEvent, createStore, sample } from 'effector';
import { ApiThrownError } from '@shared/api/client/responses/ApiErrorFactory';
import { ProfileService } from '@shared/api/client/services/ProfileService';
import { handleError } from '@shared/handle-error/handleError';
import { mapDataToUserProfile, mapDataToUpdateUserProfile } from '@shared/stores/user-profile/user-profile.mappers';
import { UserAvatar, UserProfile } from './user-profile.types';

export const resetUserProfile = createEvent();
export const $UserProfile = createStore<Partial<UserProfile>>({
  gender: 'male',
});
$UserProfile.reset(resetUserProfile);

export const $UserAvatar = createStore<UserAvatar | null>(null);

export const changeUserAvatar = createEvent<UserAvatar>();
export const updateUserProfile = createEvent<Omit<UserProfile, 'avatar'>>();
export const getUserProfileFx = createEffect<void, Partial<UserProfile>, ApiThrownError>(async () => {
  const {
    data: { data },
  } = await ProfileService.getProfile();

  return mapDataToUserProfile(data);
});
export const updateUserProfileFx = createEffect<UserProfile, UserProfile, ApiThrownError>(async (payload) => {
  const updateProfileRequestData = mapDataToUpdateUserProfile(payload);

  await ProfileService.updateProfile(updateProfileRequestData);

  return payload;
});

$UserProfile
  .on(changeUserAvatar, (state, avatar) => ({ ...state, avatar }))
  .on([getUserProfileFx.doneData, updateUserProfileFx.doneData], (state, profile) => profile)
  .on([getUserProfileFx.failData, updateUserProfileFx.failData], (state, error) => {
    handleError(error);
  });
$UserAvatar.on([getUserProfileFx.doneData, updateUserProfileFx.doneData], (state, profile) => profile.avatar);
// .on(changeUserAvatar, (state, avatar) => avatar);

sample({
  clock: updateUserProfile,
  source: $UserProfile,
  fn: (userProfileStore, eventPayload): UserProfile => ({
    ...eventPayload,
    avatar: userProfileStore.avatar,
  }),
  target: updateUserProfileFx,
});
