import React, { useEffect, useRef } from 'react';
import { Header } from '@shared/ui-kit/Header/Header.component';
import { ReactComponent as ApplicationIcon } from '@svg/outline/application.svg';
import { useTabs } from '@shared/ui-kit/Tabs/useTabs';
import { TabsPrograms } from '@shared/ui-kit/Tabs/TabsPrograms.component';
import { TabPanel } from '@shared/ui-kit/Tabs/TabsPanel.component';
import styles from './UserCourses.module.css';
import { Button } from '@ui-kit/Button/Button.component';
import { useNavigate } from 'react-router-dom';
import { NAVIGATION } from '@shared/constants';
import {
  $UserCoursesCount,
  getUserCourses,
  resetUserCourses,
  setIsPassedUserCourses,
} from '@modules/user-course/stores/user-courses-list.store';
import { useStore } from 'effector-react';
import { UserCoursesList } from '@modules/user-course/components/UserCoursesList/UserCoursesList.component';
import { ProgramTabsType } from '@shared/types/ProgramTabs.types';
import { isProgramTabsType } from '@shared/guards/isProgramTabsType';

export const INITIAL_PROGRAM_FILTER_VALUE = { value: 'relevance', label: 'По актуальности' };

/* Функционал фильтров пока не реализован на бэке */
// type ProgramsFilter = { value: string; label: string };

/**
 * Страница образовательных программ
 */
export const UserCoursesPage = () => {
  const pageWrapperRef = useRef<HTMLDivElement>(null);
  const navigate = useNavigate();
  const { currentTab, handleClickTab: handleChangeTab } = useTabs<ProgramTabsType>({ initialTab: 'all' });
  const navigateToCourseCatalog = () => {
    navigate(NAVIGATION.studentCoursesCatalog);
  };
  const userCourseCounts = useStore($UserCoursesCount);

  /* Функционал фильтров пока не реализован на бэке */
  // const [programsFilter, setProgramsFilter] = useState<ProgramsFilter>(INITIAL_PROGRAM_FILTER_VALUE);

  /* Функционал поиска пока не реализован на бэке */
  // const [search, setSearch] = useState('');

  /* Функционал поиска пока не реализован на бэке */
  // const handleChangeSearch = (newSearch: string) => {
  //   setSearch(newSearch);
  // };

  /* Функционал фильтров пока не реализован на бэке */
  // const handleChangeProgramsFilter = (filter: ProgramsFilter) => {
  //   setProgramsFilter(filter);
  // };

  useEffect(() => {
    getUserCourses();

    return () => {
      resetUserCourses();
    };
  }, []);

  const clickTabHandler = (id: string) => {
    if (isProgramTabsType(id)) {
      handleChangeTab(id);
      setIsPassedUserCourses(id);
    }
  };
  return (
    <div className={styles['page-container']} ref={pageWrapperRef}>
      <Header
        headerText="Мои программы"
        hasBackButton={false}
        rightElement={
          <Button
            className="text-xs m:text-m"
            variant="filled"
            leftIcon={<ApplicationIcon color="white" />}
            onClick={navigateToCourseCatalog}
          >
            Каталог курсов
          </Button>
        }
      >
        {/*<div className="w-[440px]">*/}
        {/* Функционал поиска пока не реализован на бэке */}
        {/*<Search placeholder="Курсы и преподаватели..." value={search} onChange={handleChangeSearch} />*/}
        {/*</div>*/}
      </Header>

      <section className="flex flex-col grow">
        <div className="flex items-center justify-between border-b-[5px] border-solid border-opacity-darkBlue-5 px-[32px]">
          <TabsPrograms
            currentTab={currentTab}
            onChange={clickTabHandler}
            allProgramsAmount={userCourseCounts.total}
            activeProgramsAmount={userCourseCounts.notPassed}
            passedProgramsAmount={userCourseCounts.passed}
          />

          {/* Функционал сортировки пока не реализован на бэке */}
          {/*<div>*/}
          {/*  <Dropdown*/}
          {/*    viewType="grouped"*/}
          {/*    onChange={handleChangeProgramsFilter}*/}
          {/*    selectedItem={programsFilter}*/}
          {/*    prefix="Сортировать по:"*/}
          {/*    options={PROGRAM_FILTERS}*/}
          {/*  />*/}
          {/*</div>*/}
        </div>
        <TabPanel index="all" currentTab={currentTab}>
          <div className="mt-[24px]">
            <UserCoursesList wrapperRef={pageWrapperRef} />
          </div>
        </TabPanel>
        <TabPanel index="active" currentTab={currentTab}>
          <div className="mt-[24px]">
            <UserCoursesList wrapperRef={pageWrapperRef} />
          </div>
        </TabPanel>
        <TabPanel index="passed" currentTab={currentTab}>
          <div className="mt-[24px]">
            <UserCoursesList wrapperRef={pageWrapperRef} />
          </div>
        </TabPanel>
      </section>
    </div>
  );
};
