import React, { FC } from 'react';

export const SubNavigationCart: FC = () => {
  return (
    <div>
      <div className={'p-[18px_20px_18px_30px]'}>
        <h5 className={'text-white'}>{'Корзина'}</h5>
      </div>
    </div>
  );
};
