import { FC } from 'react';
import { FileCard } from '@ui-kit/FileCard/FileCard.component';
import { fileSizeFormatter } from '@shared/utils/fileSizeFormatter';
import { FileType } from '@shared/types/file.types';
import { FileName, FileSize, Url } from '@generated-student';

type FileAttachment = {
  file: {
    fileSize: FileSize;
    originalFileName: FileName;
    url: Url;
    type: FileType;
  };
};

type FileAttachmentsListProps = {
  data: Array<FileAttachment>;
};

export const FileAttachmentsList: FC<FileAttachmentsListProps> = ({ data }) => {
  return (
    <ul className={'flex flex-wrap gap-[20px]'}>
      {data.map(({ file }) => (
        <li key={file.url}>
          <FileCard
            fileType={file.type}
            title={file.originalFileName}
            description={fileSizeFormatter(file.fileSize)}
            url={file.url}
          />
        </li>
      ))}
    </ul>
  );
};
