import { createEffect, createEvent, createStore } from 'effector';
import { IssuedAuthorizationCredentials } from '@generated-student';
import { ClientStorage } from '@shared/client-storage/client-storage';
import { ApiThrownError } from '@shared/api/client/responses/ApiErrorFactory';
import { AuthenticationService } from '@shared/api/client/services/Authentication';
import { ErrorHandlers, handleError } from '@shared/handle-error/handleError';
import { showErrorModalEvent } from '@ui-kit/Modal/ModalResult/ModalResult.store';
import { getUserProfileFx } from '@shared/stores/user-profile/user-profile.store';

type AuthStage = 'phone' | 'smsCode' | 'smsPasswordRestoreCode' | 'password' | 'createPassword';

/** Стейт пользователя, который желает войти в приложение, но еще не авторизовался */
type WhoTryLogin = {
  phone?: string;
  /** Стадия, на которой находится пользователь, желающий войти в приложение */
  stage: AuthStage;
};

type AuthorizedState = {
  isAuthorized: true;
  credentials: IssuedAuthorizationCredentials;
};

type NotAuthorizedState = {
  isAuthorized: false;
  credentials: null;
};

const defaultState: AuthorizationStore = {
  isLoading: false,
  isAuthorized: false,
  credentials: null,
  whoTryLogin: null,
};

// Авторизованный пользователь - тот, у которого есть необходимые данные для общения с API
// Не включает профиль и прочие персональные данные
export type AuthorizationStore = {
  isLoading: boolean;
  whoTryLogin: WhoTryLogin | null;
} & (AuthorizedState | NotAuthorizedState);

export const restoreCredentialsFx = createEffect(() => {
  // Используется effect вместо event'а при отсутствии async операций - в таком случае
  // необходимости писать ещё один reducer в store нет.
  const cred = ClientStorage.getCredentials();
  return cred === false ? null : cred;
});

export const requestAuthSmsCodeFx = createEffect<{ phone: string }, { phone: string }, ApiThrownError>(
  async (payload) => {
    await AuthenticationService.requestAuthSmsCode(payload);
    return payload;
  },
);
export const requestPasswordResettingSmsCodeFx = createEffect<{ phone: string }, { phone: string }, ApiThrownError>(
  async (payload) => {
    await AuthenticationService.requestPasswordResettingSmsCode(payload);
    return payload;
  },
);

export const resetPasswordFx = createEffect<
  { phone: string; smsCode: string },
  IssuedAuthorizationCredentials,
  ApiThrownError
>(async (payload) => {
  const {
    data: { data },
  } = await AuthenticationService.resetPassword(payload);

  const response = {
    userId: data.userId,
    expiresAt: data.expiresAt,
    refreshToken: data.refreshToken,
    accessToken: data.accessToken,
    userProfile: data.userProfile,
  };

  ClientStorage.setCredentials(response);

  return response;
});

export const loginBySmsFx = createEffect<
  { phone: string; smsCode: string },
  IssuedAuthorizationCredentials,
  ApiThrownError
>(async (payload) => {
  const {
    data: { data },
  } = await AuthenticationService.issueTokenByPhoneAndCode(payload);

  const response = {
    userId: data.userId,
    expiresAt: data.expiresAt,
    refreshToken: data.refreshToken,
    accessToken: data.accessToken,
    userProfile: data.userProfile,
  };

  ClientStorage.setCredentials(response);

  return response;
});
export const loginByPasswordFx = createEffect<
  { phone: string; password: string },
  IssuedAuthorizationCredentials,
  ApiThrownError
>(async (params) => {
  const {
    data: { data },
  } = await AuthenticationService.issueTokenByPhoneAndPassword(params);
  const response = {
    userId: data.userId,
    expiresAt: data.expiresAt,
    refreshToken: data.refreshToken,
    accessToken: data.accessToken,
    userProfile: data.userProfile,
  };

  ClientStorage.setCredentials(response);

  return response;
});
export const refreshTokenFx = createEffect<AuthorizationStore, IssuedAuthorizationCredentials, ApiThrownError>(
  async (payload) => {
    const {
      data: { data },
    } = await AuthenticationService.refreshToken({ refreshToken: payload.credentials?.refreshToken ?? '' });

    const response = {
      userId: data.userId,
      expiresAt: data.expiresAt,
      refreshToken: data.refreshToken,
      accessToken: data.accessToken,
      userProfile: data.userProfile,
    };

    ClientStorage.setCredentials(response);

    return response;
  },
);

export const logoutFx = createEffect(async () => {
  const cred = ClientStorage.getCredentials();

  if (!cred) {
    throw new Error('Сохранённых данных пользователя не обнаружено.');
  }

  await AuthenticationService.revokeToken({
    accessToken: cred.accessToken,
  });

  ClientStorage.clearCredentials();
  ClientStorage.clearCart();
});

export const changeAuthStageEvent = createEvent<AuthStage>();

export const $Authorization = createStore<AuthorizationStore>(defaultState);

export const $isPasswordNeedSet = createStore<boolean>(false);
export const setIsPasswordNeedSet = createEvent<boolean>();
$isPasswordNeedSet.on(setIsPasswordNeedSet, (state, payload) => {
  return payload;
});
$isPasswordNeedSet.on(getUserProfileFx.failData, (state, payload) => {
  return payload.data.type === 'account_must_have_password';
});

/**
 *  Action in progress
 */
$Authorization.on([requestAuthSmsCodeFx, loginBySmsFx, restoreCredentialsFx, refreshTokenFx], (prev) => {
  return {
    ...prev,
    isLoading: true,
  };
});

$Authorization.on(changeAuthStageEvent, (state, payload) => {
  return {
    ...state,
    whoTryLogin: { ...state.whoTryLogin, stage: payload },
  };
});

/**
 * Successful results
 */
$isPasswordNeedSet.on(loginByPasswordFx.doneData, () => {
  return false;
});
$Authorization
  .on(requestAuthSmsCodeFx, (state, payload) => {
    return { ...state, whoTryLogin: { phone: payload.phone, stage: 'phone' } };
  })
  .on(requestAuthSmsCodeFx.doneData, (state, payload) => {
    return {
      ...state,
      isLoading: false,
      whoTryLogin: { phone: payload.phone, stage: 'smsCode' },
    };
  })
  .on([loginBySmsFx.doneData, resetPasswordFx.doneData], (state, payload) => {
    return {
      isLoading: false,
      isAuthorized: true,
      whoTryLogin: { phone: state.whoTryLogin?.phone, stage: 'createPassword' },
      credentials: payload,
    };
  })
  .on([refreshTokenFx.doneData, loginByPasswordFx.doneData], (state, payload) => {
    return {
      isLoading: false,
      isAuthorized: true,
      whoTryLogin: null,
      credentials: payload,
    };
  })
  .on(restoreCredentialsFx.doneData, (state, payload) => {
    if (payload === null) {
      return defaultState;
    }
    return {
      credentials: payload,
      isAuthorized: true,
      isLoading: false,
      whoTryLogin: null,
    };
  })
  .on(logoutFx.doneData, () => {
    return defaultState;
  });

const handleLogoutError: ErrorHandlers['unknownError'] = (err) => {
  console.error('Ошибка выхода из сессии: ', err.message);
  showErrorModalEvent({ description: err.message, title: 'Ошибка' });
};

/**
 * Failure results
 */
$Authorization
  .on(requestAuthSmsCodeFx.failData, (state, payload) => {
    if (payload.data.type === 'password_only_access_available') {
      return {
        isLoading: false,
        isAuthorized: false,
        credentials: null,
        whoTryLogin: { phone: state.whoTryLogin?.phone, stage: 'password' },
      };
    }
    handleError(payload);

    return {
      isLoading: false,
      isAuthorized: false,
      credentials: null,
      whoTryLogin: state.whoTryLogin,
    };
  })
  .on([loginBySmsFx.failData, loginByPasswordFx.failData, resetPasswordFx.failData], (state, payload) => {
    handleError(payload);

    return {
      isLoading: false,
      isAuthorized: false,
      credentials: null,
      whoTryLogin: state.whoTryLogin,
    };
  })
  .on(requestPasswordResettingSmsCodeFx.failData, (state, payload) => {
    handleError(payload);

    return {
      isLoading: false,
      isAuthorized: false,
      credentials: null,
      whoTryLogin: state.whoTryLogin,
    };
  })
  .on(refreshTokenFx.failData, (state) => {
    // Если произошла ошибка, то очищаем Local Storage
    ClientStorage.clearCredentials();
    ClientStorage.clearCart();
    return {
      isLoading: false,
      isAuthorized: false,
      credentials: null,
      whoTryLogin: state.whoTryLogin,
    };
  })
  .on(logoutFx.failData, (state, payload) => {
    handleError(payload, { unknownError: handleLogoutError });

    return {
      whoTryLogin: null,
      isLoading: false,
      isAuthorized: false,
      credentials: null,
    };
  });
