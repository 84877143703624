import { createEffect, createEvent, createStore, sample } from 'effector';
import { Payment } from '@modules/payment/components/PaymentOperationList/PaymentOperation/PaymentOperation.component';
import { PaymentService } from '@shared/api/client/services/Payment';
import { handleError } from '@shared/handle-error/handleError';
import { mapListPaymentHistoryItemsResponseToPayments } from '@modules/payment/mappers/mapListPaymentHistoryItemsResponseToPayments';

type GetBillingParams = {
  pagination: {
    amount: number;
    cursor: string | null;
  };
  filter: null;
};

export const resetBilling = createEvent();

export const $Billing = createStore<Payment[]>([]);
$Billing.reset(resetBilling);
export const $BillingCursor = createStore<string | null>(null);
$BillingCursor.reset(resetBilling);
export const $BillingCount = createStore<number>(0);
$BillingCount.reset(resetBilling);
export const setCursor = createEvent<string>();

export const getBilling = createEvent();

export const getBillingFx = createEffect(async ({ pagination }: GetBillingParams) => {
  const { data } = await PaymentService.getListPaymentHistory({
    filter: {
      dateTimeRange: null,
    },
    pagination: pagination,
  });
  return data.data;
});
export const getBillingTotalCountFx = createEffect(async () => {
  const { data } = await PaymentService.getTotalCountOfPayments({ filter: { dateTimeRange: null } });
  return data.data;
});

sample({
  clock: getBilling,
  source: { cursor: $BillingCursor },
  fn: ({ cursor }) => {
    return {
      pagination: {
        amount: 10,
        cursor: cursor,
      },
      filter: null,
    };
  },
  target: [getBillingFx, getBillingTotalCountFx],
});
getBillingFx.failData.watch((error) => {
  handleError(error);
});
getBillingTotalCountFx.failData.watch((error) => {
  handleError(error);
});
$Billing.on(getBillingFx.doneData, (state, payload) => {
  return state.concat(mapListPaymentHistoryItemsResponseToPayments(payload.items));
});
$BillingCount.on(getBillingTotalCountFx.doneData, (state, payload) => {
  return payload.totalCount;
});
$BillingCursor.on(getBillingFx.doneData, (state, payload) => {
  return payload.cursor;
});
$BillingCursor.on(setCursor, (state, payload) => {
  return payload;
});
