import React, { FC } from 'react';
import styles from './Contacts.module.css';
import { useContactData } from '@modules/contactData/hooks/useContactData';
import { ContactDetails } from '@modules/contactData/components/ContactDetails/ContactDetails.component';

export const ContactsPage: FC = () => {
  const { data } = useContactData();

  return (
    <div className={styles['contacts-page']}>
      <div className={styles['substrate']}>
        <ContactDetails phone={data.phone} email={data.email} />
      </div>
    </div>
  );
};
