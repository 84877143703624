import React, { FC } from 'react';
import { NotPurchasedModuleCard } from './NotPurchasedModuleCard.component';
import { PurchasedModuleCard } from './PurchasedModuleCard.component';
import { ModuleCard as ModuleCardType } from './ModuleCard.types';
import { ModuleViewModelType } from '@modules/module/types/module.types';
import { CartStoreModule } from '@shared/types/cart.types';

type ModuleCardProps = ModuleCardType & {
  withButton: boolean;
  onClickModule?: (id: string, type: ModuleViewModelType) => void;
  inCart?: boolean;
  onRemoveFromCart?: (moduleId: string) => void;
  onAddToCart?: (module: CartStoreModule) => void;
};

/**
 * Компонент карточки модуля
 */
export const ModuleCard: FC<ModuleCardProps> = (props) => {
  if (props.type === 'not_purchased_module') {
    return (
      <NotPurchasedModuleCard
        onAddToCart={props.onAddToCart}
        onRemoveFromCart={props.onRemoveFromCart}
        price={props.price}
        type={props.type}
        title={props.title}
        discountedPrice={props.discountedPrice}
        description={props.description}
        discount={props.discount}
        id={props.id}
        withButton={props.withButton}
        inCart={props.inCart}
      />
    );
  }

  if (props.type === 'purchased_module') {
    return (
      <PurchasedModuleCard
        onClickModule={props.onClickModule}
        type={props.type}
        studyHours={props.studyHours}
        title={props.title}
        description={props.description}
        id={props.id}
      />
    );
  }

  return null;
};
