import {
  StudentGetExerciseResponseSchema,
  StudentListAllExerciseContentResponseSchema,
  StudentSubmitTestAnswersRequestSchema,
  StudentSubmitTestAnswersResponseSchema,
  StudentTestQuestionAndAnswerWithMatchingForSubmit,
  StudentTestQuestionAndAnswerWithMultiChoiceForSubmit,
  StudentTestQuestionAndAnswerWithSingleChoiceForSubmit,
  StudentTestQuestionOptionWithMatchingSecondPart,
} from '@generated-student';
import {
  ExerciseAttachments,
  ExerciseViewModel,
  ListAllExerciseContentViewModel,
  SubmitTestAnswersResponseSchemaViewModel,
} from '@modules/exercise/types/exercise.types';
import { TestWithAnswersViewModel } from '@modules/exercise/stores/exercise-content.store';
import { shuffleArray } from '@shared/utils/shuffleArray';
import { getYoutubeEmbedLink } from '@shared/utils/getYoutubeEmbedLink';
import { FileType } from '@shared/types/file.types';

const getOptionViewModel = ({ id, text }: { id: string; text: string }) => ({
  value: id,
  label: text,
});

export class MapperApiToViewMapper {
  static getExerciseContentViewModel(apiModel: StudentListAllExerciseContentResponseSchema): {
    listAllExerciseContentViewModel: ListAllExerciseContentViewModel;
    isAllTestInExercisePassed: boolean;
  } {
    let exercisePassedStatus = true;

    const listAllExerciseContentViewModel = apiModel.map((contentBlock) => {
      if (contentBlock.type === 'testing') {
        //Если хотя бы один из блоков с типом === testing имеет статус - не пройден или contentBlock.testAttempt === null (занятие не проходилось)
        // => занятие считается не пройденным
        if (!contentBlock.testAttempt || contentBlock.testAttempt.isTestPassed === false) {
          exercisePassedStatus = false;
        }

        const questions = contentBlock.questions.map((question) => {
          if (question.type === 'multi_choice' || question.type === 'single_choice') {
            return {
              ...question,
              options: question.options.map((option) => getOptionViewModel(option)),
            };
          } else if (question.type === 'matching') {
            //Бэк присылает ответы для сопоставления в том же порядке что и вопросы
            //необходимо отсоритровать варианты ответов в рандомном порядке
            const shuffledSecondPart = shuffleArray<StudentTestQuestionOptionWithMatchingSecondPart>(
              question.secondPart,
            );

            return {
              ...question,
              firstPart: question.firstPart.map((part) => getOptionViewModel(part)),
              secondPart: shuffledSecondPart.map((part) => getOptionViewModel(part)),
              totalQuestions: question.secondPart.length,
            };
          } else {
            return question;
          }
        });
        return {
          ...contentBlock,
          questions: questions.sort((a, b) => a.id.localeCompare(b.id)),
        };
      }
      if (contentBlock.type === 'presentation') {
        const slides = contentBlock.slides.map((slide) => `//${slide.sizes.large.url}`);
        return { ...contentBlock, slides };
      }
      if (contentBlock.type === 'video') {
        const embedVideoUrl = getYoutubeEmbedLink(contentBlock.url);
        return { ...contentBlock, url: embedVideoUrl };
      }
      return contentBlock;
    });

    return { listAllExerciseContentViewModel, isAllTestInExercisePassed: exercisePassedStatus };
  }

  static getExerciseViewModel(apiModel: StudentGetExerciseResponseSchema): ExerciseViewModel {
    const test = apiModel.attachments.reduce<ExerciseAttachments>(
      (acc, attachment) => {
        if (attachment.type === 'file') {
          const file = {
            ...attachment,
            file: { ...attachment.file, type: attachment.file.mimeType.split('/')[1] as FileType },
          };
          return { ...acc, attachmentsWithFile: [...acc.attachmentsWithFile, file] };
        }
        if (attachment.type === 'external_link') {
          return { ...acc, attachmentsWithExternalLink: [...acc.attachmentsWithExternalLink, attachment] };
        }
        return acc;
      },
      { attachmentsWithExternalLink: [], attachmentsWithFile: [] },
    );

    return {
      ...apiModel,
      attachments: test,
    };
  }

  static getTestResultViewModel(
    apiModel: StudentSubmitTestAnswersResponseSchema,
  ): SubmitTestAnswersResponseSchemaViewModel {
    const mistakes = apiModel.questionsAndAnswers.reduce<SubmitTestAnswersResponseSchemaViewModel['mistakes']>(
      (acc, mistake) => {
        if (mistake.questionWasAnsweredCorrectly) {
          return [...acc];
        } else {
          return [...acc, { questionId: mistake.questionId, questionText: mistake.questionText }];
        }
      },
      [],
    );

    return { ...apiModel, mistakes };
  }
}

export class MapperViewToApiMapper {
  static getSubmitTestAnswersRequestData(viewModel: TestWithAnswersViewModel): StudentSubmitTestAnswersRequestSchema {
    const [testIdWithAnswers] = Object.entries(viewModel);
    const [testId, testWithAnswers] = testIdWithAnswers;

    const singleChoiceTestResults = Object.entries(testWithAnswers.single_choice).reduce<
      Array<StudentTestQuestionAndAnswerWithSingleChoiceForSubmit>
    >((acc, [requestId, answerId]) => {
      return [
        ...acc,
        {
          questionId: requestId,
          answerId: answerId.answerId,
          questionType: 'single_choice',
        },
      ];
    }, []);

    const multiChoiceTestResults = Object.entries(testWithAnswers.multi_choice).reduce<
      Array<StudentTestQuestionAndAnswerWithMultiChoiceForSubmit>
    >((acc, [requestId, answerIds]) => {
      return [
        ...acc,
        {
          answerIds: answerIds.answerIds,
          questionId: requestId,
          questionType: 'multi_choice',
        },
      ];
    }, []);

    const matchingTestResults = Object.entries(testWithAnswers.matching).reduce<
      Array<StudentTestQuestionAndAnswerWithMatchingForSubmit>
    >((acc, [requestId, matchingTestAnswersViewModel]) => {
      const matching = Object.entries(matchingTestAnswersViewModel.matching).reduce<
        StudentTestQuestionAndAnswerWithMatchingForSubmit['matching']
      >((acc, [questionId, answerId]) => {
        return [
          ...acc,
          {
            firstPartId: questionId,
            secondPartId: answerId,
          },
        ];
      }, []);

      return [
        ...acc,
        {
          matching,
          questionId: requestId,
          questionType: 'matching',
        },
      ];
    }, []);

    const questionsAndAnswers = [...singleChoiceTestResults, ...multiChoiceTestResults, ...matchingTestResults];

    return {
      testId,
      questionsAndAnswers,
    };
  }
}
