import React from 'react';
import Check from '@svg/solid/check.svg';
import { Button } from '@ui-kit/Button/Button.component';

const Success = ({ onClick }: { onClick: () => void }) => {
  return (
    <div className={'flex flex-col items-center gap-[32px] w-[429px] justify-center'}>
      <img alt={'Success'} src={Check} className={'w-[100px] h-[100px]'} />
      <div className={'text-center'}>
        <h4 className={'pb-[20px]'}>Оплата прошла успешно</h4>
        <p>Вы можете перейти к материалам в разделе “Обучение-Мои программы”</p>
      </div>
      <Button onClick={onClick} variant="filled">
        Перейти к материалам
      </Button>
    </div>
  );
};

export default Success;
