import React, { FC } from 'react';
import styles from './Documents.module.css';
import { useDocumentsPage } from '@modules/documents/hooks/useDocumentsPage';
import { Button } from '@ui-kit/Button/Button.component';
import { ReactComponent as UserCardSvg } from '@svg/solid/userCard.svg';
import { PassportForm } from '@modules/documents/components/PassportForm/PassportForm.component';
import { PassportAttachment } from '@modules/documents/components/PassportAttachment/PassportAttachment';

export const DocumentsPage: FC = () => {
  const {
    documents,
    handleSubmit,
    isEditMode,
    attachedFile,
    handleResetAttachedFile,
    handleAttachFile,
    toggleEditMode,
  } = useDocumentsPage();

  const documentsTitle = documents === null ? 'Добавление' : isEditMode ? 'Редактирование' : '';

  return (
    <div className={styles['documents']}>
      <div className={styles['substrate']}>
        <section className={styles['header']}>
          <div className="flex gap-[10px] items-center">
            <span className="text-xl font-bold text-grey-90">Паспорт</span>
            <span className="text-18-regular">{documentsTitle}</span>
          </div>
          {!isEditMode && (
            <Button variant={'ghost'} rightIcon={<UserCardSvg className={'fill-blue-800'} />} onClick={toggleEditMode}>
              <span className={'text-blue-800'}>Сменили паспорт?</span>
            </Button>
          )}
        </section>

        <section className={styles['passport-form']}>
          <PassportForm data={documents} onSubmit={handleSubmit} isEditMode={isEditMode} id={'documents-form'} />
        </section>

        <section>
          <h6 className={'mb-[25px]'}>{isEditMode ? 'Загрузите копию своего паспорта' : 'Копии паспорта'}</h6>
          <PassportAttachment
            attachedFile={attachedFile}
            isEditMode={isEditMode}
            onAttach={handleAttachFile}
            onReset={handleResetAttachedFile}
          />
        </section>

        {isEditMode && (
          <section className="mt-[40px] flex flex-row-reverse">
            <Button variant={'filled'} form={'documents-form'}>
              Сохранить
            </Button>
          </section>
        )}
      </div>
    </div>
  );
};
