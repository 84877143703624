import { createEffect, createStore, createEvent } from 'effector';
import { ApiThrownError } from '@shared/api/client/responses/ApiErrorFactory';
import { handleError } from '@shared/handle-error/handleError';
import { StudentGetSoloCourseModuleRequestSchema } from '@generated-student';
import { ModuleInfoViewModel } from '@modules/module/types/module.types';
import { MapperApiToViewMapper } from '@modules/module/mappers/module.mapper';
import { SoloCourseModuleService } from '@shared/api/client/services/SoloCourseModule';

type ModuleInfoStore = ModuleInfoViewModel | null;

const defaultModuleInfoStore = null;
export const $ModuleInfo = createStore<ModuleInfoStore>(defaultModuleInfoStore);
export const resetModuleEvent = createEvent();
export const getModuleInfoFx = createEffect<
  StudentGetSoloCourseModuleRequestSchema,
  ModuleInfoViewModel,
  ApiThrownError
>(async (requestData) => {
  const {
    data: { data },
  } = await SoloCourseModuleService.getSoloCourseModule(requestData);

  return MapperApiToViewMapper.getModuleInfoViewModel(data);
});

$ModuleInfo.on(resetModuleEvent, () => defaultModuleInfoStore);

$ModuleInfo.on(getModuleInfoFx.doneData, (_, payload) => payload);

$ModuleInfo.on(getModuleInfoFx.failData, (prev, error) => {
  handleError(error);
});
