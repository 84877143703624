import React, { FC, ReactElement } from 'react';
import { ReactComponent as Visa } from '@svg/immutable/banks/visa.svg';
import { ReactComponent as Mir } from '@svg/immutable/banks/mir.svg';
import { ReactComponent as MasterCard } from '@svg/immutable/banks/masterCard.svg';
import { ReactComponent as Chip } from '@svg/immutable/banks/chip.svg';
import styles from './BankCard.module.css';
import { PaymentCardViewModel, PaymentSystem } from '../../types/payment-card';

export type BankCardProps = PaymentCardViewModel & {
  size?: 's' | 'm';
  onClick?: (id: PaymentCardViewModel['id']) => void;
};

type Settings = {
  backgroundClass: string;
  icon: (className: string) => ReactElement;
};

const paymentCardSettings: Record<PaymentSystem, Settings> = {
  visa: {
    backgroundClass: 'bg-gradient-bright-sunset',
    icon: (className) => <Visa className={className} />,
  },
  mir: {
    backgroundClass: 'bg-gradient-scarlet-dawn',
    icon: (className) => <Mir className={className} />,
  },
  masterCard: {
    backgroundClass: 'bg-gradient-deep-space',
    icon: (className) => <MasterCard className={className} />,
  },
};

export const BankCard: FC<BankCardProps> = ({ size = 'm', paymentSystem, lastFourDigits, isActive, onClick, id }) => {
  const CardIcon = paymentCardSettings[paymentSystem].icon;

  const containerClassName = `${styles['bank-card']} ${styles[`bank-card_size-${size}`]} ${
    paymentCardSettings[paymentSystem].backgroundClass
  } ${isActive ? styles['bank-card_active'] : ''}`;
  const backgroundClassName = `${styles['bank-card-background']} ${styles[`bank-card-background_size-${size}`]}`;
  const chipIconClassName = `${styles[`bank-card__chip_size-${size}`]}`;
  const cardNumberClassName = `${styles['bank-card__number']} ${styles[`bank-card__number_size-${size}`]}`;
  /** Имя карты пока отсутствует */
  // const cardOwnerLabelClassName = `${styles['bank-card__owner-label']} ${
  //   styles[`bank-card__owner-label_size-${size}`]
  // }`;
  /** Имя карты пока отсутствует */
  // const cardOwnerClassName = `${styles['bank-card__owner-name']} ${styles[`bank-card__owner-name_size-${size}`]}`;
  const cardIconClassName = `${styles['bank-card__icon']}  ${
    size === 's' ? styles[`bank-card__icon_payment-${paymentSystem}_size-s`] : ''
  }`;

  const onClickHandler = () => {
    onClick && onClick(id);
  };
  return (
    <button className={containerClassName} onClick={onClickHandler}>
      <div className={backgroundClassName}>
        <Chip className={chipIconClassName} />
        <span className={cardNumberClassName}>{`**** **** **** ${lastFourDigits}`}</span>
        <div className={'relative flex justify-between items-end w-full'}>
          {/** Имя карты пока отсутствует */}
          {/*<div className={'uppercase flex flex-col items-start'}>*/}
          {/*  <span className={cardOwnerLabelClassName}>Держатель карты</span>*/}
          {/*  <span className={cardOwnerClassName}>{ownerName}</span>*/}
          {/*</div>*/}
          {CardIcon(cardIconClassName)}
        </div>
      </div>
    </button>
  );
};
