import { matchPath, useLocation, useNavigate } from 'react-router-dom';

/* Хук является абстрацией над нативными хуками react router dom  и содержит сппецифичные функции для реализации роутинга приложения
 * В кач-ве дженерика передать union урлов приложения
 */
export const useNavigateBack = <UnionOfAppPaths extends string>() => {
  const navigate = useNavigate();
  const { pathname } = useLocation();

  /* Функция выполняет редирект на url, входящий в состав текущего, сохраняя динамические параметры
   * В кач-ве параметра необходимо перeдать путь, на кот. произойдет редирект и входящий в список урлов навигации приложения
   */
  const redirectToPreviousPage = (pathToRedirect: UnionOfAppPaths) => {
    const match = matchPath({ path: pathToRedirect, end: false }, pathname);

    if (!match) return;
    navigate(match);
  };

  return { redirectToPreviousPage };
};
