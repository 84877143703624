import React, { FC } from 'react';
import { PartiallyPurchasedCourseViewModel } from '../../types/course.types';
import { COURSE_DIRECTION_DICTIONARY } from '@shared/constants/courses';
import { ReactComponent as MagazineIcon } from '@svg/solid/magazine.svg';
import { ReactComponent as DiscountIcon } from '@svg/solid/discount.svg';
import { Button } from '@ui-kit/Button/Button.component';
import styles from './CourseCard.module.css';
import { toRubles } from '@shared/utils/toRubles';
import { useNavigate } from 'react-router-dom';
import { ENTITY_IDS, NAVIGATION } from '@shared/constants';

type PartiallyPurchasedCourseCardProps = PartiallyPurchasedCourseViewModel & {
  withButton: boolean;
  /** Флаг определяет, доступна ли карточка для клика */
  isDisabled: boolean;
};

/** Карточка частично приобретенного самостоятельного курса */
export const PartiallyPurchasedCourseCard: FC<PartiallyPurchasedCourseCardProps> = ({
  coverUrl,
  id,
  modulesAmount,
  title,
  direction,
  discountedPrice,
  discountPercentage,
  purchasedModulesAmount,
  price,
  studyHours,
  isDisabled,
  withButton,
}) => {
  const navigate = useNavigate();

  const goToBuyCourse = () => {
    /** Урл страницы курса, где пользователь может выбрать, что оплатить - или курс целиком, или поотдельности модули */
    const courseToPurchaseUrl = NAVIGATION.studentCourseToPurchase.replace(`:${ENTITY_IDS.courseId}`, id);

    navigate(courseToPurchaseUrl);
  };

  const hasDiscount = discountPercentage > 0;
  const priceText = toRubles(Number(price));
  const discountText = toRubles(Number(discountedPrice));

  return (
    <div
      className={`${styles['course-card__container']} ${isDisabled ? '' : 'cursor-pointer'}`}
      onClick={isDisabled ? undefined : goToBuyCourse}
    >
      <img className={styles['course-card__cover']} src={coverUrl} alt="course" />

      <div className="flex flex-col items-stretch">
        <div className="flex justify-between items-center mb-[15px] flex-wrap gap-[16px]">
          <div className="flex justify-between items-center gap-x-[10px]">
            {hasDiscount && (
              <div className={styles['course-card__price-container']}>
                <DiscountIcon color="#E6F7FF" className={styles['course-card__icon']} />
                <span className="text-16-regular text-grey-5">{`-${discountPercentage}%`}</span>
              </div>
            )}

            <div className={styles['course-card__direction-container']}>
              <MagazineIcon className={styles['course-card__icon']} />
              {COURSE_DIRECTION_DICTIONARY[direction]}
            </div>
          </div>

          <div className="flex items-center gap-x-[7px] text-18-bold text-grey-70 ">
            {hasDiscount ? (
              <>
                <s className="text-18-regular text-grey-75">
                  <span>{priceText}</span>
                </s>
                <span>{discountText}</span>
              </>
            ) : (
              <span>{priceText}</span>
            )}
          </div>
        </div>

        <h6 className={styles['course-card__title']}>{title}</h6>

        <div className="flex justify-between items-center mb-[20px]">
          <div className="flex items-center gap-x-[15px]">
            <span className="text-16-regular text-grey-70">{`Срок обучения: ${studyHours} ч.`}</span>
            <span className="text-16-regular text-grey-70">{`Кол-во модулей: ${modulesAmount}`}</span>
          </div>

          <span className="text-16-regular text-grey-70">{`Куплено: ${purchasedModulesAmount} из ${modulesAmount}`}</span>
        </div>

        {withButton && (
          <Button className={styles['course-card__button']} variant="outlined">
            Купить курс
          </Button>
        )}
      </div>
    </div>
  );
};
