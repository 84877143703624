import { ApiError, ApiInternalError, ApiValidationError } from '@shared/api/client/responses/ApiErrorFactory';
import { handleApiError } from '@shared/handle-error/default-handlers/handleApiError';
import { handleUnknownError } from '@shared/handle-error/default-handlers/handleUnknownError';
import { handleApiInternalError } from '@shared/handle-error/default-handlers/handleApiInternalError';
import { handleApiValidationError } from '@shared/handle-error/default-handlers/handleApiValidationError';

export type ErrorHandlers = {
  apiError: (err: ApiError) => void;
  apiValidationError: (err: ApiValidationError) => void;
  apiInternalError: (err: ApiInternalError) => void;
  unknownError: (err: Error) => void;
};

const defaultHandlers: Required<ErrorHandlers> = {
  apiError: handleApiError,
  apiValidationError: handleApiValidationError,
  apiInternalError: handleApiInternalError,
  unknownError: handleUnknownError,
};

/**
 * Централизованный обработчик ошибок
 * @param err - ошибка
 * @param handlers - обработчики разных типов ошибок
 */
export const handleError = (err: unknown, handlers?: Partial<ErrorHandlers>) => {
  const mergedHandlers = {
    ...defaultHandlers,
    ...handlers,
  };

  const { apiError, apiInternalError, apiValidationError, unknownError } = mergedHandlers;

  if (ApiError.is(err)) {
    apiError.call(null, err);
  } else if (ApiInternalError.is(err)) {
    apiInternalError.call(null, err);
  } else if (err instanceof ApiValidationError) {
    apiValidationError.call(null, err);
  } else if (err instanceof Error) {
    unknownError.call(null, err);
  } else {
    console.error('Неизвестная ошибка: ', err);
    throw err;
  }
};
