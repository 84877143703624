import React, { FC, useEffect, useRef, useState } from 'react';
import styles from './NavigationList.module.css';
import { Accordion } from '@ui-kit/Accordion/Accordion';
import { ReactComponent as DownSolid } from '@svg/solid/down.svg';
import { useLocation, useNavigate } from 'react-router-dom';
import { MainNavigationValues } from '@shared/types/navigation';
import { ElementBoundary, NavigationProps } from '@ui-kit/NavigationList/NavigationList.types';
import { getCumulativeUrlPathSegments } from '@shared/utils/getCumulativeUrlPathSegments';

const DEFAULT_MARKER_DIMENSIONS = { offsetTop: 0, offsetHeight: 0 };

export const NavigationList: FC<NavigationProps> = ({ navigationData }) => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const [activeTabMarkerDimensions, setActiveTabMarkerDimensions] =
    useState<ElementBoundary>(DEFAULT_MARKER_DIMENSIONS);
  const elementBoundary = useRef<ElementBoundary>(DEFAULT_MARKER_DIMENSIONS);
  const navigationListWrapper = useRef<HTMLUListElement>(null);
  const [isMarkerVisible, setIsMarkerVisible] = useState(true);

  //thirdSegment нужен для определения активного эл-та навигации и соответствует полю url в navigationData
  const [, , , thirdSegment] = getCumulativeUrlPathSegments(pathname);

  const handleClickNavItem = (url: MainNavigationValues) => {
    navigate(url);
  };

  /* Функция получает размеры текущего таба и сдвиг относительно враппера для последующей передачи этих значений маркеру активного таба */
  const updateActiveLabelOffset = (element: HTMLLIElement | null) => {
    //Если дочерняя кнопка таба содержит класс 'active' значит это активный лейбл => получаем его offsets
    if (element?.children.item(0)?.className.includes('active')) {
      const { offsetTop, offsetHeight } = element;
      elementBoundary.current = { offsetTop, offsetHeight };
    }
  };

  const markerStyles = {
    opacity: isMarkerVisible ? '1' : '0',
    height: `${activeTabMarkerDimensions.offsetHeight * 2}px`,
    transform: `translateY(${activeTabMarkerDimensions.offsetTop - activeTabMarkerDimensions.offsetHeight / 2}px)`,
  };

  //Стили переопределяют стандарные стили аккордиона
  const classnames = {
    header: styles['header-accordion'],
    content: styles['content-accordion'],
    wrapper: styles['wrapper-accordion'],
  };

  useEffect(() => {
    setActiveTabMarkerDimensions(elementBoundary.current);
  }, [pathname]);

  useEffect(() => {
    if (!navigationListWrapper.current) return;
    //Полная высота враппера
    const fullNavigationListWrapperHeight = navigationListWrapper.current.offsetHeight;

    const resizeObserver = new ResizeObserver(() => {
      if (!navigationListWrapper.current) return;
      const isWrapperCollapsed = navigationListWrapper.current.offsetHeight < fullNavigationListWrapperHeight;
      //Если высота враппера < полной => скрываем маркер
      if (isWrapperCollapsed) {
        setIsMarkerVisible(false);
      } else {
        setIsMarkerVisible(true);
      }
    });

    resizeObserver.observe(navigationListWrapper.current);

    return () => resizeObserver.disconnect();
  }, []);

  return (
    <div className={styles['navigation']}>
      <ul className={styles['list']} ref={navigationListWrapper}>
        <div className="w-full h-[2px] bg-white my-[10px] opacity-[0.2] shrink-0" />

        {/*=== Основная секиця навигации ===*/}
        <Accordion
          title={navigationData.mainNavigation.title}
          rightIcon={DownSolid}
          classNames={classnames}
          isActiveByDefault
        >
          {navigationData.mainNavigation.items.map(({ title, icon: Icon, url }) => (
            <li key={url} className={styles['list-item']} ref={updateActiveLabelOffset}>
              <button
                className={[styles['nav-link'], thirdSegment === url && styles['nav-link_active']].join(' ')}
                onClick={() => handleClickNavItem(url)}
              >
                {title}
              </button>
              <Icon className={styles['icon']} />
            </li>
          ))}
        </Accordion>

        {/** TODO В текущей версии не используется */}
        <div className="w-full h-[2px] bg-white my-[10px] opacity-[0.2] shrink-0" />

        {/*=== Дополнительная секиця навигации ===*/}
        <Accordion
          title={navigationData.subNavigation.title}
          rightIcon={DownSolid}
          classNames={classnames}
          isActiveByDefault
        >
          {navigationData.subNavigation.items.map(({ title, icon: Icon, url }) => (
            <li key={url} className={styles['list-item']} ref={updateActiveLabelOffset}>
              <button
                onClick={() => handleClickNavItem(url)}
                className={[styles['nav-link'], thirdSegment === url && styles['nav-link_active']].join(' ')}
              >
                {title}
              </button>
              <Icon className={styles['icon']} />
            </li>
          ))}
        </Accordion>
      </ul>

      <div className={`${styles['marker']} safari-blur`} style={markerStyles} />
    </div>
  );
};
