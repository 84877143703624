import { TimeZone } from '@shared/types/timezone.typedef';
import { allTimezones } from '@shared/constants/timezone';

export function isTimezone(value: unknown): value is TimeZone {
  return (
    typeof value === 'string' &&
    Object.keys(allTimezones).some((item) => {
      return item === value;
    })
  );
}
