import * as yup from 'yup';
import { phoneValidator } from '@shared/constants/validators/phone.validator';
import { yupResolver } from '@hookform/resolvers/yup';
import { FormHookResult } from '@shared/types/form-hook.types';
import { LoginForm } from '../types';
import { useForm } from 'react-hook-form';
import { useEvent } from 'effector-react';
import { requestAuthSmsCodeFx } from '@shared/stores/authorization.store';

const schema = yup.object<LoginForm>().shape({
  phone: phoneValidator,
});

export function useLoginForm(): FormHookResult<LoginForm> {
  const requestAuthSmsCode = useEvent(requestAuthSmsCodeFx);

  const form = useForm<LoginForm>({
    resolver: yupResolver(schema),
  });

  const handleSubmit = form.handleSubmit(async (data) => {
    await requestAuthSmsCode(data);
  });

  return {
    handleSubmit,
    params: {
      phone: form.register('phone'),
    },
    formState: form.formState,
  };
}
