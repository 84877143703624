import React, { FC } from 'react';
import { Button } from '@ui-kit/Button/Button.component';
import { ReactComponent as HomeWork } from '@svg/outline/homeWork.svg';
import { ReactComponent as Graph } from '@svg/solid/graph.svg';
import { ReactComponent as CalendarSolid } from '@svg/solid/calendar.svg';
import { ReactComponent as CloseOrDelete } from '@svg/solid/closeOrDelete.svg';
import { ReactComponent as Check } from '@svg/solid/check.svg';
import styles from './TestResult.module.css';
import { useEvent } from 'effector-react';
import { hideModalEvent } from '@ui-kit/Modal/ModalBasic/ModalBasic.store';
import { SubmitTestAnswersResponseSchemaViewModel } from '@modules/exercise/types/exercise.types';

type TestResultData = SubmitTestAnswersResponseSchemaViewModel & { title: string };

type TestResultProps = {
  data: TestResultData | null;
};

export const TestResult: FC<TestResultProps> = ({ data }) => {
  const hideModal = useEvent(hideModalEvent);
  const { title, correctAnswerPercentage, passingThreshold } = data || {};
  const isTestPassed =
    correctAnswerPercentage && passingThreshold ? correctAnswerPercentage >= passingThreshold : false;

  return (
    <div className={styles['test-result']}>
      <div className="absolute top-[-24px]">
        <CalendarSolid className="text-grey-50" />
      </div>

      <section className={styles['result']}>
        <HomeWork className={'mb-[15px] text-blue-800'} />
        <h5 className={'text-center mb-[30px]'}>{`Результаты теста «${title}»`}</h5>
        <div className="flex gap-x-[20px] mb-[30px]">
          <div className={'flex gap-x-[5px]'}>
            {isTestPassed ? (
              <Check className={'text-light-green'} />
            ) : (
              <CloseOrDelete className={'text-red-intensive'} />
            )}
            <div className="flex flex-col gap-y-[10px]">
              <p className={'text-18-bold'}>{isTestPassed ? 'Задание пройдено' : 'Задание провалено'}</p>
              <p className={'text-grey-90'}>Чтобы пройти к следующему заданию, пройдите тест</p>
            </div>
          </div>
          <div className={'flex gap-x-[5px]'}>
            <Graph className={'text-orange-strong'} />
            <div className="flex flex-col gap-y-[10px]">
              <p className={'text-18-bold'}>{`${correctAnswerPercentage}% Правильных ответов`}</p>
              <p className={'text-grey-90'}>{`Минимальный порог ${passingThreshold}% правильных ответов`}</p>
            </div>
          </div>
        </div>
      </section>

      {!!data?.mistakes?.length && (
        <section className={styles['mistakes']}>
          <div className={styles['mistakes__title-wrapper']}>
            <h6 className={styles['mistakes__title']}>Допущенные ошибки</h6>
          </div>
          <ul className={styles['mistakes__list']}>
            {data.mistakes.map(({ questionId, questionText }) => (
              <li key={questionId} className={'flex gap-x-[5px]'}>
                <CloseOrDelete className={'text-red-intensive w-[20px] h-[20px]'} />
                <div className={'flex flex-col gap-y-[10px]'}>
                  <p className={'text-18-bold'}>{`Вопрос ${questionText}`}</p>
                </div>
              </li>
            ))}
          </ul>
        </section>
      )}

      <section className={styles['bottom']}>
        <Button variant={'outlined'} onClick={hideModal} type={'button'}>
          Закрыть
        </Button>
      </section>
    </div>
  );
};
