import React, { FC, useEffect, useRef, useState } from 'react';
import { ElementBoundary, TabsProps } from './Tabs.types';

const defaultCursorDimensions = { offsetLeft: 0, offsetTop: 0, offsetWidth: 0, offsetHeight: 0 };

export const TabsCore: FC<TabsProps> = ({
  items,
  currentTab,
  onChange,
  styleConfig,
  /* По дефолту ширина/высота маркера для активного таба высчитывается как 100% активного таба. Для установки меньшего значения передать cursorDimensionCoefficient < 1*/
  activeTabMarkerDimensionCoefficient = 1,
  direction = 'horizontal',
}) => {
  const [activeTabMarkerDimensions, setActiveTabMarkerDimensions] = useState<ElementBoundary>(defaultCursorDimensions);

  const elementBoundary = useRef<ElementBoundary>(defaultCursorDimensions);

  /* Функция получает размеры текущего таба и сдвиг относительно враппера для последующей передачи этих значений маркеру активного таба */
  const updateActiveLabelOffset = (element: HTMLLIElement | null) => {
    //Если дочерняя кнопка таба содержит класс 'active' значит это активный лейбл => получаем его offsets
    if (element?.children.item(0)?.className.includes('active')) {
      const { offsetLeft, offsetTop, offsetWidth, offsetHeight } = element;
      elementBoundary.current = { offsetLeft, offsetTop, offsetWidth, offsetHeight };
    }
  };

  const cursorHorizontalStyles = {
    width: `${activeTabMarkerDimensions.offsetWidth * activeTabMarkerDimensionCoefficient}px`,
    transform: `translateX(${activeTabMarkerDimensions.offsetLeft}px)`,
  };

  const cursorVerticalStyles = {
    height: `${activeTabMarkerDimensions.offsetHeight * activeTabMarkerDimensionCoefficient}px`,
    transform: `translateY(${activeTabMarkerDimensions.offsetTop}px)`,
  };

  useEffect(() => {
    setActiveTabMarkerDimensions(elementBoundary.current);
  }, [currentTab]);

  return (
    <div className={`inline-flex ${direction === 'horizontal' ? 'flex-col' : 'flex-row'} ${styleConfig.wrapper}`}>
      <ul
        className={`inline-flex justify-between items-end relative ${
          direction === 'horizontal' ? 'flex-row' : 'flex-col items-start '
        } ${styleConfig.list}`}
      >
        {items.map(({ id, title, icon }) => (
          <li key={id} className={`flex`} ref={updateActiveLabelOffset}>
            <button
              onClick={() => onChange(id)}
              className={[
                'cursor-pointer grid place-items-center transition-all duration-300 ease-linear',
                styleConfig.tab,
                currentTab === id && styleConfig.tab_active,
              ].join(' ')}
            >
              <span>{title}</span>
            </button>
            {icon}
          </li>
        ))}
      </ul>
      <div
        className={['transition-transform duration-300 ease-linear', styleConfig.activeTabMarker].join(' ')}
        style={direction === 'horizontal' ? cursorHorizontalStyles : cursorVerticalStyles}
      />
    </div>
  );
};
