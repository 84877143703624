import { OrderSoloCourseItem } from '@shared/api/generated-student';
import { FC } from 'react';
import { ReactComponent as MagazineIcon } from '@svg/solid/magazine.svg';
import { COURSE_DIRECTION_DICTIONARY } from '@shared/constants/courses';
import { toRubles } from '@shared/utils/toRubles';

type CourseUnpaidCardProps = {
  course: OrderSoloCourseItem;
};

export const CourseUnpaidCard: FC<CourseUnpaidCardProps> = ({ course }) => {
  const hasDiscount = course.soloCourseCost.discountPercentage > 0;
  const priceText = toRubles(Number(`${course.soloCourseCost.price.rubles}.${course.soloCourseCost.price.pennies}`));
  const discountText = toRubles(
    Number(`${course.soloCourseCost.discountedPrice.rubles}.${course.soloCourseCost.discountedPrice.pennies}`),
  );

  return (
    <div
      key={course.soloCourseId}
      className={
        'flex flex-col items-start gap-[10px] p-5 rounded-[30px] border border-opacity-darkBlue-10 bg-white shadow-main min-w-[394px] flex-1'
      }
    >
      <img
        className={'rounded-[20px] object-cover w-full h-[210px]'}
        src={`https://${course.soloCourseCover.sizes.original.url}`}
        alt={'cover'}
      />

      <div className={'flex flex-col items-start gap-[15px]'}>
        <div
          className={
            'flex p-[5px_15px_5px_10px] items-center gap-[5px] justify-end rounded-[40px] bg-blue-500 border border-opacity-white-24'
          }
        >
          <MagazineIcon className={'fill-blue-800'} />
          {COURSE_DIRECTION_DICTIONARY[course.soloCourseDirection]}
        </div>
        <div className={'flex flex-col items-start gap-4'}>
          <div className={'flex flex-col items-start gap-2'}>
            <p className={'label-14-bold text-main-blue'}>{'Курс'}</p>
            <h6 className={'text-grey-400 headlines-20-bold'}>{course.soloCourseTitle}</h6>
            {/* TODO: добавить автора курса, когда его добавят в АПИ */}
            {/* <div className={'flex items-center gap-2'}>
              <img
                src={`https://${it.soloCourseCover.sizes.original.url}`}
                className={'rounded-[100%] w-[24px] h-[24px] object-cover'}
                alt={'avatar'}
              />
              <span className="text-16-regular text-grey-70">{'Злобина Анфиса'}</span>
            </div> */}
          </div>
          <p className={'text-grey-70 title-18-bold'}>{hasDiscount ? discountText : priceText}</p>
        </div>
      </div>
    </div>
  );
};
