import React, { FC } from 'react';
import { NavigationList } from '@ui-kit/NavigationList/NavigationList.component';
import { ReactComponent as Note } from '@svg/solid/note.svg';
import { NAVIGATION } from '@shared/constants';
import { ReactComponent as Gallery } from '@svg/solid/gallery.svg';
import { ReactComponent as Confidentiality } from '@svg/solid/confidentiality.svg';

const EDUCATION_TOP_NAV_ITEMS = [
  {
    title: 'Мои программы',
    icon: Note,
    url: NAVIGATION.studentUserCourses,
  },
  {
    title: 'Каталог',
    icon: Gallery,
    url: NAVIGATION.studentCoursesCatalog,
  },
];

const EDUCATION_BOTTOM_NAV_ITEMS = [
  {
    title: 'Сертификаты',
    icon: Confidentiality,
    url: NAVIGATION.studentCertificates,
  },
];

const EDUCATION_NAV_ITEMS = {
  mainNavigation: { items: EDUCATION_TOP_NAV_ITEMS, title: 'Обучение' },
  subNavigation: { items: EDUCATION_BOTTOM_NAV_ITEMS, title: 'Результаты' },
};

export const SubNavigationEducation: FC = () => {
  return (
    <div>
      <div className={'p-[10%_6%]'}>
        <h5 className={'text-white'}>{'Образование'}</h5>
        <span className={'text-16-regular text-white'}>{'Управление Вашим обучением'}</span>
      </div>
      <NavigationList navigationData={EDUCATION_NAV_ITEMS} />
    </div>
  );
};
