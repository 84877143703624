import { PaymentSystem } from '@modules/payment/types/payment-card';

type Brand = {
  name: PaymentSystem;
  pattern: RegExp;
};

type Brands = Array<Brand>;

const brands: Brands = [
  {
    name: 'visa',
    pattern: /^4/,
  },
  {
    name: 'masterCard',
    pattern: /^(51|52|53|54|55|23|24|25|26|27)/,
  },
  // {
  //   name: 'unionpay',
  //   pattern: /^(62)/,
  // },
  // {
  //   name: 'maestro',
  //   pattern: /^(49|50|56|59|63|67)/,
  // },
  {
    name: 'mir',
    pattern: /^22/,
  },
];

export const getPaymentName = (digits: string): PaymentSystem => {
  for (const brand of brands) {
    if (brand.pattern.test(digits)) {
      return brand.name;
    }
  }
  return 'mir';
};
