import { AxiosInstance } from 'axios';

/**
 * Приводит запрос к виду, соответствующему требованиям документации API;
 * Такое решение позволяет использовать axios декларативно;
 * В процессе разработки нам нужно думать о том, в какой вид нужно оборачивать параметры для методов API;
 */
export const applyArgumentsWrapperInterceptor = (axios: AxiosInstance) => {
  return axios.interceptors.request.use((config) => {
    const { data } = config;

    config.data = {
      arguments: data,
    };

    return config;
  });
};
