import { useEvent, useStore } from 'effector-react';
import { useEffect } from 'react';
import {
  $Documents,
  createDocumentsFx,
  getDocumentsFx,
  resetDocuments,
  toggleEditModeEvent,
  updateDocumentsFx,
} from '@modules/documents/stores/documents.store';
import { DocumentsForm } from '@modules/documents/types/documents.types';
import { usePassportAttachment } from '@modules/documents/components/PassportAttachment/usePassportAttachment';
import { useFus } from '@shared/hooks/useFus';
import { isFileIsDocumentsViewModelFile } from '@modules/documents/guards/isFileIsDocumentsViewModelFile';
import { showErrorModalEvent } from '@ui-kit/Modal/ModalResult/ModalResult.store';

export const useDocumentsPage = () => {
  const getDocuments = useEvent(getDocumentsFx);
  const updateDocuments = useEvent(updateDocumentsFx);
  const createDocuments = useEvent(createDocumentsFx);
  const reset = useEvent(resetDocuments);
  const { documents, isEditMode } = useStore($Documents);
  const { uploadFile } = useFus({ purpose: 'personal_document' });

  const { attachedFile, handleAttachFile, handleResetAttachedFile } = usePassportAttachment({
    defaultFile: documents?.file,
  });

  const toggleEditMode = () => {
    toggleEditModeEvent();
  };

  const createDocument = async (data: DocumentsForm) => {
    const isFileDefault = isFileIsDocumentsViewModelFile(attachedFile);
    if (isFileDefault || !attachedFile) return;
    const { token } = await uploadFile(attachedFile);
    await createDocuments({ ...data, file: token, country: 'russia' });
  };

  const updateDocument = async (data: DocumentsForm) => {
    const isFileDefault = isFileIsDocumentsViewModelFile(attachedFile);
    /** Определения необходимости обновления файла
     * Если при сабмите формы не загружался новый файл он будет соответствовать типу DocumentsViewModel['file']
     * в ином случае он будет соответствовать типу File, файл загруженн пользователем
     */
    if (isFileDefault || !attachedFile) {
      await updateDocuments({ ...data, file: { type: 'keep_as_is' } });
    } else {
      const { token } = await uploadFile(attachedFile);
      await updateDocuments({ ...data, file: { type: 'update', value: token } });
    }
  };

  const handleSubmit = async (data: DocumentsForm) => {
    if (!attachedFile) {
      showErrorModalEvent({ description: 'Нужно прикрепить скан паспорта', title: 'Ошибка' });
      return;
    }
    /** Если documents === null, значит сабмит произошел в режиме создания
     *  в ином случае в режиме редактирования
     */
    if (documents === null) {
      await createDocument(data);
    } else {
      await updateDocument(data);
    }

    await getDocuments();
  };

  useEffect(() => {
    getDocuments();
    return () => reset();
  }, []);

  return {
    documents,
    isEditMode,
    attachedFile,
    handleResetAttachedFile,
    handleAttachFile,
    toggleEditMode,
    handleSubmit,
  };
};
