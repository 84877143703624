import React from 'react';
import { ReactComponent as ContactsIcon } from '@svg/outline/contacts.svg';
import { ReactComponent as PhoneIcon } from '@svg/outline/phone.svg';
import { ReactComponent as ChangeIcon } from '@svg/outline/change.svg';
import { EmailForm } from '../EmailForm/EmailForm.component';
import { useEvent } from 'effector-react';
import { updateEmailFx } from '@shared/stores/email.store';
import { EmailFormValues } from '../EmailForm/EmailForm.typedef';
import { showSuccessModalEvent } from '@shared/ui-kit/Modal/ModalResult/ModalResult.store';

type ContactDetailsProps = {
  phone: string;
  email: string;
};

/**
 * Форма настройки контактных данных
 */
export const ContactDetails = ({ phone, email }: ContactDetailsProps) => {
  const updateEmail = useEvent(updateEmailFx);

  const submitForm = async (value: EmailFormValues) => {
    await updateEmail(value);

    showSuccessModalEvent();
  };

  return (
    <div className="flex flex-col items-center max-w-[80%]">
      <ContactsIcon width={60} height={60} className="mb-[30px] fill-blue-800" />
      <h5 className="mb-[30px] text-grey-90">Контактные данные</h5>
      <p className="mb-[29px] text-grey-60 text-[10px] text-center">
        Укажите контактные данные, чтобы осуществлять авторизацияю на ресурсе, получать своевременные уведомления и др.
      </p>

      <div className="flex flex-col items-start mb-[58px]">
        <h6 className="mb-[23px] text-grey-400">Номер телефона</h6>
        <div className="flex items-center">
          <PhoneIcon className="mr-[10px] w-[25px] h-[25px] fill-blue-800" />
          <span className="text-18-regular">{phone}</span>
        </div>
      </div>

      <div className="flex flex-col items-center mb-[42px]">
        <h6 className="mb-[23px] text-grey-400">Адрес электронной почты</h6>
        <EmailForm initialValues={{ email }} onSubmit={submitForm} />
      </div>
      <a href={'https://t.me/MOST_PSY_School'} target="_blank" className="flex items-center" rel="noreferrer">
        <span className="text-blue-800 mr-[10px]">Изменились данные</span>
        <ChangeIcon className="fill-blue-800 w-[25px] h-[25px]" />
      </a>
    </div>
  );
};
