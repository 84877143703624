import React, { useEffect, useRef, useState } from 'react';
import { Header } from '@ui-kit/Header/Header.component';
import { Search } from '@ui-kit/Search/Search.component';
import { CoursesList } from '@modules/course/components/CoursesList/CoursesList.component';
import { CoursesFilter } from '@modules/course/components/CoursesFilter/CoursesFilter.component';
import {
  $Search,
  applyFilter,
  changeSearch,
  getCourses,
  getCoursesFilterFx,
  resetCoursesList,
  resetFilter,
  resetOffset,
  resetSearch,
} from '@modules/course/stores/courses/courses-list.store';
import { useStore } from 'effector-react';
import style from './CoursesCatalogPage.module.css';
import { Button } from '@ui-kit/Button/Button.component';
import { ReactComponent as FilterSvg } from '@svg/solid/filter.svg';

/** Страница каталога курсов(как купленных, так и не купленных) */
export const CoursesCatalogPage = () => {
  const pageWrapperRef = useRef<HTMLDivElement>(null);
  const search = useStore($Search);
  const [isFilterActive, setIsFilterActive] = useState(false);

  const toggleFilter = () => {
    setIsFilterActive((prevState) => !prevState);
  };

  const handleClearSearch = () => {
    resetSearch();

    applyFilter();
  };

  useEffect(() => {
    const init = async () => {
      await getCoursesFilterFx();

      getCourses();
    };

    init();

    return () => {
      resetCoursesList();
      resetFilter();
      resetSearch();
      resetOffset();
    };
  }, []);

  return (
    <div className={style.wrapper} ref={pageWrapperRef}>
      <div className={style['top']}>
        <Header headerText="Каталог курсов" hasBackButton={false} styleConfig={{ header: style['header'] }} />
        <Search
          placeholder="Курсы..."
          value={search}
          onChange={changeSearch}
          onClear={handleClearSearch}
          onClick={() => applyFilter()}
          showSearchButton
        />
      </div>
      <div>
        <Button
          className={style['filter-toggle-button']}
          variant={'filled'}
          leftIcon={<FilterSvg color="white" />}
          onClick={toggleFilter}
        >
          Фильтр
        </Button>
      </div>
      <div className={style['content-wrapper']}>
        <div className={`${isFilterActive ? '' : style['filter-wrapper_hidden']} ${style['filter-wrapper']}`}>
          <CoursesFilter
            onClose={() => {
              setIsFilterActive(false);
            }}
          />
        </div>
        <section className={style.content}>
          <CoursesList wrapperRef={pageWrapperRef} />
        </section>
      </div>
    </div>
  );
};
