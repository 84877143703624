import { useEffect } from 'react';
import { useFus } from '@shared/hooks/useFus';
import { PersonalData } from '@modules/personalData/components/PersonalDataForm/PersonalDataForm.typedef';
import { useStore } from 'effector-react';
import { showSuccessModalEvent } from '@ui-kit/Modal/ModalResult/ModalResult.store';
import {
  $UserProfile,
  getUserProfileFx,
  changeUserAvatar,
  updateUserProfile,
  resetUserProfile,
} from '@shared/stores/user-profile/user-profile.store';

/**
 * Хук для интеграции страницы песрональных данных PersonalData
 */
export const usePersonalData = () => {
  const { uploadFile } = useFus({ purpose: 'avatar' });

  const userProfile = useStore($UserProfile);

  const onAvatarChange = async (value: File) => {
    const {
      token,
      uploadedFile: { url },
    } = await uploadFile(value);

    changeUserAvatar({ url, token });
  };

  const submitForm = async (personalData: PersonalData) => {
    updateUserProfile(personalData);

    showSuccessModalEvent();
  };

  useEffect(() => {
    getUserProfileFx();
    return () => {
      resetUserProfile();
    };
  }, []);

  return { onAvatarChange, submitForm, personalData: userProfile };
};
