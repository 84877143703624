import React, { FC } from 'react';
import styles from './ExerciseHeader.module.css';
import { ReactComponent as CloseOrDelete } from '@svg/solid/closeOrDelete.svg';

type ExerciseHeaderProps = {
  onCancelExercise: () => void;
};

export const ExerciseHeader: FC<ExerciseHeaderProps> = ({ onCancelExercise }) => {
  return (
    <div className={styles['exercise-header']}>
      <div className={'flex items-center justify-end'}>
        <button type={'button'} onClick={onCancelExercise} className={styles['close-button']}>
          <CloseOrDelete className={'w-full h-full fill-blue-800'} />
        </button>
      </div>
    </div>
  );
};
