import styles from './ToggleGroup.module.css';
import React, { ChangeEvent, forwardRef, useState } from 'react';
import { withFormField } from '@shared/ui-kit/FormField';

type Item = {
  id: string;
  title: string;
  name: string;
};

export type ToggleGroupProps = {
  items: Array<Item>;
  value?: string;
  onChange: (id: string) => void;
};

export const ToggleGroup = forwardRef<HTMLInputElement, ToggleGroupProps>(
  ({ items, onChange, value = items[0].id }, ref) => {
    const [cursorOffset, setCursorOffset] = useState(5);
    const [cursorWidth, setCursorWidth] = useState(0);
    const handleChangeCurrentValue = (e: ChangeEvent<HTMLInputElement>) => {
      onChange(e.target.id);
    };

    const getActiveLabelOffset = (element: HTMLLabelElement) => {
      if (element?.className.includes('active')) {
        setCursorOffset(element.offsetLeft);
        setCursorWidth(element.offsetWidth);
      }
    };

    return (
      <div className={styles['toggle-button-group']}>
        {items.map(({ id, title, name }) => (
          <React.Fragment key={id}>
            <input
              ref={ref}
              type={'radio'}
              value={value}
              name={name}
              id={id}
              onChange={handleChangeCurrentValue}
              checked={value === id}
              hidden
            />
            <label
              ref={getActiveLabelOffset}
              htmlFor={id}
              className={[styles['label'], value === id && styles[`label-active`]].join(' ')}
            >
              <span>{title}</span>
            </label>
          </React.Fragment>
        ))}
        <div
          className={styles.cursor}
          style={{ width: `${cursorWidth}px`, transform: `translateX(${cursorOffset}px)` }}
        />
      </div>
    );
  },
);

export const FormToggleGroup = withFormField(ToggleGroup);
