import React, { ElementType, FC, useState } from 'react';
import styles from './Sidebar.module.css';
import { ReactComponent as LogoSvg } from '@svg/immutable/logo-compact.svg';
import { ReactComponent as LeftSvg } from '@svg/solid/left.svg';
import { NavItem } from '@ui-kit/NavItem/NavItem.component';
import { Link } from 'react-router-dom';
import { STUDENT_NAVIGATION_ITEMS, TEACHER_NAVIGATION_ITEMS } from './constants';
import { NAVIGATION } from '@shared/constants';
import { Avatar } from '@ui-kit/Avatar/Avatar';
import { ReactComponent as LogoutIcon } from '@svg/solid/logout.svg';
import { useEvent, useStore } from 'effector-react';
import { logoutFx } from '@shared/stores/authorization.store';
import { $UserAvatar } from '@shared/stores/user-profile/user-profile.store';
import { $Cart, resetCartEvent } from '@shared/stores/cart.store';

type SidebarProps = {
  userRole: 'student' | 'teacher';
  innerNavComponent: ElementType;
};
export const Sidebar: FC<SidebarProps> = ({ userRole, innerNavComponent: InnerNavComponent }) => {
  const logout = useEvent(logoutFx);

  const userAvatar = useStore($UserAvatar);
  const cart = useStore($Cart);

  const navigationItemsData = userRole === 'student' ? STUDENT_NAVIGATION_ITEMS : TEACHER_NAVIGATION_ITEMS;

  const [isSidebarCollapsed, setIsSidebarCollapsed] = useState(false);

  const toggleSideBar = () => {
    setIsSidebarCollapsed((prev) => !prev);
  };

  const handleExpandSideBar = () => {
    setIsSidebarCollapsed(false);
  };

  return (
    <div className={[styles['sidebar'], isSidebarCollapsed ? styles['sidebar-collapsed'] : ''].join(' ')}>
      <div className={styles['main-navigation']}>
        <div className={'flex flex-col gap-y-[10px]'}>
          <div className={'flex flex-col gap-y-[10px]'}>
            <div className={styles['sidebar-logo']}>
              <Link to="/">
                <LogoSvg className={'w-full h-full'} />
              </Link>
            </div>
            <NavItem
              route={userRole === 'student' ? NAVIGATION.studentProfile : NAVIGATION.teacherProfile}
              onClickNavLink={handleExpandSideBar}
              className={styles['profile']}
              activeClassName={styles['profile_active']}
            >
              <Avatar url={userAvatar?.url} />
            </NavItem>
          </div>
          <div className={'pt-[10px] flex-col flex gap-y-[16px] m-auto'}>
            {navigationItemsData.map(({ logo: Logo, route }) => (
              <NavItem
                key={route}
                route={route}
                onClickNavLink={handleExpandSideBar}
                className={styles['navigation_item']}
                activeClassName={styles['navigation_item-active']}
              >
                <Logo className={styles['nav-item__logo']} />
                {route === NAVIGATION.studentCart && cart.length > 0 && (
                  <div
                    style={{
                      position: 'absolute',
                      top: 14,
                      right: 10,
                      backgroundColor: '#F4724B',
                      width: '13px',
                      height: '13px',
                      borderRadius: '50%',
                      boxShadow: '0 0 5px rgba(244, 114, 75, 0.5)',
                    }}
                  />
                )}
              </NavItem>
            ))}
          </div>
        </div>
        <div className={'flex flex-col w-full items-center justify-evenly'}>
          <span className={'w-full h-[1px] bg-grey-50 mb-5'} />
          {/** TODO В текущей версии не используется */}
          {/*<NavItem*/}
          {/*  route={userRole === 'student' ? NAVIGATION.studentSupport : NAVIGATION.teacherSupport}*/}
          {/*  onClickNavLink={handleExpandSideBar}*/}
          {/*  className={styles['navigation_item']}*/}
          {/*  activeClassName={styles['navigation_item-active']}*/}
          {/*>*/}
          {/*  <TicketSupportSvg className={styles['nav-item__logo']} />*/}
          {/*</NavItem>*/}
          <button onClick={toggleSideBar} className={'mt-[10%]'}>
            <LeftSvg
              color="white"
              className={`${styles['nav-item__logo']} ${isSidebarCollapsed ? 'transform rotate-180' : ''}`}
            />
          </button>
        </div>
      </div>
      <nav
        className={
          isSidebarCollapsed
            ? [styles['sub-navigation'], styles['sub-navigation-collapsed']].join(' ')
            : styles['sub-navigation']
        }
      >
        <InnerNavComponent />
        <button
          className="flex justify-between items-center px-[10%] pb-[5%] text-white title-16-bold m:title-18-bold"
          onClick={() => {
            logout();
            resetCartEvent();
          }}
        >
          Выйти из профиля
          <LogoutIcon />
        </button>
      </nav>
    </div>
  );
};
