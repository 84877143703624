import {
  StudentCreatePassportRequestSchema,
  StudentGetPassportResponseSchema,
  StudentUpdatePassportRequestSchema,
} from '@generated-student';
import {
  DocumentsViewModel,
  DocumentsViewModelWithCreatedFile,
  DocumentsViewModelWithUpdatedFile,
} from '@modules/documents/types/documents.types';
import dayjs from 'dayjs';
import { FileType } from '@shared/types/file.types';
import { YEAR_MONTH_DAY_DATE_FORMAT } from '@shared/constants/date';

export class MapperApiToViewMapper {
  static getDocumentsViewModel(apiModel: StudentGetPassportResponseSchema): DocumentsViewModel {
    const fileType = apiModel.file.mimeType.split('/')[1] as FileType;

    return {
      ...apiModel,
      patronymic: apiModel.patronymic || undefined,
      file: { ...apiModel.file, mimeType: fileType, name: apiModel.file.originalFileName },
      birthday: dayjs(apiModel.birthday).toDate(),
      issuedAt: dayjs(apiModel.issuedAt).toDate(),
    };
  }
}

export class MapperViewToApiMapper {
  static getUpdateDocumentsApiModel(viewModel: DocumentsViewModelWithUpdatedFile): StudentUpdatePassportRequestSchema {
    return {
      ...viewModel,
      patronymic: viewModel.patronymic || null,
      birthday: dayjs(viewModel.birthday).format(YEAR_MONTH_DAY_DATE_FORMAT),
      issuedAt: dayjs(viewModel.issuedAt).format(YEAR_MONTH_DAY_DATE_FORMAT),
    };
  }

  static getCreateDocumentsApiModel(viewModel: DocumentsViewModelWithCreatedFile): StudentCreatePassportRequestSchema {
    return {
      ...viewModel,
      patronymic: viewModel.patronymic || null,
      birthday: dayjs(viewModel.birthday).format(YEAR_MONTH_DAY_DATE_FORMAT),
      issuedAt: dayjs(viewModel.issuedAt).format(YEAR_MONTH_DAY_DATE_FORMAT),
    };
  }
}
