import styles from './Search.module.css';
import React, { ChangeEvent, FC } from 'react';
import { ReactComponent as SearchSvg } from '@svg/outline/search.svg';
import { ReactComponent as ResetSvg } from '@svg/solid/closeOrDelete.svg';
import { Button } from '../Button/Button.component';

type SearchProps = {
  onChange: (value: string) => void;
  placeholder?: string;
  value: string;
  onClear?: () => void;
  onClick?: () => void;
  showSearchButton?: boolean;
};

export const Search: FC<SearchProps> = ({
  onChange,
  placeholder,
  value,
  onClear,
  onClick,
  showSearchButton = false,
}) => {
  const handleUpdateSearchQuery = (e: ChangeEvent<HTMLInputElement>) => {
    onChange(e.target.value);
  };

  const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      onClick && onClick();
    }
    return;
  };

  const handleResetSearchQuery = () => {
    onChange('');

    if (onClear) {
      onClear();
    }
  };

  return (
    <div className={'flex items-center gap-2'}>
      <div className={styles['search']}>
        <SearchSvg className={styles['search-icon']} />
        <input
          type={'text'}
          className={styles['input']}
          onChange={handleUpdateSearchQuery}
          value={value}
          placeholder={placeholder}
          onKeyDown={handleKeyPress}
        />
        {value && (
          <button className={styles['reset']} onClick={handleResetSearchQuery} type={'button'}>
            <ResetSvg className={styles['reset-icon']} />
          </button>
        )}
      </div>
      {showSearchButton && (
        <Button onClick={onClick} variant="filled">
          {'Найти'}
        </Button>
      )}
    </div>
  );
};
