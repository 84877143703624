import {
  StudentListSoloCoursesToPurchaseFullyPurchasedItem,
  StudentListSoloCoursesToPurchaseNotPurchasedItem,
  StudentListSoloCoursesToPurchasePartiallyPurchasedItem,
} from '@generated-student';
import {
  CommonCourseViewModel,
  FullyPurchasedCourseViewModel,
  NotPurchasedCourseViewModel,
  PartiallyPurchasedCourseViewModel,
} from '../../types/course.types';

const mapToCommonCourseViewModel = (
  course:
    | StudentListSoloCoursesToPurchasePartiallyPurchasedItem
    | StudentListSoloCoursesToPurchaseNotPurchasedItem
    | StudentListSoloCoursesToPurchaseFullyPurchasedItem,
): CommonCourseViewModel => {
  return {
    id: course.id,
    coverUrl: `//${course.cover.sizes.original.url}`,
    direction: course.direction,
    modulesAmount: course.modulesAmount,
    longDescription: course.longDescription,
    shortDescription: course.shortDescription,
    studyHours: course.studyHours,
    title: course.title,
    isOnlyFullPurchase: course.isOnlyFullPurchase,
    author: course.author,
  };
};

const mapToPartiallyPurchasedCourseViewModel = (
  course: StudentListSoloCoursesToPurchasePartiallyPurchasedItem,
): PartiallyPurchasedCourseViewModel => {
  const commonCourseFields = mapToCommonCourseViewModel(course);

  return {
    ...commonCourseFields,
    type: 'partially_purchased_solo_course',
    purchasedModulesAmount: course.purchasedModulesAmount,
    price: `${course.residualCost.price.rubles}.${course.residualCost.price.pennies}`,
    discount: `${course.residualCost.discount.rubles}.${course.residualCost.discount.pennies}`,
    discountPercentage: course.residualCost.discountPercentage,
    discountedPrice: `${course.residualCost.discountedPrice.rubles}.${course.residualCost.discountedPrice.pennies}`,
  };
};
const mapToNotPurchasedCourseViewModel = (
  course: StudentListSoloCoursesToPurchaseNotPurchasedItem,
): NotPurchasedCourseViewModel => {
  const commonCourseFields = mapToCommonCourseViewModel(course);

  return {
    ...commonCourseFields,
    type: 'not_purchased_solo_course',
    price: `${course.cost.price.rubles}.${course.cost.price.pennies}`,
    discount: `${course.cost.discount.rubles}.${course.cost.discount.pennies}`,
    discountPercentage: course.cost.discountPercentage,
    discountedPrice: `${course.cost.discountedPrice.rubles}.${course.cost.discountedPrice.pennies}`,
  };
};
const mapToFullyPurchasedCourse = (
  course: StudentListSoloCoursesToPurchaseFullyPurchasedItem,
): FullyPurchasedCourseViewModel => {
  const commonCourseFields = mapToCommonCourseViewModel(course);

  return {
    ...commonCourseFields,
    type: 'fully_purchased_solo_course',
  };
};

export const courseMappers = {
  mapToCommonCourseViewModel,
  mapToFullyPurchasedCourse,
  mapToNotPurchasedCourseViewModel,
  mapToPartiallyPurchasedCourseViewModel,
};
