import { createEffect, createEvent, createStore } from 'effector';
import { StudentGetCertificateBySoloCourseIdRequestSchema } from '@generated-student';
import { ApiThrownError } from '@shared/api/client/responses/ApiErrorFactory';
import { CertificateService } from '@shared/api/client/services/CertificateService';
import { handleError } from '@shared/handle-error/handleError';
import { ApiToViewMapper } from '@modules/user-certificate/mappers/user-certificate.mapper';
import { UserCertificateViewModel } from '@modules/user-certificate/types/user-certificate.types';

type UserCertificateStore = UserCertificateViewModel | null;

export const $UserCertificateStore = createStore<UserCertificateStore>(null);

export const getCertificateBySoloCourseIdFx = createEffect<
  StudentGetCertificateBySoloCourseIdRequestSchema,
  UserCertificateStore,
  ApiThrownError
>(async (requestData) => {
  const {
    data: { data },
  } = await CertificateService.getCertificateBySoloCourseId(requestData);
  const mappedData = ApiToViewMapper.getCertificateViewModel(data);
  return mappedData;
});

export const resetUserCertificate = createEvent();

$UserCertificateStore.on(getCertificateBySoloCourseIdFx.doneData, (_, payload) => payload);
$UserCertificateStore.on(getCertificateBySoloCourseIdFx.failData, (prev, error) => {
  handleError(error);

  return prev;
});

$UserCertificateStore.reset(resetUserCertificate);
