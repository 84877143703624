import { OrderItemModule, OrderSoloCourseItem } from '@generated-student';
import { ENTITY_IDS, NAVIGATION } from '@shared/constants';

/**
 * Фукция вызывает редирект на страницу покупки при успешной статусе оплаты
 * @param orderItems - массив элесентов из корзины (но возьмется только первый!)
 */
export const getSuccessRedirectUrl = (orderItems: (OrderItemModule | OrderSoloCourseItem)[]) => {
  const [order] = orderItems;
  if ('moduleId' in order) {
    return NAVIGATION.studentUserCourseModule
      .replace(`:${ENTITY_IDS.moduleId}`, order.moduleId)
      .replace(`:${ENTITY_IDS.courseId}`, order.soloCourseId);
  }
  return NAVIGATION.studentUserCourse.replace(`:${ENTITY_IDS.courseId}`, order.soloCourseId);
};
