import {
  StudentListSoloCourseModulesRequestSchema,
  StudentListSoloCourseModulesResponseSchema,
} from '@generated-student';
import { CourseId } from '@shared/types/course.types';
import { ModulesViewModel } from '../types/modules.types';
import { mapToPurchasedModuleViewModel, mapToNotPurchasedModuleViewModel } from './module.mapper';
import { NotPurchasedModuleViewModel, PurchasedModuleViewModel } from '../types/module.types';
import { DEFAULT_PAGE_SIZE } from '@shared/constants/pagination';

export type GetModulesListRequestParams = {
  courseId: CourseId;
  offset: number;
};

const getModulesListRequestData = (params: GetModulesListRequestParams): StudentListSoloCourseModulesRequestSchema => {
  return {
    filter: { soloCourseId: params.courseId },
    pagination: {
      amount: DEFAULT_PAGE_SIZE,
      offset: params.offset,
    },
  };
};

const getModulesList = (responseData: StudentListSoloCourseModulesResponseSchema): ModulesViewModel => {
  return {
    itemsAmount: responseData.itemsAmount,
    items: responseData.items.reduce<Array<PurchasedModuleViewModel | NotPurchasedModuleViewModel>>((acc, module) => {
      return [
        ...acc,
        module.type === 'not_purchased_module'
          ? mapToNotPurchasedModuleViewModel(module)
          : mapToPurchasedModuleViewModel(module),
      ];
    }, []),
  };
};

export const modulesListViewModelToRequestDataMappers = {
  getModulesList: getModulesListRequestData,
};

export const modulesListResponseDataToViewModelMappers = {
  getModulesList,
};
