import React, { FC } from 'react';
import { Button } from '@ui-kit/Button/Button.component';
import { BasicInput } from '@ui-kit/Input/BasicInput.component';
import { ReactComponent as RightOutlineSvg } from '@svg/outline/right.svg';
import { useLoginForm } from '../../hooks/useLoginForm';
import { useAuthorization } from '@modules/authorization/useAuthorization';
// import { ReactComponent as MessageSvg } from '@svg/outline/message.svg';

export const LoginForm: FC = () => {
  const {
    params,
    formState: { errors },
    handleSubmit,
  } = useLoginForm();

  const { isLoading: isSubmitting } = useAuthorization();

  return (
    <form className={'w-full h-full flex flex-col'} onSubmit={handleSubmit}>
      <h4 className={'text-dark-blue mb-[128px] text-l m:text-3xl'}>Вход в личный кабинет</h4>
      <div className={'flex flex-col'}>
        <BasicInput
          {...params.phone}
          error={errors.phone?.message}
          maskChar={null}
          mask="+9999999999999999"
          label={'Номер телефона'}
        />
        <Button
          disabled={isSubmitting}
          variant={'filled'}
          style={{ background: '#39B4FF', marginTop: '8px' }}
          rightIcon={<RightOutlineSvg color={'white'} />}
          type={'submit'}
        >
          <span>Продолжить</span>
        </Button>
      </div>
      {/** В текущей версии не реализуется */}
      {/*<Button variant={'text'} type={'button'} rightIcon={<MessageSvg color={'white'} />}>*/}
      {/*  <span className={'text-white text-16-bold'}>Обратиться в поддержку</span>*/}
      {/*</Button>*/}
    </form>
  );
};
