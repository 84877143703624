import React, { FC, RefObject, useMemo } from 'react';
import { Payment, PaymentOperation } from './PaymentOperation/PaymentOperation.component';
import { InfiniteScroll } from '@shared/components/InfiniteScroll/InfiniteScroll.component';

type PaymentOperationListProps = {
  loadMore: () => void;
  wrapperRef: RefObject<HTMLElement>;
  hasMore: boolean;
  items: Payment[];
};

export const PaymentOperationList: FC<PaymentOperationListProps> = ({ items = [], loadMore, wrapperRef, hasMore }) => {
  const datesEntries = useMemo(() => {
    const entries: Record<string, Payment[]> = {};

    for (const payment of items) {
      const date = new Intl.DateTimeFormat('ru', {
        day: 'numeric',
        month: 'long',
      }).format(new Date(payment.createdAt));
      entries[date] = entries[date] ?? [];
      entries[date].push(payment);
    }

    return entries;
  }, [items]);

  const entries = Object.entries(datesEntries);

  return (
    <div className={'flex flex-col w-full gap-y-[46px]'}>
      {entries.length ? (
        <InfiniteScroll
          scrollContainerRef={wrapperRef}
          loadMore={loadMore}
          hasMore={Boolean(hasMore)}
          containerClassName="flex flex-col gap-y-[30px] w-full grow"
        >
          {entries.map(([date, payments]) => {
            return (
              <div key={date} className={'flex flex-col'}>
                <span className={'text-18-regular text-grey-50 ml-[8px] mb-[30px] mt-4'}>{date}</span>
                <ul className={'flex flex-col gap-y-[30px]'}>
                  {payments.map((payment) => {
                    return (
                      <PaymentOperation
                        receipt={payment.receipt}
                        id={payment.id}
                        course={payment.course}
                        sum={payment.sum}
                        createdAt={payment.createdAt}
                        key={payment.id}
                      />
                    );
                  })}
                </ul>
              </div>
            );
          })}
        </InfiniteScroll>
      ) : (
        <p className={'text-18-regular text-grey-blue ml-[8px] mb-[30px]'}>{'История операций пуста'}</p>
      )}
    </div>
  );
};
