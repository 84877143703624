/* Функция соритрует массив в рандомном порядке */
export const shuffleArray = <T>(array: Array<T>): Array<T> => {
  const shuffledArray = [...array];
  let currentIndex = shuffledArray.length,
    randomIndex;

  while (currentIndex !== 0) {
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex--;

    [shuffledArray[currentIndex], shuffledArray[randomIndex]] = [
      shuffledArray[randomIndex],
      shuffledArray[currentIndex],
    ];
  }

  return shuffledArray;
};
