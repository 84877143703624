import { SliderValue } from './Filter.types';

// Определяет активен ли трек
export const getIsActiveTrack = (index: number, value: SliderValue): boolean => {
  if (Array.isArray(value) && value.length !== 1) {
    return index < value.length && index !== 0;
  }

  return index === 0;
};

// Вычисляет label для отображения пользователю
export const getLabelText = (value: SliderValue) => {
  return Array.isArray(value) ? `От ${value[0]} до ${value[1]}` : value;
};
