import { ApiThrownError } from '@shared/api/client/responses/ApiErrorFactory';
import { AccountService } from '@shared/api/client/services/Account';
import { handleError } from '@shared/handle-error/handleError';
import { createEffect } from 'effector';

export const updateEmailFx = createEffect<{ email: string }, void, ApiThrownError>(async (params) => {
  await AccountService.updateEmail(params);
});

updateEmailFx.failData.watch((error) => {
  handleError(error);
});
