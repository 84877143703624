/* Функция получает на вход [] всех блоков с занятиями
 * если есть блок с заняием типа тест - для него формируется объект типа TestAnswers
 * использующийся для хранения результатов пользовательского выбора
 */
import {
  ListAllExerciseContentViewModel,
  MatchTestAnswersViewModel,
  QuestionWasAnsweredCorrectly,
  TestAnswers,
} from '@modules/exercise/types/exercise.types';
import { TestWithAnswersViewModel } from '@modules/exercise/stores/exercise-content.store';
import { StudentExerciseContentTestAttempt } from '@generated-student';

/* Если блок теста проходился ранее testAttempt содержит результаты прерыдущих ответов
 * Функции getMatchingAnswersIdAntQuestionStatus | getMultiChoiceAnswersIdAndQuestionStatus | getSingleChoiceAnswersId
 * возвращают результаты пользовательского выбора
 * если ранее вопрос теста не проходился testAttempt === null начальное начение результата устанавливается в зав-ти от
 * типа вопроса
 * если ранее вопрос теста проходился, testAttempt содержит объект с результатами предыдущего выбора пользователя,
 * которые устанавливаются в кач-ве начальных
 */
const getMatchingAnswersIdAntQuestionStatus = (
  testAttempt: StudentExerciseContentTestAttempt | null,
  questionId: string,
): { matching: MatchTestAnswersViewModel; questionWasAnsweredCorrectly: QuestionWasAnsweredCorrectly } => {
  const defaultMatchingAnswersAndStatus = { matching: {}, questionWasAnsweredCorrectly: null };

  if (testAttempt === null) return defaultMatchingAnswersAndStatus;
  const testWithAnswers = testAttempt.questionsAndAnswers.find((questionWithAnswers) => {
    return questionWithAnswers.questionId === questionId;
  });

  if (testWithAnswers?.questionType !== 'matching') return defaultMatchingAnswersAndStatus;

  const matchTestAnswersViewModel = testWithAnswers.matching.reduce<MatchTestAnswersViewModel>((acc, questionMatch) => {
    const [questionId, answerId] = Object.values(questionMatch);
    return { ...acc, [questionId]: answerId };
  }, {});

  return {
    matching: matchTestAnswersViewModel,
    questionWasAnsweredCorrectly: testWithAnswers.questionWasAnsweredCorrectly,
  };
};

const getMultiChoiceAnswersIdAndQuestionStatus = (
  testAttempt: StudentExerciseContentTestAttempt | null,
  questionId: string,
): { answerIds: Array<string>; questionWasAnsweredCorrectly: QuestionWasAnsweredCorrectly } => {
  const defaultMultiChoiceAnswersAndStatus = { answerIds: [], questionWasAnsweredCorrectly: null };

  if (testAttempt === null) return defaultMultiChoiceAnswersAndStatus;
  const testWithAnswers = testAttempt.questionsAndAnswers.find((questionWithAnswers) => {
    return questionWithAnswers.questionId === questionId;
  });

  if (testWithAnswers?.questionType !== 'multi_choice') return defaultMultiChoiceAnswersAndStatus;

  return {
    answerIds: testWithAnswers.answerIds,
    questionWasAnsweredCorrectly: testWithAnswers.questionWasAnsweredCorrectly,
  };
};

const getSingleChoiceAnswersId = (
  testAttempt: StudentExerciseContentTestAttempt | null,
  questionId: string,
): { answerId: string; questionWasAnsweredCorrectly: QuestionWasAnsweredCorrectly } => {
  const defaultSingleChoiceAnswersAndStatus = { answerId: '', questionWasAnsweredCorrectly: null };

  if (testAttempt === null) return defaultSingleChoiceAnswersAndStatus;
  const testWithAnswers = testAttempt.questionsAndAnswers.find((questionWithAnswers) => {
    return questionWithAnswers.questionId === questionId;
  });

  if (testWithAnswers?.questionType !== 'single_choice') return defaultSingleChoiceAnswersAndStatus;

  return {
    answerId: testWithAnswers.answerId,
    questionWasAnsweredCorrectly: testWithAnswers.questionWasAnsweredCorrectly,
  };
};

/*=================================================================================*/

const getDefaultValidation = (testAttempt: StudentExerciseContentTestAttempt | null) => {
  //Если тест не был ранее пройден - testAttempt === null => тогда устанавливаем значение начальной валидации === false
  //Если тест имеет результаты, которые были получены ранее, (testAttempt !== null) => значит валидация пройдена === true
  const isTestAttempt = Boolean(testAttempt);
  return { isValid: isTestAttempt, errorMsg: '' };
};

/* Если в массиве с блоками заняти есть блоки типа testing, функция конструирует объект типа TestWithAnswersViewModel
 * иначе возвращает null (нет блоков с типом test)
 * в  резулльтирующем объекте ключом является id  теста, значением объект типа TestAnswers
 * объект TestAnswers содержит резуьтаты каждого вопроса в тесте и значения валидации
 */
export const getTestWithAnswersData = (
  exerciseContent: ListAllExerciseContentViewModel,
): TestWithAnswersViewModel | null => {
  const tests: TestWithAnswersViewModel = {};

  for (const exercise of exerciseContent) {
    if (exercise.type === 'testing') {
      const testAnswers = exercise.questions.reduce<TestAnswers>(
        (acc, question) => {
          if (question.type === 'matching') {
            const { matching, questionWasAnsweredCorrectly } = getMatchingAnswersIdAntQuestionStatus(
              exercise.testAttempt,
              question.id,
            );
            const validation = getDefaultValidation(exercise.testAttempt);

            return {
              ...acc,
              matching: {
                ...acc.matching,
                [question.id]: { matching, validation, questionWasAnsweredCorrectly },
              },
            };
          } else if (question.type === 'multi_choice') {
            const { answerIds, questionWasAnsweredCorrectly } = getMultiChoiceAnswersIdAndQuestionStatus(
              exercise.testAttempt,
              question.id,
            );
            const validation = getDefaultValidation(exercise.testAttempt);

            return {
              ...acc,
              multi_choice: {
                ...acc.multi_choice,
                [question.id]: { answerIds, validation, questionWasAnsweredCorrectly },
              },
            };
          } else if (question.type === 'single_choice') {
            const { answerId, questionWasAnsweredCorrectly } = getSingleChoiceAnswersId(
              exercise.testAttempt,
              question.id,
            );
            const validation = getDefaultValidation(exercise.testAttempt);

            return {
              ...acc,
              single_choice: {
                ...acc.single_choice,
                [question.id]: { answerId, validation, questionWasAnsweredCorrectly },
              },
            };
          } else {
            return acc;
          }
        },
        { matching: {}, multi_choice: {}, single_choice: {} },
      );
      tests[exercise.id] = testAnswers;
    }
  }
  return Object.keys(tests).length ? tests : null;
};
