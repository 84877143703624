import { createEffect, createEvent, createStore, sample } from 'effector';
import { ApiThrownError } from '@shared/api/client/responses/ApiErrorFactory';
import { handleError } from '@shared/handle-error/handleError';
import { ExerciseService } from '@shared/api/client/services/Exercises';
import { StudentListExercisesResponseSchema } from '@generated-student';
import { ListModuleExercise } from '@modules/module/types/module.types';
import { DEFAULT_PAGE_SIZE } from '@shared/constants/pagination';

type ModuleExercisesStore = ListModuleExercise;
type OffsetStore = number;

type ModuleId = string;

const defaultModuleExercisesStore: ModuleExercisesStore = {
  items: [],
  itemsAmount: 0,
};
export const defaultOffsetStore: OffsetStore = 0;

export const $Offset = createStore<OffsetStore>(defaultOffsetStore);
export const changeOffset = createEvent<number>();
export const resetOffset = createEvent();

export const $ModuleExercises = createStore<ModuleExercisesStore>(defaultModuleExercisesStore);
export const resetModuleExercisesEvent = createEvent();
export const getModuleExercisesEvent = createEvent<ModuleId>();
export const getModuleExercisesFx = createEffect<
  { moduleId: ModuleId; offset: number },
  StudentListExercisesResponseSchema,
  ApiThrownError
>(async (requestData) => {
  const {
    data: { data },
  } = await ExerciseService.getListExercises({
    filter: { moduleId: requestData.moduleId },
    pagination: { amount: DEFAULT_PAGE_SIZE, offset: requestData.offset },
  });

  return data;
});

$ModuleExercises.on(resetModuleExercisesEvent, () => defaultModuleExercisesStore);
$ModuleExercises.on([getModuleExercisesFx.doneData], (currentStore, exercises) => ({
  itemsAmount: exercises.itemsAmount,
  items: [...currentStore.items, ...exercises.items],
}));
$ModuleExercises.on([getModuleExercisesFx.failData], (prev, payload) => {
  handleError(payload);
});
$Offset.on(changeOffset, (_, value) => value).on(resetOffset, () => defaultOffsetStore);

sample({
  clock: getModuleExercisesEvent,
  source: $Offset,
  fn: (offset, moduleId) => ({ offset, moduleId }),
  target: getModuleExercisesFx,
});
