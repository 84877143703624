import React, { FC } from 'react';
import { Navigate, Outlet, Route, Routes } from 'react-router-dom';
import { NAVIGATION } from '@shared/constants';
import { PersonalDataPage } from './student/PersonalData/PersonalData.page';
import { UserCoursesPage } from './student/UserCourses/UserCourses.page';
import { CoursesCatalogPage } from './student/CoursesCatalog/CoursesCatalog.page';
import { CartPage } from './student/Cart/Cart.page';
import { CourseToPurchasePage } from './student/CourseToPurchase/CourseToPurchase.page';
import { CalendarPage } from './student/Calendar/Calendar.page';
import { MainLayout } from '@shared/layouts/MainLayout/MainLayout';
import NotificationPage from './student/Notification/Notification.page';
import SettingsPage from './student/Settings/Settings.page';
import { ContactsPage } from './student/Contacts/Contacts.page';
import { DocumentsPage } from './student/Documents/Documents.page';
import { BillingPage } from './student/Billing/Billing.page';
import { ProtectedAccess } from '@modules/authorization/components/ProtectedAccess/ProtectedAccess.component';
import { LoginPage } from './Login/Login.page';
import { UserCoursePage } from './student/UserCourse/UserCourse.page';
import ModulePage from './student/Module/Module.page';
import { ExercisePage } from './student/Exercise/Exercise.page';
import { UserCourseResultPage } from './student/UserCourseResult/UserCourseResult.page';
import { CertificatesPage } from './student/Certificates/Certificates.page';
import { ResultPage } from './PaymentResult/Result.page';
import PasswordPage from './student/Password/Password.page';
import CreatePasswordPage from './CreatePasswordPage/CreatePasswordPage';

const Empty: FC = () => {
  return <p>Здесь ничего нет :(</p>;
};

export const RoutingPage: FC = () => {
  return (
    <Routes>
      {/* Приватные роуты */}
      <Route
        element={
          <ProtectedAccess redirectTo={NAVIGATION.login}>
            <Outlet />
          </ProtectedAccess>
        }
      >
        {/*Результат оплаты */}
        <Route path={NAVIGATION.paymentRedirect} element={<ResultPage />} />
        <Route path={NAVIGATION.createPassword} element={<CreatePasswordPage />} />

        <Route element={<MainLayout />}>
          {/* Профиль */}
          <Route path={NAVIGATION.studentProfile} element={<Navigate to={NAVIGATION.studentPersonalData} replace />} />
          <Route path={NAVIGATION.studentPersonalData} element={<PersonalDataPage />} />
          <Route path={NAVIGATION.studentContacts} element={<ContactsPage />} />
          <Route path={NAVIGATION.studentDocuments} element={<DocumentsPage />} />
          <Route path={NAVIGATION.studentBilling} element={<BillingPage />} />
          <Route path={NAVIGATION.studentPassword} element={<PasswordPage />} />

          {/* Образование */}
          <Route path={NAVIGATION.studentEducation} element={<Navigate to={NAVIGATION.studentUserCourses} replace />} />
          <Route path={NAVIGATION.studentUserCourses} element={<UserCoursesPage />} />
          <Route path={NAVIGATION.studentUserCourse} element={<UserCoursePage />} />
          <Route path={NAVIGATION.studentUserCourseModule} element={<ModulePage />} />
          <Route path={NAVIGATION.studentUserCourseResult} element={<UserCourseResultPage />} />
          <Route path={NAVIGATION.studentCourseToPurchase} element={<CourseToPurchasePage />} />
          <Route path={NAVIGATION.studentCoursesCatalog} element={<CoursesCatalogPage />} />
          <Route path={NAVIGATION.studentCertificates} element={<CertificatesPage />} />
          <Route path={NAVIGATION.studentUserCourseExercise} element={<ExercisePage />} />

          {/* Корзина */}
          <Route path={NAVIGATION.studentCart} element={<CartPage />} />

          {/* Календарь */}
          <Route path={NAVIGATION.studentCalendar} element={<CalendarPage />} />

          {/* Уведомления */}
          <Route path={NAVIGATION.studentNotification} element={<NotificationPage />} />

          {/* Настройки */}
          <Route path={NAVIGATION.studentSettings} element={<SettingsPage />} />
        </Route>
      </Route>

      {/* Публичные роуты */}
      <Route
        path={NAVIGATION.login}
        element={
          <ProtectedAccess
            loggedRules={{
              redirectTo: NAVIGATION.studentUserCourses,
            }}
            isPublic={true}
          >
            <LoginPage />
          </ProtectedAccess>
        }
      />

      <Route path="/" element={<Navigate to={NAVIGATION.studentUserCourses} replace />} />
      <Route path="*" element={<Empty />} />
    </Routes>
  );
};
