import { useEffect, useState } from 'react';

type UseCountdownParams = {
  seconds?: number;
};

/**
 * Хук предоставляет функциональность таймера обратного отсчета
 */
export const useCountdown = ({ seconds = 0 }: UseCountdownParams = {}) => {
  const [secondsLeft, setSecondsLeft] = useState(seconds);

  useEffect(() => {
    const timer = setTimeout(() => {
      if (secondsLeft > 0) {
        setSecondsLeft((prev) => prev - 1);
      } else {
        clearInterval(timer);
      }
    }, 1000);

    return () => clearTimeout(timer);
  }, [secondsLeft]);

  return {
    secondsLeft,
  };
};
