export const allTimezones = {
  'Pacific/Midway': 'Мидуэй, Самоа',
  'Pacific/Honolulu': 'Гаваи',
  'America/Juneau': 'Аляска',
  'America/Boise': 'Горное время',
  'America/Dawson': 'Юкон',
  'America/Chihuahua': 'Чихуахуа',
  'America/Phoenix': 'Аризона',
  'America/Chicago': 'Центральное время',
  'America/Regina': 'Саскачеван',
  'America/Mexico_City': 'Мехико, Монтереей',
  'America/Belize': 'Центральное американское время',
  'America/Detroit': 'Детройт',
  'America/Bogota': 'Богота, Лима',
  'America/Caracas': 'Каракас, Ла Пас',
  'America/Santiago': 'Сантьяго',
  'America/St_Johns': 'Ньюфаундленд и Лабрадор',
  'America/Sao_Paulo': 'Бразилия',
  'America/Tijuana': 'Тихуана',
  'America/Montevideo': 'Монтевидео',
  'America/Argentina/Buenos_Aires': 'Буэнос-Айрес, Джорджтаун',
  'America/Godthab': 'Гренландия',
  'America/Los_Angeles': 'Тихоокеанское время',
  'Atlantic/Azores': 'Азорские острова',
  'Atlantic/Cape_Verde': 'Кабо-Верде',
  GMT: 'Всемирное координированное время',
  'Europe/London': 'Эдинбург, Лондон',
  'Europe/Dublin': 'Даблин',
  'Europe/Lisbon': 'Лиссабон',
  'Africa/Casablanca': 'Касабланка, Монро́вия',
  'Atlantic/Canary': 'Канарские острова',
  'Europe/Belgrade': 'Белград, Братислава, Будапешт, Любляна, Прага',
  'Europe/Sarajevo': 'Сараево, Скопье, Варшава, Загреб',
  'Europe/Brussels': 'Брюссель, Копенгаген, Мадрид, Париж',
  'Europe/Amsterdam': 'Амстердам, Берлин, Берн, Рим, Стокгольм, Вена',
  'Africa/Algiers': 'Центрально-африканское время',
  'Europe/Bucharest': 'Бухарест',
  'Africa/Cairo': 'Каир',
  'Europe/Helsinki': 'Хельсинки, Киев, Рига, София, Таллин, Вильнюс',
  'Europe/Athens': 'Афины, Минск',
  'Asia/Jerusalem': 'Иерусалим',
  'Africa/Harare': 'Хараре, Претория',
  'Europe/Moscow': 'Стамбул, Москва, Санкт - Петербург, Волгоград',
  'Asia/Kuwait': 'Кувейт, Эр-Рияд',
  'Africa/Nairobi': 'Найроби',
  'Asia/Baghdad': 'Багдад',
  'Asia/Tehran': 'Тегеран',
  'Asia/Dubai': 'Абу - Даби, Маскат',
  'Asia/Baku': 'Баку, Тбилиси, Ереван',
  'Asia/Kabul': 'Кабул',
  'Asia/Yekaterinburg': 'Екатеринбург',
  'Asia/Karachi': 'Исламабад, Карачи, Ташкент',
  'Asia/Kolkata': 'Ченнай, Калькутта, Мумбаи, Нью Дели',
  'Asia/Kathmandu': 'Катманду',
  'Asia/Dhaka': 'Астана, Дакка',
  'Asia/Colombo': 'Шри - Джаяварденепура - Котте',
  'Asia/Almaty': 'Алматы, Новосибирск',
  'Asia/Rangoon': 'Янгон',
  'Asia/Bangkok': 'Бангкок, Ханой, Джакарта',
  'Asia/Krasnoyarsk': 'Красноярск',
  'Asia/Shanghai': 'Пекин, Чунцин, Гонконг, Урумчи',
  'Asia/Kuala_Lumpur': 'Куала-Лумпур, Сингапур',
  'Asia/Taipei': 'Тайбэй',
  'Australia/Perth': 'Перт',
  'Asia/Irkutsk': 'Иркутск, Улан-Батор',
  'Asia/Seoul': 'Сеул',
  'Asia/Tokyo': 'Осака, Саппоро, Токио',
  'Asia/Yakutsk': 'Якутск',
  'Australia/Darwin': 'Дарвин',
  'Australia/Adelaide': 'Аделаида',
  'Australia/Sydney': 'Канберра, Мельбурн, Сидней',
  'Australia/Brisbane': 'Брисбен',
  'Australia/Hobart': 'Хобарт',
  'Asia/Vladivostok': 'Владивосток',
  'Pacific/Guam': 'Гуам, Порт-Морсби',
  'Asia/Magadan': 'Магадан, Соломоновы острова, Новая Каледония',
  'Asia/Kamchatka': 'Камчатка, Маршалловы Острова',
  'Pacific/Fiji': 'Фиджи',
  'Pacific/Auckland': 'Окленд, Веллингтон',
  'Pacific/Tongatapu': 'Нукуалофа',
} as const;

export const DEFAULT_TIMEZONE = 'Europe/Moscow';
