import { createEffect, createEvent, createStore } from 'effector';
import { ApiThrownError } from '@shared/api/client/responses/ApiErrorFactory';
import { handleError } from '@shared/handle-error/handleError';
import {
  DocumentsViewModel,
  DocumentsViewModelWithCreatedFile,
  DocumentsViewModelWithUpdatedFile,
} from '@modules/documents/types/documents.types';
import { MapperApiToViewMapper, MapperViewToApiMapper } from '@modules/documents/mappers/documents.mapper';
import { DocumentsService } from '@shared/api/client/services/DocumentsService';

type DocumentsStore = {
  isEditMode: boolean;
  isLoading: boolean;
  documents: DocumentsViewModel | null;
};

export const $Documents = createStore<DocumentsStore>({ isLoading: false, documents: null, isEditMode: false });

export const resetDocuments = createEvent();

export const getDocumentsFx = createEffect<void, DocumentsViewModel | null, ApiThrownError>(async () => {
  const {
    data: { data },
  } = await DocumentsService.getPassport();

  return data === null ? null : MapperApiToViewMapper.getDocumentsViewModel(data);
});

export const updateDocumentsFx = createEffect<DocumentsViewModelWithUpdatedFile, void, ApiThrownError>(async (data) => {
  const mappedData = MapperViewToApiMapper.getUpdateDocumentsApiModel(data);
  await DocumentsService.updatePassport(mappedData);
});

export const createDocumentsFx = createEffect<DocumentsViewModelWithCreatedFile, void, ApiThrownError>(async (data) => {
  const mappedData = MapperViewToApiMapper.getCreateDocumentsApiModel(data);
  await DocumentsService.createPassport(mappedData);
});

export const toggleEditModeEvent = createEvent();

$Documents.on(toggleEditModeEvent, (prev) => ({ ...prev, isEditMode: !prev.isEditMode }));

$Documents.on([getDocumentsFx], (prev) => {
  return {
    ...prev,
    isLoading: true,
  };
});

$Documents.on([getDocumentsFx.doneData], (prev, payload) => {
  return {
    isEditMode: payload === null,
    documents: payload,
    isLoading: false,
  };
});

$Documents.on([getDocumentsFx.failData, updateDocumentsFx.failData, createDocumentsFx.failData], (prev, payload) => {
  handleError(payload);

  return {
    ...prev,
    isLoading: false,
  };
});

$Documents.reset(resetDocuments);
