import React, { useEffect, useRef } from 'react';
import { ModuleList } from '@modules/module/components/ModuleList/ModuleList.component';
import { ModulesAmount } from '@modules/module/components/ModulesAmount/ModulesAmount.component';
import { Header } from '@ui-kit/Header/Header.component';
import {
  $ModulesList,
  $Offset,
  getModules,
  getModulesListFx,
  resetModulesList,
  resetOffset,
} from '@modules/module/stores/modules-list.store';
import { useNavigate, useParams } from 'react-router-dom';
import {
  $CoursesToPurchase,
  getCourseToPurchaseFx,
  resetCourseToPurchaseEvent,
} from '@modules/course/stores/course/course-purchase.store';
import { useStore, useUnit } from 'effector-react';
import { ENTITY_IDS, NAVIGATION } from '@shared/constants';
import { Button } from '@ui-kit/Button/Button.component';
import { toRubles } from '@shared/utils/toRubles';
import { handleError } from '@shared/handle-error/handleError';
import { handleApiError } from '@shared/handle-error/default-handlers/handleApiError';
import { CoursePreviewCard } from '@modules/course/components/CoursePreviewCard/CoursePreviewCard.component';
import styles from './CourseToPurchase.module.css';
import { useIsFirstRender } from '@shared/hooks/useIsFirstRender';
import { $Cart, addToCartEvent, removeFromCartEvent } from '@shared/stores/cart.store';

/**
 * Страница курса, где пользователь может выбрать что он хочет оплатить
 * - или курс целиком, или модули курса по отдельности
 */
export const CourseToPurchasePage = () => {
  const { courseId } = useParams();
  const navigate = useNavigate();
  const pageWrapperRef = useRef<HTMLDivElement>(null);
  const isFirstRender = useIsFirstRender();
  const coursesToPurchase = useStore($CoursesToPurchase);
  const offset = useStore($Offset);
  const cartStore = useStore($Cart);
  const { items, itemsAmount } = useStore($ModulesList);

  const isLoading = useUnit(getModulesListFx.pending);

  const currentCourse = coursesToPurchase.find((course) => {
    return course.id === courseId;
  });

  const handleClickModule = (moduleId: string) => {
    if (courseId && currentCourse?.isOnlyFullPurchase) {
      /** Урл страницы оплаты модуля курса */
      const modulePurchasePageUrl = NAVIGATION.studentCourseModulePurchase
        .replace(`:${ENTITY_IDS.courseId}`, courseId)
        .replace(`:${ENTITY_IDS.moduleId}`, moduleId);

      navigate(modulePurchasePageUrl);
    }
  };

  const courseInCart = cartStore.some((it) => {
    return it.id === courseId;
  });

  const moduleIsPaid = items.some((module) => {
    return module.type === 'purchased_module';
  });

  const moduleInCart = cartStore.some((item) => items.some((module) => item.id === module.id));

  useEffect(() => {
    const fetchInitialData = async () => {
      if (!courseId) return;

      try {
        if (courseId) {
          await getCourseToPurchaseFx(courseId);
          getModules(courseId);
        }
      } catch (error) {
        handleError(error, {
          apiError: (err) => {
            /**
             * Если появлась ошибка того, что курс уже куплен,
             * то перенаправляем пользователя на страницу купленного курса
             */
            if (err.data.type === 'entity_is_purchased') {
              const purchasedCourseUrl = NAVIGATION.studentCoursePurchase.replace(`:${ENTITY_IDS.courseId}`, courseId);

              navigate(purchasedCourseUrl);
            } else {
              handleApiError(err);
            }
          },
        });
      }
    };

    fetchInitialData();

    return () => {
      resetModulesList();
      resetCourseToPurchaseEvent();
      resetOffset();
    };
  }, []);

  useEffect(() => {
    if (!isFirstRender && courseId) {
      getModules(courseId);
    }
  }, [offset]);

  const handleCartButtonClick = () => {
    if (!currentCourse) {
      return;
    }

    return courseInCart
      ? removeFromCartEvent(currentCourse.id)
      : addToCartEvent({ id: currentCourse.id, type: currentCourse.type });
  };

  return (
    <div className={styles['course-to-purchase']} ref={pageWrapperRef}>
      <Header
        hasBackButton
        redirectTo={NAVIGATION.studentCoursesCatalog}
        headerText={''}
        rightElement={
          courseId !== undefined && (
            <div className="flex items-center gap-x-5">
              {currentCourse?.price && (
                <h5 className={'text-l m:text-xl'}>{toRubles(Number(currentCourse.discountedPrice))}</h5>
              )}
              <Button disabled={moduleInCart || moduleIsPaid} variant="filled" onClick={handleCartButtonClick}>
                <span>{!courseInCart ? 'Положить в корзину' : 'Удалить из корзины'}</span>
              </Button>
            </div>
          )
        }
      />
      {!isLoading && (
        <section className={styles[itemsAmount === 1 ? 'courses-list-alt' : 'courses-list']}>
          {currentCourse && (
            <CoursePreviewCard
              author={currentCourse.author}
              studyHours={currentCourse.studyHours}
              coverUrl={currentCourse.coverUrl}
              // Добавить progress, когда в модель курса для покупки на бэке добавят кол-во пройденных часов
              progress={undefined}
              description={currentCourse.longDescription}
            />
          )}

          <div>
            <div className="mb-[20px] w-full">
              <ModulesAmount />
            </div>
            <ModuleList
              wrapperRef={pageWrapperRef}
              withButton={!currentCourse?.isOnlyFullPurchase && !courseInCart}
              onClick={handleClickModule}
            />
          </div>
        </section>
      )}
    </div>
  );
};
