import React from 'react';

import { Button } from '@ui-kit/Button/Button.component';
import { ReactComponent as RightOutlineSvg } from '@svg/outline/right.svg';
import { useForm } from 'react-hook-form';
import { BasicInput } from '@ui-kit/Input/BasicInput.component';
import { useEvent, useStore } from 'effector-react';
import { updatePasswordFx } from '@shared/stores/password.store';
import {
  $Authorization,
  changeAuthStageEvent,
  loginByPasswordFx,
  requestPasswordResettingSmsCodeFx,
} from '@shared/stores/authorization.store';
import { useNavigate } from 'react-router-dom';
import { NAVIGATION } from '@shared/constants';
import { getUserProfileFx } from '@shared/stores/user-profile/user-profile.store';
type LoginPasswordFormTypes = 'CreatePassword' | 'ChangePassword' | 'Password';

type LoginPasswordFormProps = {
  type: LoginPasswordFormTypes;
};
const HEADERS: Record<LoginPasswordFormTypes, string> = {
  CreatePassword: 'Создание пароля',
  ChangePassword: 'Восстанволение пароля',
  Password: 'Вход в личный кабинет',
};
type Password = { password: string; passwordConfirm?: string };

const LoginPasswordForm = ({ type }: LoginPasswordFormProps) => {
  const changeAuthStage = useEvent(changeAuthStageEvent);
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
  } = useForm<Password>();
  const { whoTryLogin } = useStore($Authorization);
  const updatePassword = useEvent(updatePasswordFx);
  const loginByPassword = useEvent(loginByPasswordFx);

  const submitHandler = async (values: Password) => {
    if (type === 'Password') {
      await loginByPassword({
        phone: whoTryLogin?.phone ?? '',
        password: values.password,
      });
    }
    await updatePassword(values.password);
    await getUserProfileFx();
    navigate(NAVIGATION.root);
  };
  const onRestorePasswordClick = async () => {
    await requestPasswordResettingSmsCodeFx({ phone: whoTryLogin?.phone ?? '' });
    changeAuthStage('smsPasswordRestoreCode');
  };

  return (
    <form className={'w-full h-full flex flex-col'} onSubmit={handleSubmit(submitHandler)}>
      <h4 className={'mb-[128px] text-l m:text-3xl text-dark-blue'}>{HEADERS[type]}</h4>
      <div className={'flex flex-col'}>
        <BasicInput
          {...register('password', { required: true })}
          type={'password'}
          error={errors.password?.message}
          maskChar={null}
          label={'Пароль'}
        />
        {['CreatePassword', 'ChangePassword'].includes(type) && (
          <BasicInput
            {...register('passwordConfirm', {
              required: true,
              validate: (val: string | undefined) => {
                if (watch('password') !== val) {
                  return 'Пароли не совпадают';
                }
              },
            })}
            type={'password'}
            error={errors.passwordConfirm?.message}
            maskChar={null}
            label={'Повторите пароль'}
          />
        )}
        <Button className={'mt-2'} variant={'filled'} rightIcon={<RightOutlineSvg color={'white'} />}>
          <span>Войти</span>
        </Button>
      </div>
      {type === 'Password' && (
        <Button onClick={onRestorePasswordClick} variant={'text'} type={'button'}>
          <span className={'text-dark-blue text-16-bold'}>Забыли пароль?</span>
        </Button>
      )}
    </form>
  );
};

export default LoginPasswordForm;
