import { useNavigate } from 'react-router-dom';
import { OrderItemModule, OrderSoloCourseItem } from '@generated-student';
import { ENTITY_IDS } from '@shared/constants';
import { getOrderStatusFx } from '@shared/stores/purchase-store';
import { useEffect, useState } from 'react';
import { useUnit } from 'effector-react';
import { getSuccessRedirectUrl } from '@shared/utils/getSuccessRedirectUrl';
import { getFailRedirectUrl } from '@shared/utils/getFailRedirectUrl';
import { updateCartEvent } from '@shared/stores/cart.store';
import { hideModalEvent } from '@shared/ui-kit/Modal/ModalBasic/ModalBasic.store';

export const usePaymentResultHook = () => {
  const navigate = useNavigate();
  const isLoading = useUnit(getOrderStatusFx.pending);
  const [resultState, setResultState] = useState<'Error' | 'Success' | 'Pending' | 'Splash'>('Splash');
  const [orderItems, setOrderItems] = useState<(OrderItemModule | OrderSoloCourseItem)[]>([]);

  const callBack = () => {
    switch (resultState) {
      case 'Success':
        return navigate(getSuccessRedirectUrl(orderItems));
      case 'Error':
        return navigate(getFailRedirectUrl(orderItems));
      default:
        return;
    }
  };
  const init = async () => {
    const searchParams = new URLSearchParams(window.location.search);
    const orderId = searchParams.get(ENTITY_IDS.orderId);
    const { status, orderItems } = await getOrderStatusFx({ id: orderId ?? '' });

    setOrderItems(orderItems);
    if (['payment_canceled', 'payment_failed', 'payment_form_initializing_failed'].includes(status)) {
      setResultState('Error');
    }

    if (status === 'payment_succeeded') {
      setResultState('Success');
      updateCartEvent(orderItems.map((it) => ('moduleId' in it ? it.moduleId : it.soloCourseId)));
      hideModalEvent();
    }

    if (status === 'payment_form_initializing_succeeded') {
      setResultState('Pending');
      await init();
    }
  };

  useEffect(() => {
    init();
  }, []);

  return { isLoading, resultState, callBack };
};
