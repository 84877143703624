import { publicApiClient } from '@shared/api/client/client';
import {
  IssuedAuthorizationCredentials,
  RequestSmsCodeRequestSchema,
  ResetPasswordRequestSchema,
} from '@generated-student';
import { ApiSuccessResponse } from '@shared/api/client/responses/ApiSuccessResponse';

export type IssueTokenArguments = {
  phone: string;
  smsCode: string;
};
export type IssueTokenPasswordArguments = {
  phone: string;
  password: string;
};

export type RequestAuthSmsCodeArguments = {
  phone: string;
};

export type RevokeTokenArguments = {
  accessToken: string;
};
export type RefreshTokenArguments = {
  refreshToken: string;
};

export class AuthenticationService {
  static issueTokenByPhoneAndCode(data: IssueTokenArguments) {
    return publicApiClient.post<ApiSuccessResponse<IssuedAuthorizationCredentials>>('/issueToken', data);
  }
  static issueTokenByPhoneAndPassword(data: IssueTokenPasswordArguments) {
    return publicApiClient.post<ApiSuccessResponse<IssuedAuthorizationCredentials>>(
      '/issueTokenByPhoneAndPassword',
      data,
    );
  }
  static requestAuthSmsCode(data: RequestAuthSmsCodeArguments) {
    return publicApiClient.post<ApiSuccessResponse>('/requestAuthenticationSmsCode', data);
  }
  static requestPasswordResettingSmsCode(data: RequestSmsCodeRequestSchema) {
    return publicApiClient.post<ApiSuccessResponse>('/requestPasswordResettingSmsCode', data);
  }
  static resetPassword(data: ResetPasswordRequestSchema) {
    return publicApiClient.post<ApiSuccessResponse<IssuedAuthorizationCredentials>>('/resetPassword', data);
  }
  static revokeToken(data: RevokeTokenArguments) {
    return publicApiClient.post<ApiSuccessResponse<RevokeTokenArguments>>('/revokeToken', data);
  }
  static refreshToken(data: RefreshTokenArguments) {
    return publicApiClient.post<ApiSuccessResponse<IssuedAuthorizationCredentials>>('/refreshToken', data);
  }
}
