import React, { FC } from 'react';
import { ModalCore } from '../ModalCore.component';
import { ReactComponent as Close } from '@svg/outline/close.svg';
import { ReactComponent as CloseOrDeleteError } from '@svg/solid/closeOrDeleteError.svg';
import { ReactComponent as Check } from '@svg/solid/check.svg';
import { useStore } from 'effector-react';
import { $ResultModal, hideResultModal } from './ModalResult.store';

/** Модальное окно о результате(ошибка или успех) какого либо действия */
export const ModalResult: FC = () => {
  const resultModal = useStore($ResultModal);

  const isOpen = resultModal !== null;

  return (
    <ModalCore variant="result" isOpen={isOpen} hideModal={hideResultModal} onCancel={resultModal?.onCancel}>
      <ModalResultContent />
    </ModalCore>
  );
};

const ModalResultContent: FC = () => {
  const resultModal = useStore($ResultModal);

  /** Предполагается, что ModalResult будет использоваться через передачу поля modal в стор $ModalStore */
  if (!resultModal) {
    return null;
  }

  const handleCancel = () => {
    hideResultModal();

    if (resultModal.onCancel) resultModal.onCancel();
  };

  const { description, title, type } = resultModal;

  const titleText = title ?? (type === 'error' ? 'Ошибка' : 'Успех');

  return (
    <div className="flex flex-col min-h-[150px] min-w-[300px] max-w-[380px]">
      <div className="flex justify-end mb-5">
        <Close className="cursor-pointer" onClick={handleCancel} />
      </div>
      <div className="flex flex-col items-center gap-y-5">
        {type === 'error' && <CloseOrDeleteError className={'text-red-intensive'} />}
        {type === 'success' && <Check className="w-[60px] h-[60px] text-light-green" />}
        <h5 className="text-center">{titleText}</h5>
        {description && <p className="text-14-regular text-center text-grey-60">{description}</p>}
      </div>
    </div>
  );
};
