import React, { FC } from 'react';
import styles from './YouTubeVideoPlayer.module.css';

export type YouTubeVideoPlayerProps = {
  url: string;
  className?: string;
};

export const YouTubeVideoPlayer: FC<YouTubeVideoPlayerProps> = (props) => (
  <div className={`${styles.container} ${props.className || ''}`}>
    <div className={styles.content}>
      <iframe
        className={styles.video}
        src={props.url}
        title="YouTube video player"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
      />
    </div>
  </div>
);
