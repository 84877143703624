import React, { useEffect } from 'react';
import { ExerciseHeader } from '@modules/exercise/components/ExerciseHeader.component';
import { useExerciseContentPage } from '@modules/exercise/hooks/useExerciseContentPage';
import { BLOCK_TYPE_TO_COMPONENT_MAPPER } from '@modules/exercise/constants/constants';
import { Button } from '@ui-kit/Button/Button.component';
import { resetExercise } from '@modules/exercise/stores/exercise-content.store';
import styles from './Exercise.module.css';

/**
 * Страница занятия
 */

export const ExercisePage = () => {
  const { exerciseContent, exercise, handlePassExercise, handleCancelExercise, isAllTestInExercisePassed } =
    useExerciseContentPage();

  useEffect(() => {
    return () => {
      resetExercise();
    };
  }, []);

  const isContinueButtonBlocked = exercise?.isPassed || !isAllTestInExercisePassed;

  return (
    <div className={styles['exercise']}>
      <section>
        <ExerciseHeader onCancelExercise={handleCancelExercise} />
      </section>

      <section className={'mt-[30px]'}>
        <ul className={'flex flex-col gap-y-[30px]'}>
          {exerciseContent?.map((exercise) => {
            const Block = BLOCK_TYPE_TO_COMPONENT_MAPPER[exercise.type];

            return (
              <li key={exercise.id}>
                <Block data={{ ...exercise }} />
              </li>
            );
          })}
        </ul>
      </section>

      {!isContinueButtonBlocked && (
        <section className={'mt-[40px] flex justify-end'}>
          <Button variant={'filled'} onClick={handlePassExercise}>
            Продолжить
          </Button>
        </section>
      )}
    </div>
  );
};
