import { ALPHABET_LETTERS_REGEX, SPECIAL_SYMBOLS_REGEX } from '@shared/constants/regexp';

export const alphabetStringValidator = (allowNumbers?: boolean) => {
  const message = 'Некорректный формат данных';
  return {
    validate: (value?: string) => {
      if (!value) {
        return true;
      }
      /* Регулярка включает в себя спец символы, но не включает цифры
                  Здесь мы проверяем, что строка не содержит спец символы, но может содержать цифры, слэш, дефис и запятую */
      const alphabetRegex = allowNumbers ? !SPECIAL_SYMBOLS_REGEX.test(value) : ALPHABET_LETTERS_REGEX.test(value);
      return alphabetRegex || message;
    },
  };
};
