/**
 * Функция собирает массив сегментов pathname
 * Например, при pathname равном '/educational-programs/modules',
 * функция вернет ['/', '/educational-programs', '/educational-programs/modules']
 */
export const getCumulativeUrlPathSegments = (pathname: string): Array<string> => {
  if (!pathname) {
    return [];
  }

  /** filter удаляет пустые строки, оставшиеся после сплита */
  const splitPathname = pathname.split('/').filter(Boolean);

  const cumulativePathSegments = splitPathname.reduce<Array<string>>(
    (segments, segment) => {
      const previous = segments[segments.length - 1];
      const current = previous === '/' ? `${previous}${segment}` : `${previous}/${segment}`;

      segments.push(current);

      return segments;
    },
    ['/'],
  );

  return cumulativePathSegments;
};
