import React, { FC } from 'react';
import { Button } from '@shared/ui-kit/Button/Button.component';
import { ReactComponent as BackIcon } from '@svg/solid/back.svg';
import styles from './Header.module.css';
import { useNavigate } from 'react-router-dom';
import { DELTA_STACK_HISTORY } from '@shared/constants';

type StyleConfig = {
  header: string;
};

export type HeaderProps = {
  rightElement?: React.ReactNode;
  hasBackButton?: boolean;
  redirectTo?: string;
  headerText: string;
  headerTextClassName?: string;
  children?: React.ReactNode;
  styleConfig?: Partial<StyleConfig>;
};

/**
 * Компонент заголовка страниц
 */
export const Header: FC<HeaderProps> = ({
  redirectTo,
  children,
  rightElement,
  hasBackButton = true,
  headerText,
  styleConfig,
  headerTextClassName,
}) => {
  const navigate = useNavigate();

  const navigateBack = () => {
    if (redirectTo) {
      navigate(redirectTo);
    } else {
      navigate(-DELTA_STACK_HISTORY);
    }
  };

  const textClassName = [styles['header-text'], headerTextClassName].filter(Boolean).join(' ');

  return (
    <header className={`${styles['header']} ${styleConfig?.header ?? ''}`}>
      <div className="flex gap-x-[20px] items-center h-full">
        {hasBackButton && (
          <Button
            variant="filled"
            className={styles['back-button']}
            onClick={navigateBack}
            leftIcon={<BackIcon color="white" className={'h-[20px] m:h-[60px] aspect-square'} />}
            style={{ background: '#39B4FF' }}
          />
        )}
        <h2 className={textClassName}>{headerText}</h2>
        {children}
      </div>

      {rightElement !== undefined && rightElement}
    </header>
  );
};
