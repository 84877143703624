import React, { FC } from 'react';
import { Slider } from '@ui-kit/Slider/Slider.component';
import { ExerciseContentPresentation } from '@modules/exercise/types/exercise.types';

type BlockPresentationProps = {
  data: ExerciseContentPresentation;
};

export const BlockPresentation: FC<BlockPresentationProps> = ({ data }) => {
  const { title, description, slides } = data;

  return (
    <div className={'flex flex-col gap-y-[40px]'}>
      <div className={'h-[1px] w-full bg-opacity-darkBlue-30'} />
      <h3 className={'text-grey-90'}>{title}</h3>
      <Slider items={slides} />
      <p className={'text-18-bold text-grey-90'}>{description}</p>
    </div>
  );
};
