import React, { FC } from 'react';
import { BrowserRouter } from 'react-router-dom';

export function withAppProviders<C extends FC, P extends React.ComponentProps<C>>(App: C) {
  const constructor: FC<P> = (props) => {
    return <BrowserRouter>{App(props)}</BrowserRouter>;
  };
  constructor.displayName = `withAppProviders(${App.displayName})`;
  return constructor;
}
