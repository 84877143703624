import React from 'react';
import ReactDOM from 'react-dom/client';
import reportWebVitals from './reportWebVitals';
import App from './app/App';
import { restoreCredentialsFx } from '@shared/stores/authorization.store';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import { getUserProfileFx } from '@shared/stores/user-profile/user-profile.store';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
dayjs.extend(utc);
dayjs.extend(timezone);

const initApp = async () => {
  const currentUser = await restoreCredentialsFx();

  if (currentUser) {
    await getUserProfileFx();
  }
};

initApp();

root.render(
  // TODO Разобраться, как избежать двойных запросов при использовании React.StrictMode https://github.com/spiks/most-edu-front-public/issues/383
  // Иначе появляются проблемы - например, дублирование элементов в листинге при пагинации.
  // <React.StrictMode>
  <App />,
  // </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
