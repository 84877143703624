import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useEvent, useStore } from 'effector-react';
import { useEffect } from 'react';
import { $ExerciseContent, getListAllExerciseContentFx } from '@modules/exercise/stores/exercise-content.store';
import { ExerciseService } from '@shared/api/client/services/Exercises';
import { useNavigateBack } from '@shared/hooks/useNavigateBack';
import { ENTITY_IDS, NAVIGATION } from '@shared/constants';
import { handleError } from '@shared/handle-error/handleError';

export const useExerciseContentPage = () => {
  const { courseId, exerciseId } = useParams();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { redirectToPreviousPage } = useNavigateBack();
  const { exerciseContent, exercise, isAllTestInExercisePassed, testResult } = useStore($ExerciseContent);
  const getListAllExerciseContent = useEvent(getListAllExerciseContentFx);

  const handleCancelExercise = () => {
    redirectToPreviousPage(NAVIGATION.studentUserCourseModule);
  };

  /* Функция-обработчик нажатия пользователем кнопки прохождения занятия */
  const handlePassExercise = async () => {
    if (!courseId || !exerciseId) return;
    try {
      //Отмечаем текущее занятие как пройденное
      await ExerciseService.markExerciseAsPassed({ id: exerciseId });

      //Получаем следующее занятие в модуле
      const {
        data: { data },
      } = await ExerciseService.getCurrentNotPassedExerciseInSoloCourse({ id: courseId });

      //Если все упражнения пройдены переводим юзера на страницу сертификатов
      if (data === null) {
        const studentUserCourseResultUrl = NAVIGATION.studentUserCourseResult.replace(
          `:${ENTITY_IDS.courseId}`,
          courseId,
        );

        navigate(studentUserCourseResultUrl);
      } else {
        const { exerciseId: nextExerciseId, moduleId } = data;

        const nextUserExerciseUrl = NAVIGATION.studentUserCourseExercise
          .replace(`:${ENTITY_IDS.courseId}`, courseId)
          .replace(`:${ENTITY_IDS.moduleId}`, moduleId)
          .replace(`:${ENTITY_IDS.exerciseId}`, nextExerciseId);

        navigate(nextUserExerciseUrl);
      }
    } catch (error) {
      handleError(error);
    }
  };

  useEffect(() => {
    if (!exerciseId) return;
    getListAllExerciseContent({ exerciseId });
  }, [pathname, testResult]);

  return { exerciseContent, exercise, handlePassExercise, handleCancelExercise, isAllTestInExercisePassed };
};
