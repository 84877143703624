import React, { FC } from 'react';
import { RoutingPage } from '../pages/Routing.page';
import { withAppProviders } from './providers/withAppProviders';
import { withRuntimeEnv } from './providers/withRuntimeEnv';
import './styles/index.css';
import { ModalResult } from '@ui-kit/Modal/ModalResult/ModalResult.component';

export const App: FC = () => {
  return (
    <>
      <ModalResult />
      <RoutingPage />
    </>
  );
};
export default withRuntimeEnv(withAppProviders(App));
