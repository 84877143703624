import styles from './TabsModule.module.css';
import { TabsCore } from '@shared/ui-kit/Tabs/TabsCore.component';
import React, { FC } from 'react';
import { StyleConfig, TabsModuleProps } from '@shared/ui-kit/Tabs/Tabs.types';

const styleConfig: StyleConfig = {
  wrapper: styles['tabs-module'],
  activeTabMarker: styles['active-tab-marker'],
  tab: styles['tab'],
  tab_active: styles['tab-active'],
  list: styles['list'],
};

const TAB_ITEMS_MODULE = [
  {
    id: 'info',
    title: 'Сведения',
  },
  {
    id: 'exercises',
    title: 'Занятия',
  },
  {
    id: 'materials',
    title: 'Материалы',
  },
];

export const TabsModule: FC<TabsModuleProps> = ({ currentTab, onChange }) => {
  return (
    <TabsCore
      items={TAB_ITEMS_MODULE}
      currentTab={currentTab}
      onChange={onChange}
      styleConfig={styleConfig}
      activeTabMarkerDimensionCoefficient={0.8}
    />
  );
};
