import { $isLoading, getUploadUrlFx, uploadFileFx } from '@shared/stores/fus.store';
import { useEvent, useStore } from 'effector-react';
import { FuseResponse, UseFusProps } from '@shared/types/fus.typedef';

/**
 * Хук для загрузки файлов на фус
 * @param purpose - назначение загружаемого файла
 */
export const useFus = ({ purpose }: UseFusProps) => {
  const getUploadUrl = useEvent(getUploadUrlFx);
  const uploadFileRequest = useEvent(uploadFileFx);

  const isLoading = useStore($isLoading);

  const uploadFile = async (file: Blob): Promise<FuseResponse> => {
    const { url } = await getUploadUrl({ purpose });
    const isProtocolIncluded = url.includes('https://');
    return uploadFileRequest({ url: `${isProtocolIncluded ? '' : 'https://'}${url}`, file });
  };
  return { isLoading, uploadFile };
};
