import { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useEvent, useStore } from 'effector-react';
import { $ModuleInfo, getModuleInfoFx, resetModuleEvent } from '@modules/module/stores/module-info.store';
import { ExerciseService } from '@shared/api/client/services/Exercises';
import { ENTITY_IDS, NAVIGATION } from '@shared/constants';
import { handleError } from '@shared/handle-error/handleError';
import { showSuccessModalEvent } from '@ui-kit/Modal/ModalResult/ModalResult.store';

export const useModuleInfo = () => {
  const { moduleId, courseId } = useParams();
  const navigate = useNavigate();

  const moduleInfo = useStore($ModuleInfo);
  const getModuleInfo = useEvent(getModuleInfoFx);

  const handleRedirectToNextCourse = async () => {
    if (!courseId || !moduleId) return;

    try {
      const {
        data: { data },
      } = await ExerciseService.getCurrentNotPassedExerciseInSoloCourseModule({ id: moduleId });

      //Если все упражнения в блоке пройдены => показываем модалку
      if (data === null) {
        showSuccessModalEvent({
          description: 'Все занятия в блоке пройдены',
        });
        //Если не все управждения в блоке пройдены =>  редиректим на след блок
      } else {
        const { exerciseId: nextExerciseId } = data;

        const nextUserExerciseUrl = NAVIGATION.studentUserCourseExercise
          .replace(`:${ENTITY_IDS.courseId}`, courseId)
          .replace(`:${ENTITY_IDS.moduleId}`, moduleId)
          .replace(`:${ENTITY_IDS.exerciseId}`, nextExerciseId);

        navigate(nextUserExerciseUrl);
      }
    } catch (error) {
      handleError(error);
    }
  };

  useEffect(() => {
    if (!moduleId) return;
    getModuleInfo({
      id: moduleId,
    });

    return () => {
      resetModuleEvent();
    };
  }, []);

  return { moduleInfo, handleRedirectToNextCourse };
};
