/** Длина номера телефона в системе без маски */
export const PHONE_NUMBER_WITHOUT_MASK_LEN = 11;

export const GENDER_SELECT_ITEMS = [
  { id: 'male', name: 'male', title: 'Мужчина' },
  { id: 'female', name: 'female', title: 'Женщина' },
];

/**
 * Константа определяет дельту перехода по стеку истории браузера
 */
export const DELTA_STACK_HISTORY = 1;

export * from './navigation';

export const KEEP_AS_IS = 'keep_as_is';
