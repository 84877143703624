import { createEffect, createEvent, createStore, sample } from 'effector';
import { handleError } from '@shared/handle-error/handleError';
import { ApiThrownError } from '@shared/api/client/responses/ApiErrorFactory';
import { ModulesViewModel } from '../types/modules.types';
import { SoloCourseModuleService } from '@shared/api/client/services/SoloCourseModule';
import {
  GetModulesListRequestParams,
  modulesListResponseDataToViewModelMappers,
  modulesListViewModelToRequestDataMappers,
} from '../mappers/modules-list.mapper';
import { CourseId } from '@shared/types/course.types';

type ModulesListStore = ModulesViewModel;
type OffsetStore = number;

/**
 * Объявление сторов
 */

export const defaultModulesListStore: ModulesListStore = {
  items: [],
  itemsAmount: 0,
};
export const defaultOffsetStore: OffsetStore = 0;

/**
 * Модель отступа при пагинации по модулям
 *
 *
 */
export const $Offset = createStore<OffsetStore>(defaultOffsetStore);
export const changeOffset = createEvent<number>();
export const resetOffset = createEvent();

export const getModulesListFx = createEffect<GetModulesListRequestParams, ModulesViewModel, ApiThrownError>(
  async (payload) => {
    const requestPayload = modulesListViewModelToRequestDataMappers.getModulesList(payload);

    const {
      data: { data },
    } = await SoloCourseModuleService.getListSoloCourseModules(requestPayload);

    return modulesListResponseDataToViewModelMappers.getModulesList(data);
  },
);
export const getModules = createEvent<CourseId>();
export const resetModulesList = createEvent();
export const $ModulesList = createStore<ModulesListStore>(defaultModulesListStore);

/**
 * Бизнес логика листинга модулей
 */

$ModulesList
  .on(resetModulesList, () => defaultModulesListStore)
  .on(getModulesListFx.doneData, (currentStore, modulesList) => ({
    itemsAmount: modulesList.itemsAmount,
    items: [...currentStore.items, ...modulesList.items],
  }))
  .on(getModulesListFx.failData, (currentStore, error) => {
    handleError(error);
  });
$Offset.on(changeOffset, (_, value) => value).on(resetOffset, () => defaultOffsetStore);

sample({
  clock: getModules,
  source: $Offset,
  fn: (offset, courseId) => ({ offset, courseId }),
  target: getModulesListFx,
});
