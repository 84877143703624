import styles from './CertificateCard.module.css';
import React, { FC } from 'react';
import { ReactComponent as DiplomaSvg } from '@svg/outline/diploma.svg';
import { ReactComponent as CalendarSvg } from '@svg/outline/calendar.svg';
import { UserCertificateViewModel } from '@modules/user-certificate/types/user-certificate.types';
import { ReactComponent as DownloadSvg } from '@svg/outline/download.svg';
type CertificateCardProps = UserCertificateViewModel;

export const CertificateCard: FC<CertificateCardProps> = ({ certificate, soloCourse }) => {
  const { dateOfIssue, pdf } = certificate;
  const { cover, title } = soloCourse;

  return (
    <li className={styles['diploma']}>
      <div className={'w-full mb-[15px] relative'}>
        <img src={cover} className={'w-full aspect-video object-cover rounded-[15px]'} alt={'Обложка диплома'} />
        <div className={styles['badge']}>
          <DiplomaSvg width={60} height={60} className={styles['diploma-svg']} />
        </div>
      </div>
      <p className={'text-14-regular mb-[10px] m:text-18-regular'}>Сертификат о прохождении курса</p>
      <h5 className={'mb-[35px] text-l m:text-xl'}>{title}</h5>
      <div className="flex justify-between">
        <div className="flex gap-x-[10px]">
          <CalendarSvg className="text-grey-50" />
          <span className="text-18-regular">{dateOfIssue}</span>
        </div>
        {!pdf ? (
          <p className={'text-14-regular flex items-center'}>Генерация файла...</p>
        ) : (
          <a href={pdf?.url}>
            <DownloadSvg className={'fill-blue-800'} />
          </a>
        )}
      </div>
    </li>
  );
};
