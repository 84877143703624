import styles from './TabsProgramsComponent.module.css';
import { TabsCore } from '@shared/ui-kit/Tabs/TabsCore.component';
import React, { FC } from 'react';
import { StyleConfig, TasProgramsProps } from '@shared/ui-kit/Tabs/Tabs.types';

type ProgramsCounterProps = {
  amount: number;
  isActive: boolean;
};

const styleConfig: StyleConfig = {
  wrapper: styles['tabs-programs'],
  activeTabMarker: styles['active-tab-marker'],
  tab: styles['tab'],
  tab_active: styles['tab_active'],
  list: styles['list'],
};

const ProgramsCounter: FC<ProgramsCounterProps> = ({ amount, isActive }) => (
  <div className={[styles['counter'], isActive && styles['counter_active']].join(' ')}>
    <span>{amount}</span>
  </div>
);

export const TabsPrograms: FC<TasProgramsProps> = ({
  allProgramsAmount,
  activeProgramsAmount,
  passedProgramsAmount,
  currentTab,
  onChange,
}) => {
  const TAB_ITEMS_PROGRAMS = [
    {
      id: 'all',
      title: 'Все',
      icon: <ProgramsCounter amount={allProgramsAmount} isActive={currentTab === 'all'} />,
    },
    {
      id: 'active',
      title: 'Активные',
      icon: <ProgramsCounter amount={activeProgramsAmount} isActive={currentTab === 'active'} />,
    },
    {
      id: 'passed',
      title: 'Пройденные',
      icon: <ProgramsCounter amount={passedProgramsAmount} isActive={currentTab === 'passed'} />,
    },
  ];

  return (
    <div className={'overflow-hidden px-[20px] inline-block'}>
      <TabsCore items={TAB_ITEMS_PROGRAMS} currentTab={currentTab} onChange={onChange} styleConfig={styleConfig} />
    </div>
  );
};
